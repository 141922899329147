import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { PATH_AUTH, PROFILE_PAGE } from "src/constants";
import { useCurrentAccount, useHasSeveralAccounts } from "src/hooks";
import { useAuthStore } from "src/stores/auth-store";

import { MainLayoutStructure } from "./_components";

type Props = {
  children: React.ReactNode;
};

export const MainLayout = ({ children }: Props) => {
  const navigate = useNavigate();
  const logout = useAuthStore((state) => state.logout);

  const currentAccount = useCurrentAccount();
  const hasSeveralAccounts = useHasSeveralAccounts();

  const toProfile = useCallback(() => {
    navigate(PROFILE_PAGE);
  }, []);
  const toAuth = useCallback(() => {
    navigate(PATH_AUTH);
  }, []);

  return (
    <MainLayoutStructure
      onLogout={logout}
      toProfile={toProfile}
      toAuth={toAuth}
      customer={currentAccount.customer}
      account={currentAccount}
      hasSeveralAccounts={hasSeveralAccounts}
    >
      {children}
    </MainLayoutStructure>
  );
};
