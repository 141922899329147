import React from "react";

import { TicketActivityFragment, TicketMessageFragment } from "src/graphql/generated/types";
import { useOpenDocument, useDeviceWidth } from "src/hooks";
import { formatDate } from "src/utils";

import { getMessagesWithActivities } from "../../_utils";
import { ActivityInfo } from "../ActivityInfo";
import { HeaderDescription } from "./_components/HeaderDescription";
import * as S from "./styles";

type Props = {
  messages: Array<TicketMessageFragment>;
  activities: Array<TicketActivityFragment>;
};

export const TicketContent = ({ messages, activities }: Props) => {
  const { openDocument, openDocumentLoading } = useOpenDocument();
  const { isMobile } = useDeviceWidth();

  const messagesWithActivities = getMessagesWithActivities(messages, activities);

  return (
    <>
      {messagesWithActivities.map((item, index) => {
        const isAssociate = item.__typename === "TicketMessage" && !!item.associate;
        const hasDocuments = item.__typename === "TicketMessage" && !!item.documents?.length;

        if (item.__typename === "TicketMessage") {
          return (
            <S.Container $isAssociate={isAssociate} key={index}>
              <S.Header>
                <S.FirstTitle>
                  {isAssociate ? <S.AccountIcon /> : <S.MessageIcon />}
                  <S.Margin />
                  <HeaderDescription associate={item.associate} />
                </S.FirstTitle>
                <S.SecondTitle>
                  {!isMobile && (
                    <S.Typography>
                      {isAssociate ? "Réponse reçue le " : "Demande déposée le "}
                      {formatDate(item.createdAt, { pattern: "DATE_TIME" })}
                    </S.Typography>
                  )}
                </S.SecondTitle>
              </S.Header>

              <S.Content>
                <S.Typography sx={{ color: "gray.dark", p: 2, fontWeight: 500 }}>
                  {item.content}
                </S.Typography>
              </S.Content>

              {item.documents && hasDocuments && (
                <S.DocumentContainer>
                  <S.Typography sx={{ fontWeight: "bold" }}>Pièces jointes</S.Typography>
                  <S.DocumentList>
                    {item.documents.map(({ originalFilename, hashFile }) => (
                      <S.Document
                        key={hashFile}
                        startIcon={<S.DescriptionIcon />}
                        loading={openDocumentLoading}
                        onClick={() => openDocument(hashFile)}
                      >
                        {originalFilename}
                      </S.Document>
                    ))}
                  </S.DocumentList>
                </S.DocumentContainer>
              )}

              {isMobile && (
                <S.MobileDateContainer>
                  <S.Typography>
                    {formatDate(item.createdAt, { pattern: "DATE_TIME" })}
                  </S.Typography>
                </S.MobileDateContainer>
              )}
            </S.Container>
          );
        }
        if (item.__typename === "TicketActivity")
          return <ActivityInfo key={index} activityInfo={item} />;

        return null;
      })}
    </>
  );
};
