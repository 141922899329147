import { SxProps } from "@mui/material";

export const activityContainer: SxProps = {
  mx: 10,
  my: 2,
  p: 1,
  display: "flex",
  alignItems: "center",
  color: "gray.blueGray",
  borderBottomStyle: "solid",
  borderTopStyle: "solid",
  borderBottomColor: "gray.deep",
  borderTopColor: "gray.deep",
};

export const iconStyle: SxProps = {
  mr: 2,
};

export const newCategoryStyle = {
  fontWeight: "bold",
};
