import * as Sentry from "@sentry/react";
import React, { Component } from "react";

type ErrorBoundaryProps = {
  fallbackComponent: React.ComponentType;
  children: React.ReactNode;
};

type ErrorBoundaryState = { hasError: boolean };

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error = {}) {
    Sentry.captureException(error, {
      tags: {
        componentDidCatch: "ErrorBoundary",
      },
    });
  }

  render() {
    const { children, fallbackComponent: FallbackComponent } = this.props;
    const { hasError } = this.state;

    if (!hasError) {
      return children;
    }

    return <FallbackComponent />;
  }
}

export default ErrorBoundary;
