import { Box, BoxProps } from "@mui/material";
import { rem } from "polished";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import lazyWithRetry from "src/containers/LazyWithRetry";
import { SortDirectionEnum, PageInfoFragment, SortByType } from "src/graphql/generated/types";
import { useDeviceWidth } from "src/hooks";
import { mobileStyle } from "src/utils";

import { Card } from "../Card";
import { ListDefaultHeader } from "./_parts/DefaultHeader";
import { ListDefaultRow } from "./_parts/DefaultRow";
import { ListFetchMore } from "./_parts/FetchMore";
import { ListPaginator } from "./_parts/Paginator";
import { ListTable } from "./_parts/Table";

const ResponsiveList = lazyWithRetry(() => import("./_parts/ListResponsive"));

export type ListSingleColumnConfig<T, U> = {
  type?: "selection" | "expand"; // Special for component internal need
  label: string | React.ReactElement;
  width?: number;
  headerAlign?: "left" | "center" | "right";
  rowAlign?: "left" | "center" | "right";
  render: (data: T, extraProps: U) => any;
  // better
  sortableKey?: string;
};

const selectionColumn: ListSingleColumnConfig<any, any> = {
  type: "selection",
  label: "", // Overide by _type
  width: 5,
  headerAlign: "center",
  rowAlign: "center",
  render: () => null, // Overide by _type
};

const expandColumn: ListSingleColumnConfig<any, any> = {
  type: "expand",
  label: "", // Overide by _type
  width: 5,
  headerAlign: "center",
  rowAlign: "center",
  render: () => null, // Overide by _type
};

export type ListColumnsConfig<T, U = any> = Array<ListSingleColumnConfig<T, U>>;

type Props<T, U> = {
  columns: ListColumnsConfig<T, U>;
  data: Array<T>;
  dataType?: string;
  dataKey?: string;
  extraProps?: U;
  options?: React.ReactElement;
  onRowClick?: (data: T) => void;
  emptyListText?: string;
  canFetchMore?: boolean; // @note: use for infinite pagination
  isFetchingNextPage?: boolean;
  onFetchMore?: () => void; // @note: use for infinite pagination
  expandComponent?: FC<{ data: T; extraProps: U }>;
  maxSelectedItems?: number;
  onSelectedItemsChange?: (selectedItems: Array<T>) => void;
  settingVariables?: boolean;
  paginationInfo?: PageInfoFragment; // @note: use for basic pagination
  onPageChange?: (newPageNumber: number) => void; // @note: use for basic pagination
  ResponsiveView?: FC<T>;
  // Sorting
  activeSort?: SortByType;
  onSort?: (newSort: SortByType) => void;
  defaultSortDirection?: SortDirectionEnum; // Default : ASC
} & BoxProps;

const StyledRoot = styled(Box)`
  position: relative;
`;

const StyledSettingVariablesOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 110;
`;

const StyledEmptyListText = styled(Card)`
  text-align: center;
  ${mobileStyle`
    font-size: ${rem(18)};
    height: ${rem(50)};
  `}
`;

export function List<T extends { [key: string]: any }, U = undefined>({
  data,
  dataType,
  dataKey = "id",
  columns,
  extraProps,
  options,
  onRowClick,
  emptyListText = "Aucune donnée",
  canFetchMore,
  isFetchingNextPage,
  onFetchMore,
  expandComponent,
  maxSelectedItems = 5,
  onSelectedItemsChange,
  settingVariables = false,
  paginationInfo,
  onPageChange,
  ResponsiveView,
  // Sorting
  activeSort,
  onSort,
  defaultSortDirection = SortDirectionEnum.Asc,
  // Rest props
  ...boxProps
}: Props<T, U>): React.ReactElement {
  const [selectedItems, setSelectedItems] = useState<{
    [key: string]: { isSelected: boolean };
  }>({});
  const { isMobile } = useDeviceWidth();

  const selectedCount = useMemo(
    () => Object.values(selectedItems).filter((value) => value.isSelected).length,
    [selectedItems]
  );

  const toggleAll = useCallback(
    (checked: boolean) => {
      if (!checked) {
        setSelectedItems({});
      } else {
        const newState = data.reduce((acc, d) => {
          acc[d[dataKey]] = {
            isSelected: true,
          };
          return acc;
        }, {} as { [key: string]: { isSelected: boolean } });
        setSelectedItems(newState);
      }
    },
    [data, dataKey]
  );

  const toggleRow = useCallback(
    (rawData: T, checked: boolean) => {
      setSelectedItems((currentState) => {
        const key = rawData[dataKey];
        return {
          ...currentState,
          [key]: {
            isSelected: checked,
            data: rawData,
          },
        };
      });
    },
    [dataKey]
  );

  useEffect(() => {
    if (onSelectedItemsChange) {
      onSelectedItemsChange(data.filter((d) => selectedItems[d[dataKey]]?.isSelected));
    }
  }, [data, selectedItems, onSelectedItemsChange]);

  const finalColumnsConfig = useMemo(() => {
    if (onSelectedItemsChange) {
      return [selectionColumn, ...columns];
    }
    if (expandComponent) {
      return [expandColumn, ...columns];
    }
    return columns;
  }, [columns, onSelectedItemsChange, expandComponent]);

  return (
    <StyledRoot {...boxProps}>
      {settingVariables && <StyledSettingVariablesOverlay />}
      {(!isMobile || !ResponsiveView) && (
        <ListTable>
          <thead>
            <ListDefaultHeader
              isMaxSelectedReached={data.length > maxSelectedItems}
              columnsConfig={finalColumnsConfig}
              toggleAll={toggleAll}
              areAllRowSelected={data.length === selectedCount}
              activeSort={activeSort}
              onSort={onSort}
              defaultSortDirection={defaultSortDirection}
            />
            {options}
          </thead>
          <tbody>
            {data.map((value) => {
              const rowKey = value[dataKey];
              return (
                <ListDefaultRow
                  key={rowKey}
                  data={value}
                  dataType={dataType}
                  columnsConfig={finalColumnsConfig}
                  isSelected={Boolean(selectedItems[rowKey]?.isSelected)}
                  isMaxSelectedReached={selectedCount >= maxSelectedItems}
                  onToggle={toggleRow}
                  onRowClick={onRowClick}
                  extraProps={extraProps}
                  expandComponent={expandComponent}
                />
              );
            })}
          </tbody>
        </ListTable>
      )}
      {isMobile === true && ResponsiveView && (
        <ResponsiveList>
          <table>
            <thead>{options}</thead>
          </table>
          {data.map((value) => {
            const rowKey = value[dataKey];
            return (
              <ResponsiveView
                key={rowKey}
                onClick={() => onRowClick && onRowClick(value)}
                {...value}
              />
            );
          })}
        </ResponsiveList>
      )}

      {!data.length && <StyledEmptyListText>{emptyListText}</StyledEmptyListText>}

      {canFetchMore && onFetchMore && (
        <ListFetchMore
          data-cy="list-fetch-more"
          onClick={onFetchMore}
          isLoading={isFetchingNextPage}
        />
      )}

      {paginationInfo && onPageChange && (
        <ListPaginator
          data-cy="list-paginator"
          onPageChange={onPageChange}
          pageInfo={paginationInfo}
        />
      )}
    </StyledRoot>
  );
}
