import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";

import {
  ConfidentialityPartnersOffersInformation,
  ConfidentialityPersonalData,
  Modal,
  ModalFooter,
} from "src/components";
import { ConfidentialityUrl } from "src/constants";

export interface ConfidentialityInputs {
  personalData: boolean;
  partnersOffersInformation: boolean;
}

const InlineLink = styled<any>(Button)`
  font-size: 14px;
  display: inline;
`;

interface Props {
  open: boolean;
  loading: boolean;
  onSubmit(data: ConfidentialityInputs): void;
}

export const ConfidentialityModal = ({ open, loading, onSubmit }: Props) => {
  const { control, handleSubmit } = useForm<ConfidentialityInputs>({
    defaultValues: {
      personalData: false,
      partnersOffersInformation: false,
    },
  });

  return (
    <Modal
      data-cy="mandatory-consent-modal"
      hideCloseButton
      disableBackdropClick
      disableEscapeKeyDown
      icon="confidentiality"
      title="Confidentialité"
      open={open}
      handleClose={handleSubmit(onSubmit)}
    >
      <Box sx={{ textAlign: "center" }}>
        <Typography>
          <b> Bienvenue sur votre espace client MyFoncia </b>
        </Typography>
      </Box>

      <Box sx={{ mt: 2, textAlign: "center" }}>
        <Typography>
          Foncia traite vos données personnelles dans le cadre de l’exécution de sa prestation de
          service vous concernant et pour vous permettre d’accéder à votre compte afin de faciliter
          les échanges et l’accès à vos informations.
        </Typography>
      </Box>

      <Box sx={{ mt: 2, textAlign: "center" }}>
        <Typography>
          Pour en savoir plus sur la gestion de vos données personnelles et sur vos droits,
          consultez
          <InlineLink
            href={ConfidentialityUrl}
            color="secondary"
            size="small"
            target="_blank"
            rel="noopener"
          >
            notre politique de protection des données.
          </InlineLink>
        </Typography>
      </Box>

      <Box sx={{ textAlign: "center" }}>
        <Typography>
          Si vous souhaitez recevoir des communications et des offres adaptées à vos besoins, il
          vous suffit de cocher les cases ci-dessous. Cette étape n’est pas un prérequis pour
          accéder à votre espace.
        </Typography>
      </Box>

      <Box sx={{ mt: 2 }}>
        <FormGroup row>
          <FormControlLabel
            control={
              <Controller
                name="personalData"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Checkbox
                    data-cy="mandatory-consent-personal-data-checkbox"
                    checked={value}
                    onChange={(_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                      onChange(checked)
                    }
                  />
                )}
              />
            }
            label={<ConfidentialityPersonalData />}
          />
        </FormGroup>
        <FormGroup row>
          <FormControlLabel
            control={
              <Controller
                name="partnersOffersInformation"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Checkbox
                    checked={value}
                    onChange={(_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                      onChange(checked)
                    }
                  />
                )}
              />
            }
            label={<ConfidentialityPartnersOffersInformation />}
          />
        </FormGroup>
      </Box>

      <Box sx={{ mt: 2, textAlign: "center" }}>
        <Typography>
          À tout moment, vous pouvez ajuster vos préférences marketing au niveau de votre profil ou
          vous adresser à notre service DPO :
          <Button
            href="mailto:dpo@foncia.com"
            sx={{ fontSize: "14px", display: "inline !important" }}
            color="secondary"
            size="small"
            target="_blank"
            rel="noopener"
          >
            dpo@foncia.com
          </Button>
        </Typography>
      </Box>

      <ModalFooter>
        <LoadingButton
          data-cy="mandatory-consent-submit-button"
          type="submit"
          loading={loading}
          variant="contained"
          onClick={handleSubmit(onSubmit)}
        >
          Me connecter
        </LoadingButton>
      </ModalFooter>
    </Modal>
  );
};
