import { Box, Button, Typography } from "@mui/material";
import { rem } from "polished";
import React from "react";
import styled from "styled-components";

import { Modal, ModalFooter } from "src/components";
import { useDeviceWidth } from "src/hooks";
import { mobileStyle } from "src/utils";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  isCoOwner: boolean | undefined;
  isDebtor: boolean | undefined;
};

const StyledBox = styled(Box)`
  padding: ${({ theme }) => theme.spacing(2)};

  ${mobileStyle`
    font-size: ${rem(13)};
    line-height: ${rem(21)};
    color: ${({ theme }) => theme.palette.secondary.main};
    padding-top: 0;
  `}
`;

const StyledTypography = styled(Typography)`
  ${mobileStyle`
    font-size: ${rem(13)};
    line-height: ${rem(21)};
    color: ${({ theme }) => theme.palette.secondary.main};
  `}
`;

export const PaymentAutomaticSuccessModal = ({ isOpen, onClose, isCoOwner, isDebtor }: Props) => {
  const { isMobile } = useDeviceWidth();

  return (
    <Modal
      icon="automaticPayment"
      handleClose={onClose}
      open={isOpen}
      title="Merci"
      data-cy="automatic-success-modal"
      hideCloseButton={isMobile}
    >
      <StyledBox>
        <StyledTypography align="center">
          Votre souscription au prélèvement automatique a été enregistrée. Le prélèvement
          automatique sera effectif à compter{" "}
          {isCoOwner ? "du prochain appel de fonds" : "de la prochaine échéance"}.
        </StyledTypography>
        {isDebtor && (
          <Box sx={{ mt: 4 }}>
            <StyledTypography align="center">
              Pour le règlement de l'échéance en cours, nous vous remercions d'effectuer un
              <b> E-paiement à la demande</b> dans la rubrique mes paiements.
            </StyledTypography>
          </Box>
        )}

        {isMobile && (
          <ModalFooter>
            <Button size="large" fullWidth variant="contained" color="primary" onClick={onClose}>
              Fermer
            </Button>
          </ModalFooter>
        )}
      </StyledBox>
    </Modal>
  );
};
