import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";

import { logScreenName } from "src/utils";

interface Props {
  title: string;
}

export const MetaTags = ({ title }: Props) => {
  useEffect(() => logScreenName(title), [title]);

  return (
    <Helmet titleTemplate="MyFoncia | %s">
      <html lang="fr" />
      <title>{title}</title>
      <meta
        name="description"
        content={
          "Tout l'immobilier Foncia : annonces en achat immobilier et location de nos 550 agences, estimation et vente immobilière, gestion locative de votre bien, syndic de copropriété, location de vacances, investissement, espace client MyFoncia, déménagement, ..."
        }
      />
    </Helmet>
  );
};
