import { Menu, MenuProps } from "@mui/material";
import { rem } from "polished";
import React from "react";
import styled from "styled-components";

const StyledMenu = styled(Menu)`
  .MuiMenu-paper {
    margin-top: ${rem(10)};
  }
  .MuiList-padding {
    padding: 0;
  }
`;

export const HeaderMenu = ({ children, ...props }: MenuProps) => {
  return (
    <StyledMenu
      keepMounted
      elevation={8}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    >
      {children}
    </StyledMenu>
  );
};
