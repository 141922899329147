import AdapterDateFns from "@date-io/date-fns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Locale, format } from "date-fns";
import { fr } from "date-fns/locale";
import React from "react";

class FrLocalizedUtils extends AdapterDateFns {
  getDatePickerHeaderText(date: number | Date) {
    return format(date, "d MMM yyyy", { locale: (this.locale as unknown) as Locale });
  }
}

const DatePickerProvider = ({ children }: { children?: React.ReactNode }) => {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={fr}
      dateLibInstance={FrLocalizedUtils}
    >
      {children}
    </LocalizationProvider>
  );
};

export default DatePickerProvider;
