import { useCurrentAccount } from "./useCurrentAccount";

/*
This hook should only be used when an account is selected
*/
export const useHasBlockedPayment = () => {
  const account = useCurrentAccount();

  // @note : Co-ownership mandate is lost
  if (account.__typename === "CoownerAccount" && !account.hasActiveCoOwnershipMandate) {
    return true;
  }

  // @note : Landlords doesn't pay
  if (account.__typename === "LandlordAccount") {
    return true;
  }

  return false;
};
