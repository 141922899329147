import BigNumber from "bignumber.js";
import numeral from "numeral";

import { theme } from "src/constants";
import { Currency } from "src/graphql-next/generated/types";
import { AmountFragment, CurrencyEnum } from "src/graphql/generated/types";
import i18n from "src/i18n";

export const formatAmount = (
  {
    value,
    currency,
  }: {
    value: number;
    currency: Currency | CurrencyEnum;
  },
  options?: { maximumFractionDigits?: number }
): string | null => {
  if (!Number.isInteger(value)) return null;

  return new Intl.NumberFormat(i18n.language, {
    style: "currency",
    currency,
    maximumFractionDigits: options?.maximumFractionDigits,
  }).format(value / 100);
};

export const formatAmountInput = (amount: string, options?: { maximumFractionDigits?: number }) => {
  const locale = i18n.language;
  const maximumFractionDigits = options?.maximumFractionDigits ?? 2;

  const defaultDecimalSeparator = ".";
  const localDecimalSeparator = new Intl.NumberFormat(locale)
    .formatToParts(1.1)
    .find((part) => part.type === "decimal")?.value;

  // Find the first occurrence of decimalSeparator or defaultDecimalSeparator from the end
  const lastSeparatorIndex = Math.max(
    localDecimalSeparator ? amount.lastIndexOf(localDecimalSeparator) : -1,
    amount.lastIndexOf(defaultDecimalSeparator)
  );

  // Determine the separator, defaulting to defaultDecimalSeparator if none is found
  const decimalSeparator =
    lastSeparatorIndex !== -1 ? amount[lastSeparatorIndex] : defaultDecimalSeparator;

  const sanitizedAmount = amount.replace(new RegExp(`[^0-9\\${decimalSeparator}]`, "g"), "");

  // Determine the number of digits after the decimal separator
  const decimalIndex = sanitizedAmount.lastIndexOf(decimalSeparator);
  const digitsAfterSeparator = decimalIndex !== -1 ? sanitizedAmount.length - decimalIndex - 1 : 0;

  // Normalize to default decimal separator for parseFloat
  const normalizedAmount = sanitizedAmount.replace(
    new RegExp(`\\${decimalSeparator}`, "g"),
    defaultDecimalSeparator
  );

  const value = parseFloat(normalizedAmount);

  if (Number.isNaN(value)) return "";

  // Avoid floating point errors by truncating safely
  const factor = 10 ** maximumFractionDigits;
  const flooredValue = Math.floor(BigNumber(value).multipliedBy(factor).toNumber());
  const truncatedValue = BigNumber(flooredValue).dividedBy(factor).toNumber();

  const formattedValue = truncatedValue.toLocaleString(locale, {
    minimumFractionDigits: Math.min(digitsAfterSeparator, maximumFractionDigits),
    maximumFractionDigits: options?.maximumFractionDigits,
  });

  // If user is still typing after decimal
  const endsWithSeparator = sanitizedAmount.endsWith(decimalSeparator);
  if (endsWithSeparator) {
    return `${formattedValue}${localDecimalSeparator ?? defaultDecimalSeparator}`;
  }

  return formattedValue;
};

export const formatAmountCSV = (
  amount: Omit<AmountFragment, "__typename"> & { __typename: "Amount" | "Debit" | "Credit" }
) => {
  const value = amount.__typename === "Credit" ? -amount.value : amount.value;
  return `"${numeral(value).divide(100).format("0,0.00").replace(/\s/g, "")}"`;
};

export const getAmountColor = (amount: number) => {
  if (amount === 0) return undefined;
  return amount > 0 ? theme.palette.amountColor.positive : theme.palette.amountColor.negative;
};

export const amountInCents = (rawAmount: string | number | undefined) =>
  numeral(rawAmount).value() ? Math.round(numeral(rawAmount).multiply(100).value() || 0) : 0;
