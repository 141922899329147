import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion as MuiAccordion,
  AccordionProps,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import { rem } from "polished";
import React from "react";
import styled from "styled-components";

const StyledAccordionSummary = styled(AccordionSummary)`
  background-color: ${(props) => props.theme.palette.primary.main};
  font-size: ${rem(16)};
  font-weight: bold;
  div {
    color: ${(props) => props.theme.palette.white.main};
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  background-color: ${(props) => props.theme.palette.background.default};
  padding: ${(props) => props.theme.spacing(4)};
`;

interface Props extends AccordionProps {
  title: string;
}

export const Accordion = ({ title, children, ...accordionProps }: Props) => {
  return (
    <MuiAccordion {...accordionProps}>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
        {title}
      </StyledAccordionSummary>
      <StyledAccordionDetails>{children}</StyledAccordionDetails>
    </MuiAccordion>
  );
};
