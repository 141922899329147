import { Box, Button, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";

import Fusee from "src/assets/images/fusee.svg";
import { LogoMyFonciaInlineSvg } from "src/components/LogoMyFoncia";

const ErrorHeader = styled(Box)`
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.palette.blue.main};
`;

const HeaderContainer = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
`;

const BodyContainer = styled(Box)`
  max-width: 800px;
  margin: auto;
  min-height: 100%;
  flex-direction: column;
`;

const BackgroundImgContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: ${(props) => props.theme.spacing(-10)};
`;

const RefreshButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.main};
  background-color: white;
  margin-top: 100px;
  &:hover {
    background-color: #b7e0ea;
  }
`;

export const ErrorHappened = () => (
  <Box sx={{ backgroundColor: "primary.main", height: "100vh", overflowY: "hidden" }}>
    <ErrorHeader>
      <HeaderContainer>
        <LogoMyFonciaInlineSvg />
      </HeaderContainer>
    </ErrorHeader>

    <BodyContainer>
      <Box sx={{ mt: 10, mx: 2 }}>
        <Typography variant="h4" sx={{ color: "white.main" }}>
          Une erreur est survenue...
        </Typography>
        <Typography variant="h5" sx={{ color: "white.main" }}>
          Nos équipes sont mobilisées pour corriger le problème,
        </Typography>
        <Typography variant="h5" sx={{ color: "white.main" }}>
          merci de revenir ultérieurement
        </Typography>

        <RefreshButton
          variant="contained"
          color="primary"
          size="large"
          onClick={() => window.location.reload()}
        >
          Rafraîchir
        </RefreshButton>
      </Box>
      <BackgroundImgContainer>
        <img src={Fusee} alt="Fusee" height={500} />
      </BackgroundImgContainer>
    </BodyContainer>
  </Box>
);
