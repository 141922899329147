import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { SnackbarKey, SnackbarProvider as NotistackProvider } from "notistack";
import React, { createRef, useCallback } from "react";

export const SnackbarProvider = ({ children }: { children: React.ReactNode }) => {
  const notistackRef = createRef<NotistackProvider>();
  const onClickDismiss = useCallback(
    (key: SnackbarKey) => () => {
      if (!notistackRef.current) {
        return;
      }

      notistackRef.current.closeSnackbar(key);
    },
    []
  );

  const action = useCallback((key: SnackbarKey) => {
    return (
      <IconButton aria-label="close" onClick={onClickDismiss(key)}>
        <CloseIcon />
      </IconButton>
    );
  }, []);

  return (
    <NotistackProvider
      ref={notistackRef}
      preventDuplicate
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      action={action}
    >
      {children}
    </NotistackProvider>
  );
};
