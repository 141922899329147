import { Checkbox } from "@mui/material";
import React from "react";

type Props = {
  isMaxSelectedReached: boolean;
  checked: boolean;
  onChange: (checked: boolean) => void;
};

export const ListSelectionCheckbox = ({ isMaxSelectedReached, checked, onChange }: Props) => {
  return (
    <Checkbox
      size="small"
      disabled={isMaxSelectedReached && !checked}
      checked={checked}
      onChange={(e) => {
        onChange(e.target.checked);
      }}
    />
  );
};
