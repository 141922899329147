import React, { createContext, useCallback, useMemo, useState } from "react";

import EReco from "src/pages/_shared/EReco";

interface Props {
  openEReco(): void;
}

export const ERecoContext = createContext<Props>({
  openEReco: () => ({}),
});

export const ERecoProvider = ({ children }: { children: React.ReactNode }) => {
  const [open, setOpen] = useState(false);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <ERecoContext.Provider
      value={useMemo<Props>(
        () => ({
          openEReco: () => setOpen(true),
        }),
        []
      )}
    >
      {children}
      <EReco open={open} onClose={onClose} />
    </ERecoContext.Provider>
  );
};
