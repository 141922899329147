import styled from "styled-components";

export const ListTable = styled.table`
  width: 100%;
  background: ${(props) => props.theme.palette.white.main};
  border-collapse: collapse;
  table-layout: fixed;

  thead tr:not(:first-child) {
    background: #dce5ee;

    td {
      padding: ${(props) => props.theme.spacing(1)};
    }
  }
`;
