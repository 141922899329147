import { IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";

import { useGetDocumentUrlQuery } from "src/graphql/generated/queries";

import { Icon } from "../../Icon";

type Props = {
  hash: string;
};

export const ExpenseDocument = ({ hash }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const { refetch: getInvoiceUrl } = useGetDocumentUrlQuery(
    {
      hash,
    },
    {
      onSettled: (data) => {
        if (!data?.documentURL) {
          enqueueSnackbar("Erreur lors de la récupération de la facture", {
            variant: "error",
          });
          return;
        }

        window.open(data?.documentURL, "_blank");
      },
      enabled: false,
    }
  );

  return (
    <IconButton aria-label="download" onClick={() => getInvoiceUrl()}>
      <Icon name="document" />
    </IconButton>
  );
};
