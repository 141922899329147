import React from "react";

import { theme } from "src/constants";

type Props = {
  className?: string;
  width?: string | number;
  height?: string | number;
  primaryColor?: string;
  secondaryColor?: string;
};

export const LogoMyFonciaInlineSvg = ({
  className,
  width = "150px",
  height = "21px",
  primaryColor = theme.palette.secondary.main,
  secondaryColor = theme.palette.textLogo.secondary,
}: Props) => (
  <svg width={width} height={height} viewBox="0 0 180 24" version="1.1" className={className}>
    <title>logo-my-foncia</title>
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      fontFamily="Montserrat"
      fontSize="31.5"
      fontWeight="bold"
    >
      <g id="logo-my-foncia" transform="translate(-2.000000, -9.000000)">
        <text id="MY" fill={primaryColor}>
          <tspan x="0.2420378" y="32">
            MY
          </tspan>
        </text>
        <text id="FONCIA" fill={secondaryColor}>
          <tspan x="52.0700382" y="32">
            FONCIA
          </tspan>
        </text>
      </g>
    </g>
  </svg>
);
