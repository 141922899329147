export const legalFormMapping = {
  COMPANY_LEGAL_STATUS_SCM: "SCM",
  COMPANY_LEGAL_STATUS_SCOAGR: "SCOAGR",
  COMPANY_LEGAL_STATUS_SCOICRL: "SCOICRL",
  COMPANY_LEGAL_STATUS_SCOM: "SCOM",
  COMPANY_LEGAL_STATUS_SCOPRL: "SCOPRL",
  COMPANY_LEGAL_STATUS_SCOTFA: "SCOTFA",
  COMPANY_LEGAL_STATUS_SCPI: "SCPI",
  AGRICULTURAL_COOPERATIVE: "COOPERATIVE AGRICOLE",
  COMPANY_LEGAL_STATUS_EARL: "EARL",
  FARMING_LAND_OWNERSHIP_COLLECTIVE: "FARMING_LAND_OWNERSHIP_COLLECTIVE",
  COMPANY_LEGAL_STATUS_SC: "SC",
  COMPANY_LEGAL_STATUS_SICAAS: "SICAAS",
  COMPANY_LEGAL_STATUS_SPFPLFS: "SPFPLFS",
  COMPANY_LEGAL_STATUS_SPPICV: "SPPICV",
  UNION_OF_AGRICULTURAL_COOPERATIVE: "UNION_OF_AGRICULTURAL_COOPERATIVE",
  FOREIGN_TERRITORY_BUSINESS_OFFICE: "FOREIGN_TERRITORY_BUSINESS_OFFICE",
  BONDS_EMMITTER_ASSOCIATION: "BONDS_EMMITTER_ASSOCIATION",
  COMPANY_LEGAL_STATUS_SCOICAS: "SCOICAS",
  COMPANY_LEGAL_STATUS_SCOPAS: "SCOPAS",
  COMPANY_LEGAL_STATUS_SCOPRDFA: "SCOPRDFA",
  COMMERCIAL_REPRESENTATION_OF_A_FOREIGN_COMMLOTY:
    "COMMERCIAL_REPRESENTATION_OF_A_FOREIGN_COMMLOTY",
  COMPANY_LEGAL_STATUS_SAP: "SAP",
  COMPANY_LEGAL_STATUS_SCCV: "SCCV",
  COMPANY_LEGAL_STATUS_SCEA: "SCEA",
  COMPANY_LEGAL_STATUS_SDC: "SDC",
  COMPANY_LEGAL_STATUS_SDE: "SDE",
  COMPANY_LEGAL_STATUS_SEM: "SEM",
  COMPANY_LEGAL_STATUS_SICAGRAS: "SICAGRAS",
  COMPANY_LEGAL_STATUS_SISA: "SISA",
  COMPANY_LEGAL_STATUS_SPFPLRL: "SPFPLRL",
  COMPANY_LEGAL_STATUS_SPFPLFA: "SPFPLFA",
  UNION_OF_LIMITED_LIABILITY_COOPERATIVE_COMPANY: "UNION_OF_LIMITED_LIABILITY_COOPERATIVE_COMPANY",
  SOCIAL_ECONOMY_UNION_SIMPLIFY_COOPERATIVE_STOCK_COMPANY:
    "SOCIAL_ECONOMY_UNION_SIMPLIFY_COOPERATIVE_STOCK_COMPANY",
  FOREIGN_COUNTRY_BUSINESS_OFFICE: "FOREIGN_COUNTRY_BUSINESS_OFFICE",
  COMPANY_LEGAL_STATUS_SCOC: "SCOC",
  COMPANY_LEGAL_STATUS_SCOICFA: "SCOICFA",
  COMPANY_LEGAL_STATUS_SCOOPRL: "SCOOPRL",
  COMPANY_LEGAL_STATUS_SCOPFA: "SCOPFA",
  COMPANY_LEGAL_STATUS_SCOTRL: "SCOTRL",
  COMPANY_LEGAL_STATUS_SACICAP: "SACICAP",
  COMPANY_LEGAL_STATUS_SAOS: "SAOS",
  COMPANY_LEGAL_STATUS_SAS: "SAS",
  COMPANY_LEGAL_STATUS_SASU: "SASU",
  COMPANY_LEGAL_STATUS_SASP: "SASP",
  COMPANY_LEGAL_STATUS_SCA: "SCA",
  COMPANY_LEGAL_STATUS_SCP: "SCP",
  COMPANY_LEGAL_STATUS_SCS: "SCS",
  COMPANY_LEGAL_STATUS_SELCA: "SELCA",
  COMPANY_LEGAL_STATUS_SPLA: "SPLA",
  COMPANY_LEGAL_STATUS_SCORL: "SCORL",
  COMPANY_LEGAL_STATUS_SCOA: "SCOA",
  COMPANY_LEGAL_STATUS_SCOBP: "SCOBP",
  FARMING_COLLECTIVE_GROUP: "FARMING_COLLECTIVE_GROUP",
  EUROPEAN_ECONOMIC_INTEREST_COLLECTIVE: "EUROPEAN_ECONOMIC_INTEREST_COLLECTIVE",
  FOREST_COLLECTIVE: "FOREST_COLLECTIVE",
  COMMERCIAL_REPRESENTATION_OF_A_FOREIGN_PUBLIC_ESTABLISMENT:
    "COMMERCIAL_REPRESENTATION_OF_A_FOREIGN_PUBLIC_ESTABLISMENT",
  EUROPEAN_COMPANY: "ENTREPRISE EUROPEENNE",
  COMPANY_LEGAL_STATUS_SELAS: "SELAS",
  COMPANY_LEGAL_STATUS_SEMOU: "SEMOU",
  COMPANY_LEGAL_STATUS_SICAGRRL: "SICAGRRL",
  COMPANY_LEGAL_STATUS_SNC: "SNC",
  UNION_OF_COOPERATIVE_COMPANY_WITH_ANONYMOUS_FORM:
    "UNION_OF_COOPERATIVE_COMPANY_WITH_ANONYMOUS_FORM",
  FOREIGN_PUBLIC_BUSINESS_OFFICE: "FOREIGN_PUBLIC_BUSINESS_OFFICE",
  COMPANY_LEGAL_STATUS_SCAS: "SCAS",
  COMPANY_LEGAL_STATUS_SCC: "SCC",
  COMPANY_COOPERATIVE_DE_COMMERCANTS_DETAILLANTS_A_FORME_ANONYME:
    "COMPANY_COOPERATIVE_DE_COMMERCANTS_DETAILLANTS_A_FORME_ANONYME",
  COMPANY_LEGAL_STATUS_SCOE: "SCOE",
  COMPANY_LEGAL_STATUS_SCOFA: "SCOFA",
  COMPANY_LEGAL_STATUS_SCOOPFA: "SCOOPFA",
  COMPANY_LEGAL_STATUS_SCOPRDRL: "SCOPRDRL",
  COMPANY_LEGAL_STATUS_SCOPRDAS: "SCOPRDAS",
  INDUSTRIAL_AND_COMMERCIAL_PUBLIC_OFFICES: "INDUSTRIAL_AND_COMMERCIAL_PUBLIC_OFFICES",
  COUNTRY_SIDE_LAND_OWNERSHIP_COLLECTIVE: "COUNTRY_SIDE_LAND_OWNERSHIP_COLLECTIVE",
  ECONOMIC_INTEREST_COLLECTIVE: "ECONOMIC_INTEREST_COLLECTIVE",
  COMMERCIAL_REPRESENTATION_OF_A_FOREIGN_STATE: "COMMERCIAL_REPRESENTATION_OF_A_FOREIGN_STATE",
  COMPANY_LEGAL_STATUS_SA: "SA",
  COMPANY_LEGAL_STATUS_SAPO: "SAPO",
  COMPANY_LEGAL_STATUS_SARL: "SARL",
  COMPANY_LEGAL_STATUS_SCIAGR: "SCIAGR",
  COMPANY_LEGAL_STATUS_SCI: "SCI",
  COMPANY_LEGAL_STATUS_SELRL: "SELRL",
  COMPANY_LEGAL_STATUS_SICAGRFA: "SICAGRFA",
  COMPANY_LEGAL_STATUS_SICF: "SICF",
  COMPANY_LEGAL_STATUS_SLP: "SLP",
  COMPANY_LEGAL_STATUS_SPFPLCA: "SPFPLCA",
  SOCIAL_ECONOMY_UNION_LIMITED_LIABILITY_COOPERATIVE_COMPANY:
    "SOCIAL_ECONOMY_UNION_LIMITED_LIABILITY_COOPERATIVE_COMPANY",
  SOCIAL_ECONOMY_UNION_COOPERATIVE_COMPANY_WITH_ANONYMOUS_FORM:
    "SOCIAL_ECONOMY_UNION_COOPERATIVE_COMPANY_WITH_ANONYMOUS_FORM",
  COMPANY_LEGAL_STATUS_EURL: "EURL",
  COMPANY_LEGAL_STATUS_SELARL: "SELARL",
  COMPANY_LEGAL_STATUS_SCIC: "SCIC",
  COMPANY_LEGAL_STATUS_EIRL: "EIRL",
  COMPANY_LEGAL_STATUS_SE: "SE",
  COMPANY_LEGAL_STATUS_EI: "EI",
  COMPANY_LEGAL_STATUS_ADMI: "ADMI",
  COMPANY_LEGAL_STATUS_AFUL: "AFUL",
  COMPANY_LEGAL_STATUS_AGEN: "AGEN",
  COMPANY_LEGAL_STATUS_ASL: "ASL",
  COMPANY_LEGAL_STATUS_ASSO: "ASSO",
  COMPANY_LEGAL_STATUS_CBT: "CBT",
  COMPANY_LEGAL_STATUS_CIE: "CIE",
  COMPANY_LEGAL_STATUS_COOP: "COOP",
  COMPANY_LEGAL_STATUS_CRTS: "CRTS",
  COMPANY_LEGAL_STATUS_DOCT: "DOCT",
  COMPANY_LEGAL_STATUS_ENTR: "ENTR",
  COMPANY_LEGAL_STATUS_ETS: "ETS",
  COMPANY_LEGAL_STATUS_ETUD: "ETUD",
  COMPANY_LEGAL_STATUS_FOND: "FOND",
  COMPANY_LEGAL_STATUS_GIE: "GIE",
  COMPANY_LEGAL_STATUS_GRP: "GRP",
  COMPANY_LEGAL_STATUS_STE: "STE",
  COMPANY_LEGAL_STATUS_TRIB: "TRIB",
  COMPANY_LEGAL_STATUS_IND: "IND",
  COMPANY_LEGAL_STATUS_OPA: "OPA",
  COMPANY_LEGAL_STATUS_HOIR: "HOIR",
} as const;
