import { Box, BoxProps } from "@mui/material";
import React, { memo, ReactNode } from "react";

type Props = {
  children?: ReactNode;
} & BoxProps;

const FormCardItem = memo(({ children, ...props }: Props) => (
  <Box
    sx={{
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "space-between",
      bgcolor: "background.default",
      borderRadius: 1,
      m: 2,
      p: 3,
    }}
    {...props}
  >
    {children}
  </Box>
));

export default FormCardItem;
