import { Box, BoxProps } from "@mui/material";
import { rem } from "polished";
import React, { ReactNode } from "react";
import { NavLink, To } from "react-router-dom";
import styled from "styled-components";

import { mobileStyle } from "src/utils";

type Props = {
  links: Array<{ label: string; link: To }>;
  leftNode?: ReactNode;
} & BoxProps;

const StyledLinkWraper = styled(Box)<{ $withLeftNode?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.$withLeftNode ? "flex-start" : "center")};
  padding: 0 ${(props) => props.theme.spacing(2)};
  border-bottom: ${rem(1)} solid ${(props) => props.theme.palette.background.default};
  ${mobileStyle`
    flex-direction: column;
  `}

  & > a {
    position: relative;
    text-decoration: none;
    margin: 0 ${(props) => props.theme.spacing(0.5)};
    padding: 1.5rem 0.5rem;
    color: #99b2cb;
    font-weight: 500;
    text-align: center;

    &:hover,
    &.active {
      color: ${(props) => props.theme.palette.primary.main};
    }

    &.active {
      &:after {
        position: absolute;
        content: "";
        height: ${rem(4)};
        left: 0;
        right: 0;
        bottom: ${rem(-2)};
        background-color: ${(props) => props.theme.palette.primary.main};
      }
    }
  }
`;

export const SubNav = ({ links, leftNode, ...boxProps }: Props) => {
  return (
    <StyledLinkWraper
      $withLeftNode={!!leftNode}
      {...boxProps}
      data-cy="sub-nav"
      sx={{
        backgroundColor: "#f5f9fa",
        ...boxProps.sx,
      }}
    >
      {leftNode && <>{leftNode}</>}
      {leftNode && <Box sx={{ flex: 0.3 }} />}
      {links.map((item, index) => (
        <NavLink key={index} to={item.link}>
          {item.label}
        </NavLink>
      ))}
    </StyledLinkWraper>
  );
};
