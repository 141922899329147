/* eslint-disable import/no-duplicates */
import { Box, BoxProps } from "@mui/material";
import { isValid } from "date-fns";
import React from "react";

import { DATE_PATTERN } from "src/constants";
import { formatDate } from "src/utils";

type Props = {
  value: string | Date | null | undefined;
  pattern?: DATE_PATTERN;
  timeZone?: string;
  className?: string;
} & BoxProps;

export const FormatDate = ({
  value,
  pattern = "DATE",
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone,
  className,
  ...props
}: Props) => {
  return (
    <Box component="span" className={className} {...props}>
      {value && isValid(new Date(value)) ? formatDate(value, { timeZone, pattern }) : "--"}
    </Box>
  );
};
