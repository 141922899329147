import React from "react";
import { useLocation } from "react-router-dom";

import { AuthLayout, ErrorGlobal, Loader } from "src/components";
import {
  API_MAINTENANCE_CODE,
  PATH_LOGIN_ASSOCIATION,
  VERIFY_CREDENTIALS_PAGE,
} from "src/constants";
import { TemporaryLogin } from "src/containers/TemporaryLogin";
import { useGetMeQuery } from "src/graphql/generated/queries";
import { useAuthStore } from "src/stores/auth-store";
import { getSpecificError } from "src/utils";

type Props = {
  children: React.ReactNode;
};

export const GetMeWrapper = ({ children }: Props) => {
  const logout = useAuthStore((state) => state.logout);
  const { data, refetch, error } = useGetMeQuery();
  const { pathname } = useLocation();

  if (error) {
    return (
      <ErrorGlobal
        isInMaintenance={
          error.response?.errors?.some((e) => e.extensions?.code === API_MAINTENANCE_CODE) || false
        }
        title="Une erreur est survenue lors de la récupération des informations client"
        description={getSpecificError(error, "Veuillez réessayer plus tard")}
        onRefetch={() => {
          // @note : onRefetch={refetch} cause `Converting circular structure to JSON` error ??
          refetch();
        }}
        onLogout={logout}
      />
    );
  }

  // @note: if has temporary login but no contact email
  if (
    data?.me.isTemporary &&
    !pathname.includes(PATH_LOGIN_ASSOCIATION) &&
    !pathname.includes(VERIFY_CREDENTIALS_PAGE)
  )
    return (
      <AuthLayout>
        <TemporaryLogin />
      </AuthLayout>
    );

  if (!data) {
    return <Loader />;
  }

  return children as React.ReactElement;
};
