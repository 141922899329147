import React, { ComponentType, lazy } from "react";

import { logger } from "src/utils";

const wait = (ms = 1000) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

/**
 * Retry the import
 * @param importFn
 * @param retries
 * @param interval
 */
const importRetry = async (
  importFn: () => Promise<{ default: ComponentType<any> }>,
  retries = 2,
  interval = 1000
): Promise<{ default: React.ComponentType<any> }> => {
  try {
    return await importFn();
  } catch (error) {
    logger.info("Error on import, maybe a network error.");
    if (retries) {
      await wait(interval);
      logger.info("Error on import, retrying...");
      return importRetry(importFn, retries - 1, interval);
    }

    if (error instanceof Error) {
      throw error;
    } else {
      throw new Error(String(error));
    }
  }
};

/**
 * Retry the import if fail (for network error)
 * And reload the page if still fail (for error from app update and chunks doesn't exists anymore)
 * @param componentImport
 */
const lazyWithRetry = (componentImport: () => Promise<{ default: ComponentType<any> }>) =>
  // @ts-ignore
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem("page-has-been-force-refreshed") || "false"
    );

    try {
      const component = await importRetry(componentImport);

      window.localStorage.setItem("page-has-been-force-refreshed", "false");

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem("page-has-been-force-refreshed", "true");
        logger.info("Error on import, reload the page");
        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      logger.info("Error on import, after reload !");
      throw error;
    }
  });
export default lazyWithRetry;
