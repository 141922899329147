import LoadingButton from "@mui/lab/LoadingButton";
import { Typography, Box } from "@mui/material";
import React from "react";
import styled from "styled-components";

import { Icon } from "../Icon";

const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) => props.theme.spacing(10)} 0;
  > * {
    margin: ${(props) => props.theme.spacing(2)};
  }
`;

interface Props {
  title?: string;
  description?: string;
  refetching: boolean;
  refetch: () => void;
}

export const ErrorPage = ({ title, description, refetch, refetching: refeshing }: Props) => {
  return (
    <StyledContainer>
      <Icon name="settings" size={100} />

      <Typography variant="h4">{title || "Une erreur est survenue."}</Typography>

      {description && <Typography sx={{ wordBreak: "break-word" }}>{description}</Typography>}

      <LoadingButton loading={refeshing} size="large" variant="contained" onClick={refetch}>
        Rafraichir
      </LoadingButton>
    </StyledContainer>
  );
};
