import { tz } from "@date-fns/tz";
import { Locale, format, isAfter, add } from "date-fns";
import { enGB, fr } from "date-fns/locale";

import { DATE_PATTERN } from "src/constants";
import i18n from "src/i18n";

type Language = "fr-FR" | "en-GB";

const dateFnsLocales: Record<Language, Locale> = {
  "fr-FR": fr,
  "en-GB": enGB,
};

const dateFnsPatterns: Record<Language, Record<DATE_PATTERN, string>> = {
  "fr-FR": {
    DATE: "P", // 01/05/2021
    TIME: "H'h'mm", // 9h20
    DATE_TIME: "P 'à' H'h'mm", // 01/05/2021 à 09h20
    DAY: "EEEE", // Lundi
    MONTH_YEAR: "MMMM yyyy", // mai 2021
    DAY_DATE: "EEEE dd/MM/yyyy", // Mercredi 01/05/2021
  },
  "en-GB": {
    DATE: "P",
    TIME: "p",
    DATE_TIME: "Pp",
    DAY: "EEEE",
    MONTH_YEAR: "MMMM yyyy",
    DAY_DATE: "EEEE P",
  },
};

interface FormatDateParams {
  timeZone?: string;
  pattern?: DATE_PATTERN;
}

export const isDate = (value: string | Date) =>
  !!value &&
  (value instanceof Date
    ? !Number.isNaN(value.getTime())
    : !Number.isNaN(new Date(value).getTime()));

export const formatDate = (date: string | Date, params?: FormatDateParams) => {
  const timeZone = params?.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;
  const pattern = params?.pattern || "DATE";

  const lng = i18n.language as Language;
  const locale = dateFnsLocales[lng];

  if (!isDate(date)) return null;

  const formattedDate = format(date, dateFnsPatterns[lng][pattern], {
    locale,
    ...(!!timeZone && { in: tz(timeZone) }),
  });

  // Capitalize the first letter if the pattern starts with 'EEEE' : mardi -> Mardi
  if (dateFnsPatterns[lng][pattern].startsWith("EEEE")) {
    return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
  }

  return formattedDate;
};

export const addYears = (date: string, years: number = 1): string | null => {
  if (!isDate(date)) return null;

  return add(date, { years }).toISOString();
};

export const isAfterDate = (date1: string, date2: string | undefined = undefined): boolean => {
  if (!isDate(date1) || (date2 && !isDate(date2))) return false;

  if (date2) return isAfter(date1, date2);

  return isAfter(new Date(date1), new Date());
};
