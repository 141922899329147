import React, { createContext, useMemo, useRef } from "react";
import LoadingBar from "react-top-loading-bar";

interface Props {
  add(value: number): void;
  decrease(value: number): void;
  continuousStart(startingValue?: number, refreshRate?: number): void;
  staticStart(startingValue?: number): void;
  complete(): void;
}

export const LoadingBarContext = createContext<Props>({
  add: () => ({}),
  decrease: () => ({}),
  continuousStart: () => ({}),
  staticStart: () => ({}),
  complete: () => ({}),
});

export const LoadingBarProvider = ({ children }: { children: React.ReactNode }) => {
  const ref = useRef<Props>(null);

  return (
    <LoadingBarContext.Provider
      value={useMemo<Props>(
        () => ({
          add: (value) => ref.current?.add(value),
          decrease: (value) => ref.current?.decrease(value),
          continuousStart: (startingValue, refreshRate) =>
            ref.current?.continuousStart(startingValue, refreshRate),
          staticStart: (startingValue) => ref.current?.staticStart(startingValue),
          complete: () => ref.current?.complete(),
        }),
        [ref]
      )}
    >
      <LoadingBar ref={ref} />
      {children}
    </LoadingBarContext.Provider>
  );
};
