import { useQueryClient } from "@tanstack/react-query";
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from "react";

import { MetaTags, Modal } from "src/components";
import { TICKET_CREATION_ERROR, DEFAULT_TICKETS_SORT } from "src/constants";
import {
  useCreateTicketMutation,
  useGetDashboardTicketsQuery,
} from "src/graphql/generated/queries";
import type {
  GetDashboardTicketsQuery,
  Scalars,
  TicketCategoryEnum,
  TicketHeadingCategoryEnum,
} from "src/graphql/generated/types";
import { useCurrentAccount, useSnackbar } from "src/hooks";
import { getMainUnits, getBuildings, getDefaultMetadata } from "src/utils";

import { CreateTicketInputs, FormCreateTicket } from "./_components/FormCreateTicket";

interface Props {
  uuid: Scalars["EncodedID"];
  redirect?: () => void;
  renderButton: ({ handleOpen }: { handleOpen: () => void }) => ReactElement | null;
  preselectedCategory?: {
    headingCategory: TicketHeadingCategoryEnum;
    category: TicketCategoryEnum | undefined;
  };
  preFilledDescription?: string;
}

export const CreateTicket = ({
  uuid,
  redirect,
  renderButton,
  preselectedCategory,
  preFilledDescription,
}: Props) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const account = useCurrentAccount();

  const [open, setOpen] = useState(false);

  const {
    isLoading: createTicketLoading,
    isSuccess,
    mutate: createTicket,
    reset,
  } = useCreateTicketMutation({
    onSuccess: (data) => {
      const updatedTicket = data?.createTicket;
      // @note: update Dashboard tickets screen
      if (!updatedTicket) {
        return;
      }

      const GET_DASHBOARD_TICKETS_KEY = useGetDashboardTicketsQuery.getKey({
        accountUuid: uuid,
        sortBy: DEFAULT_TICKETS_SORT,
      });

      queryClient.setQueryData<GetDashboardTicketsQuery>(
        GET_DASHBOARD_TICKETS_KEY,
        (cachedData) => {
          if (!cachedData) {
            return undefined;
          }

          return {
            __typename: "Query",
            tickets: {
              ...cachedData.tickets,
              edges: [
                ...cachedData.tickets.edges.filter((edge) => !edge.node.isRead),
                {
                  __typename: "TicketNode" as const,
                  node: {
                    ...updatedTicket,
                  },
                },
                ...cachedData.tickets.edges.filter((edge) => edge.node.isRead),
              ].slice(0, 5), // @note: To display a maximum of 5 tickets on Dashboard
            },
          };
        }
      );

      // @note: update Tickets screen
      queryClient.invalidateQueries(["getTickets.infinite"]);
    },

    onError: () => {
      enqueueSnackbar(TICKET_CREATION_ERROR, { variant: "error" });
    },
  });

  useEffect(() => {
    if (open) reset();
  }, [open]);

  const mainUnits = useMemo(() => getMainUnits(account), [account]);
  const buildings = useMemo(() => getBuildings(account), [account]);
  const { defaultBuildingId, defaultUnitId } = useMemo(() => getDefaultMetadata(account), [
    account,
  ]);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = () => {
    if (redirect) {
      redirect();
    }

    setOpen(false);
  };

  const onSubmit = useCallback(
    ({ building, mainUnit, description, selectedCategory, documents }: CreateTicketInputs) => {
      const buildingId = building?.id ?? defaultBuildingId;
      const unitId = mainUnit?.id ?? defaultUnitId;

      if (!buildingId) {
        enqueueSnackbar(TICKET_CREATION_ERROR, { variant: "error" });
        return;
      }

      if (selectedCategory) {
        createTicket({
          accountUuid: uuid,
          request: {
            category: selectedCategory,
            metadata: {
              buildingId,
              unitId,
            },
            message: {
              content: description,
            },
          },
          documents,
        });
      }
    },
    [account]
  );

  if (!account.manager?.id) {
    return null;
  }

  return (
    <>
      {open && <MetaTags title="Nouvelle demande" />}
      {renderButton({ handleOpen })}
      <form>
        <Modal
          data-cy="requests-create-modal"
          disableBackdropClick
          disableEscapeKeyDown
          title="Nouvelle demande"
          open={open}
          handleClose={handleClose}
        >
          <FormCreateTicket
            loading={createTicketLoading}
            mainUnits={mainUnits}
            buildings={buildings}
            quality={account.quality}
            onSubmit={onSubmit}
            handleClose={handleClose}
            isTicketCreated={isSuccess}
            preselectedCategory={preselectedCategory}
            preFilledDescription={preFilledDescription}
          />
        </Modal>
      </form>
    </>
  );
};
