import styled from "styled-components";

export const ExpenseTypeRow = styled.tr`
  > td {
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: ${(props) => props.theme.spacing(1.5)};
    font-weight: 500;
    color: ${(props) => props.theme.palette.text.primary};

    &:nth-child(1) {
      border-left: 1px solid #ddd;
    }

    &:nth-child(2) {
      text-align: center;
    }
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      text-align: center;
    }
  }
`;
