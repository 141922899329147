import { useEffect } from "react";

import { useAuthStore } from "src/stores/auth-store";

export const LogoutComponent = () => {
  const logout = useAuthStore((state) => state.logout);

  useEffect(() => {
    logout();
  }, []);

  return null;
};
