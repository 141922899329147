import React from "react";

import { Icon, SubLevelLayout } from "src/components";
import {
  PROFILE_CONFIDENTIALITY_PAGE,
  PROFILE_CREDENTIALS_PAGE,
  PROFILE_INFOS_PAGE,
  PROFILE_LANDLORD_BANKINFOS_PAGE,
  PROFILE_PAYMENTS_PAGE,
  PROFILE_PREFERENCES_PAGE,
} from "src/constants";
import { AccountQualityEnum } from "src/graphql/generated/types";

interface Props {
  accountQuality: AccountQualityEnum;
  canSeeServices: boolean;
  children: React.ReactNode;
}

export const ProfileLayout = ({ accountQuality, canSeeServices, children }: Props) => {
  const paymentLink =
    accountQuality === AccountQualityEnum.Landlord
      ? { label: "Mes informations bancaires", link: PROFILE_LANDLORD_BANKINFOS_PAGE }
      : { label: "Mon mode de paiement", link: PROFILE_PAYMENTS_PAGE };

  const links = [
    {
      label: "Mes identifiants",
      link: PROFILE_CREDENTIALS_PAGE,
    },
    {
      label: "Mes informations",
      link: PROFILE_INFOS_PAGE,
    },
    paymentLink,
    {
      label: "Confidentialité",
      link: PROFILE_CONFIDENTIALITY_PAGE,
    },
  ];

  const servicesLink = {
    label: "Mes services",
    link: PROFILE_PREFERENCES_PAGE,
  };

  return (
    <SubLevelLayout
      headerIcon={<Icon name="user" />}
      headerTitle="Mon profil"
      links={canSeeServices ? [...links, servicesLink] : links}
    >
      {children}
    </SubLevelLayout>
  );
};
