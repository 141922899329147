import React, { ReactNode } from "react";

import { Section } from "../Section";
import { SubNav } from "../SubNav";

type Props = {
  headerIcon: ReactNode;
  headerTitle: string;
  rightNode?: ReactNode;
  upperChildren?: ReactNode;
  links: Array<{ label: string; link: string }>;
  subNavLeftNode?: ReactNode;
  children: ReactNode;
};

export const SubLevelLayout = ({
  headerIcon,
  headerTitle,
  rightNode,
  links,
  children,
  upperChildren,
  subNavLeftNode,
}: Props) => {
  return (
    <Section icon={headerIcon} title={headerTitle} rightNode={rightNode}>
      {upperChildren}
      <SubNav links={links} leftNode={subNavLeftNode} />
      {children}
    </Section>
  );
};
