import { rem } from "polished";
import React, { useState, useCallback } from "react";
import styled, { css } from "styled-components";

import { Footer, footerHeight } from "src/components";
import { AccountFragment, CustomerFragment } from "src/graphql/generated/types";
import { useDeviceWidth } from "src/hooks";
import { DeviceProps } from "src/types/device-props";
import { isTrusteeCouncilMember, tabletMobileStyle, tabletStyle, mobileStyle } from "src/utils";

import { MainLayoutAccountInfosDesktop } from "./AccountInfosDesktop";
import { MainLayoutAccountInfosMobile } from "./AccountInfosMobile";
import { MainLayoutHeader, MainLayoutHeaderProps } from "./Header";
import { MainLayoutNavBar } from "./NavBar";
import { contentWidth, leftBar } from "./layout";

const mobileWidthLimit = css`
  ${tabletMobileStyle`
    min-width: unset;
    width: 100vw;
  `}
`;
export interface MainLayoutStructureProps
  extends Omit<MainLayoutHeaderProps, "hasRestrictedAccess" | "openSidebar"> {
  customer: CustomerFragment;
  account: AccountFragment;
  children: React.ReactNode;
}

const StyledRoot = styled.div`
  min-height: 100vh;
  min-width: ${rem(contentWidth)};
  ${mobileWidthLimit}
`;

const StyledMain = styled.div`
  display: flex;
  flex: 1;
  width: ${rem(contentWidth)};
  margin: auto;
  min-height: calc(100vh - (${rem(footerHeight)}));
  padding: calc(${(props) => props.theme.spacing(4)}) 0 ${(props) => props.theme.spacing(4)} 0;
  ${mobileWidthLimit}
  ${tabletStyle`
    padding: ${(props) => props.theme.spacing(1.5)};
  `}
  ${mobileStyle`
    padding: ${(props) => props.theme.spacing(0.5)};
  `}
`;

const StyledLeftBar = styled.div<DeviceProps>`
  width: ${rem(leftBar)};
  min-width: ${rem(leftBar)};
  margin-right: ${(props) => props.theme.spacing(4)};

  ${tabletMobileStyle`
      position: fixed;
      top: 0;
      left: 0;
      overflow-y: auto;
      height: 100vh;
      width: 100vw;
      z-index: 20;
      background-color: ${(props) => props.theme.palette.common.white};
      padding-bottom: ${(props) => props.theme.spacing(2)};
      ${(props) =>
        props.$isSidebarOpen === false &&
        css`
          left: -100vw;
          overflow: hidden;
        `}}
    `};

  ${tabletStyle`
    width: 50vw;
  `}

  transition: left 0.2s ease-in-out;
`;

const StyledContent = styled.div`
  flex: 1;
`;

const Mask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => props.theme.palette.common.black};
  opacity: 0.3;
  z-index: 10;
`;

export const MainLayoutStructure = ({
  onLogout,
  toProfile,
  toAuth,
  children,
  account,
  customer,
  hasSeveralAccounts,
}: MainLayoutStructureProps) => {
  const { isTabletMobile } = useDeviceWidth();
  const [isOpen, setOpen] = useState(isTabletMobile);

  const closeSidebar = useCallback(() => {
    setOpen(false);
  }, []);
  const openSidebar = useCallback(() => {
    setOpen(true);
  }, []);
  const hasAccessToGeneralAssembly =
    account.__typename === "CoownerAccount" && account.hasAccessToGeneralAssembly;

  return (
    <StyledRoot>
      <MainLayoutHeader
        hasRestrictedAccess={account.hasRestrictedAccess}
        hasSeveralAccounts={hasSeveralAccounts}
        onLogout={onLogout}
        toProfile={toProfile}
        toAuth={toAuth}
        openSidebar={openSidebar}
      />
      <StyledMain>
        {isTabletMobile && isOpen && <Mask onClick={closeSidebar} />}
        <StyledLeftBar $isSidebarOpen={isOpen}>
          {isTabletMobile === false && (
            <MainLayoutAccountInfosDesktop
              hasSeveralAccounts={hasSeveralAccounts}
              account={account}
              customer={customer}
              toAuth={toAuth}
            />
          )}
          {isTabletMobile && (
            <MainLayoutAccountInfosMobile
              hasSeveralAccounts={hasSeveralAccounts}
              account={account}
              customer={customer}
              toAuth={toAuth}
              closeSidebar={closeSidebar}
            />
          )}
          <MainLayoutNavBar
            hasRestrictedAccess={account.hasRestrictedAccess}
            hasAccessToGeneralAssembly={hasAccessToGeneralAssembly}
            isTrusteeCouncilMember={isTrusteeCouncilMember(account)}
            accountQuality={account.quality}
            closeSidebar={closeSidebar}
            sx={isTabletMobile ? { mb: 8 } : { mt: 4 }}
          />
        </StyledLeftBar>
        <StyledContent>{children}</StyledContent>
      </StyledMain>
      <Footer />
    </StyledRoot>
  );
};
