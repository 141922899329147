import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import numeral from "numeral";
import React, { useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";

import { theme } from "src/constants";
import { FontProvider } from "src/containers/FontProvider";
import { SsoWrapper } from "src/containers/SsoWrapper";
import { LoadingBarProvider, SnackbarProvider } from "src/contexts";
import { queryClient } from "src/libs";
import { initializeAnalytics } from "src/utils";

import { DatePickerProvider } from "./DatePickerProvider";
import ErrorBoundary from "./ErrorBoundary";
import { ErrorHappened } from "./ErrorBoundary/_components/ErrorHappened";
import Router from "./Router";
import "numeral/locales/fr";

numeral.locale("fr");

const App = () => {
  useEffect(() => initializeAnalytics(), []);

  return (
    <>
      <CssBaseline />
      <FontProvider>
        <HelmetProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <DatePickerProvider>
                <ErrorBoundary fallbackComponent={ErrorHappened}>
                  <QueryClientProvider client={queryClient}>
                    <ReactQueryDevtools initialIsOpen={false} />
                    <SsoWrapper>
                      <LoadingBarProvider>
                        <SnackbarProvider>
                          <Router />
                        </SnackbarProvider>
                      </LoadingBarProvider>
                    </SsoWrapper>
                  </QueryClientProvider>
                </ErrorBoundary>
              </DatePickerProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </HelmetProvider>
      </FontProvider>
    </>
  );
};

export default App;
