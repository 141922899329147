import { Typography } from "@mui/material";
import React, { useMemo } from "react";

import { ManagerFragment } from "src/graphql/generated/types";
import { useCurrentAccount } from "src/hooks";

type Props = {
  associate?: ManagerFragment | null;
};

export const HeaderDescription = ({ associate }: Props) => {
  const { manager } = useCurrentAccount();

  const { firstName, lastName, branch } = associate || {};

  const description = useMemo(() => {
    if (!associate) return "VOTRE MESSAGE";

    const mainDescription = [firstName, lastName].filter(Boolean).join(" ");
    const branchName = branch?.commercialName || "";

    return associate && manager && associate.id !== manager.id
      ? `${mainDescription} ${branchName ? "-" : ""} ${branchName}`
      : `${mainDescription} - Votre gestionnaire ${branchName}`;
  }, [associate, manager]);

  return <Typography sx={{ fontWeight: "bold" }}>{description}</Typography>;
};
