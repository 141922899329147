import { TicketCategory, TicketHeadingCategory } from "src/constants";
import {
  AccountQualityEnum,
  TicketCategoryEnum,
  TicketHeadingCategoryEnum,
} from "src/graphql/generated/types";

type TicketHeadingCategoryByQualityType = Record<
  AccountQualityEnum,
  Array<{
    headingCategory: TicketHeadingCategoryEnum;
    label: string;
  }>
>;

type getTicketHeadingCategoriesType = Record<
  TicketHeadingCategoryEnum,
  Array<{
    category: TicketCategoryEnum;
    label: string;
  }>
>;

const TicketHeadingCategoryByQuality: Record<
  AccountQualityEnum,
  Array<{ headingCategory: TicketHeadingCategoryEnum; label: string }>
> = Object.values(AccountQualityEnum).reduce((accu, quality) => {
  return {
    ...accu,
    [quality]: Object.entries(TicketHeadingCategory)
      .filter(
        ([, ticketCategoryQualityDetails]) => ticketCategoryQualityDetails.quality === quality
      )
      .sort(([, a], [, b]) => a.order - b.order)
      .map(([headingCategory, { label }]) => ({
        headingCategory,
        label,
      })),
  };
}, <TicketHeadingCategoryByQualityType>{});

export const getTicketHeadingCategories = (
  quality: AccountQualityEnum
): Array<{
  headingCategory: TicketHeadingCategoryEnum;
  label: string;
}> => TicketHeadingCategoryByQuality[quality];

const TicketCategoryByHeadingCategory: Record<
  TicketHeadingCategoryEnum,
  Array<{
    category: TicketCategoryEnum;
    label: string;
  }>
> = Object.values(TicketHeadingCategoryEnum).reduce((accu, headingCategory) => {
  return {
    ...accu,
    [headingCategory]: Object.entries(TicketCategory)
      .filter(
        ([, ticketCategoryDetails]) => ticketCategoryDetails.headingCategory === headingCategory
      )
      .sort(([, a], [, b]) => a.order - b.order)
      .map(([category, { label }]) => ({
        category,
        label,
      })),
  };
}, <getTicketHeadingCategoriesType>{});

export const getTicketCategories = (
  headingCategory: TicketHeadingCategoryEnum
): Array<{
  category: TicketCategoryEnum;
  label: string | null;
}> => TicketCategoryByHeadingCategory[headingCategory];

export const getTicketHeadingCategoryOptions = (quality: AccountQualityEnum) =>
  Object.values(TicketHeadingCategoryEnum)
    .filter(
      (headingCategory) =>
        TicketHeadingCategory[headingCategory].isHeadingCategoryActive &&
        TicketHeadingCategory[headingCategory].quality === quality
    )
    .map((headingCategory) => ({
      label: TicketHeadingCategory[headingCategory].label,
      value: headingCategory,
      icon: TicketHeadingCategory[headingCategory].icon,
      order: TicketHeadingCategory[headingCategory].order,
    }))
    .sort((categoryA, categoryB) => (categoryA.order > categoryB.order ? 1 : -1));

// @note: alternative to .flat()
// source: MDN - /Array/flat#alternatives
export const flattenCategories = (
  categories: Array<
    Array<{
      category: TicketCategoryEnum;
      label: string | null;
    }>
  >
) => categories.reduce((acc, val) => acc.concat(val), []).map(({ category }) => category);

export const getProfileInformationCategory = (headingCategory: TicketHeadingCategoryEnum) => {
  switch (headingCategory) {
    case TicketHeadingCategoryEnum.TenantDocuments:
      return TicketCategoryEnum.TenantDocumentsContacts;
    case TicketHeadingCategoryEnum.CoOwnerDocuments:
      return TicketCategoryEnum.CoOwnerDocumentsContact;
    case TicketHeadingCategoryEnum.LandlordDocuments:
      return TicketCategoryEnum.LandlordDocumentsContacts;
    default:
      return undefined;
  }
};

export const bytesToMegabytes = (bytes: number, type: "decimal" | "binary" = "decimal") => {
  if (type === "binary") {
    return bytes / 1048576;
  }
  return bytes / 1000000;
};

export const megabytesTobytes = (megabytes: number, type: "decimal" | "binary" = "decimal") => {
  if (type === "binary") {
    return megabytes * 1048576;
  }
  return megabytes * 1000000;
};
