import LoadingButton from "@mui/lab/LoadingButton";
import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import { startOfToday } from "date-fns";
import React from "react";
import { Controller, UseControllerProps } from "react-hook-form";
import styled from "styled-components";

import { LandlordFiltersInputType, SELECT_ALL_VALUE, SELECT_ALL_TEXT } from "src/constants";
import { useGetLandlordAccountingLinesCsvQuery } from "src/graphql/generated/queries";
import { Amount, BasicBuildingFragment } from "src/graphql/generated/types";
import { useSnackbar, useUuid } from "src/hooks";
import { generateAccountingExportFilename } from "src/pages/Accounting/utils";
import { downloadFile, formatAmount, shortenString } from "src/utils";

import { ChoiceListItem } from "../ChoiceListItem";
import { CustomMenuProps } from "../CustomMenuProps";
import { DatePicker } from "../Form/DatePicker";

const StyledCardContainer = styled(Box)`
  border-radius: ${(props) => props.theme.spacing(0.5)};
  margin: ${(props) => props.theme.spacing(2)} 0;
  padding: ${(props) => props.theme.spacing(2)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;

const StyledCardText = styled(Box)`
  display: flex;
  font-size: 15px;
`;

type Props = {
  currentBalance?: Omit<Amount, "__typename"> | null;
  buildings?: Array<BasicBuildingFragment>;
  control: UseControllerProps<LandlordFiltersInputType>["control"];
  pageNumber: number;
  selectedBuilding?: Array<string>;
  startingDate?: Date | null;
  endingDate?: Date | null;
};

export const AccountingLandlordFilters = ({
  currentBalance,
  buildings,
  control,
  pageNumber,
  selectedBuilding,
  startingDate,
  endingDate,
}: Props) => {
  const uuid = useUuid();
  const { enqueueSnackbar } = useSnackbar();

  const {
    isInitialLoading,
    isRefetching,
    refetch: exportToCsv,
  } = useGetLandlordAccountingLinesCsvQuery(
    {
      accountUuid: uuid,
      buildingIds: selectedBuilding,
      startingDate: startingDate ? startingDate.toISOString() : undefined,
      endingDate: endingDate ? endingDate.toISOString() : undefined,
    },
    {
      onSuccess: (data) => {
        const url = `data:text/csv;base64,${
          data.account.__typename === "LandlordAccount" &&
          data.account.landlordAccounting.exportedData
        }`;
        const filename = generateAccountingExportFilename(pageNumber);

        downloadFile({ url, filename });
      },
      onError: () => {
        enqueueSnackbar("Une erreur est survenue lors du téléchargement des lignes comptables", {
          variant: "error",
        });
      },
      enabled: false,
    }
  );

  const selectRenderValue = (selectedValue: string) => {
    if (selectedValue === SELECT_ALL_VALUE) return "Tous";

    const building = buildings?.find((building) => building.id === selectedValue);

    return shortenString(building?.address.address1, 40);
  };

  const handleExportData = () => exportToCsv();

  return (
    <StyledCardContainer sx={{ backgroundColor: "blue.transparent" }}>
      {buildings && (
        <FormControl fullWidth>
          <Controller
            name="selectedBuilding"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                style={{ backgroundColor: "#fff", minWidth: 200 }}
                variant="outlined"
                MenuProps={CustomMenuProps}
                value={buildings.length === 1 ? buildings[0].id : value}
                onChange={onChange}
                renderValue={(buildingId) => selectRenderValue(buildingId || "")}
                native={false}
              >
                {buildings.length > 1 && (
                  <MenuItem value={SELECT_ALL_VALUE}>
                    <Typography>{SELECT_ALL_TEXT}</Typography>
                  </MenuItem>
                )}
                {buildings.map((building) => (
                  <ChoiceListItem key={building.id} value={building.id}>
                    <Typography>{`Immeuble n°${building.number}`}</Typography>
                    <Typography
                      sx={{ width: "100%", whiteSpace: "pre-wrap", overflowWrap: "break-word" }}
                    >
                      {building?.address.address1}
                    </Typography>
                    <Typography>
                      {`${[building.address.zipCode, building.address.city]
                        .filter(Boolean)
                        .join(" ")}`}
                    </Typography>
                  </ChoiceListItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      )}

      <Box sx={{ display: "flex", alignItems: "center", mx: 2 }}>
        <StyledCardText component={Typography} sx={{ mr: 1, color: "blue.dark" }}>
          Du{" "}
        </StyledCardText>
        <Controller
          name="startingDate"
          control={control}
          render={({ field: { value, onChange } }) => (
            <DatePicker
              sx={{ backgroundColor: "#fff", width: 160 }}
              value={value}
              placeholder="Date"
              clearable
              error={false}
              maxDate={startOfToday()}
              onChange={onChange}
            />
          )}
        />

        <StyledCardText component={Typography} sx={{ mx: 1, color: "blue.dark" }}>
          Au
        </StyledCardText>
        <Controller
          name="endingDate"
          control={control}
          render={({ field: { value, onChange } }) => (
            <DatePicker
              sx={{ backgroundColor: "#fff", width: 160 }}
              value={value}
              placeholder="Date"
              clearable
              error={false}
              maxDate={startOfToday()}
              onChange={onChange}
            />
          )}
        />
      </Box>

      <StyledCardText sx={{ color: "blue.dark", mx: 1, minWidth: 150 }}>
        Solde :
        <StyledCardText component="span" sx={{ color: "blue.main", ml: 1, fontWeight: "bold" }}>
          {currentBalance ? formatAmount(currentBalance) : null}
        </StyledCardText>
      </StyledCardText>

      <LoadingButton
        loading={isInitialLoading || isRefetching}
        onClick={handleExportData}
        sx={{ minWidth: 100 }}
        variant="contained"
      >
        Exporter
      </LoadingButton>
    </StyledCardContainer>
  );
};
