import React from "react";

import { SubNav } from "src/components/SubNav";
import { COUNCIL_INCIDENTS_PAGE, COUNCIL_WORKS_PAGE } from "src/constants";

type Props = {
  children: React.ReactNode;
};

export const CouncilServicesLayout = ({ children }: Props) => {
  const links = [
    {
      label: "Travaux",
      link: COUNCIL_WORKS_PAGE,
    },
    {
      label: "Dépannage",
      link: COUNCIL_INCIDENTS_PAGE,
    },
  ];

  return (
    <div>
      <SubNav links={links} sx={{ backgroundColor: "white" }} />
      <div>{children}</div>
    </div>
  );
};
