import { createTheme, SimplePaletteColorOptions, Theme } from "@mui/material/styles";
// eslint-disable-next-line no-restricted-imports
import { Palette } from "@mui/material/styles/createPalette";
import { rem } from "polished";

export type CustomTheme = Omit<Theme, "palette"> & {
  palette: Palette & {
    ereco: SimplePaletteColorOptions;
    danger: SimplePaletteColorOptions;
    warning: SimplePaletteColorOptions;
    white: SimplePaletteColorOptions;
    gray: SimplePaletteColorOptions & {
      modalCloseButton: string;
      light: string;
      main: string;
      dark: string;
      contrastText?: string;
      boxBackground: string;
      blueGray: string;
      tooltip: string;
      subnavTab: string;
      deep: string;
    };
    black: SimplePaletteColorOptions & {
      shadow: string;
    };
    orange: SimplePaletteColorOptions;
    green: SimplePaletteColorOptions;
    blue: SimplePaletteColorOptions;
    red: SimplePaletteColorOptions;
    brown: SimplePaletteColorOptions;
    yellow: SimplePaletteColorOptions;
    cyan: SimplePaletteColorOptions;
    boxShadow: string;
    textShadow: string;
    accountSelection: {
      blue: string;
      darkBlue: string;
      gray: string;
      shadow: string;
      buttonShadow: string;
      multipleAddressBackground: string;
    };
    amountColor: {
      positive: string;
      negative: string;
    };
    councilAccounting: {
      rootBoxBackground: string;
    };
    tickets: {
      boxBackground: string;
      categoryText: string;
      border: string;
    };
    textLogo: {
      secondary: string;
    };
    postalVoting: {
      boxShadow: string;
    };
  };
};

export const mainPalette = {
  primary: {
    light: "#6fc1d4",
    main: "#4cb2ca",
    dark: "#357c8d",
    contrastText: "#fff",
  },
  secondary: {
    light: "#366798",
    main: "#04427f",
    dark: "#022e58",
    contrastText: "#fff",
  },
  ereco: {
    main: "#7eb02b",
    light: "#b7da27",
    contrastText: "#fff",
  },
  danger: {
    main: "#ff5f6c",
    light: "#ff9fa7",
    contrastText: "#fff",
  },
  warning: {
    light: "#FFC175",
    main: "#EE9D4E",
    dark: "#D18241",
    contrastText: "#fff",
  },
  white: {
    main: "#FFF",
  },
  orange: {
    main: "#FF7800",
    dark: "#F7B500",
    boxBackground: "#f2e8e4",
    light: "#FDECDF",
  },
  gray: {
    main: "#b7b9b8",
    light: "#F8F9FB",
    dark: "#576A80",
    deep: "#E5E9ED",
    boxBackground: "#f5f7fb",
    blueGray: "#355376",
    modalCloseButton: "#becadd",
    pageContainerBackground: "#f3f7fd",
    tooltip: "#A1B8D0",
    subnavTab: "#99b2cb",
    badge: "#e8e4e4",
  },
  black: {
    main: "#000",
    shadow: "#00000099",
  },
  blue: {
    light: "#1d789b",
    main: "#2f92b1",
    dark: "#013d7c",
    transparent: "#D8EEF4",
    contrastText: "#fff",
  },
  green: {
    main: "#319F6E",
    light: "#E7F8F3",
    deep: "#eff6ec",
  },
  yellow: {
    main: "#ffeed0",
  },
  brown: {
    main: "#a67e39",
  },
  red: {
    main: "#c74949",
    dark: "#AF2B2B",
    light: "#FFEDE9",
  },
  cyan: {
    main: "#DFF3F7",
    light: "#F4F9FA",
    dark: "#d1e8f2",
  },
  accountSelection: {
    blue: "#1E5091",
    darkBlue: "#003C81",
    gray: "#7193B630",
    shadow: "0 0 28px 0 rgba(44, 64, 89, 0.42)",
    buttonShadow: "0 0 12px 0 rgba(44, 64, 89, 0.42)",
    multipleAddressBackground: "#D4DDE8",
  },
  amountColor: {
    positive: "#07A430",
    negative: "#eb690b",
  },
  councilAccounting: {
    rootBoxBackground: "#f5f7fb",
  },
  boxShadow: "0 32px 64px rgba(0,0,0,0.07)",
  textShadow: "1px 1px 3px #fff, -1px -1px 3px #fff, -1px 1px 3px #fff, 1px -1px 3px #fff",
  background: {
    default: "#f2f2f2",
    paper: "white",
  },
  tickets: {
    boxBackground: "#F9FAFB",
    categoryText: "#55687E",
    border: "#adb9c7",
  },
  textLogo: {
    secondary: "#FBFBFB",
  },
  postalVoting: {
    boxShadow: "0 2px 72px 0 rgba(0, 0, 0, 0.29)",
  },
};

const materialBaseTheme = createTheme({
  typography: {
    fontFamily: "Montserrat, Roboto, Helvetica, Arial, sans-serif",
    body1: {
      fontSize: 14,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        type: "button",
      },
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        contained: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: "secondary",
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: mainPalette.blue.dark,
          "&$focused": {
            color: mainPalette.blue.dark,
          },
        },
      },
    },
  },
  spacing: (factor: number) => rem(8 * factor),
});

export const theme: CustomTheme = {
  ...materialBaseTheme,
  palette: {
    ...materialBaseTheme.palette,
    ...mainPalette,
  },
};
