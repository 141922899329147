import ArrowBackIconMUI from "@mui/icons-material/ArrowBack";
import { SxProps } from "@mui/material";

export const headerStyle: SxProps = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderBottomStyle: "solid",
  borderBottomColor: "gray.deep",
  borderWidth: 2,
  pt: 2,
  pb: 3,
};

export const subHeaderStyle: SxProps = {
  py: 2,
  borderBottomStyle: "solid",
  borderBottomColor: "gray.deep",
  borderWidth: 2,
};

export const titleContainer: SxProps = {
  display: "flex",
  alignItems: "center",
};

export const titleStyle: SxProps = {
  color: "secondary.main",
  fontWeight: "bold",
  ml: 1,
};

export const titleLinkStyle: SxProps = {
  display: "flex",
  color: "gray.blueGray",
  "&:hover": { textDecoration: "underline", cursor: "pointer" },
};

export const categoryContainer: SxProps = {
  display: "flex",
  alignItems: "baseline",
};

export const categoryStyle: SxProps = {
  color: "gray.blueGray",
};

export const buildingInfoContainer: SxProps = {
  display: "flex",
  flexDirection: "column",
  color: "gray.blueGray",
  borderLeftStyle: "solid",
  borderLeftColor: "gray.deep",
  borderWidth: 2,
  pl: 2,
  my: 1,
};

export const ArrowBackIcon = ArrowBackIconMUI;
