import { Box } from "@mui/material";
import styled from "styled-components";

export const ExpandIcon = styled(Box)`
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 8px solid white;

  &[aria-expanded="true"] {
    transform: rotate(90deg);
  }
`;
