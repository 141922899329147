import MobileDatePicker from "@mui/lab/MobileDatePicker";
import { SxProps, TextField, TextFieldProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import React from "react";

interface DatePickerProps {
  format?: string;
  clearable?: boolean;
  clearText?: string;
  cancelText?: string;
  okText?: string;
  placeholder?: string;
  showToolbar?: boolean;
  onChange: (date: Date | null, keyboardInputValue?: string | undefined) => void;
  value?: Date | null;
  maxDate?: Date;
  maxDateMessage?: string;
  minDate?: Date;
  minDateMessage?: string;
  error?: boolean;
  sx?: SxProps<Theme>;
}

const DatePicker = (props: DatePickerProps) => {
  const {
    format = "dd/MM/yyyy",
    clearable,
    clearText = "Réinitialiser",
    cancelText = "Annuler",
    okText = "Valider",
    placeholder,
    showToolbar = true,
    onChange,
    value,
    maxDate,
    maxDateMessage = "",
    minDate,
    minDateMessage = "",
    error,
    sx,
  } = props;

  const showError = Boolean(error || minDateMessage || maxDateMessage);

  const renderInput = (params: TextFieldProps) => {
    return (
      <TextField
        {...params}
        placeholder={placeholder}
        helperText={
          showError && params.error
            ? maxDateMessage || minDateMessage || params.helperText || "Une date valide est requise"
            : undefined
        }
        sx={sx}
      />
    );
  };

  return (
    <MobileDatePicker
      clearable={Boolean(clearable)}
      inputFormat={format}
      clearText={clearText}
      okText={okText}
      cancelText={cancelText}
      showToolbar={showToolbar}
      toolbarTitle={placeholder}
      toolbarFormat={format}
      onChange={onChange}
      renderInput={renderInput}
      value={value}
      maxDate={maxDate}
      minDate={minDate}
    />
  );
};

export default DatePicker;
