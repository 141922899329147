import React, { ReactElement } from "react";

import { Loader, ErrorGlobal } from "src/components";
import { API_MAINTENANCE_CODE } from "src/constants";
import { useGetFeatureFlagsQuery } from "src/graphql/generated/queries";
import { useAuthStore } from "src/stores/auth-store";
import { getSpecificError } from "src/utils";

interface Props {
  children: ReactElement;
}

export const FeatureFlagsWrapper = ({ children }: Props) => {
  const logout = useAuthStore((state) => state.logout);

  const { data, isLoading, error, refetch } = useGetFeatureFlagsQuery();

  if (error) {
    return (
      <ErrorGlobal
        isInMaintenance={
          error.response?.errors?.some((e) => e.extensions?.code === API_MAINTENANCE_CODE) || false
        }
        title="Erreur lors de la récupération des fonctionnalités"
        description={getSpecificError(error, "Veuillez réessayer plus tard")}
        onRefetch={refetch}
        onLogout={logout}
      />
    );
  }

  if (isLoading || !data) {
    return <Loader />;
  }

  return children;
};
