import { IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";

import { useGetInvoiceUrlQuery } from "src/graphql/generated/queries";

import { Icon } from "../../Icon";

type Props = {
  invoiceId: string;
};

export const ExpenseInvoice = ({ invoiceId }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const { refetch: getInvoiceUrl } = useGetInvoiceUrlQuery(
    {
      invoiceId,
    },
    {
      onSettled: (data) => {
        if (!data?.invoiceURL) {
          enqueueSnackbar("Erreur lors de la récupération de la facture", {
            variant: "error",
          });
          return;
        }

        window.open(data?.invoiceURL, "_blank");
      },
      enabled: false,
    }
  );

  return (
    <IconButton aria-label="download" onClick={() => getInvoiceUrl()}>
      <Icon name="document" />
    </IconButton>
  );
};
