import { rem } from "polished";
import React from "react";
import styled from "styled-components";

import { BasicBuildingFragment } from "src/graphql/generated/types";
import { tabletMobileStyle } from "src/utils";

const StyledContentEstate = styled.div`
  border-left: ${rem(3)} solid ${(props) => props.theme.palette.primary.main};
  padding-left: ${(props) => props.theme.spacing(2)};
  font-weight: bold;
  background-color: ${(props) => props.theme.palette.common.white};
  ${tabletMobileStyle`
    padding: ${(props) => props.theme.spacing(2)};
  `}
`;

const StyledContentEstateTitle = styled.div`
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: ${rem(16)};
`;

const StyledContentEstateAddress = styled.div`
  margin-top: ${(props) => props.theme.spacing(1)};
  color: ${(props) => props.theme.palette.gray.main};
  font-size: ${rem(13)};
`;

type Props = {
  buildings: Array<BasicBuildingFragment>;
};
export const ContentEstate = ({ buildings }: Props) => {
  return (
    <StyledContentEstate>
      {buildings.length === 1 && (
        <>
          <StyledContentEstateTitle>{buildings[0].name}</StyledContentEstateTitle>
          <StyledContentEstateTitle>Immeuble {buildings[0].number}</StyledContentEstateTitle>
          <StyledContentEstateAddress>
            <div>{buildings[0].address.address1}</div>
            <div>
              {[buildings[0].address.zipCode, buildings[0].address.city].filter((i) => i).join(" ")}
            </div>
          </StyledContentEstateAddress>
        </>
      )}

      {buildings.length > 1 && <div>{buildings.length} immeubles</div>}
    </StyledContentEstate>
  );
};
