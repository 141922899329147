export function hasLowerCase(str: string) {
  return /[a-z]/.test(str);
}

export function hasUpperCase(str: string) {
  return /[A-Z]/.test(str);
}

export function hasDigit(str: string) {
  return /[0-9]/.test(str);
}

export function isLengthEnought(str: string) {
  return str.length >= 8;
}

export function isValidPassword(str: string) {
  return hasLowerCase(str) && hasUpperCase(str) && hasDigit(str) && isLengthEnought(str);
}
