import ThumbnailQualityCoowner from "src/assets/images/acc_thmb_coowner.png";
import ThumbnailQualityLandlord from "src/assets/images/acc_thmb_landlord.png";
import ThumbnailQualityTenant from "src/assets/images/acc_thmb_tenant.png";
import ImageQualityCoowner from "src/assets/images/quality-coowner.png";
import ImageQualityLandlord from "src/assets/images/quality-landlord.png";
import ImageQualityTenant from "src/assets/images/quality-tenant.png";
import { AccountQualityEnum, HolderPropertyEnum } from "src/graphql/generated/types";

export const AccountQualityLabels: { [key in AccountQualityEnum]: string } = {
  [AccountQualityEnum.CoOwner]: "Copropriétaire",
  [AccountQualityEnum.Landlord]: "Propriétaire bailleur",
  [AccountQualityEnum.Tenant]: "Locataire",
};

export const AccountQualityLabelsShort: { [key in AccountQualityEnum]: string } = {
  [AccountQualityEnum.CoOwner]: "Copropriétaire",
  [AccountQualityEnum.Landlord]: "Bailleur",
  [AccountQualityEnum.Tenant]: "Locataire",
};

export const AccountQualityImageSource: { [key in AccountQualityEnum]: any } = {
  [AccountQualityEnum.CoOwner]: ImageQualityCoowner,
  [AccountQualityEnum.Landlord]: ImageQualityLandlord,
  [AccountQualityEnum.Tenant]: ImageQualityTenant,
};

export const AccountQualityThumbnailSource: { [key in AccountQualityEnum]: any } = {
  [AccountQualityEnum.CoOwner]: ThumbnailQualityCoowner,
  [AccountQualityEnum.Landlord]: ThumbnailQualityLandlord,
  [AccountQualityEnum.Tenant]: ThumbnailQualityTenant,
};

export const AccountQualityColors: { [key in AccountQualityEnum]: string } = {
  [AccountQualityEnum.CoOwner]: "#3A91AE",
  [AccountQualityEnum.Landlord]: "#6973B7",
  [AccountQualityEnum.Tenant]: "#33AEA0",
};

// SRC: https://gitlab.factory.fonciamillenium.net/FonciaStark/front/-/blob/develop/packages/transformers/src/constants/accounts.js
export const HolderPropertyLabels: { [key in HolderPropertyEnum]: string } = {
  [HolderPropertyEnum.FullOwnership]: "Copropriétaire",
  [HolderPropertyEnum.NakedOwner]: "Nu-propriétaire",
  [HolderPropertyEnum.Usufructuary]: "Usufruitier",
  [HolderPropertyEnum.MergedJointOwner]: "Indivision",
  [HolderPropertyEnum.JointOwner]: "Indivisaire",
  [HolderPropertyEnum.JointOwnershipAccount]: "Compte de l'indivision",
};

export const RestrictedAccountMessage: { [key in AccountQualityEnum]: string } = {
  [AccountQualityEnum.Landlord]:
    "Suite à la fin de votre mandat de gestion, nous vous informons que vous aurez désormais un accès limité à votre espace MyFoncia.",
  [AccountQualityEnum.CoOwner]:
    "Suite à la fin de votre engagement contractuel avec Foncia, nous vous informons que vous aurez désormais un accès limité à votre espace MyFoncia.",
  [AccountQualityEnum.Tenant]:
    "Suite à la fin de votre contrat de bail, nous vous informons que vous aurez désormais un accès limité à votre espace MyFoncia. ",
};

export const DOUBLE_VERIFICATION_ERROR =
  "Erreur de paramètre, si vous avez copié le lien depuis l'e-mail reçu, veuillez vérifier à bien copier l'intégralité de l'adresse";

export const TWO_FACTOR_CODE_VALIDATION_ERROR = "Code incorrect";
