import styled from "styled-components";

import { mobileStyle } from "src/utils";

export const Headband = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.theme.spacing(2)};
  color: ${(props) => props.theme.palette.white.main};
  background-color: ${(props) => props.theme.palette.blue.main};
  border-radius: ${(props) => props.theme.spacing(0.5)};
  ${mobileStyle`
    margin: ${(props) => props.theme.spacing(1)};
    margin-bottom: ${(props) => props.theme.spacing(5)};
    border-radius: ${(props) => props.theme.spacing(0.5)};
  `}
`;
