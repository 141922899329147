import { Box, BoxProps } from "@mui/material";
import { rem } from "polished";
import React from "react";
import styled from "styled-components";

import { mobileStyle } from "src/utils";

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  height: ${rem(190)};
  background: #f5f9fa;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: ${rem(24)};
  color: ${(props) => props.theme.palette.secondary.main};
  ${mobileStyle`
  height: ${rem(100)};
`}
`;

export const Card = ({ children, ...boxProps }: BoxProps) => {
  return <StyledBox {...boxProps}>{children}</StyledBox>;
};
