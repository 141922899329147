import type { AccountFragment, BuildingFragment, Unit } from "src/graphql/generated/types";

export interface MainUnit extends Unit {
  building: BuildingFragment;
}

export const getDefaultMetadata = (
  account: AccountFragment
): { defaultBuildingId: string | null; defaultUnitId: string | undefined } => {
  if (account.__typename === "CoownerAccount") {
    return { defaultBuildingId: account.building.id, defaultUnitId: undefined };
  }

  if (account.__typename === "TenantAccount") {
    return { defaultBuildingId: account.building.id, defaultUnitId: account.mainUnit.id };
  }

  return { defaultBuildingId: null, defaultUnitId: undefined };
};

export const getBuildings = (account: AccountFragment): Array<BuildingFragment> | null => {
  if (account.__typename !== "LandlordAccount") return null;
  return account.buildings;
};

export const getBuildingMainUnits = (
  mainUnits?: Array<MainUnit> | null,
  buildingId?: string | null
): Array<MainUnit> | null => {
  if (!mainUnits || !buildingId) return null;
  return mainUnits.filter((mainUnit) => mainUnit.building.id === buildingId);
};

export const getMainUnits = (account: AccountFragment): Array<MainUnit> | null => {
  if (account.__typename !== "LandlordAccount") {
    return null;
  }

  const { buildings } = account;

  return account.mainUnits.reduce<Array<MainUnit>>((mainUnits, mainUnit) => {
    const building = buildings.find((building) =>
      building.units.some((unit) => unit.id === mainUnit.id)
    );

    if (!building) return mainUnits;

    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: "base" });

    return [...mainUnits, { ...mainUnit, building }].sort((u1, u2) =>
      collator.compare(u1.coOwnershipByLawsId || "0", u2.coOwnershipByLawsId || "0")
    );
  }, []);
};

export const getCoOwnershipBylawsId = (
  mainUnits?: Array<MainUnit> | null,
  unitIds?: Array<string> | null
): string | null => {
  if (!mainUnits || !unitIds) return null;
  return mainUnits
    .filter((mainUnit) => unitIds.includes(mainUnit.id))
    .map((mainUnit) => mainUnit.coOwnershipByLawsId || null)
    .join(" ");
};
