import { IbanErrorCodesEnum } from "src/graphql/generated/types";

export const IbanErrors: { [key in IbanErrorCodesEnum]: string } = {
  [IbanErrorCodesEnum.Error301]:
    "Une erreur est survenue, veuillez réessayer plus tard (Erreur 301)",
  [IbanErrorCodesEnum.Error302]:
    "Une erreur est survenue, veuillez réessayer plus tard (Erreur 302)",
  [IbanErrorCodesEnum.Error303]:
    "Une erreur est survenue, veuillez réessayer plus tard (Erreur 303)",
  [IbanErrorCodesEnum.Error304]:
    "Une erreur est survenue, veuillez réessayer plus tard (Erreur 304)",
  [IbanErrorCodesEnum.Error305]:
    "Une erreur est survenue, veuillez réessayer plus tard (Erreur 305)",
  [IbanErrorCodesEnum.Error201]: "Le numéro de compte est incorrect",
  [IbanErrorCodesEnum.Error202]: "L'IBAN renseigné est incorrect",
  [IbanErrorCodesEnum.Error203]: "Le nombre de caractères de l'IBAN est incorrect",
  [IbanErrorCodesEnum.Error205]: "La structure de l'IBAN est incorrecte",
  [IbanErrorCodesEnum.Error206]: "L'IBAN renseigné contient des caractères non autorisés",
  [IbanErrorCodesEnum.Error207]:
    "Le pays associé à votre compte ne supporte pas les standards IBAN",
};
