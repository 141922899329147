import { toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import React from "react";
import { useNavigate } from "react-router-dom";

import { oktaAuth } from "src/libs";

import AppRoutes from "../AppRoutes";

const SecurityLevel = () => {
  const navigate = useNavigate();

  const restoreOriginalUri = (_oktaAuth: any, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };
  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <AppRoutes />
    </Security>
  );
};

export default SecurityLevel;
