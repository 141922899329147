import { AccountingPeriodStatusEnum } from "src/graphql/generated/types";

export const AccountingPeriodStatusLabels: { [key in AccountingPeriodStatusEnum]: string } = {
  [AccountingPeriodStatusEnum.Open]: "ouvert",
  [AccountingPeriodStatusEnum.Divided]: "clôturé",
  [AccountingPeriodStatusEnum.Approved]: "validé",
  [AccountingPeriodStatusEnum.Refused]: "non réparti",
  [AccountingPeriodStatusEnum.ToAllocate]: "non réparti",
  [AccountingPeriodStatusEnum.ToBeValidatedByPropertyManager]: "non réparti",
  [AccountingPeriodStatusEnum.ToValidateByAccountant]: "non réparti",
  [AccountingPeriodStatusEnum.ToValidateByTrustee]: "non réparti",
};
export enum AccountingType {
  "AccountingSummary",
  "AccountingBuilding",
}

export type LandlordFiltersInputType = {
  startingDate: Date | null;
  endingDate: Date | null;
  selectedBuilding?: string;
};

export const SELECT_ALL_VALUE = "All";
export const SELECT_ALL_TEXT = "Tous";

export const ITEMS_PER_PAGE = 12;
