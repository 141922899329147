import "./i18n";
import * as React from "react";
import { createRoot } from "react-dom/client";

import { onConfigLoaded } from "./config";
import App from "./containers/App";
import { activateGTag } from "./utils/gtag";

onConfigLoaded(() => {
  activateGTag();
  const container = document.querySelector("#root");
  const root = createRoot(container!);
  root.render(<App />);
});
