import EuroIcon from "@mui/icons-material/Euro";
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import React, { useMemo } from "react";
import { Control, Controller, FieldError, FieldValues } from "react-hook-form";
import styled from "styled-components";

import { amountInCents, formatAmountInput } from "src/utils";

const StyledOutlinedInput = styled(OutlinedInput)`
  background-color: ${(props) => props.theme.palette.white.main};
`;

const MESSAGE_MIN_AMOUNT_CONTROL = "Le montant à prélever ne peut être inférieur à 1 €";
const MESSAGE_MAX_AMOUNT_CONTROL = "Le montant à prélever ne peut être supérieur au reste à payer";

type Props<T extends FieldValues> = {
  projectedBalance: number;
  error: FieldError | undefined;
  control: Control<T>;
  name?: string;
  messageMinAmountControl?: string;
  messageMaxAmountControl?: string;
};

export const AmountFormControl = <TFieldValues extends FieldValues>({
  projectedBalance,
  error,
  control,
  name = "amount",
  messageMinAmountControl = MESSAGE_MIN_AMOUNT_CONTROL,
  messageMaxAmountControl = MESSAGE_MAX_AMOUNT_CONTROL,
}: Props<TFieldValues>) => {
  const rules = useMemo(
    () => ({
      required: "Un montant est requis",
      validate: (value: any) => {
        const amountValue = amountInCents(value);

        if (amountValue < 100) {
          // < 1€
          return messageMinAmountControl;
        }
        if (amountValue > Math.abs(projectedBalance)) {
          return messageMaxAmountControl;
        }
        return true;
      },
    }),
    [projectedBalance]
  );
  return (
    <FormControl fullWidth variant="outlined" error={Boolean(error)}>
      <Controller
        // @ts-ignore: Unreachable code error
        name={name}
        control={control}
        rules={rules}
        render={({ field: { value, onChange } }) => (
          <StyledOutlinedInput
            name={name}
            value={value}
            onChange={(event) => {
              onChange(formatAmountInput(event.target.value));
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton disabled>
                  <EuroIcon color={error ? "error" : "inherit"} />
                </IconButton>
              </InputAdornment>
            }
          />
        )}
      />
      <FormHelperText>{error?.message}</FormHelperText>
    </FormControl>
  );
};
