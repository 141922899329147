import { BoxProps, Box, Typography } from "@mui/material";
import React from "react";

import { Icon, IconName } from "src/components";

type Props = {
  isSelected: boolean;
  icon: IconName;
  label: string;
  disabled?: boolean;
} & BoxProps;

export const CategoryCard = ({ isSelected, icon, label, disabled, ...props }: Props) => (
  <Box
    sx={{
      backgroundColor: isSelected ? "primary.main" : "tickets.boxBackground",
      color: isSelected ? "white.main" : "tickets.categoryText",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      cursor: disabled ? "unset" : "pointer",
      borderColor: "tickets.border",
      borderStyle: "solid",
      borderRadius: 1,
      borderWidth: 0.5,
      p: 2,
      minHeight: 100,
    }}
    {...props}
  >
    <Icon name={icon} size={50} style={{ fill: isSelected ? "white" : undefined }} />
    <Typography sx={{ ml: 2, fontWeight: "bold" }}>{label}</Typography>
  </Box>
);
