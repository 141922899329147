import InfoIcon from "@mui/icons-material/Info";
import {
  CircularProgress,
  IconButton,
  OutlinedInput,
  OutlinedInputProps,
  Tooltip,
} from "@mui/material";
import React from "react";
import MaskedInput from "react-text-mask";

// @note: source https://en.wikipedia.org/wiki/International_Bank_Account_Number
// shorter iban : Norway - 15 char (18 with spaces)
// longer iban : Saint Lucia - 32 char (39 with spaces)

const l = /[a-zA-Z0-9]/;
const o = /^[a-zA-Z0-9]?$/;
const beg = [l, l, l, l];
const req = [" ", l, l, l, l];
const one = [" ", l, l, l, o];
const opt = [" ", o, o, o, o];
const ibanMask = [...beg, ...req, ...req, ...one, ...opt, ...opt, ...opt, ...opt];

const TextMaskCustom = React.forwardRef((props, ref) => {
  const setRef = React.useCallback(
    (maskedInputRef: any) => {
      const value = maskedInputRef?.inputElement || null;

      if (typeof ref === "function") {
        ref(value);
      } else if (ref) {
        // eslint-disable-next-line no-param-reassign
        ref.current = value;
      }
    },
    [ref]
  );

  // @ts-ignore
  return <MaskedInput {...props} ref={setRef} mask={ibanMask} guide={false} />;
});

type Props = {
  loading: boolean;
} & OutlinedInputProps;

export const IbanInput = ({ onChange, loading, ...props }: Props) => {
  return (
    <OutlinedInput
      {...props}
      onChange={(e) => {
        e.target.value = e.target.value.toUpperCase();
        return onChange && onChange(e);
      }}
      name="iban"
      label="N° IBAN"
      placeholder="FR12 3456 7890 1234 5678 901"
      inputComponent={TextMaskCustom as any}
      endAdornment={
        loading ? (
          <CircularProgress size={30} />
        ) : (
          <Tooltip
            title="En France, cet identifiant compte 27 caractères et commence par « FR ». Vous pouvez le trouver sur votre RIB"
            placement="right"
            arrow
          >
            <IconButton aria-label="iban-infos">
              <InfoIcon />
            </IconButton>
          </Tooltip>
        )
      }
    />
  );
};
