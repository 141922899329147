import React from "react";

import { useAuthStore } from "src/stores/auth-store";

type Props = {
  children: React.ReactNode;
};

export const SsoWrapper = ({ children }: Props) => {
  const login = useAuthStore((state) => state.login);

  // @note: handle potential SSO token
  const parsedUrl = new URL(window.location.href);
  const params = new URLSearchParams(parsedUrl.searchParams);
  const ssoToken = params.get("sso");

  if (ssoToken) {
    params.delete("sso");
    const urlWithoutSso = `${parsedUrl.origin}${parsedUrl.pathname}${
      params.toString() ? `?${params.toString()}` : ""
    }`;
    window.history.replaceState("", "", urlWithoutSso);

    login(ssoToken);
  }

  return children as React.ReactElement;
};
