import IcomoonReact from "icomoon-react";
import React, { ComponentProps } from "react";
import styled from "styled-components";

import iconSet from "../../assets/fonts/selection.json";
import { IconName } from "./types";

export type { IconName } from "./types";

const IcomoonComponent = (props: ComponentProps<typeof IcomoonReact>) => (
  <IcomoonReact {...props} />
);

const StyledIcon = styled(IcomoonComponent)`
  fill: ${(props) => props.theme.palette.primary.main};
`;

interface Props extends Omit<ComponentProps<typeof IcomoonReact>, "icon" | "iconSet"> {
  name: IconName;
}

export const Icon = ({ name, size = 24, ...rest }: Props) => {
  return <StyledIcon iconSet={iconSet} icon={name} size={size} {...rest} />;
};
