import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Typography } from "@mui/material";
import React, { ReactNode } from "react";

import { useDeviceWidth } from "src/hooks";

import { sectionContainer, rightNodeContainer, labelContainer, labelStyle } from "./styles";

type Props = {
  text: string;
  rightNode?: ReactNode;
};

export const ActionsSection = ({ text, rightNode }: Props) => {
  const { isMobile } = useDeviceWidth();

  return (
    <Box
      sx={{
        ...sectionContainer,
        flexDirection: isMobile ? "column" : undefined,
      }}
    >
      <Box sx={labelContainer}>
        <InfoOutlinedIcon color="inherit" />
        <Typography sx={labelStyle}>{text}</Typography>
      </Box>
      <Box sx={rightNodeContainer}>{rightNode}</Box>
    </Box>
  );
};
