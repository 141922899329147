import { useQueryClient } from "@tanstack/react-query";

import { GetMeQuery } from "src/graphql/generated/types";
import { notUndefined } from "src/utils";

/*
This hook should only be used when user is Login
*/

export const useCurrentLogin = (): string => {
  const queryClient = useQueryClient();

  const data = notUndefined(
    queryClient.getQueryData<GetMeQuery>(["getMe"])
  );

  return data.me.login;
};
