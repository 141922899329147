import { Scalars, MyFonciaFileCategoryEnum } from "src/graphql/generated/types";

// Public paths
export const PATH_ROOT = "/";
export const PATH_PUBLIC_LOGIN = "/login";
export const PATH_PUBLIC_RESET_PASSWORD = "/reset";
export const PATH_PUBLIC_VERIFY_LOGIN = "/verify-login";
export const PATH_FIRST_CONNECTION = "/first-connection";

// Private login paths
export const PATH_TEMPORARY_LOGIN = "/login-first-connection";
export const PATH_VERIFY_CREDENTIALS = "/verify-credentials";
export const PATH_RESET_PASSWORD = "/reset";
export const PATH_LOGIN_ASSOCIATION = "/first-connection/login-association-confirmation";
export const VERIFY_PASSWORD_UPDATE_PATH = "/update-password";
export const VERIFY_BANKING_INFORMATION_UPDATE_PATH = "/update-iban";

// Authenticated paths
export const PATH_AUTH = "/account";
export const PATH_SELECTED_ACCOUNT_BASE = `${PATH_AUTH}/:uuid`;

export const PATH_ACCOUNTING = `${PATH_SELECTED_ACCOUNT_BASE}/accounting`;
export const PATH_DASHBOARD = "/home";
export const PATH_DASHBOARD_RESTRICTED = "/home-restricted";
export const PATH_ESTATE = "/estate";
export const PATH_GLOBAL_ACCOUNTING = `${PATH_ACCOUNTING}/global`;
export const PATH_BUILDING_ACCOUNTING = `${PATH_ACCOUNTING}/building`;

export const PATH_DOCUMENTS_CATEGORIES = "/documents";
export const PATH_DOCUMENTS_LIST = `${PATH_DOCUMENTS_CATEGORIES}/:categoryKey`;
export const PATH_DOCUMENTS_OK_FONCIA = `${PATH_DOCUMENTS_CATEGORIES}/okFoncia`;

export const PATH_PAYMENTS = "/payments";
export const PATH_PAYMENTS_SUMMARY = `${PATH_PAYMENTS}/summary`;
export const PATH_PAYMENTS_AUTOMATIC = `${PATH_PAYMENTS}/automatic`;
export const PATH_PAYMENTS_ON_DEMAND = `${PATH_PAYMENTS}/on-demand`;

export const PATH_TICKETS = `${PATH_SELECTED_ACCOUNT_BASE}/tickets`;
export const PATH_TICKET_MESSAGES = "/ticket/:ticketId";

export const PATH_PROFILE = "/profile";
export const PATH_PROFILE_CREDENTIALS = `${PATH_PROFILE}/credentials`;
export const PATH_PROFILE_INFOS = `${PATH_PROFILE}/infos`;
export const PATH_PROFILE_LANDLORD_BANKINFOS = `${PATH_PROFILE}/landlord-bankinfos`;
export const PATH_PROFILE_PAYMENT = `${PATH_PROFILE}/payment`;
export const PATH_PROFILE_CONFIDENTIALITY = `${PATH_PROFILE}/confidentiality`;
export const PATH_PROFILE_PREFERENCES = `${PATH_PROFILE}/preferences`;

export const PATH_COUNCIL = "/council";
export const PATH_COUNCIL_MEETINGS = `${PATH_COUNCIL}/meetings`;
export const PATH_COUNCIL_SERVICES = `${PATH_COUNCIL}/services`;
export const PATH_COUNCIL_WORKS = `${PATH_COUNCIL_SERVICES}/works`;
export const PATH_COUNCIL_INCIDENTS = `${PATH_COUNCIL_SERVICES}/incidents`;
export const PATH_COUNCIL_COOWNERS = `${PATH_COUNCIL}/coowners`;
export const PATH_COUNCIL_SUPPLIERS = `${PATH_COUNCIL}/suppliers`;
export const PATH_COUNCIL_VISITS = `${PATH_COUNCIL}/visits`;
export const PATH_COUNCIL_ACCOUNTING = `${PATH_COUNCIL}/accounting`;
export const PATH_COUNCIL_ACCOUNTING_HOME = `${PATH_COUNCIL_ACCOUNTING}/home`;
export const PATH_COUNCIL_ACCOUNTING_REPAIR = `${PATH_COUNCIL_ACCOUNTING}/repair`;
export const PATH_COUNCIL_ACCOUNTING_RGDD = `${PATH_COUNCIL_ACCOUNTING}/rgdd`;
export const PATH_COUNCIL_ACCOUNTING_REPAIR_BUDGETS = `${PATH_COUNCIL_ACCOUNTING}/repair-budgets`;
export const PATH_COUNCIL_PROJECT_DOCUMENTS = `${PATH_COUNCIL}/project-documents`;

export const PATH_OFFERS_SERVICES = `${PATH_SELECTED_ACCOUNT_BASE}/offers-services`;
export const PATH_SALE_AGREEMENT_DOCUMENTS = "documents/coOwnershipTransfer";
export const PATH_PAYMENT_DALENYS = "/payment-dalenys";

export const PATH_GENERAL_ASSEMBLY = `${PATH_SELECTED_ACCOUNT_BASE}/general-assembly`;
export const PATH_POSTAL_VOTING = `${PATH_GENERAL_ASSEMBLY}/postal-voting`;

export type AccountPageParams = {
  uuid: Scalars["EncodedID"];
};

export type TicketPageParams = {
  ticketId: string;
};

export type DocumentListPageParams = AccountPageParams & {
  categoryKey: MyFonciaFileCategoryEnum;
};
