import { BoxProps, Box } from "@mui/material";
import { rem } from "polished";
import React from "react";
import styled from "styled-components";

import { AmountFragment } from "src/graphql/generated/types";
import { formatAmount, getAmountColor, mobileStyle } from "src/utils";

import { Card } from "../Card";

interface Props extends BoxProps {
  currentBalance: AmountFragment;
  projectedBalance: AmountFragment;
  isLandlord?: boolean;
}

const StyledCard = styled(Card)`
  ${mobileStyle`
    border-bottom: ${rem(1)} solid ${(props) => props.theme.palette.background.default};
  `}
`;

const Balance = styled.div`
  font-weight: 500;

  ${mobileStyle`
    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`;

const BalanceTitle = styled.span`
  font-size: ${rem(16)};
`;

const BalanceAmount = styled.span<AmountFragment>`
  font-size: ${rem(22)};
  font-weight: bold;
  color: ${({ value }) => getAmountColor(value)};

  ${mobileStyle`
    font-size: ${rem(22)};
    line-height: 30px;
    margin-top: ${rem(4)};
  `}
`;

export const CardBalances = ({
  currentBalance,
  projectedBalance,
  isLandlord,
  ...boxProps
}: Props) => {
  return (
    <StyledCard {...boxProps}>
      <Balance>
        <BalanceTitle>Solde actuel : </BalanceTitle>
        <BalanceAmount {...currentBalance}>{formatAmount(currentBalance)}</BalanceAmount>
      </Balance>
      {(currentBalance.value !== projectedBalance.value ||
        currentBalance.currency !== projectedBalance.currency) && (
        <Balance>
          {isLandlord ? "Réserve pour dépenses à venir : " : "Solde à venir : "}
          <Box
            component="span"
            sx={projectedBalance.value < 0 ? { color: "amountColor.negative" } : undefined}
          >
            {formatAmount(projectedBalance)}
          </Box>
        </Balance>
      )}
    </StyledCard>
  );
};
