import {
  Autocomplete,
  Divider,
  InputAdornment,
  TextField,
  OutlinedInput,
  Paper,
  Typography,
  createFilterOptions,
} from "@mui/material";
import ReactFormatInput from "input-format/react";
import parsePhoneNumber from "libphonenumber-js";
import { rem } from "polished";
import React, { ComponentProps, useRef, useState } from "react";
import styled from "styled-components";

import { CountryCodeOption, CountryCodeOptions } from "src/constants";

import { parse, format } from "./_utils";

const StyledInputAdornment = styled(InputAdornment)`
  position: relative;
  height: auto;
  max-height: none;
  padding-right: ${rem(4)};
`;

const StyledFlagImg = styled.img`
  width: ${rem(24)};
  margin-right: ${(props) => props.theme.spacing(2)};
`;

const StyledPaper = styled(Paper)`
  width: ${rem(250)};
  margin-left: ${rem(-45)};
`;

const StyledTextField = styled(TextField)`
  .MuiInput-underline:before {
    border: none;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border: none;
  }
  .MuiInput-underline:after {
    border: none;
  }
`;

const StyledDivider = styled(Divider)`
  position: absolute;
  top: 0;
  right: 0;
`;

const DefaultCountryCodeOption: CountryCodeOption =
  CountryCodeOptions.find((phoneOption) => phoneOption.countryCode === "FR") ||
  CountryCodeOptions[0];

const filterOptions = createFilterOptions({
  stringify: (option: CountryCodeOption) => option.name.fra,
});

interface Props {
  name: string;
  value: string;
  onChange(value: string): void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CustomFormatInput = React.forwardRef(function CustomFormatInput(props: any, ref) {
  const { inputRef, onChangeValue, ...rest } = props;

  return <ReactFormatInput {...rest} ref={inputRef} onChange={onChangeValue} />;
});

const PaperComponent = (props: ComponentProps<typeof Paper>) => <StyledPaper {...props} />;

export const PhoneInput = ({ name, value, onChange }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [focused, setFocused] = useState(false);
  const [countryCodeOption, setCountryCodeOption] = useState<CountryCodeOption>(() => {
    const phoneNumber = parsePhoneNumber(value);
    return (
      CountryCodeOptions.find((phoneOption) => phoneOption.countryCode === phoneNumber?.country) ||
      DefaultCountryCodeOption
    );
  });

  const onChangeCountryCode = (_event: React.ChangeEvent<{}>, value?: CountryCodeOption | null) => {
    setCountryCodeOption(value || DefaultCountryCodeOption);
    onChange("");
    inputRef.current?.focus();
  };

  return (
    <OutlinedInput
      name={name}
      value={value}
      className={focused ? "Mui-focused" : undefined}
      inputComponent={CustomFormatInput as any}
      inputProps={{
        inputRef,
        onChangeValue: (value: string) => {
          const phoneNumber = parsePhoneNumber(value, countryCodeOption.countryCode);

          onChange(phoneNumber?.number.toString() || value.trim());
        },
        parse,
        format: (value: string) => format(value, countryCodeOption.countryCode),
      }}
      startAdornment={
        <StyledInputAdornment position="start">
          <StyledFlagImg src={countryCodeOption.flag} alt={countryCodeOption.countryCode} />
          <Autocomplete
            disableClearable
            noOptionsText="Aucun résultat"
            filterOptions={filterOptions}
            getOptionLabel={(option) => option.countryCode}
            renderOption={(props, option) => (
              <li {...props}>
                <StyledFlagImg src={option.flag} alt={option.countryCode} />
                <Typography>
                  {option.name.fra} (+{option.callingCode})
                </Typography>
              </li>
            )}
            PaperComponent={PaperComponent}
            value={countryCodeOption}
            onChange={onChangeCountryCode}
            options={CountryCodeOptions}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                placeholder="Pays"
                variant="standard"
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
              />
            )}
          />
          <StyledDivider orientation="vertical" />
        </StyledInputAdornment>
      }
    />
  );
};
