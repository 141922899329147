import { Box, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React, { useMemo } from "react";
import styled from "styled-components";

import { getFutureYearOptions, getPastYearOptions } from "src/utils";

export type DateType = {
  year: number;
  month: number | null;
} | null;

interface Props {
  dateFilter: DateType;
  period: "PAST" | "FUTURE";
  onChangeDate: (e: SelectChangeEvent<unknown>) => void;
  onChangeMonth: (e: SelectChangeEvent<unknown>) => void;
}

const StyledSelectField = styled(Select)`
  background-color: ${(props) => props.theme.palette.white.main};
`;

export const NO_SELECTED_CATEGORIES_VALUE = "_all";

const MonthsLabel = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];

const months = [...Array(12)].map((_, index) => {
  return { label: MonthsLabel[index], value: index + 1 };
});

export const DateFilter = ({ dateFilter, period, onChangeDate, onChangeMonth }: Props) => {
  const years = useMemo(() => {
    return period === "PAST" ? getPastYearOptions() : getFutureYearOptions();
  }, [period]);

  return (
    <Box component="td" sx={{ display: "flex" }}>
      <StyledSelectField
        fullWidth
        variant="outlined"
        value={dateFilter?.year || NO_SELECTED_CATEGORIES_VALUE}
        onChange={onChangeDate}
      >
        <MenuItem value={NO_SELECTED_CATEGORIES_VALUE}>
          <em>Année</em>
        </MenuItem>
        {years.map((year, index) => (
          <MenuItem key={index} value={year}>
            {year}
          </MenuItem>
        ))}
      </StyledSelectField>

      <StyledSelectField
        disabled={!dateFilter?.year}
        fullWidth
        variant="outlined"
        value={dateFilter?.month || NO_SELECTED_CATEGORIES_VALUE}
        onChange={onChangeMonth}
      >
        <MenuItem value={NO_SELECTED_CATEGORIES_VALUE}>
          <em>Mois</em>
        </MenuItem>
        {months.map((month, index) => (
          <MenuItem key={index} value={month.value}>
            {month.label}
          </MenuItem>
        ))}
      </StyledSelectField>
    </Box>
  );
};
