/* eslint-disable import/no-duplicates */
import { format } from "date-fns";
import { fr } from "date-fns/locale";

export const generateAccountingExportFilename = (pageNumber: number) => {
  const filenameDate = format(new Date(), "dd_MM_yyyy", {
    locale: fr,
  });

  return `comptabilite_${filenameDate}_page_${pageNumber}.csv`;
};
