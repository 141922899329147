import React from "react";
import { Route, Navigate, Routes } from "react-router-dom";

import { AuthLayout, LogoutComponent } from "src/components";
import {
  PATH_PUBLIC_LOGIN,
  PATH_ROOT,
  FIRST_CONNECTION_PAGE,
  RESET_PASSWORD_PAGE,
  VERIFY_CREDENTIALS_PAGE,
  VERIFY_PASSWORD_UPDATE_PAGE,
  VERIFY_BANKING_INFORMATION_UPDATE_PAGE,
  LOGIN_PAGE,
  TEMPORARY_LOGIN_PAGE,
  LOGIN_ASSOCIATION_PAGE,
  PATH_FIRST_CONNECTION,
  PATH_LOGIN_ASSOCIATION,
  PATH_RESET_PASSWORD,
  PATH_TEMPORARY_LOGIN,
  PATH_VERIFY_CREDENTIALS,
  VERIFY_BANKING_INFORMATION_UPDATE_PATH,
  VERIFY_PASSWORD_UPDATE_PATH,
} from "src/constants";
import { useAuthStore } from "src/stores/auth-store";

import { FeatureFlagsWrapper } from "../FeatureFlagsWrapper";
import { GetMeWrapper } from "../GetMeWrapper";
import lazyWithRetry from "../LazyWithRetry";
import { AuthenticatedPages } from "../Router";
import LoginCallback from "../Router/LoginCallback";

const FirstConnection = lazyWithRetry(() => import("src/pages/FirstConnection"));
const Login = lazyWithRetry(() => import("src/pages/Login"));
const ResetPassword = lazyWithRetry(() => import("src/pages/ResetPassword"));
const Root = lazyWithRetry(() => import("src/pages/Root"));
const TemporaryLogin = lazyWithRetry(() => import("src/pages/TemporaryLogin"));
const VerifyAssociation = lazyWithRetry(() => import("src/pages/VerifyAssociation"));
const VerifyBankingInformationUpdate = lazyWithRetry(
  () => import("src/pages/VerifyBankingInformationUpdate")
);
const VerifyCredentials = lazyWithRetry(() => import("src/pages/VerifyCredentials"));
const VerifyPasswordUpdate = lazyWithRetry(() => import("src/pages/VerifyPasswordUpdate"));

const AppRoutes = () => {
  const token = useAuthStore((state) => state.token);

  const hasPrivateLoginPathname = [
    PATH_FIRST_CONNECTION,
    PATH_TEMPORARY_LOGIN,
    PATH_VERIFY_CREDENTIALS,
    PATH_RESET_PASSWORD,
    PATH_LOGIN_ASSOCIATION,
    VERIFY_BANKING_INFORMATION_UPDATE_PATH,
    VERIFY_PASSWORD_UPDATE_PATH,
  ].some((path) => window.location.href.includes(path));
  return (
    <>
      {!token && (
        <AuthLayout>
          <Routes>
            <Route path="*" element={<Navigate replace to={PATH_PUBLIC_LOGIN} />} />
            <Route path="/login/callback" element={<LoginCallback />} />
            <Route element={<Navigate replace to={PATH_PUBLIC_LOGIN} />} />
            <Route path={PATH_ROOT} element={<Root />} />
            <Route path={FIRST_CONNECTION_PAGE} element={<FirstConnection />} />
            <Route path={RESET_PASSWORD_PAGE} element={<ResetPassword />} />
            <Route path={VERIFY_CREDENTIALS_PAGE} element={<VerifyCredentials />} />
            <Route path={VERIFY_PASSWORD_UPDATE_PAGE} element={<VerifyPasswordUpdate />} />
            <Route
              path={VERIFY_BANKING_INFORMATION_UPDATE_PAGE}
              element={<VerifyBankingInformationUpdate />}
            />
            <Route path="*" element={<Navigate replace to={PATH_PUBLIC_LOGIN} />} />
            <Route path={LOGIN_PAGE} element={<Login />} />
          </Routes>
        </AuthLayout>
      )}
      {token && (
        <GetMeWrapper>
          {hasPrivateLoginPathname ? (
            <AuthLayout>
              <Routes>
                <Route path={FIRST_CONNECTION_PAGE} element={<LogoutComponent />} />
                <Route path={TEMPORARY_LOGIN_PAGE} element={<TemporaryLogin />} />
                <Route path={VERIFY_CREDENTIALS_PAGE} element={<LogoutComponent />} />
                <Route path={RESET_PASSWORD_PAGE} element={<LogoutComponent />} />
                <Route path={LOGIN_ASSOCIATION_PAGE} element={<VerifyAssociation />} />
                <Route path={VERIFY_PASSWORD_UPDATE_PAGE} element={<VerifyPasswordUpdate />} />
                <Route
                  path={VERIFY_BANKING_INFORMATION_UPDATE_PAGE}
                  element={<VerifyBankingInformationUpdate />}
                />
              </Routes>
            </AuthLayout>
          ) : (
            <FeatureFlagsWrapper>
              <AuthenticatedPages />
            </FeatureFlagsWrapper>
          )}
        </GetMeWrapper>
      )}
    </>
  );
};

export default AppRoutes;
