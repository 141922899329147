import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputBaseComponentProps,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import React, { useState } from "react";
import { Controller, FieldValues, Path, UseControllerProps } from "react-hook-form";

interface Props<T extends FieldValues> {
  error: string | undefined;
  control: UseControllerProps<T>["control"];
  rules?: UseControllerProps<T>["rules"];
  label?: string;
  placeholder?: string;
  controllerName?: string;
  inputProps?: InputBaseComponentProps;
}

export function PasswordInput<T extends FieldValues>({
  error,
  control,
  rules,
  label = "Votre mot de passe",
  placeholder = "Votre mot de passe",
  controllerName = "password",
  inputProps,
}: Props<T>) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <FormControl fullWidth variant="outlined" error={error !== undefined}>
      <InputLabel htmlFor="password">{label}</InputLabel>
      <Controller<T>
        name={controllerName as Path<T>}
        control={control}
        rules={rules}
        render={({ field }) => (
          <OutlinedInput
            label={label}
            type={showPassword ? "text" : "password"}
            placeholder={placeholder}
            inputProps={inputProps}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle current password visibility"
                  onClick={() => setShowPassword(true)}
                  onMouseDown={() => setShowPassword(false)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            {...field}
          />
        )}
      />
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
}
