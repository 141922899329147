import { useOktaAuth } from "@okta/okta-react";
import React, { useEffect } from "react";

import { Loader } from "src/components";
import { useAuthStore } from "src/stores/auth-store";

const LoginCallback = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const { login } = useAuthStore();

  useEffect(() => {
    async function handleLogin() {
      await oktaAuth.handleLoginRedirect();
    }

    handleLogin();
  }, [oktaAuth]);

  useEffect(() => {
    if (authState?.isAuthenticated && authState.accessToken) {
      login(authState.accessToken.accessToken);
    }
  }, [authState, login]);

  return <Loader />;
};

export default LoginCallback;
