import { SxProps } from "@mui/material";

export const containerStyle: SxProps = {
  display: "flex",
  bgcolor: "gray.pageContainerBackground",
  flexDirection: "column",
  justifyContent: "center",
  borderRadius: 2,
  p: 2,
};

export const buttonStyle: SxProps = {
  margin: "0 auto",
  my: 2,
  fontWeight: "bold",
  fontSize: 13,
};

export const mobileButtonStyle: SxProps = {
  ...buttonStyle,
  position: "sticky",
  bottom: 20,
  width: "100%",
};

export const separatorStyle: SxProps = { mx: 1 };
