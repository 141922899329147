import { addTypenameToDocument } from "@apollo/client/utilities";
import { GraphQLError, parse } from "graphql";
import { request } from "graphql-request";

import appConfig from "config/app";
import { APOLLO_ERROR_CODES } from "src/constants";
import { useAuthStore } from "src/stores/auth-store";
import { logger, maskSensitiveData, stringify } from "src/utils";

import { PlatoErrorCodesEnum } from "./generated/types";

interface ErrorResponse {
  message: string;
  path: Array<string>;
  extensions: {
    [key: string]: any;
  };
}

export interface ServerError {
  response?: {
    errors?: Array<GraphQLError>;
  };
  request?: {
    query?: string;
    variables?: {
      [key: string]: any;
    };
  };
}

interface Error {
  response: {
    errors: Array<ErrorResponse>;
  };
  request: {
    [key: string]: any;
  };
}

const handleErrors = (errors: Array<ErrorResponse>) => {
  const { logout } = useAuthStore.getState();

  errors.forEach(async (error) => {
    const { message, path, extensions } = error;
    const isUpdatePasswordPath = path && path[0] === "updatePassword";

    if (extensions?.code === APOLLO_ERROR_CODES.UNAUTHENTICATED && !isUpdatePasswordPath) {
      logout();
      return;
    }

    // @note : Don't log login user errors
    if (
      extensions?.errorCode === PlatoErrorCodesEnum.LoginAlreadyChanged ||
      (extensions?.code === APOLLO_ERROR_CODES.UNAUTHENTICATED && isUpdatePasswordPath)
    ) {
      return;
    }

    logger.error(
      `[GraphQL error]: Message: ${message}, Path: ${path}, Extensions: ${stringify(extensions)}`
    );
  });
};

export const fetcher = <TData, TVariables extends Record<string, any>>(
  query: string,
  variables?: TVariables
): (() => Promise<TData>) => {
  return async () => {
    const { token } = useAuthStore.getState();

    const requestHeaders = {
      authorization: token ? `Bearer ${token}` : "",
    };

    // Adds "__typename" to the query
    const document = addTypenameToDocument(parse(query));

    try {
      logger.info(
        `query ${query} start with variables: ${JSON.stringify(maskSensitiveData(variables))}`
      );

      if (appConfig.graphql.url) {
        const data = await request(
          `${appConfig.graphql.url}/graphql`,
          document,
          variables,
          requestHeaders
        );

        logger.info(`query ${query} end with: ${JSON.stringify(data)}`);

        return data;
      }

      return null;
    } catch (err) {
      const error = err as Error;
      if (error?.response?.errors) {
        handleErrors(error.response.errors);
      }

      throw error;
    }
  };
};
