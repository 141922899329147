import { Chip, ChipProps } from "@mui/material";
import React from "react";
import styled from "styled-components";

import { PaymentStatusColor, PaymentStatusLabel } from "src/constants";
import { MediaPaymentStatusEnum } from "src/graphql/generated/types";

interface Props extends ChipProps {
  status: MediaPaymentStatusEnum;
}

const StyledChip = styled(Chip)<{ status: MediaPaymentStatusEnum }>`
  background-color: ${(props) => PaymentStatusColor[props.status]};
  color: ${(props) => props.theme.palette.white.main};
  span {
    font-weight: bold;
  }
`;

export const ChipPaymentStatus = ({ status, ...chipProps }: Props) => {
  return <StyledChip status={status} label={PaymentStatusLabel[status]} {...chipProps} />;
};
