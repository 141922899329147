import { TableSortLabel } from "@mui/material";
import { rem } from "polished";
import React from "react";
import styled from "styled-components";

import { SortDirectionEnum, SortByType } from "src/graphql/generated/types";

import { ListColumnsConfig } from "../index";
import { ListSelectionCheckbox } from "./SelectionCheckbox";

type Props<T = any> = {
  isMaxSelectedReached: boolean;
  columnsConfig: ListColumnsConfig<T>;
  toggleAll: (checked: boolean) => void;
  areAllRowSelected: boolean;
  // Sorting
  activeSort?: SortByType;
  onSort?: (newSort: SortByType) => void;
  defaultSortDirection: SortDirectionEnum;
};

const StyledHeader = styled.tr<Pick<Props, "columnsConfig">>`
  background: ${(props) => props.theme.palette.blue.main};
  color: ${(props) => props.theme.palette.blue.contrastText};
  width: 100%;

  > th .MuiIconButton-label {
    color: ${(props) => props.theme.palette.white.main};
  }

  ${(props) =>
    props.columnsConfig.map((c, index) => {
      // Column Width
      let widthValue = "auto";
      if (c.type === "selection" || c.type === "expand") {
        widthValue = "4%";
      } else if (c.width) {
        widthValue = `${c.width}%`;
      }

      // TextAlign
      let textAlign = "left";
      if (c.headerAlign) {
        textAlign = c.headerAlign;
      }

      return `& th:nth-child(${index + 1}) {
        width: ${widthValue};
        text-align: ${textAlign};
      }`;
    })}

  > th {
    padding: ${(props) =>
      props.theme.spacing(props.columnsConfig.find((c) => c.type === "selection") ? 1 : 2)};
    font-weight: bold;
    font-size: ${rem(13)};
  }
`;

const StyledTableSortLabel = styled(TableSortLabel)`
  &.MuiButtonBase-root.MuiTableSortLabel-root {
    color: white;

    & svg.MuiTableSortLabel-icon {
      fill: white;
      opacity: 0.3;
    }

    &:hover svg.MuiTableSortLabel-icon {
      opacity: 0.6;
    }

    &.MuiTableSortLabel-active svg.MuiTableSortLabel-icon {
      opacity: 1;
    }
  }
`;

function convertDirectionToMUI(direction: SortDirectionEnum): "asc" | "desc" {
  return direction === SortDirectionEnum.Asc ? "asc" : "desc";
}

export const ListDefaultHeader = React.memo(
  ({
    isMaxSelectedReached,
    columnsConfig,
    toggleAll,
    areAllRowSelected,
    activeSort,
    onSort,
    defaultSortDirection,
  }: Props) => {
    return (
      <StyledHeader columnsConfig={columnsConfig}>
        {columnsConfig.map((c, index) => {
          let content = null;

          if (c.type === "selection") {
            content = (
              <ListSelectionCheckbox
                isMaxSelectedReached={isMaxSelectedReached}
                checked={areAllRowSelected}
                onChange={toggleAll}
              />
            );
          } else if (c.type === "expand") {
            content = null;
          } else if (activeSort && onSort && c.sortableKey) {
            let nextDirection: SortDirectionEnum = defaultSortDirection;
            if (activeSort.key === c.sortableKey) {
              nextDirection =
                activeSort.direction === SortDirectionEnum.Asc
                  ? SortDirectionEnum.Desc
                  : SortDirectionEnum.Asc;
            }

            content = (
              <StyledTableSortLabel
                onClick={() => {
                  if (!c.sortableKey) {
                    return;
                  }

                  onSort({
                    key: c.sortableKey,
                    direction: nextDirection,
                  });
                }}
                active={activeSort.key === c.sortableKey}
                direction={convertDirectionToMUI(
                  activeSort.key === c.sortableKey ? activeSort.direction : nextDirection
                )}
                hideSortIcon={false}
              >
                {c.label}
              </StyledTableSortLabel>
            );
          } else {
            content = c.label;
          }

          return <th key={index}>{content}</th>;
        })}
      </StyledHeader>
    );
  }
);
