import MessageIcon from "@mui/icons-material/Message";
import { Box, Button } from "@mui/material";
import { InfiniteData, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { ErrorPage, Loader } from "src/components";
import { TicketPageParams, TICKETS_PAGE, TICKET_CREATION_ERROR } from "src/constants";
import {
  useAddMessageTicketMutation,
  useApproveTicketMutation,
  useGetTicketQuery,
  useUpdateIsReadTicketMutation,
} from "src/graphql/generated/queries";
import {
  TicketStatusEnum,
  GetTicketsQuery,
  AnalyticsCustomEventNameEnum,
} from "src/graphql/generated/types";
import { useCurrentAccount, useDeviceWidth } from "src/hooks";
import { logCustomEvent, notUndefined } from "src/utils";

import { CreateTicket } from "../_shared/CreateTicket";
import { filterInfiniteTicketsQuery } from "../_shared/Tickets/_utils";
import { ActionsSection } from "./_components/ActionsSection";
import { TicketContent } from "./_components/TicketContent";
import { TicketInfoSection } from "./_components/TicketInfoSection";
import { TicketModal } from "./_components/TicketModal";
import { mobileButtonStyle, buttonStyle, containerStyle, separatorStyle } from "./styles";

export type AddMessageInputs = {
  content: string;
  documents: Array<File> | null;
};

export const TicketMessages = () => {
  const navigate = useNavigate();
  const params = useParams<TicketPageParams>();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { isMobile } = useDeviceWidth();

  const { uuid, hasRestrictedAccess } = useCurrentAccount();
  const {
    control,
    watch,
    handleSubmit,
    reset: resetForm,
    formState: { errors },
  } = useForm<AddMessageInputs>();

  const [openModal, setOpenModal] = useState(false);

  const ticketId = notUndefined(params.ticketId);

  const { mutate: updateIsReadTicket } = useUpdateIsReadTicketMutation();
  const { mutate: approveTicket } = useApproveTicketMutation();

  // FIXME:
  const cachedTickets = queryClient.getQueryData<InfiniteData<GetTicketsQuery>>(
    ["getTickets.infinite"],
    {
      exact: false,
    }
  );

  const filteredCachedTickets = useMemo(() => filterInfiniteTicketsQuery(cachedTickets), [
    cachedTickets?.pages,
  ]);

  const ticketInfo = filteredCachedTickets.find((ticket) => ticket.id === ticketId);

  const { data, isLoading, isError, refetch, isRefetching } = useGetTicketQuery(
    { ticketId },

    // @note: initialize query data with ticket from cache
    ticketInfo && { initialData: { ticket: ticketInfo, __typename: "Query" } }
  );

  const {
    isSuccess,
    isLoading: addMessageLoading,
    mutate: addMessage,
    reset,
  } = useAddMessageTicketMutation({
    onSuccess: () => queryClient.invalidateQueries(["getTicket"]),
    onError: () => {
      enqueueSnackbar(TICKET_CREATION_ERROR, { variant: "error" });
    },
  });

  useEffect(() => {
    if (data?.ticket && !data.ticket?.isRead)
      updateIsReadTicket(
        { ticketId, isRead: true },
        { onSuccess: () => queryClient.invalidateQueries(["getTickets.infinite"]) }
      );
  }, [data]);

  useEffect(() => {
    if (openModal) reset();
  }, [openModal]);

  const messageContent = watch("content");
  const documents = watch("documents");

  const onSubmit = () => {
    addMessage({
      ticketId,
      message: { content: messageContent },
      documents,
    });
  };

  const goToTickets = () => navigate(`../../${TICKETS_PAGE}`);

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModal = () => {
    setOpenModal(false);
    resetForm();
  };

  const handleApproveTicket = () => {
    approveTicket({ ticketId });
    goToTickets();
  };

  const handleCloseWithRedirect = () => {
    setOpenModal(false);
    resetForm();
    goToTickets();
  };

  if (isLoading) return <Loader />;

  if (!data || isError)
    return (
      <ErrorPage
        title="Erreur lors du chargement du ticket"
        refetch={refetch}
        refetching={isRefetching}
      />
    );

  const {
    ticketNumber,
    status,
    building,
    unit,
    category,
    messages,
    activities,
    canBeReopened,
    customerApprovalDate,
    cancelledAt,
  } = data.ticket;

  return (
    <Box component="form" sx={containerStyle}>
      <TicketInfoSection
        ticketNumber={ticketNumber}
        status={status}
        building={building}
        unit={unit}
        category={category}
        goToTickets={goToTickets}
      />

      <TicketContent messages={messages} activities={activities} />

      {status !== TicketStatusEnum.Finished ? (
        <Button
          data-testid="btnResponse"
          startIcon={<MessageIcon />}
          variant="contained"
          sx={isMobile ? mobileButtonStyle : buttonStyle}
          onClick={handleOpenModal}
          size="large"
        >
          RÉPONDRE
        </Button>
      ) : (
        <>
          {canBeReopened && !customerApprovalDate && !hasRestrictedAccess && !cancelledAt && (
            <ActionsSection
              text="Votre demande a été traitée."
              rightNode={
                <>
                  <Button
                    variant="contained"
                    color="success"
                    sx={buttonStyle}
                    onClick={handleApproveTicket}
                  >
                    Confirmer la clôture de ma demande
                  </Button>
                  <Box sx={separatorStyle} />
                  <Button
                    variant="contained"
                    color="info"
                    sx={buttonStyle}
                    onClick={handleOpenModal}
                  >
                    Poursuivre ma demande
                  </Button>
                </>
              }
            />
          )}
        </>
      )}

      {customerApprovalDate && !hasRestrictedAccess && !cancelledAt && (
        <ActionsSection
          text="Cette demande est cloturée."
          rightNode={
            <CreateTicket
              uuid={uuid}
              redirect={goToTickets}
              renderButton={({ handleOpen }) => (
                <Button
                  startIcon={<MessageIcon />}
                  variant="contained"
                  onClick={() => {
                    handleOpen();
                    logCustomEvent(AnalyticsCustomEventNameEnum.TicketMessagesNewTicket);
                  }}
                >
                  Nouvelle demande
                </Button>
              )}
            />
          }
        />
      )}

      {cancelledAt && (
        <ActionsSection
          text="En raison de la fin du contrat de syndic de votre immeuble, votre demande a été
          automatiquement fermée."
        />
      )}

      <TicketModal
        isSuccess={isSuccess}
        handleCloseWithoutRedirect={handleCloseModal}
        handleCloseWithRedirect={handleCloseWithRedirect}
        openModal={openModal}
        addMessageLoading={addMessageLoading}
        control={control}
        handleSubmit={handleSubmit(onSubmit)}
        errorMessage={errors.content?.message}
      />
    </Box>
  );
};
