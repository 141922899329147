const PROTECTED_KEYS = ["password", "newPassword"];

export const maskSensitiveData = (
  data: Record<string, any> | undefined
): Record<string, any> | undefined => {
  if (!data) return data;

  return Object.entries(data).reduce((acc, [key, value]) => {
    if (value && typeof value === "string" && PROTECTED_KEYS.includes(key)) {
      acc[key] = "[PROTECTED DATA]";
    } else if (typeof value === "object" && value !== null) {
      acc[key] = maskSensitiveData(value);
    } else {
      acc[key] = value;
    }
    return acc;
  }, {} as Record<string, any>);
};
