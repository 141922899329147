// Pages names
export const LOGIN_PAGE = "login";
export const LOGIN_CALLBACK_PAGE = "login/callback";
export const FIRST_CONNECTION_PAGE = "first-connection";
export const RESET_PASSWORD_PAGE = "reset";
export const VERIFY_CREDENTIALS_PAGE = "verify-credentials";
export const TEMPORARY_LOGIN_PAGE = "login-first-connection";
export const LOGIN_ASSOCIATION_PAGE = "first-connection/login-association-confirmation";
export const VERIFY_PASSWORD_UPDATE_PAGE = "update-password";
export const VERIFY_BANKING_INFORMATION_UPDATE_PAGE = "update-iban";

export const DASHBOARD_PAGE = "home";
export const RESTRICTED_DASHBOARD_PAGE = "home-restriced";

export const TICKETS_PAGE = "tickets";
export const TICKET_MESSAGES_PAGE = ":ticketId";

// accounting pages
export const ACCOUNTING_PAGE = "accounting";

// landord accounting pages
export const GLOBAL_ACCOUNTING_PAGE = "global";
export const BUILDING_ACCOUNTING_PAGE = "building";
export const PROPERTY_TAX_FORM = "property-tax-form/:fiscalYear";
export const PROPERTY_TAXES_FORM = "property-taxes-form";
export const PROPERTY_TAXES_DOCUMENTS_PAGE = "documents/landlordPropertyTaxes";

// payment pages
export const PAYMENTS_PAGE = "payments";
export const PAYMENTS_SUMMARY_PAGE = "summary";
export const PAYMENT_ON_DEMAND_PAGE = "on-demand";
export const PAYMENT_AUTOMATIC_PAGE = "automatic";
export const PAYMENT_DALENYS_PAGE = "payment-dalenys";

export const OFFERS_AND_SERVICES_PAGE = "offers-services";

// council pages
export const COUNCIL_PAGE = "council";
export const COUNCIL_MEETINGS_PAGE = "meetings";
export const COUNCIL_SERVICES_PAGE = "services";
export const COUNCIL_WORKS_PAGE = "works";
export const COUNCIL_INCIDENTS_PAGE = "incidents";
export const COUNCIL_COOWNERS_PAGE = "coowners";
export const COUNCIL_SUPPLIERS_PAGE = "suppliers";
export const COUNCIL_VISITS_PAGE = "visits";
export const COUNCIL_ACCOUNTING_PAGE = "accounting";
export const COUNCIL_ACCOUNTING_HOME_PAGE = "home";
export const COUNCIL_ACCOUNTING_REPAIR_PAGE = "repair";
export const COUNCIL_ACCOUNTING_REPAIR_BUDGETS_PAGE = "repair-budgets";
export const COUNCIL_ACCOUNTING_RGDD_PAGE = "rgdd";
export const COUNCIL_DOCUMENTS_PAGE = "documents";

export const ESTATE_PAGE = "estate";
export const TENANTS_BALANCE_PAGE = "tenants-balance";

// documents page
export const DOCUMENTS_PAGE = "documents";
export const DOCUMENTS_OKFONCIA_PAGE = "okFoncia";

// profile pages
export const PROFILE_PAGE = "profile";
export const PROFILE_CREDENTIALS_PAGE = "credentials";
export const PROFILE_INFOS_PAGE = "infos";
export const PROFILE_LANDLORD_BANKINFOS_PAGE = "landlord-bankinfos";
export const PROFILE_PAYMENTS_PAGE = "payments";
export const PROFILE_CONFIDENTIALITY_PAGE = "confidentiality";
export const PROFILE_PREFERENCES_PAGE = "preferences";

// general assembly pages
export const GENERAL_ASSEMBLY_PAGE = "general-assembly";
export const POSTAL_VOTING_PAGE = "postal-voting";

// global
export const PREVIOUS_PAGE = "../";
