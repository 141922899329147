import { datadogLogs } from "@datadog/browser-logs";
import * as Sentry from "@sentry/react";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

import { LOCAL_STORAGE_KEYS } from "src/constants";
import { queryClient } from "src/libs";
import { localStorage } from "src/utils";

interface AuthState {
  token: string | null;
  login: (token: string) => void;
  logout: () => Promise<void>;
}

export const useAuthStore = create<AuthState>()(
  persist(
    (set) => {
      const login = (token: string) => {
        set({ token });
      };

      const logout = async () => {
        localStorage.remove(LOCAL_STORAGE_KEYS.USER_TOKEN);
        set({ token: null });
        queryClient.clear();
        Sentry.setUser(null);
        datadogLogs.clearUser();
      };

      return {
        token: localStorage.get(LOCAL_STORAGE_KEYS.USER_TOKEN),
        login,
        logout,
      };
    },
    {
      name: LOCAL_STORAGE_KEYS.AUTH_STORAGE,
      storage: createJSONStorage(() => ({
        setItem: (key: string, value: string) => localStorage.set(key as LOCAL_STORAGE_KEYS, value),
        getItem: (key: string) => localStorage.get(key as LOCAL_STORAGE_KEYS),
        removeItem: (key: string) => localStorage.remove(key as LOCAL_STORAGE_KEYS),
      })),
    }
  )
);
