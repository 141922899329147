import { LoadingButton } from "@mui/lab";
import {
  Box,
  BoxProps,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";

import { Loader } from "src/components";
import { useDeviceWidth } from "src/hooks";
import { emailPattern, mobileStyle } from "src/utils";

const Container = styled(Box)`
  ${mobileStyle`
    padding: ${(props) => props.theme.spacing(1)};
  `}
`;
interface Props extends BoxProps {
  email?: string | null;
  handleClose(): void;
  onSubmitEmail: (emailValue: string) => void;
  subscriptionLoading: boolean;
  updateCustomerLoading: boolean;
}

export const ERecoSubscriptionForm = ({
  handleClose,
  email,
  onSubmitEmail,
  subscriptionLoading,
  updateCustomerLoading,
  ...boxProps
}: Props) => {
  const { isMobile } = useDeviceWidth();

  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm<{ email: string }>({
    defaultValues: { email: email || "" },
  });

  const emailValue = watch("email");

  return (
    <Container {...boxProps}>
      <Typography variant="subtitle1" align="center">
        Votre adresse mail de contact est nécessaire pour l’envoi des eReco,
      </Typography>
      <Typography variant="subtitle1" align="center">
        merci de valider celle-ci afin de valider votre souscription au service dématéralisé E-reco.
      </Typography>
      <Box component="form" sx={{ pt: 2, pb: 6, width: isMobile ? 275 : 400 }}>
        {subscriptionLoading ? (
          <Loader />
        ) : (
          <Box sx={{ mt: 2 }}>
            <FormControl fullWidth variant="outlined" error={Boolean(errors.email)}>
              <InputLabel htmlFor="email">Email</InputLabel>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "Un email est requis",
                  pattern: { value: emailPattern, message: "Email invalide" },
                }}
                render={({ field }) => (
                  <OutlinedInput
                    disabled={email ? emailPattern.test(email) : false}
                    label="Email"
                    placeholder="Votre adresse email"
                    {...field}
                  />
                )}
              />
              <FormHelperText>{errors.email?.message}</FormHelperText>
            </FormControl>
          </Box>
        )}

        {!subscriptionLoading && (
          <Box sx={{ mt: 2 }}>
            <LoadingButton
              loading={updateCustomerLoading}
              type="submit"
              onClick={handleSubmit(() => onSubmitEmail(emailValue))}
              fullWidth
              variant="contained"
            >
              Valider
            </LoadingButton>
          </Box>
        )}
      </Box>
      <Button variant="outlined" onClick={handleClose} sx={{ display: "flex", mx: "auto", mb: 4 }}>
        Annuler
      </Button>
    </Container>
  );
};
