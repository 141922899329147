import { CountryCodeEnum } from "src/graphql/generated/types";

export const CountryLabels: { [K in CountryCodeEnum]: string } = {
  [CountryCodeEnum.Afg]: "Afghanistan",
  [CountryCodeEnum.Zaf]: "Afrique du Sud",
  [CountryCodeEnum.Alb]: "Albanie",
  [CountryCodeEnum.Dza]: "Algerie",
  [CountryCodeEnum.Deu]: "Allemagne",
  [CountryCodeEnum.And]: "Andorre",
  [CountryCodeEnum.Ago]: "Angola",
  [CountryCodeEnum.Aia]: "Anguilla",
  [CountryCodeEnum.Ata]: "Antarctique",
  [CountryCodeEnum.Atg]: "Antigua et Barbuda",
  [CountryCodeEnum.Ant]: "Antilles Néerlandai.",
  [CountryCodeEnum.Sau]: "Arabie Saoudite",
  [CountryCodeEnum.Arg]: "Argentine",
  [CountryCodeEnum.Arm]: "Arménie",
  [CountryCodeEnum.Abw]: "Aruba",
  [CountryCodeEnum.Aus]: "Australie",
  [CountryCodeEnum.Aut]: "Autriche",
  [CountryCodeEnum.Aze]: "Azerbaidjan",
  [CountryCodeEnum.Bhs]: "Bahamas",
  [CountryCodeEnum.Bhr]: "Bahrein",
  [CountryCodeEnum.Bgd]: "Bangladesh",
  [CountryCodeEnum.Brb]: "Barbade",
  [CountryCodeEnum.Blr]: "Bélarus Biélorussie",
  [CountryCodeEnum.Bel]: "Belgique",
  [CountryCodeEnum.Blz]: "Belize",
  [CountryCodeEnum.Ben]: "Bénin",
  [CountryCodeEnum.Bmu]: "Bermudes",
  [CountryCodeEnum.Btn]: "Bhoutan",
  [CountryCodeEnum.Bol]: "Bolivie",
  [CountryCodeEnum.Bih]: "Bosnie Herzégovine",
  [CountryCodeEnum.Bwa]: "Botswana",
  [CountryCodeEnum.Bvt]: "Bouvet Island",
  [CountryCodeEnum.Bra]: "Brésil",
  [CountryCodeEnum.Brn]: "Brunei",
  [CountryCodeEnum.Bgr]: "Bulgarie",
  [CountryCodeEnum.Bfa]: "Burkina Faso",
  [CountryCodeEnum.Bdi]: "Burundi",
  [CountryCodeEnum.Khm]: "Cambodge",
  [CountryCodeEnum.Cmr]: "Cameroun",
  [CountryCodeEnum.Can]: "Canada",
  [CountryCodeEnum.Cpv]: "Cap Vert",
  [CountryCodeEnum.Chl]: "Chili",
  [CountryCodeEnum.Chn]: "Chine",
  [CountryCodeEnum.Cyp]: "Chypre",
  [CountryCodeEnum.Vat]: "Cité du Tvaican",
  [CountryCodeEnum.Col]: "Colombie",
  [CountryCodeEnum.Com]: "Comores",
  [CountryCodeEnum.Cog]: "Congo, Républic",
  [CountryCodeEnum.Cod]: "Congo, Rép. Dém. du",
  [CountryCodeEnum.Prk]: "Corée du Nord",
  [CountryCodeEnum.Kor]: "Corée du Sud",
  [CountryCodeEnum.Cri]: "Costa Rica",
  [CountryCodeEnum.Civ]: "Côte d Ivoire",
  [CountryCodeEnum.Hrv]: "Croatie",
  [CountryCodeEnum.Cub]: "Cuba",
  [CountryCodeEnum.Cuw]: "Curacao",
  [CountryCodeEnum.Dnk]: "Danemark",
  [CountryCodeEnum.Dji]: "Djibouti",
  [CountryCodeEnum.Dma]: "Dominique",
  [CountryCodeEnum.Egy]: "Egypte",
  [CountryCodeEnum.Are]: "Emirats Arabes Unis",
  [CountryCodeEnum.Ecu]: "Equateur",
  [CountryCodeEnum.Eri]: "Erythrée",
  [CountryCodeEnum.Esp]: "Espagne",
  [CountryCodeEnum.Est]: "Estonie",
  [CountryCodeEnum.Usa]: "States-Unis",
  [CountryCodeEnum.Eth]: "Ethiopie",
  [CountryCodeEnum.Fji]: "Fidji",
  [CountryCodeEnum.Fin]: "Finlande",
  [CountryCodeEnum.Fra]: "France",
  [CountryCodeEnum.Fxx]: "France, Métropoli.",
  [CountryCodeEnum.Gab]: "Gabon",
  [CountryCodeEnum.Gmb]: "Gambie",
  [CountryCodeEnum.Geo]: "Géorgie",
  [CountryCodeEnum.Sgs]: "Géorgie du Sud",
  [CountryCodeEnum.Gha]: "Ghana",
  [CountryCodeEnum.Gib]: "Gibraltar",
  [CountryCodeEnum.Grc]: "Grèce",
  [CountryCodeEnum.Grd]: "Grenade",
  [CountryCodeEnum.Grl]: "Greoenland",
  [CountryCodeEnum.Glp]: "Guadeloupe",
  [CountryCodeEnum.Gum]: "Guam",
  [CountryCodeEnum.Gtm]: "Guatemala",
  [CountryCodeEnum.Gin]: "Guinée",
  [CountryCodeEnum.Gnb]: "Guinée Bissau",
  [CountryCodeEnum.Gnq]: "Guinée Equatoriale",
  [CountryCodeEnum.Guy]: "Guyane",
  [CountryCodeEnum.Guf]: "Guyane Française",
  [CountryCodeEnum.Hti]: "Haïti",
  [CountryCodeEnum.Hnd]: "Honduras",
  [CountryCodeEnum.Hkg]: "Hong Kong",
  [CountryCodeEnum.Hun]: "Hongrie",
  [CountryCodeEnum.Imn]: "Ile de Man",
  [CountryCodeEnum.Cym]: "Iles Caïman",
  [CountryCodeEnum.Cxr]: "Iles Christmas",
  [CountryCodeEnum.Cck]: "Iles Cocos",
  [CountryCodeEnum.Cok]: "Iles Cook",
  [CountryCodeEnum.Fro]: "Iles Féroé",
  [CountryCodeEnum.Ggy]: "Iles Guernesey",
  [CountryCodeEnum.Hmd]: "Il. Heardet McDonald",
  [CountryCodeEnum.Flk]: "Iles Malouines",
  [CountryCodeEnum.Mnp]: "Iles Mariannes Nord",
  [CountryCodeEnum.Mhl]: "Iles Marshall",
  [CountryCodeEnum.Mus]: "Iles Maurice",
  [CountryCodeEnum.Umi]: "Il. mineures des EU",
  [CountryCodeEnum.Nfk]: "Iles Norfolk",
  [CountryCodeEnum.Slb]: "Iles Salomon",
  [CountryCodeEnum.Tca]: "Iles Turques Caïque",
  [CountryCodeEnum.Vir]: "Iles Vierges des E.U",
  [CountryCodeEnum.Vgb]: "Iles Vierges du RU",
  [CountryCodeEnum.Ind]: "Inde",
  [CountryCodeEnum.Idn]: "Indonésie",
  [CountryCodeEnum.Irq]: "Iraq",
  [CountryCodeEnum.Irn]: "Iran",
  [CountryCodeEnum.Irl]: "Irlande",
  [CountryCodeEnum.Isl]: "Islande",
  [CountryCodeEnum.Isr]: "Israël",
  [CountryCodeEnum.Ita]: "Italie",
  [CountryCodeEnum.Jam]: "Jamaique",
  [CountryCodeEnum.Jpn]: "Japon",
  [CountryCodeEnum.Jey]: "Jersey",
  [CountryCodeEnum.Jor]: "Jordanie",
  [CountryCodeEnum.Kaz]: "Kazakhstan",
  [CountryCodeEnum.Ken]: "Kenya",
  [CountryCodeEnum.Kgz]: "Kirghizistan",
  [CountryCodeEnum.Kir]: "Kiribati",
  [CountryCodeEnum.Xks]: "Kosovo",
  [CountryCodeEnum.Kwt]: "Koweit",
  [CountryCodeEnum.Lao]: "Laos",
  [CountryCodeEnum.Lso]: "Lesotho",
  [CountryCodeEnum.Lva]: "Lettonie",
  [CountryCodeEnum.Lbn]: "Liban",
  [CountryCodeEnum.Lbr]: "Libéria",
  [CountryCodeEnum.Lby]: "Libye",
  [CountryCodeEnum.Lie]: "Liechtenstein",
  [CountryCodeEnum.Ltu]: "Lituanie",
  [CountryCodeEnum.Lux]: "Luxembourg",
  [CountryCodeEnum.Mac]: "Macao",
  [CountryCodeEnum.Mkd]: "Macédoine",
  [CountryCodeEnum.Mdg]: "Madagascar",
  [CountryCodeEnum.Mys]: "Malaisie",
  [CountryCodeEnum.Mwi]: "Malawi",
  [CountryCodeEnum.Mdv]: "Maldives",
  [CountryCodeEnum.Mli]: "Mali",
  [CountryCodeEnum.Mlt]: "Malte",
  [CountryCodeEnum.Mar]: "Maroc",
  [CountryCodeEnum.Mtq]: "Martinique",
  [CountryCodeEnum.Mrt]: "Mauritanie",
  [CountryCodeEnum.Myt]: "Mayotte",
  [CountryCodeEnum.Mex]: "Mexique",
  [CountryCodeEnum.Fsm]: "Micronésie",
  [CountryCodeEnum.Mda]: "Moldavie",
  [CountryCodeEnum.Mco]: "Monaco",
  [CountryCodeEnum.Mng]: "Mongolie",
  [CountryCodeEnum.Mne]: "Monténégro",
  [CountryCodeEnum.Msr]: "Montserrat",
  [CountryCodeEnum.Moz]: "Mozambique",
  [CountryCodeEnum.Mmr]: "Myanmar (Birmanie)",
  [CountryCodeEnum.Nam]: "Namibie",
  [CountryCodeEnum.Nru]: "Nauru",
  [CountryCodeEnum.Npl]: "Népal",
  [CountryCodeEnum.Nic]: "Nicaragua",
  [CountryCodeEnum.Ner]: "Niger",
  [CountryCodeEnum.Nga]: "Nigeria",
  [CountryCodeEnum.Niu]: "Niue",
  [CountryCodeEnum.Nor]: "Norvège",
  [CountryCodeEnum.Ncl]: "Nouvelle Calédonie",
  [CountryCodeEnum.Nzl]: "Nouvelle Zélande",
  [CountryCodeEnum.Omn]: "Oman",
  [CountryCodeEnum.Uga]: "Ouganda",
  [CountryCodeEnum.Uzb]: "Ouzbékistan",
  [CountryCodeEnum.Pak]: "Pakistan",
  [CountryCodeEnum.Plw]: "Palau",
  [CountryCodeEnum.Pan]: "Panama",
  [CountryCodeEnum.Png]: "Papouasie Nle Guinée",
  [CountryCodeEnum.Pry]: "Paraguay",
  [CountryCodeEnum.Nld]: "Country-Bas",
  [CountryCodeEnum.Per]: "Pérou",
  [CountryCodeEnum.Phl]: "Philippines",
  [CountryCodeEnum.Pcn]: "Pitcairn",
  [CountryCodeEnum.Pol]: "Pologne",
  [CountryCodeEnum.Pyf]: "Polynésie Française",
  [CountryCodeEnum.Pri]: "Porto Rico",
  [CountryCodeEnum.Prt]: "Portugal",
  [CountryCodeEnum.Qat]: "Qatar",
  [CountryCodeEnum.Caf]: "Rép. Centraficaine",
  [CountryCodeEnum.Dom]: "Rép. Dominicaine",
  [CountryCodeEnum.Cze]: "Rép. Tchèque",
  [CountryCodeEnum.Reu]: "Réunion",
  [CountryCodeEnum.Rou]: "Roumanie",
  [CountryCodeEnum.Gbr]: "Royaume Uni",
  [CountryCodeEnum.Rus]: "Russie",
  [CountryCodeEnum.Rwa]: "Rwanda",
  [CountryCodeEnum.Esh]: "Sahara Occidental",
  [CountryCodeEnum.Blm]: "Saint Barthelemy",
  [CountryCodeEnum.Shn]: "Saint Hélène",
  [CountryCodeEnum.Kna]: "Saint Kitts et Nevis",
  [CountryCodeEnum.Maf]: "Saint Martin",
  [CountryCodeEnum.Sxm]: "Saint Martin",
  [CountryCodeEnum.Spm]: "St Stone Miquelon",
  [CountryCodeEnum.Vct]: "Saint Vincent Grena.",
  [CountryCodeEnum.Lca]: "Sainte Lucie",
  [CountryCodeEnum.Slv]: "Salvador",
  [CountryCodeEnum.Asm]: "Samoa Americaines",
  [CountryCodeEnum.Wsm]: "Samoa Occidentales",
  [CountryCodeEnum.Smr]: "San Marin",
  [CountryCodeEnum.Stp]: "Sao Tomé et Principe",
  [CountryCodeEnum.Sen]: "Sénégal",
  [CountryCodeEnum.Srb]: "Serbie",
  [CountryCodeEnum.Syc]: "Seychelles",
  [CountryCodeEnum.Sle]: "Sierra Léone",
  [CountryCodeEnum.Sgp]: "Singapour",
  [CountryCodeEnum.Svk]: "Slovaquie",
  [CountryCodeEnum.Svn]: "Slovénie",
  [CountryCodeEnum.Som]: "Somalie",
  [CountryCodeEnum.Sdn]: "Soudan",
  [CountryCodeEnum.Lka]: "Sri Lanka",
  [CountryCodeEnum.Ssd]: "Sud Soudan",
  [CountryCodeEnum.Swe]: "Suède",
  [CountryCodeEnum.Che]: "Suisse",
  [CountryCodeEnum.Sur]: "Surinam",
  [CountryCodeEnum.Sjm]: "Svalbard Jan Mayen",
  [CountryCodeEnum.Swz]: "Swaziland",
  [CountryCodeEnum.Syr]: "Syrie",
  [CountryCodeEnum.Tjk]: "Tadjikistan",
  [CountryCodeEnum.Twn]: "Taiwan",
  [CountryCodeEnum.Tza]: "Tanzanie",
  [CountryCodeEnum.Tcd]: "Tchad",
  [CountryCodeEnum.Atf]: "Soils Aust/Antar Fr",
  [CountryCodeEnum.Iot]: "Térr Brit. Océan Ind",
  [CountryCodeEnum.Pse]: "Terr Palest (Gasa)",
  [CountryCodeEnum.Tha]: "Thaïlande",
  [CountryCodeEnum.Tls]: "Timor-Leste",
  [CountryCodeEnum.Tgo]: "Togo",
  [CountryCodeEnum.Tkl]: "Tokelau",
  [CountryCodeEnum.Ton]: "Tonga",
  [CountryCodeEnum.Tto]: "Trinité et Tobago",
  [CountryCodeEnum.Tun]: "Tunisie",
  [CountryCodeEnum.Tkm]: "Turkmenistan",
  [CountryCodeEnum.Tur]: "Turquie",
  [CountryCodeEnum.Tuv]: "Tuvalu",
  [CountryCodeEnum.Ukr]: "Ukraine",
  [CountryCodeEnum.Ury]: "Uruguay",
  [CountryCodeEnum.Vut]: "Vanuatu",
  [CountryCodeEnum.Ven]: "Venezuela",
  [CountryCodeEnum.Vnm]: "Vietnam",
  [CountryCodeEnum.Wlf]: "Wallis et Futuna",
  [CountryCodeEnum.Yem]: "Yemen",
  [CountryCodeEnum.Zmb]: "Zambie",
  [CountryCodeEnum.Zwe]: "Zimbabwe",
};
