export const pluralize = (noun: string, count: number, suffix = "s"): string =>
  `${noun}${count > 1 ? suffix : ""}`;

export const capitalize = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1);

export const capitalizePhrase = (str: string): string => str.split(" ").map(capitalize).join(" ");

// cf: https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value
const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key: any, value: object | null) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    // eslint-disable-next-line consistent-return
    return value;
  };
};

export const stringify = (value: any): string => {
  return JSON.stringify(value, getCircularReplacer());
};

export const shortenString = (value: string | null | undefined, num: number) => {
  if (!value) return null;

  if (value.length <= num) {
    return value;
  }

  return `${value.slice(0, num)}...`;
};
