import { Box, Typography } from "@mui/material";
import React from "react";

import { ChipTicketStatus } from "src/components";
import { TicketCategory, TicketHeadingCategory } from "src/constants";
import { TicketFragment } from "src/graphql/generated/types";
import { useDeviceWidth } from "src/hooks";

import {
  headerStyle,
  subHeaderStyle,
  categoryStyle,
  categoryContainer,
  titleStyle,
  buildingInfoContainer,
  titleContainer,
  titleLinkStyle,
  ArrowBackIcon,
} from "./styles";

type Props = { goToTickets: () => void } & Pick<
  TicketFragment,
  "ticketNumber" | "status" | "building" | "unit" | "category"
>;

export const TicketInfoSection = ({
  ticketNumber,
  status,
  building,
  category,
  unit,
  goToTickets,
}: Props) => {
  const { label, headingCategory } = TicketCategory[category];
  const { isMobile } = useDeviceWidth();

  return (
    <Box sx={{ p: isMobile ? 0 : 4 }}>
      <Box sx={headerStyle}>
        <Box sx={titleContainer}>
          <Typography variant="h6" sx={titleLinkStyle} onClick={goToTickets}>
            {isMobile ? <ArrowBackIcon /> : "Mes demandes"}
          </Typography>
          <Typography sx={titleStyle} variant="h6" data-testid="ticketNumber">
            {!isMobile && "/ "}Demande {ticketNumber}
          </Typography>
        </Box>
        {!isMobile && <ChipTicketStatus status={status} />}
      </Box>

      <Box sx={subHeaderStyle}>
        <Box sx={{ ...categoryContainer, flexDirection: isMobile ? "column" : undefined }}>
          <Typography sx={{ ...categoryStyle, fontWeight: "bold" }} variant="subtitle1">
            Objet de la demande :
          </Typography>
          <Typography sx={{ ...categoryStyle, ml: 1 }} variant="subtitle2">
            {headingCategory && TicketHeadingCategory[headingCategory].label}
            {headingCategory && label && " / "}
            {label && label}
          </Typography>
        </Box>

        <Box sx={buildingInfoContainer}>
          <Typography variant="subtitle2">
            Immeuble {building?.number} - {building?.address.address1},{" "}
            {[building?.address.zipCode, building?.address.city].filter(Boolean).join(" ")}
          </Typography>
          {unit && (
            <Typography variant="subtitle2">
              {unit.number} / {unit.description.entityType} / {unit.situation.floor}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};
