import { Box } from "@mui/material";
import React from "react";
import styled from "styled-components";

import { Footer } from "../Footer";

const StyledBackground = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: ${(props) => props.theme.palette.primary.light};
`;

interface Props {
  children: React.ReactNode;
}

export const AuthLayout = ({ children }: Props) => {
  return (
    <StyledBackground>
      {children}
      <Footer inverted />
    </StyledBackground>
  );
};
