import { MenuItem } from "@mui/material";
import styled from "styled-components";

export const ChoiceListItem = styled(MenuItem)`
  display: flex;
  flex-direction: column;
  align-items: baseline !important;
  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.palette.grey[200]};
  }
`;
