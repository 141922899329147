/* eslint-disable class-methods-use-this */
import { LOCAL_STORAGE_KEYS } from "src/constants";

class LocalStorage {
  public get(key: LOCAL_STORAGE_KEYS): string | null {
    return window.localStorage.getItem(key);
  }

  public remove(key: LOCAL_STORAGE_KEYS): void {
    return window.localStorage.removeItem(key);
  }

  public set(key: LOCAL_STORAGE_KEYS, value: string): void {
    return window.localStorage.setItem(key, value);
  }
}

export const localStorage = new LocalStorage();
