import LoadingButton from "@mui/lab/LoadingButton";
import { Box } from "@mui/material";
import { rem } from "polished";
import React, { ReactElement } from "react";
import styled from "styled-components";

import { useDeviceWidth } from "src/hooks";
import { tabletMobileStyle, mobileStyle } from "src/utils";

import { Icon, IconName } from "../Icon";

const StyledCardBox = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  ${mobileStyle`
    width: 100%;
  `}
`;

const StyledContentWrapper = styled(Box)`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin: 0 ${(props) => props.theme.spacing(2)};
  padding: ${(props) => props.theme.spacing(4)} ${(props) => props.theme.spacing(2)};
  min-height: ${rem(400)};
  max-width: ${rem(330)};
  border-width: ${rem(2)};
  border-style: solid;
  border-color: ${(props) => props.theme.palette.primary.main};
  border-radius: ${(props) => props.theme.spacing(1)};
  background: ${(props) => props.theme.palette.white.main};

  ${tabletMobileStyle`
    max-width: 100%;
    border: none;
    border-radius: ${rem(3)};
    min-height: unset;
    margin-top: ${(props) => props.theme.spacing(2)};
  `}
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${tabletMobileStyle`
    width: 100%;
  `}

  ${mobileStyle`
    flex-direction: row;
  `}
`;

const StyledTitle = styled.h3`
  color: ${(props) => props.theme.palette.text.secondary};

  ${mobileStyle`
    margin: 0;
    color: ${(props) => props.theme.palette.primary.main};
  `}
`;

interface PaymentCardProps {
  icon: IconName;
  title: string;
  content: string | ReactElement;
  action?: ReactElement;
  resiliateLoading?: boolean;

  onResiliate?(): void;
}

function PaymentCard({
  icon,
  title,
  content,
  action,
  resiliateLoading,
  onResiliate,
}: PaymentCardProps) {
  const { isMobile } = useDeviceWidth();

  return (
    <StyledCardBox>
      <StyledContentWrapper>
        {isMobile ? (
          <StyledContent>
            <Icon name={icon} size={55} color="primary" />
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                paddingLeft: 1.5,
              }}
            >
              <StyledTitle>{title}</StyledTitle>
              {content}
            </Box>
          </StyledContent>
        ) : (
          <StyledContent>
            <Icon name={icon} size={50} color="primary" />

            <StyledTitle>{title}</StyledTitle>

            {content}
          </StyledContent>
        )}
        {action}
      </StyledContentWrapper>

      {onResiliate && (
        <Box sx={{ display: "flex", justifyContent: "center", height: 40, mt: 1 }}>
          <LoadingButton
            data-cy="resiliate-button"
            loading={resiliateLoading}
            onClick={onResiliate}
          >
            Résilier le mandat
          </LoadingButton>
        </Box>
      )}
    </StyledCardBox>
  );
}

export default PaymentCard;
