import { Box, Button, FormControl, FormHelperText, InputLabel, OutlinedInput } from "@mui/material";
import React from "react";
import { Controller, Path, FieldError, UseControllerProps, FieldValues } from "react-hook-form";
import styled from "styled-components";

import { useDeviceWidth } from "src/hooks";

import { FormCardItem } from "../FormCardItem";

const StyledOutlinedInput = styled(OutlinedInput)`
  background-color: ${(props) => props.theme.palette.white.main};
`;

const Spacer = styled.div`
  width: ${(props) => props.theme.spacing(4)};
  height: ${(props) => props.theme.spacing(4)};
  flex-shrink: 0;
`;

interface Props<T extends FieldValues> {
  hasBankingInformation?: boolean;
  setKeepMandate?: (value: boolean) => void;
  firstnameError: FieldError | undefined;
  lastnameError: FieldError | undefined;
  control: UseControllerProps<T>["control"];
}

export function PaymentPersonalInformations<T extends FieldValues>({
  hasBankingInformation = false,
  setKeepMandate,
  firstnameError,
  lastnameError,
  control,
}: Props<T>) {
  const { isMobile } = useDeviceWidth();

  const handleSetKeepMandate = () => {
    if (setKeepMandate) setKeepMandate(true);
  };

  return (
    <>
      <FormCardItem data-cy="ondemand-newmandate-infos">
        <Box
          sx={{
            display: "flex",
            flexBasis: "100%",
            justifyContent: isMobile ? "center" : "space-between",
            flexWrap: "wrap",
            alignItems: "center",
            color: "primary.main",
            mb: 3,
          }}
        >
          <Box component="h3" sx={{ flex: isMobile ? "auto" : undefined }}>
            Mes informations personnelles
          </Box>
          {hasBankingInformation && (
            <Button
              data-cy="ondemand-goBack-existingMandate"
              variant="outlined"
              onClick={handleSetKeepMandate}
            >
              Utiliser le mandat existant
            </Button>
          )}
        </Box>

        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexWrap: isMobile ? "wrap" : undefined,
            alignItems: "center",
          }}
        >
          <FormControl fullWidth variant="outlined" error={Boolean(firstnameError)}>
            <InputLabel htmlFor="firstName">Prénom</InputLabel>
            <Controller<T>
              name={"firstName" as Path<T>}
              control={control}
              rules={{
                required: "Un prénom est requis",
                minLength: {
                  value: 2,
                  message: "Le prénom doit comporter au moins 2 caractères",
                },
                maxLength: {
                  value: 25,
                  message: "Le prénom ne peut comporter plus de 25 caractères",
                },
              }}
              render={({ field }) => <StyledOutlinedInput label="Prénom" {...field} />}
            />
            <FormHelperText>{firstnameError?.message}</FormHelperText>
          </FormControl>

          <Spacer />

          <FormControl fullWidth variant="outlined" error={Boolean(lastnameError)}>
            <InputLabel htmlFor="lastName">Nom</InputLabel>
            <Controller
              name={"lastName" as Path<T>}
              control={control}
              rules={{
                required: "Un nom est requis",
                minLength: {
                  value: 2,
                  message: "Le nom doit comporter au moins 2 caractères",
                },
                maxLength: {
                  value: 25,
                  message: "Le nom ne peut comporter plus de 25 caractères",
                },
              }}
              render={({ field }) => <StyledOutlinedInput label="Nom" {...field} />}
            />
            <FormHelperText>{lastnameError?.message}</FormHelperText>
          </FormControl>
        </Box>
      </FormCardItem>
    </>
  );
}

export default PaymentPersonalInformations;
