import { Box, BoxProps } from "@mui/material";
import { rem } from "polished";
import React from "react";
import styled, { css } from "styled-components";

type Props = {
  badgeText?: React.ReactNode;
  $hasBorder?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
} & BoxProps;

const StyledResponsiveCard = styled(Box)<Props>`
  background-color: ${(props) => props.theme.palette.common.white};
  padding: ${(props) => props.theme.spacing(2)};
  position: relative;
  box-shadow: ${(props) => props.theme.palette.boxShadow};
  ${(props) =>
    props.$hasBorder &&
    css`
      border-left: ${rem(2)} solid ${props.theme.palette.orange.main};
    `};
  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;
    `};
`;

export const ResponsiveCard = ({ badgeText, onClick, children, ...props }: Props) => (
  <StyledResponsiveCard onClick={onClick} {...props}>
    {<>{badgeText}</> && (
      <Box
        sx={{
          backgroundColor: "orange.main",
          color: "white.main",
          position: "absolute",
          top: -15,
          right: 15,
          mt: 1,
          borderRadius: 0.5,
          px: 0.5,
        }}
      >
        {badgeText}
      </Box>
    )}
    {children}
  </StyledResponsiveCard>
);
