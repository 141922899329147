import i18n from "i18next";
import { initReactI18next } from "react-i18next";

export const defaultNS = "myfoncia-web";

i18n.use(initReactI18next).init({
  debug: false,
  fallbackLng: "fr-FR",
  defaultNS,
  ns: ["myfoncia-web"],
  interpolation: {
    escapeValue: false, // @note: not needed for react as it escapes by default
  },
  react: {
    transKeepBasicHtmlNodesFor: ["br", "strong", "i", "p", "b"], // default is ["br", "strong", "i", "p"]
  },
});

export default i18n;
