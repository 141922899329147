import firebase from "firebase/app";

import "firebase/analytics";

import appConfig from "config/app";
import { AccountQualityEnum, AnalyticsCustomEventNameEnum } from "src/graphql/generated/types";

const validFirebaseConfig =
  appConfig.firebaseConfig && Object.values(appConfig.firebaseConfig).every(Boolean);

export const initializeAnalytics = () => {
  if (validFirebaseConfig) {
    firebase.initializeApp(appConfig.firebaseConfig);
    firebase.analytics();
  }
};

const analytics = validFirebaseConfig ? firebase.analytics : null;

export function setCurrentQuality(quality: AccountQualityEnum) {
  if (!analytics) return;
  analytics().setUserProperties({ quality });
}

export function logScreenName(screenName: string) {
  if (!analytics) return;
  analytics().logEvent("page_view", {
    app_name: "MyFoncia",
    screen_name: screenName,
  });
}

export function logCustomEvent(eventName: AnalyticsCustomEventNameEnum) {
  if (!analytics) return;
  analytics().logEvent(eventName);
}
