import { Link } from "@mui/material";
import React from "react";

import { EmeriaEuropeUrl } from "src/constants";

export const EmeriaEuropeLink = () => {
  return (
    <Link href={EmeriaEuropeUrl} color="inherit" underline="always" target="_blank" rel="noopener">
      Emeria
    </Link>
  );
};
