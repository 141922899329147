import { REACT_QUERY_STALE_TIME } from "../constants";
import { useCustomInfiniteGetBasicAccountsQuery } from "./useCustomInfiniteGetBasicAccountsQuery";

/*
This hook should only be used when user is authenticated
*/

export const useHasSeveralAccounts = (): boolean => {
  const { data } = useCustomInfiniteGetBasicAccountsQuery({
    staleTime: REACT_QUERY_STALE_TIME,
  });

  const accounts = data?.pages.flatMap((page) => page.accounts) ?? [];

  return accounts.length > 1;
};
