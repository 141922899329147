import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Box, IconButton, Typography } from "@mui/material";
import React, { memo } from "react";
import styled from "styled-components";

import { Icon } from "../Icon";

const StyledIconButton = styled(IconButton)`
  position: relative;
  top: 12px;
  left: 20px;
`;

type Props = {
  name: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const UploadedDocument = memo(({ name, onClick }: Props) => (
  <Box sx={{ textAlign: "center", mr: 3, mb: 1 }}>
    <StyledIconButton
      sx={{ color: "gray.main" }}
      aria-label="supprimer fichier"
      onClick={onClick}
      size="small"
    >
      <HighlightOffIcon fontSize="small" />
    </StyledIconButton>
    <Box>
      <Icon name="attachment" size={30} />
      <Typography sx={{ mt: 1, color: "gray.main" }}>{name}</Typography>
    </Box>
  </Box>
));
