import { Box, Link } from "@mui/material";
import { rem } from "polished";
import React from "react";
import styled from "styled-components";

import LogoFonciaPrimary from "src/assets/images/logo-foncia-primary.png";
import LogoFonciaWhite from "src/assets/images/logo-foncia-white.png";
import { ConfidentialityUrl } from "src/constants";
import { useDeviceWidth } from "src/hooks";
import { tabletMobileStyle } from "src/utils";

// Dimension are in pixels but will be converted in rem
export const footerHeight = 110;

interface Props {
  inverted?: boolean;
}

const StyledRoot = styled.div<Props>`
  background: ${(props) =>
    props.inverted ? props.theme.palette.white.main : props.theme.palette.primary.main};
  width: 100vw;
`;

const StyledContent = styled.div<Props>`
  height: ${rem(footerHeight)};
  display: flex;
  margin-left: ${(props) => props.theme.spacing(8)};
  margin-right: ${(props) => props.theme.spacing(8)};
  align-items: center;
  justify-content: space-between;
  color: ${(props) =>
    props.inverted ? props.theme.palette.primary.main : props.theme.palette.white.main};

  ${tabletMobileStyle`
    justify-content: space-around;
    margin-left: 0;
    margin-right: 0;
  `}
`;

const LinkList = styled.div`
  display: flex;
  & > div {
    margin-right: ${(props) => props.theme.spacing(1)};
  }

  ${tabletMobileStyle`
    display: flex;
    flex-direction: column;
    & > a {
      margin-top: ${(props) => props.theme.spacing(1)};
    }
  `}
`;

const FonciaSignature = styled.div`
  ${tabletMobileStyle`
    display: flex;
    flex-direction: column;
    & > * {
      margin-top: ${(props) => props.theme.spacing(1)};
    }
  `}
`;

const VericalDivider = styled.div<Props>`
  width: ${rem(1)};
  height: ${rem(70)};
  background-color: ${(props) =>
    props.inverted ? props.theme.palette.primary.main : props.theme.palette.common.white};
`;

const links = [
  { href: "https://fr.foncia.com/mentions-legales", label: "Mentions légales" },
  { href: ConfidentialityUrl, label: "Charte RGPD" },
  {
    href: "https://fr.foncia.com/notre-preoccupation-la-securite-de-vos-donnees",
    label: "Sécurité informatique",
  },
  { href: "https://fr.foncia.com/services", label: "Services" },
];

export const Footer = ({ inverted = false }: Props) => {
  const { isTabletMobile } = useDeviceWidth();

  return (
    <StyledRoot inverted={inverted}>
      <StyledContent inverted={inverted}>
        <FonciaSignature>
          {isTabletMobile && (
            <img alt="logo" width="120" src={inverted ? LogoFonciaPrimary : LogoFonciaWhite} />
          )}
          <Box sx={{ m: 1 }}>{`© ${new Date().getFullYear()} Foncia`}</Box>
        </FonciaSignature>

        {isTabletMobile && <VericalDivider inverted={inverted} />}

        <LinkList>
          {links.map((link, index) => (
            <div key={link.href}>
              <Link href={link.href} color="inherit" target="_blank" rel="noopener">
                {link.label}
              </Link>
              {isTabletMobile === false && index < links.length - 1 && " | "}
            </div>
          ))}
        </LinkList>

        {!isTabletMobile && (
          <img alt="logo" width={120} src={inverted ? LogoFonciaPrimary : LogoFonciaWhite} />
        )}
      </StyledContent>
    </StyledRoot>
  );
};
