import GroupsIcon from "@mui/icons-material/Groups";
import React from "react";

import { SubLevelLayout } from "src/components";
import {
  COUNCIL_ACCOUNTING_PAGE,
  COUNCIL_COOWNERS_PAGE,
  COUNCIL_DOCUMENTS_PAGE,
  COUNCIL_MEETINGS_PAGE,
  COUNCIL_SERVICES_PAGE,
  COUNCIL_SUPPLIERS_PAGE,
  COUNCIL_VISITS_PAGE,
} from "src/constants";

type Props = {
  children: React.ReactNode;
};

export const CouncilLayout = ({ children }: Props) => {
  const links = [
    {
      label: "Mes réunions CS",
      link: COUNCIL_MEETINGS_PAGE,
    },
    {
      label: "Travaux et interventions",
      link: COUNCIL_SERVICES_PAGE,
    },
    {
      label: "Copropriétaires",
      link: COUNCIL_COOWNERS_PAGE,
    },
    {
      label: "Contrats et fournisseurs",
      link: COUNCIL_SUPPLIERS_PAGE,
    },
    {
      label: "Visites de l'immeuble",
      link: COUNCIL_VISITS_PAGE,
    },
    {
      label: "Comptes de l'immeuble",
      link: COUNCIL_ACCOUNTING_PAGE,
    },
    {
      label: "Documents AG à venir",
      link: COUNCIL_DOCUMENTS_PAGE,
    },
  ];

  return (
    <SubLevelLayout
      headerIcon={<GroupsIcon sx={{ color: "primary.main" }} />}
      headerTitle="Mon conseil syndical"
      links={links}
    >
      {children}
    </SubLevelLayout>
  );
};
