import { LoadingButton } from "@mui/lab";
import { Box, Button, Typography } from "@mui/material";
import { rem } from "polished";
import React, { useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";

import { FormatDate, Modal, ModalFooter } from "src/components";
import {
  AccountPageParams,
  DEBIT_INSTRUCTION_DAYS,
  PaymentHistoryState,
  PaymentPageOriginEnum,
  PaymentPagePath,
} from "src/constants";
import { useCreateDebitMutation } from "src/graphql/generated/queries";
import { Amount } from "src/graphql/generated/types";
import { useDeviceWidth, useSnackbar } from "src/hooks";
import { formatAmount, getSpecificError, mobileStyle, notUndefined } from "src/utils";

const StyledTypography = styled(Typography)`
  ${mobileStyle`
    font-size: ${rem(13)};
    line-height: ${rem(21)};
    color: ${({ theme }) => theme.palette.secondary.main};
  `}
`;

type Props = {
  amount?: Omit<Amount, "__typename">;
  date?: Date;
  instructionDayInt?: number;
  isOpen: boolean;
  onClose: () => void;
  keepMandate?: boolean;
};

export const PaymentOnDemandModal = ({
  amount,
  date,
  instructionDayInt,
  isOpen,
  onClose,
  keepMandate,
}: Props) => {
  const params = useParams<AccountPageParams>();
  const uuid = notUndefined(params.uuid);
  const { enqueueSnackbar } = useSnackbar();
  const { isMobile } = useDeviceWidth();

  const navigate = useNavigate();
  const location = useLocation();
  const pageOrigin = (location.state as PaymentHistoryState)?.pageOrigin;

  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleCloseModal = () => {
    setShowConfirmation(false);
    if (keepMandate) {
      let pageOriginPath = PaymentPagePath[pageOrigin];
      if (!pageOriginPath) {
        pageOriginPath = PaymentPagePath[PaymentPageOriginEnum.Profile];
      }
      navigate(pageOriginPath);
    }
    onClose();
  };

  const { mutate: createDebit, isLoading } = useCreateDebitMutation({
    onSuccess: () => {
      setShowConfirmation(true);
    },

    onError: (error) => {
      enqueueSnackbar(
        getSpecificError(error, "Erreur lors du E-paiement à la demande avec un mandat existant"),
        {
          variant: "error",
        }
      );
    },
  });

  const validation = () => {
    if (amount && typeof instructionDayInt === "number")
      createDebit({
        accountUuid: uuid,
        request: {
          amount,
          instructionDay: DEBIT_INSTRUCTION_DAYS[instructionDayInt],
        },
      });
  };

  return (
    <Modal
      icon="onDemandPayment"
      handleClose={handleCloseModal}
      open={isOpen}
      title={keepMandate ? "Confirmation paiement en ligne" : "Merci"}
      data-cy="ondemand-newMandate-success"
      hideCloseButton={isMobile}
    >
      <Box>
        {keepMandate && !showConfirmation ? (
          <>
            {amount && date && (
              <Box component="h3" sx={{ textAlign: "center", color: "primary.main", mb: 4 }}>
                Une demande de prélèvement d'un montant de {formatAmount(amount)} va être créée le{" "}
                <FormatDate value={date} />. Confirmez-vous cette demande ?
              </Box>
            )}

            <ModalFooter>
              <Button
                data-cy="ondemand-exitingMandate-abort"
                variant="outlined"
                onClick={handleCloseModal}
              >
                Annuler
              </Button>
              <LoadingButton
                data-cy="ondemand-exitingMandate-confirm"
                variant="contained"
                loading={isLoading}
                onClick={validation}
              >
                Valider
              </LoadingButton>
            </ModalFooter>
          </>
        ) : (
          <>
            <StyledTypography align="center" variant="h6">
              Votre demande de prélèvement a bien été prise en compte.
            </StyledTypography>
            {(amount || date) && (
              <Box sx={{ my: 2, display: "flex", justifyContent: "space-around" }}>
                {amount && (
                  <Typography
                    variant="h6"
                    color="secondary"
                    sx={{ "& > span": { color: "primary.main" } }}
                  >
                    Montant : {formatAmount(amount)}
                  </Typography>
                )}

                {date && (
                  <Typography
                    variant="h6"
                    color="secondary"
                    sx={{ "& > span": { color: "primary.main" } }}
                  >
                    Date d'échéance : <FormatDate value={date} />
                  </Typography>
                )}
              </Box>
            )}

            <ModalFooter>
              <Button
                size={isMobile ? "large" : "medium"}
                fullWidth={isMobile}
                variant="contained"
                onClick={handleCloseModal}
              >
                Fermer
              </Button>
            </ModalFooter>
          </>
        )}
      </Box>
    </Modal>
  );
};
