import {
  MissionKindRestrictedEnum,
  MissionSourceEnum,
  MissionStatusEnum,
} from "src/graphql/generated/types";

export const MissionStatusLabel: {
  [K in MissionStatusEnum]: string;
} = {
  [MissionStatusEnum.Open]: "Enregistrée",
  [MissionStatusEnum.WorkInProgress]: "En cours",
  [MissionStatusEnum.Summoned]: "Convoquée",
  [MissionStatusEnum.MinutesSent]: "PV envoyé",
  [MissionStatusEnum.Finished]: "Clôturée",
  [MissionStatusEnum.Close]: "Clôturée",
  [MissionStatusEnum.Ended]: "Clôturée",
};

export const MissionStatusColor: {
  [K in MissionStatusEnum]: string;
} = {
  [MissionStatusEnum.Open]: "#FF7800",
  [MissionStatusEnum.WorkInProgress]: "#f8b322",
  [MissionStatusEnum.Summoned]: "#f8b322",
  [MissionStatusEnum.MinutesSent]: "#f8b322",
  [MissionStatusEnum.Finished]: "#00925B",
  [MissionStatusEnum.Close]: "#FF5300",
  [MissionStatusEnum.Ended]: "#FF5300",
};

export const MissionRequestSourceLabel: {
  [K in MissionSourceEnum]: string;
} = {
  [MissionSourceEnum.Telephone]: "Téléphone",
  [MissionSourceEnum.Email]: "E-mail",
  [MissionSourceEnum.Myfoncia]: "MyFoncia",
  [MissionSourceEnum.Agence]: "Agence",
};

export const MissionKindLabel: {
  [K in MissionKindRestrictedEnum]: string;
} = {
  [MissionKindRestrictedEnum.MissionCustomer]: "Client",
  [MissionKindRestrictedEnum.MissionGeneralAssembly]: "Assemblée générale",
  [MissionKindRestrictedEnum.MissionIncident]: "Dépannage/Réparation",
  [MissionKindRestrictedEnum.MissionRepair]: "Travaux",
  [MissionKindRestrictedEnum.MissionTrusteeCouncil]: "Conseil syndical",
  [MissionKindRestrictedEnum.MissionVisit]: "Visite d'immeuble",
};
