import { Box, Button, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";

const StyledContainer = styled(Box)`
  height: 100vh;
  background-image: url(/images/bg-space.svg);
  background-size: cover;
`;
const StyledContent = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  padding: 10rem 0;
  background-image: url(/images/bg-spaceship.svg);
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 40rem) {
    background-image: url(/images/bg-spaceship-mobile.svg);
  }
`;

const MAINTENANCE_TITLE = "Maintenance";
const MAINTENANCE_DESCRIPTION = "Le site est en mode maintenance. Nous reviendrons bientôt...";

interface Props {
  isInMaintenance: boolean;
  title: string;
  description: string;
  onRefetch?(): void;
  onLogout(): void;
}

export const ErrorGlobal = ({
  title,
  description,
  isInMaintenance,
  onRefetch,
  onLogout,
}: Props) => {
  return (
    <StyledContainer>
      <StyledContent>
        <Box sx={{ textAlign: "center", color: "white.main", p: 2 }}>
          <Typography variant="h3">
            <b>{isInMaintenance ? MAINTENANCE_TITLE : title}</b>
          </Typography>
          <Typography variant="h6">
            {isInMaintenance ? MAINTENANCE_DESCRIPTION : description}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {Boolean(onRefetch || isInMaintenance) && (
            <Box sx={{ m: 2 }}>
              <Button
                size="large"
                color="primary"
                variant="contained"
                onClick={isInMaintenance ? () => window.location.reload() : onRefetch}
              >
                Mettre à jour
              </Button>
            </Box>
          )}
          <Box sx={{ m: 2 }}>
            <Button size="large" color="secondary" variant="contained" onClick={onLogout}>
              Déconnexion
            </Button>
          </Box>
        </Box>
      </StyledContent>
    </StyledContainer>
  );
};
