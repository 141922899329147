import { useSnackbar } from "notistack";
import React from "react";
import styled from "styled-components";

import { MetaTags, Modal } from "src/components";
import { useUpdateCustomerMutation } from "src/graphql/generated/queries";
import { useCurrentAccount, useDeviceWidth, useUuid } from "src/hooks";
import { useSubscribeEReco } from "src/hooks/useSubscribeEReco";
import { decodeBase64UUID, mobileStyle } from "src/utils";

import { ERecoSubscriptionForm } from "./_components/SubscriptionForm";

const StyledModal = styled(Modal)<{ hideCloseButton: boolean }>`
  .MuiDialogContent-root {
    ${({ hideCloseButton }) => hideCloseButton && "padding: 0;"}
    ${mobileStyle`
      padding: 0;
    `}
  }
`;

interface Props {
  open: boolean;
  onClose(): void;
}

const EReco = ({ open, onClose }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isMobile } = useDeviceWidth();
  const uuid = useUuid();
  const { customer } = useCurrentAccount();
  const { accountId } = decodeBase64UUID(uuid);

  const {
    mutate: subscriptionMutation,
    isLoading: subscriptionMutationIsLoading,
  } = useSubscribeEReco();

  const { mutate: updateCustomer, ...updateCustomerInfos } = useUpdateCustomerMutation({
    onSuccess: () => {
      onClose();
      subscriptionMutation({ customerId: customer.id, accountId });
    },
    onError: () => {
      enqueueSnackbar("Une erreur est survenue lors de la sauvegarde de votre adresse email", {
        variant: "error",
      });
    },
  });

  const onSubmitEmail = (emailValue: string) =>
    updateCustomer({
      customerId: customer.id,
      request: {
        email: emailValue,
      },
    });

  return (
    <>
      {open && <MetaTags title="EReco" />}
      <StyledModal
        icon="erecoComputer"
        title="Souscription au E-reco"
        hideCloseButton={isMobile === false}
        disableBackdropClick={subscriptionMutationIsLoading}
        disableEscapeKeyDown={subscriptionMutationIsLoading}
        open={open}
        handleClose={onClose}
        fullWidth
      >
        <ERecoSubscriptionForm
          sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
          email={customer.email}
          handleClose={onClose}
          onSubmitEmail={onSubmitEmail}
          subscriptionLoading={subscriptionMutationIsLoading}
          updateCustomerLoading={updateCustomerInfos.isLoading}
        />
      </StyledModal>
    </>
  );
};

export default EReco;
