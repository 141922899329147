import { CivilityEnum } from "src/graphql/generated/types";

export const CivilityLabels: { [K in CivilityEnum]: string } = {
  [CivilityEnum.Mr]: "M.",
  [CivilityEnum.Mrs]: "Mme",
  [CivilityEnum.Sirs]: "MM.",
  [CivilityEnum.MrAndMrs]: "M et Mme",
  [CivilityEnum.MrOrMrs]: "M. ou Mme",
  [CivilityEnum.LadiesGentlemens]: "MM. et Mmes",
  [CivilityEnum.Hoir]: "Hoir",

  [CivilityEnum.Admi]: "",
  [CivilityEnum.Aful]: "",
  [CivilityEnum.Agen]: "",
  [CivilityEnum.Asl]: "",
  [CivilityEnum.Asso]: "",
  [CivilityEnum.Cbt]: "",
  [CivilityEnum.Cie]: "",
  [CivilityEnum.Coop]: "",
  [CivilityEnum.Crts]: "",
  [CivilityEnum.Doct]: "",
  [CivilityEnum.Entr]: "",
  [CivilityEnum.Ets]: "",
  [CivilityEnum.Etud]: "",
  [CivilityEnum.Eurl]: "",
  [CivilityEnum.Fond]: "",
  [CivilityEnum.Gie]: "",
  [CivilityEnum.Grp]: "",
  [CivilityEnum.Ind]: "",
  [CivilityEnum.Mmes]: "",
  [CivilityEnum.Sa]: "",
  [CivilityEnum.Sarl]: "",
  [CivilityEnum.Sas]: "",
  [CivilityEnum.Sc]: "",
  [CivilityEnum.Sci]: "",
  [CivilityEnum.Scp]: "",
  [CivilityEnum.Scpi]: "",
  [CivilityEnum.Sdc]: "",
  [CivilityEnum.Selarl]: "",
  [CivilityEnum.Selas]: "",
  [CivilityEnum.Snc]: "",
  [CivilityEnum.Ste]: "",
  [CivilityEnum.Trib]: "",
};
