import { DocapostErrorCodesEnum } from "src/graphql/generated/types";

export const DocapostErrors: { [key in DocapostErrorCodesEnum]: string } = {
  [DocapostErrorCodesEnum.SignatureNotFound]:
    "Une erreur est survenue lors de la création de la signature, veuillez réessayer plus tard",
  [DocapostErrorCodesEnum.SignatoryUndefinedPhoneNumber]:
    "Une erreur est survenue lors de l'enregistrement de votre numéro de téléphone, veuillez réessayer plus tard",
  [DocapostErrorCodesEnum.SignatureRequiresBundleGenotp]:
    "Une erreur est survenue lors de la génération des documents de la transaction, veuillez réessayer plus tard",
  [DocapostErrorCodesEnum.SignatoryNotInitialized]:
    "Une erreur est survenue lors de la création du signataire, veuillez réessayer plus tard",
  [DocapostErrorCodesEnum.TransactionNotOpen]:
    "Une erreur est survenue lors de la création de la transaction, veuillez réessayer plus tard",
  [DocapostErrorCodesEnum.SignatureAlreadyDone]:
    "La signature a déjà été validée pour cette demande",
  [DocapostErrorCodesEnum.PlaceholderMissing]:
    "Une erreur est survenue lors de la création du message",
  [DocapostErrorCodesEnum.InvalidEmail]:
    "Une erreur est survenue lors de l'enregistrement de votre adresse email, veuillez réessayer plus tard",
  [DocapostErrorCodesEnum.InvalidEmailSender]:
    "Une erreur est survenue, veuillez réessayer plus tard (Erreur INVALID_EMAIL_SENDER)",
  [DocapostErrorCodesEnum.InvalidEmailDomain]:
    "Une erreur est survenue, veuillez réessayer plus tard (Erreur INVALID_EMAIL_DOMAIN)",
  [DocapostErrorCodesEnum.TooManyOtpSent]: "Vous avez atteint le nombre maximal d'envois",
  [DocapostErrorCodesEnum.ValidationError]:
    "Une erreur est survenue lors de la validation de la signature",
  [DocapostErrorCodesEnum.DeliveryModeForbidden]:
    "Une erreur est survenue, veuillez réessayer plus tard (Erreur DELIVERY_MODE_FORBIDDEN)",
  [DocapostErrorCodesEnum.OtpSendError]:
    "Une erreur est survenue lors de l'envoi du code, veuillez réessayer plus tard",
  [DocapostErrorCodesEnum.SmsCustomSenderInvalidLength]:
    "Une erreur est survenue, veuillez réessayer plus tard (Erreur SMS_CUSTOM_SENDER_INVALID_LENGTH)",
  [DocapostErrorCodesEnum.SmsCustomSenderWrongFormat]:
    "Une erreur est survenue, veuillez réessayer plus tard (Erreur SMS_CUSTOM_SENDER_WRONG_FORMAT)",
  [DocapostErrorCodesEnum.SmsCustomSenderNoAlpha]:
    "Une erreur est survenue, veuillez réessayer plus tard (Erreur SMS_CUSTOM_SENDER_NO_ALPHA)",
  [DocapostErrorCodesEnum.IncorrectOtpCode]: "Le code de vérification renseigné n'est pas valide",
  [DocapostErrorCodesEnum.OtpNotSent]: "Le code de vérification n'a pas encore été envoyé",
  [DocapostErrorCodesEnum.TooManyOtpTries]: "Vous avez atteint le nombre maximal de tentatives",
  [DocapostErrorCodesEnum.SignatureIncorrectType]:
    "Une erreur est survenue, veuillez réessayer plus tard (Erreur SIGNATURE_INCORRECT_TYPE)",
  [DocapostErrorCodesEnum.NoDocumentToSign]:
    "Une erreur est survenue, la transaction ne contient aucun document à signer",
};
