import { Box, Typography } from "@mui/material";
import React from "react";
import { generatePath } from "react-router-dom";

import { SubLevelLayout, Icon, Loader } from "src/components";
import { PATH_GLOBAL_ACCOUNTING, PATH_BUILDING_ACCOUNTING } from "src/constants";
import { useGetLandlordAccountingBalanceQuery } from "src/graphql/generated/queries";
import { useUuid } from "src/hooks";
import { formatAmount } from "src/utils";

type Props = {
  children: React.ReactNode;
};

export const AccountingLayout = ({ children }: Props) => {
  const uuid = useUuid();
  const { data, isLoading } = useGetLandlordAccountingBalanceQuery({ accountUuid: uuid });

  const globalSituationAmount =
    data?.account.__typename === "LandlordAccount" &&
    data.account.landlordAccounting.balances.globalSituation;

  const links = [
    {
      label: "Compte global",
      link: generatePath(PATH_GLOBAL_ACCOUNTING, {
        uuid,
      }),
    },
    {
      label: "Compte par adresse",
      link: generatePath(PATH_BUILDING_ACCOUNTING, {
        uuid,
      }),
    },
  ];

  if (data?.account.__typename !== "LandlordAccount") return null;

  if (isLoading) return <Loader />;

  return (
    <SubLevelLayout
      headerIcon={<Icon name="messages" />}
      headerTitle="Mon compte"
      links={links}
      subNavLeftNode={
        <Typography variant="body1" sx={{ color: "secondary.main" }}>
          Situation globale :{" "}
          <Box component="span" sx={{ color: "primary.main" }}>
            {globalSituationAmount ? formatAmount(globalSituationAmount) : null}
          </Box>
        </Typography>
      }
    >
      {children}
    </SubLevelLayout>
  );
};
