import { AccountCircle, Menu as MenuIcon } from "@mui/icons-material";
import MessageIcon from "@mui/icons-material/Message";
import { AppBar, Button, IconButton, Toolbar, Divider, Box } from "@mui/material";
import { rem } from "polished";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { LogoMyFonciaInlineSvg } from "src/components/LogoMyFoncia";
import { TICKETS_PAGE } from "src/constants";
import { AnalyticsCustomEventNameEnum } from "src/graphql/generated/types";
import { useDeviceWidth, useUuid } from "src/hooks";
import { CreateTicket } from "src/pages/_shared/CreateTicket";
import { logCustomEvent } from "src/utils";

import { HeaderMenu } from "../HeaderMenu";
import { contentWidth } from "../layout";

export interface MainLayoutHeaderProps {
  hasRestrictedAccess: boolean;
  hasSeveralAccounts: boolean;
  toAuth(): void;
  toProfile(): void;
  onLogout(): void;
  openSidebar(): void;
}

const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${rem(contentWidth)};
  margin: auto;
  @media (max-width: ${rem(contentWidth)}) {
    width: 100vw;
    margin: initial;
  }
`;

const StyledLogo = styled.div`
  display: flex;
  &:hover {
    cursor: ${(props) => (props.onClick ? "pointer" : "initial")};
  }
`;

const StyledMenuButton = styled(Button)`
  padding: ${(props) => props.theme.spacing(2)} ${(props) => props.theme.spacing(4)};
  border-radius: 0;
  &:hover {
    background-color: ${(props) => props.theme.palette.primary.light};
    color: ${(props) => props.theme.palette.white.main};
  }
`;

const StyledMenuIcon = styled(MenuIcon)`
  &.MuiSvgIcon-root {
    fill: ${(props) => props.theme.palette.common.white};
    width: 2rem;
    height: 2rem;
  }
`;

export const MainLayoutHeader = ({
  hasRestrictedAccess,
  hasSeveralAccounts,
  toAuth,
  toProfile,
  onLogout,
  openSidebar,
}: MainLayoutHeaderProps) => {
  const [profileAnchor, setProfileAnchor] = useState<null | HTMLElement>(null);
  const [ticketsAnchor, setTicketsAnchor] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const uuid = useUuid();

  const { isTabletMobile } = useDeviceWidth();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setProfileAnchor(event.currentTarget);
  };

  const handleTicketMenu = (event: React.MouseEvent<HTMLElement>) => {
    setTicketsAnchor(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setProfileAnchor(null);
    setTicketsAnchor(null);
  }, []);

  const goToTickets = () => {
    navigate(TICKETS_PAGE);
    handleClose();
  };

  const handleProfile = useCallback(() => {
    toProfile();
    handleClose();
  }, []);

  return (
    <AppBar position="static">
      <StyledToolbar>
        {isTabletMobile && (
          <IconButton onClick={openSidebar}>
            <StyledMenuIcon />
          </IconButton>
        )}
        <StyledLogo onClick={hasSeveralAccounts ? toAuth : undefined}>
          <LogoMyFonciaInlineSvg width="8rem" />
        </StyledLogo>

        <Box>
          <IconButton
            aria-label="Mes demandes"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleTicketMenu}
          >
            <MessageIcon fontSize="large" sx={{ color: "white.main" }} />
          </IconButton>
          <IconButton
            aria-label="paramètres compte client"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle fontSize="large" />
          </IconButton>
        </Box>

        <HeaderMenu anchorEl={profileAnchor} open={Boolean(profileAnchor)} onClose={handleClose}>
          <Box>
            {!hasRestrictedAccess && (
              <>
                <StyledMenuButton fullWidth onClick={handleProfile}>
                  Mon profil
                </StyledMenuButton>
                <Divider />
              </>
            )}
            <StyledMenuButton fullWidth onClick={onLogout}>
              Déconnexion
            </StyledMenuButton>
          </Box>
        </HeaderMenu>

        <HeaderMenu anchorEl={ticketsAnchor} open={Boolean(ticketsAnchor)} onClose={handleClose}>
          <Box>
            {!hasRestrictedAccess && (
              <>
                <CreateTicket
                  uuid={uuid}
                  renderButton={({ handleOpen }) => (
                    <StyledMenuButton
                      fullWidth
                      onClick={() => {
                        handleClose();
                        handleOpen();
                        logCustomEvent(AnalyticsCustomEventNameEnum.HeaderNewTicket);
                      }}
                    >
                      Faire une demande
                    </StyledMenuButton>
                  )}
                />
                <Divider />
              </>
            )}
            <StyledMenuButton fullWidth onClick={goToTickets}>
              Mes demandes
            </StyledMenuButton>
          </Box>
        </HeaderMenu>
      </StyledToolbar>
    </AppBar>
  );
};
