import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React, { FC, useState } from "react";
import styled from "styled-components";

import { ListColumnsConfig } from "../index";
import { ListSelectionCheckbox } from "./SelectionCheckbox";

export type ListRowProps<T = any, U = any> = {
  data: T;
  dataType?: string;
  columnsConfig: ListColumnsConfig<T>;
  isSelected: boolean;
  isMaxSelectedReached: boolean;
  onToggle: (data: T, checked: boolean) => void;
  onRowClick?: (data: T, extraProps: U) => void;
  expandComponent?: FC<{ data: T; extraProps: U }>;
  extraProps?: U;
};

export const StyledExpandWrapper = styled.td`
  background-color: #eceeed;
  padding: ${(props) => props.theme.spacing(2)};
`;

type StyledRowProps = {
  isSelected: boolean;
  isExpanded: boolean;
  onClick?: () => void;
} & Pick<ListRowProps, "columnsConfig">;

const StyledListRow = styled.tr<StyledRowProps>`
  background-color: #fff;
  border-bottom: 1px solid #eee;
  text-align: left;
  color: ${(props) => props.theme.palette.text.primary};
  cursor: ${(props) => (props.onClick ? "pointer" : "auto")};

  ${(props) => {
    if (props.isSelected || props.isExpanded) {
      return `
        & {
          background-color : #eaf6f9;
        }
      `;
    }

    if (props.onClick) {
      return `
            &:hover {
                background-color : #eaf6f9;
            }
        `;
    }

    return "";
  }}

  ${(props) =>
    props.columnsConfig.map((c, index) => {
      // Column Width
      let widthValue = "auto";
      if (c.type === "selection" || c.type === "expand") {
        widthValue = "4%";
      } else if (c.width) {
        widthValue = `${c.width}%`;
      }

      // TextAlign
      let textAlign = "left";
      if (c.rowAlign) {
        textAlign = c.rowAlign;
      }

      return `& > td:nth-child(${index + 1}) {
        max-width: ${widthValue};
        text-align: ${textAlign};
      }`;
    })}

  > td {
    padding: ${(props) =>
      props.theme.spacing(props.columnsConfig.find((c) => c.type === "selection") ? 1 : 2)};

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
  }
`;

export const ListDefaultRow = React.memo(
  ({
    data,
    dataType,
    columnsConfig,
    isSelected,
    isMaxSelectedReached,
    onToggle,
    onRowClick,
    expandComponent,
    extraProps,
  }: ListRowProps) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const ExpandComponent = expandComponent;

    let onClick: (() => void) | undefined;
    if (ExpandComponent) {
      onClick = () => setIsExpanded(!isExpanded);
    } else if (onRowClick) {
      onClick = () => onRowClick(data, extraProps);
    }

    return (
      <>
        <StyledListRow
          columnsConfig={columnsConfig}
          onClick={onClick}
          isSelected={isSelected}
          isExpanded={isExpanded}
          data-cy-rowtype={dataType}
        >
          {columnsConfig.map((c, index) => {
            let content = null;
            if (c.type === "selection") {
              content = (
                <ListSelectionCheckbox
                  isMaxSelectedReached={isMaxSelectedReached}
                  checked={isSelected}
                  onChange={(checked) => {
                    onToggle(data, checked);
                  }}
                />
              );
            } else if (c.type === "expand") {
              content = isExpanded ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />;
            } else {
              content = c.render(data, extraProps);
            }

            return <td key={index}>{content}</td>;
          })}
        </StyledListRow>
        {isExpanded && ExpandComponent && (
          <tr>
            <StyledExpandWrapper colSpan={columnsConfig.length}>
              <ExpandComponent data={data} extraProps={extraProps} />
            </StyledExpandWrapper>
          </tr>
        )}
      </>
    );
  }
);
