import {
  DocapostErrors,
  IbanErrors,
  PlatoErrors,
  API_MAINTENANCE_CODE,
  TOO_MANY_REQUESTS_CODE,
} from "src/constants";
import { ServerError } from "src/graphql/fetcher";

const sources: { [key: string]: { [key: string]: string } } = {
  docapost: DocapostErrors,
  plato: PlatoErrors,
  iban: IbanErrors,
};
const GENERIC_ERROR_MESSAGE = "Une erreur est survenue.";

export function getSpecificError(error: ServerError, errorMessage?: string | null) {
  const errors = error.response?.errors;
  const finalErrorMessage = errorMessage || GENERIC_ERROR_MESSAGE;

  if (!errors?.length) return finalErrorMessage;

  const code = errors[0].extensions?.code as string | undefined;

  if (code === API_MAINTENANCE_CODE) {
    return "Cette page est momentanément indisponible, veuillez réessayer plus tard";
  }

  if (code === TOO_MANY_REQUESTS_CODE) {
    return "Vous avez dépassé le nombre maximum de demandes. Réessayez dans 10 minutes.";
  }

  const source = errors[0].extensions?.source as string | undefined;
  const errorCode = errors[0].extensions?.errorCode as string | undefined;

  if (!source || !errorCode || !sources[source] || !sources[source][errorCode]) {
    return finalErrorMessage;
  }

  return sources[source][errorCode];
}
