import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Typography, Button } from "@mui/material";
import React, { useState, useEffect } from "react";

type Props = {
  email: string;
  nextEmail: string;
  loading: boolean;
  onAccept: () => void;
  onCancel: () => void;
};

export const AskToAssociate = ({ email, nextEmail, loading, onAccept, onCancel }: Props) => {
  const [isAssociationRejected, setIsAssociationRejected] = useState(false);
  const isNextEmailExist = nextEmail.length > 0;

  useEffect(() => {
    if (isNextEmailExist) setIsAssociationRejected(false);
  }, [nextEmail]);

  const handleCancelClick = () => {
    setIsAssociationRejected(true);
    onCancel();
  };

  return (
    <Box sx={{ m: 1, textAlign: "center" }}>
      <Box sx={{ mb: 2 }}>
        <Typography
          variant="subtitle1"
          sx={{
            mb: 2,
            color: "primary.light",
            lineHeight: "24px",
          }}
        >
          <b>
            L'adresse email
            <Box
              component="span"
              sx={{ color: isNextEmailExist ? "danger.main" : "primary.light" }}
            >
              {" "}
              {isNextEmailExist ? nextEmail : email}
            </Box>{" "}
            est déjà utilisée comme identifiant MyFoncia.
          </b>
        </Typography>

        <Typography variant="subtitle1" sx={{ lineHeight: "24px", color: "secondary.main" }}>
          <b>Souhaitez-vous associer cette adresse email au compte en cours ?</b>
        </Typography>
      </Box>
      <Box
        sx={{
          mb: 2,
          color: isAssociationRejected ? "gray.dark" : undefined,
          backgroundColor: isAssociationRejected ? "gray.deep" : undefined,
        }}
      >
        <LoadingButton
          fullWidth
          size="large"
          color={isAssociationRejected ? "inherit" : "primary"}
          variant="contained"
          loading={loading}
          onClick={onAccept}
        >
          OUI, ASSOCIER À L'EMAIL EXISTANT
        </LoadingButton>
      </Box>
      <Box
        sx={{
          color: isAssociationRejected ? "white.main" : "gray.dark",
          backgroundColor: isAssociationRejected ? "gray.dark" : "gray.deep",
        }}
      >
        <Button fullWidth color="inherit" onClick={handleCancelClick}>
          NON, JE SOUHAITE UTILISER UN AUTRE EMAIL
        </Button>
      </Box>
    </Box>
  );
};
