import { Box, BoxProps } from "@mui/material";
import { rem } from "polished";
import React, { useState } from "react";
import styled from "styled-components";

import { amountCellWidth } from "src/constants";
import { ExpenseTypeFragment } from "src/graphql/generated/types";
import { formatAmount } from "src/utils";

import { ExpandIcon } from "../../ExpandIcon";
import { FormatDate } from "../../FormatDate";
import { ExpenseDocument } from "../ExpenseDocument";
import { ExpenseInvoice } from "../ExpenseInvoice";
import { ExpenseTable } from "../ExpenseTable";
import { ExpenseTypeAmountLabel } from "../ExpenseTypeAmountLabel";
import { ExpenseTypeRow } from "../ExpenseTypeRow";

export const ExpenseTypeStyled = styled.div`
  background-color: ${(props) => props.theme.palette.primary.main};
  color: white;
  cursor: ${(props) => (props.onClick ? "pointer" : "auto")};
  display: flex;

  > div {
    width: ${rem(amountCellWidth)};
    padding: ${(props) => props.theme.spacing(1.5)};
    border-left: 1px solid #ddd;
    text-align: center;
    font-weight: 500;

    &:first-child {
      flex: 1;
      width: auto;
      display: flex;
      align-items: center;
      border-left: 0;
      text-align: left;
    }
  }
`;

type Props = {
  data: ExpenseTypeFragment;
} & BoxProps;

export const ExpenseType = ({ data, ...boxProps }: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  return (
    <Box {...boxProps}>
      <ExpenseTypeStyled
        key={data.id}
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
      >
        <div>
          <ExpandIcon aria-expanded={isExpanded} sx={{ mr: 2 }} />
          <div>
            {data.code} - {data.name}
          </div>
        </div>
        <div>
          <ExpenseTypeAmountLabel>A répartir</ExpenseTypeAmountLabel>
          {formatAmount(data.toAllocate)}
        </div>
        <div>
          <ExpenseTypeAmountLabel>TVA</ExpenseTypeAmountLabel>
          {formatAmount(data.vat)}
        </div>
        <div>
          <ExpenseTypeAmountLabel>Récupérable</ExpenseTypeAmountLabel>
          {formatAmount(data.recoverable)}
        </div>
      </ExpenseTypeStyled>
      {isExpanded && (
        <ExpenseTable
          header={
            <>
              <td />
              <td>Date</td>
              <td>Libellé</td>
              <td>A répartir</td>
              <td>TVA</td>
              <td>Récupérable</td>
            </>
          }
        >
          {data.expenses.map((expense) => {
            const { invoiceId, piece } = expense;
            return (
              <ExpenseTypeRow key={expense.id}>
                <td>
                  {invoiceId ? (
                    <ExpenseInvoice invoiceId={invoiceId} />
                  ) : (
                    piece?.hashFile && <ExpenseDocument hash={piece?.hashFile} />
                  )}
                </td>
                <td>
                  <FormatDate value={expense.date} />
                </td>
                <td>{expense.label}</td>
                <td>{formatAmount(expense.toAllocate)}</td>
                <td>{formatAmount(expense.vat)}</td>
                <td>{formatAmount(expense.recoverable)}</td>
              </ExpenseTypeRow>
            );
          })}
        </ExpenseTable>
      )}
    </Box>
  );
};
