import { Box, BoxProps } from "@mui/material";
import { rem } from "polished";
import React, { useContext } from "react";
import styled from "styled-components";

import { ERecoContext } from "src/contexts";
import { mobileStyle } from "src/utils";

const StyledRootBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.ereco.main};
  color: ${(props) => props.theme.palette.white.main};
  padding: ${(props) => props.theme.spacing(3)};
  text-align: center;
  cursor: pointer;
  ${mobileStyle`
    margin: ${(props) => props.theme.spacing(1)};
    border-radius: ${(props) => props.theme.spacing(0.5)};
  `}
`;

const StyledTitle = styled.div`
  font-weight: bold;
  font-size: ${rem(24)};
`;

const StyledContent = styled.div`
  width: 80%;
  margin: auto;
  margin-top: ${(props) => props.theme.spacing(2)};
`;

export const ErecoSubscription = (boxProps: BoxProps) => {
  const { openEReco } = useContext(ERecoContext);

  return (
    <StyledRootBox {...boxProps} onClick={openEReco}>
      <StyledTitle>Ayez le bon réflexe, passez à l'E-reco</StyledTitle>
      <StyledContent>
        <div>
          Ce service sécurisé de notification électronique vous permet de recevoir vos convocations
          et procès-verbaux d'assemblée générale dans votre espace MyFoncia.
        </div>
        <div>
          Cette solution est pratique, éco-responsable et contribue à réduire le montant de vos
          charges de copropriété.
        </div>
      </StyledContent>
    </StyledRootBox>
  );
};
