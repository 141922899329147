import { useGetAccountQuery } from "src/graphql/generated/queries";
import type { AccountFragment } from "src/graphql/generated/types";

import { useUuid } from "./useUuid";

/*
This hook should only be used when an account is selected
*/

export const useCurrentAccount = (): AccountFragment => {
  const uuid = useUuid();

  const { data } = useGetAccountQuery({
    uuid,
  });

  if (!data?.account) {
    throw new Error(
      "useCurrentAccount must be used after the account data has been fetched. Ensure that the account query has successfully completed before calling this hook."
    );
  }

  return data.account;
};
