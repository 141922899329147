import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";

import { megabytesTobytes } from "src/utils";

import { UploadedDocument } from "../UploadedDocuments";

interface Props {
  documents: Array<File> | null;
  onChange: (files: Array<File> | null) => void;
  placeholder: string;
  maxFiles: number;
  isLoading?: boolean;
  isSuccess?: boolean;
}

const Container = styled.div<{ hasDocument: boolean | null }>`
  border: 1px dashed ${(props) => props.theme.palette.blue.main};
  border-radius: ${(props) => props.theme.spacing(0.5)};
  height: 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: ${(props) => (props.hasDocument ? props.theme.spacing(1) : props.theme.spacing(4))};
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.palette.gray.light};
  }
`;

export const FileUpload = ({
  documents,
  onChange,
  placeholder,
  maxFiles,
  isLoading,
  isSuccess,
}: Props) => {
  const onDropAccepted = (files: Array<File>) => onChange([...(documents || []), ...files]);

  const disabled = documents ? documents?.length === maxFiles : false;

  const { getRootProps, getInputProps, isDragReject, isDragActive, isDragAccept } = useDropzone({
    accept: {
      "application/pdf": [],
    },
    maxFiles,
    multiple: maxFiles > 1,
    maxSize: Math.round(megabytesTobytes(10)),
    disabled,
    onDropAccepted,
  });

  const reset = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, file: File) => {
      event.stopPropagation();

      if (documents) onChange(documents.filter((document: File) => document.name !== file.name));
    },
    [documents]
  );

  return (
    <Container
      hasDocument={documents && documents?.length > 0}
      {...getRootProps({
        isDragActive,
        isDragAccept,
        isDragReject,
      })}
    >
      <Box sx={{ display: "flex" }}>
        <input {...getInputProps()} disabled={disabled} />
        {documents && documents?.length !== 0 ? (
          documents.map((document) => (
            <>
              {isLoading && <CircularProgress sx={{ m: 4 }} size={30} />}
              {isSuccess && (
                <UploadedDocument
                  key={document.name}
                  name={document.name}
                  onClick={(e) => reset(e, document)}
                />
              )}
            </>
          ))
        ) : (
          <Stack direction="row" gap={5} alignItems="center">
            <CloudUploadIcon sx={{ color: "blue.main", fontSize: "2rem" }} />
            <Typography sx={{ mr: 2 }} color="gray.main">
              {placeholder}
            </Typography>
          </Stack>
        )}
      </Box>
    </Container>
  );
};
