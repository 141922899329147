import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const Wisepops = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.wisepops) {
      window.wisepops("pageview");
    }
  }, [location.pathname, location.key]);

  return null;
};
