import { parseDigit } from "input-format";
import parsePhoneNumber, { CountryCode, AsYouType } from "libphonenumber-js";

export const parse = (character: string, value: string) => {
  // Leading plus is allowed
  if (character === "+") {
    if (!value) {
      return character;
    }
  }
  // Only digits are allowed
  return parseDigit(character);
};

export const format = (value: string, countryCode: CountryCode) => {
  try {
    const phoneNumber = parsePhoneNumber(value, countryCode);
    const nationalPhoneNumber = phoneNumber?.formatNational() || value;

    // @note: improved formatting for french number under 4 characters
    // landline and mobile phone format are prioritize over professional one (0 800)
    if (countryCode === "FR" && nationalPhoneNumber.length < 4) {
      const text = nationalPhoneNumber.replace(/[0-9]{2}(?=.)/g, "$& ");
      const template = text.replace(/[0-9]/g, "x");
      return { text, template };
    }

    const asYouType = new AsYouType(countryCode);
    const text = asYouType.input(nationalPhoneNumber);
    const template = asYouType.getTemplate();

    return { text, template };
  } catch (error) {
    if (error instanceof Error) {
      if (error.message.indexOf("Unknown country") === 0) {
        return { text: value, template: value.replace(/./g, "x") };
      }
    }
    throw error;
  }
};
