import InfoIcon from "@mui/icons-material/InfoOutlined";
import { Box, Typography } from "@mui/material";
import React from "react";

import { TicketActivityLabel, TicketCategory, TicketHeadingCategory } from "src/constants";
import { TicketActivityFragment } from "src/graphql/generated/types";

import { activityContainer, iconStyle, newCategoryStyle } from "./styles";

type Props = {
  activityInfo: TicketActivityFragment;
};

export const ActivityInfo = ({ activityInfo }: Props) => {
  const { activityType } = activityInfo;

  const ticketActivityLabel = TicketActivityLabel[activityType];

  const ticketCategory =
    activityInfo.metadata?.categoryAfter && TicketCategory[activityInfo.metadata?.categoryAfter];
  const ticketCategoryLabel = [
    ticketCategory?.headingCategory && TicketHeadingCategory[ticketCategory.headingCategory].label,
    ticketCategory?.label,
  ]
    .filter(Boolean)
    .join(" / ");

  if (!ticketActivityLabel) return null;

  return (
    <Box sx={activityContainer}>
      <InfoIcon color="inherit" sx={iconStyle} />

      <Box>
        <Typography display="inline">{ticketActivityLabel}</Typography>{" "}
        <Typography display="inline" sx={newCategoryStyle}>
          {ticketCategoryLabel}
        </Typography>
      </Box>
    </Box>
  );
};
