import { Close } from "@mui/icons-material";
import { Box, Button, IconButton } from "@mui/material";
import { rem } from "polished";
import React from "react";
import styled from "styled-components";

import { AccountQualityLabels } from "src/constants";
import { AccountFragment, CustomerFragment } from "src/graphql/generated/types";
import { getCustomerDisplayName, tabletStyle } from "src/utils";

import { ContentEstate } from "../ContentStateProps";

type Props = {
  hasSeveralAccounts: boolean;
  account: AccountFragment;
  customer: CustomerFragment;
  toAuth(): void;
  closeSidebar(): void;
};

const StyledRootBox = styled(Box)`
  background: ${(props) => props.theme.palette.white.main};
`;

const StyledHead = styled.div`
  background-color: ${(props) => props.theme.palette.common.white};
  width: 100vw;
  ${tabletStyle`
    width: 50vw;
  `}
  height: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${(props) => props.theme.spacing(2)} 0 0;
`;

const CloseIcon = styled(Close)`
  width: 2rem;
  height: 2rem;
  & path {
    fill: ${(props) => props.theme.palette.blue.light};
  }
`;

const AccountDetails = styled.div`
  padding: ${(props) => props.theme.spacing(2)};
  background-color: ${(props) => props.theme.palette.background.default};
`;

const ContentCustomer = styled.div`
  border-left: ${rem(3)} solid ${(props) => props.theme.palette.primary.main};
  background-color: ${(props) => props.theme.palette.common.white};
  padding: ${(props) => props.theme.spacing(2)};
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: ${rem(16)};
`;

const CustomerName = styled.div`
  font-weight: bold;
  text-transform: capitalize;
`;

export const MainLayoutAccountInfosMobile = ({
  hasSeveralAccounts,
  account,
  customer,
  toAuth,
  closeSidebar,
}: Props) => {
  const buildings =
    account.__typename === "LandlordAccount" ? account.buildings : [account.building];

  return (
    <StyledRootBox>
      <StyledHead>
        <IconButton onClick={closeSidebar}>
          <CloseIcon />
        </IconButton>
        {hasSeveralAccounts && (
          <Button variant="contained" onClick={toAuth}>
            Changer de compte
          </Button>
        )}
      </StyledHead>
      <AccountDetails>
        <ContentEstate buildings={buildings} />
        <ContentCustomer>
          <CustomerName>{getCustomerDisplayName(customer)}</CustomerName>
          <div>{AccountQualityLabels[account.quality]}</div>
          <div>N° du compte : {account.number}</div>
        </ContentCustomer>
      </AccountDetails>
    </StyledRootBox>
  );
};
