import { Box, BoxProps, Stack, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import styled from "styled-components";

const StyledFormElementContainer = styled(Box)`
  background-color: ${(props) => props.theme.palette.tickets.boxBackground};
  border-color: ${(props) => props.theme.palette.tickets.border};
  border-radius: ${(props) => props.theme.spacing(0.5)};
  border-width: thin;
  border-style: solid;
`;

type Props = {
  label?: string;
  icon?: ReactNode;
} & BoxProps;
export const FormElementContainer = ({ label, icon, ...props }: Props) => {
  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        {label && <Typography sx={{ color: "gray.main", fontSize: 12 }}>{label}</Typography>}
        {icon && icon}
      </Stack>
      <StyledFormElementContainer {...props} />
    </>
  );
};
