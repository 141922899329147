/* eslint-disable */
import * as Types from './types';

import { ServerError } from "../fetcher";

import { useQuery, useInfiniteQuery, useMutation, UseQueryOptions, UseInfiniteQueryOptions, UseMutationOptions } from '@tanstack/react-query';
import { fetcher } from '../fetcher';
export const AmountFragmentDoc = `
    fragment amount on Amount {
  value
  currency
}
    `;
export const BuildingBalanceFragmentDoc = `
    fragment buildingBalance on BuildingBalance {
  buildingId
  total {
    ...amount
  }
}
    `;
export const AddressFragmentDoc = `
    fragment address on Address {
  address1
  address2
  city
  zipCode
  countryCode
}
    `;
export const CustomerFragmentDoc = `
    fragment customer on Customer {
  id
  number
  civility
  familyStatus
  firstName
  lastName
  email
  address {
    ...address
  }
  birthDate
  phones {
    landline
    mobile
  }
  noSnailMail
  contactPreferences {
    eventNotification {
      sms
      email
      postalMail
    }
    litigationPrevention {
      sms
      email
      postalMail
    }
    privilegedOffers {
      sms
      email
      postalMail
    }
    myFonciaNews {
      sms
      email
      postalMail
    }
    newsLetter {
      sms
      email
      postalMail
    }
  }
  confidentiality {
    personalData
    isFirstSubmit
    partnersOffersInformation
  }
  company {
    name
    siret
    legalForm
    address {
      ...address
    }
  }
}
    `;
export const BranchFragmentDoc = `
    fragment branch on Branch {
  commercialName
  agency {
    id
  }
  schedule {
    day
    startHourAm
    startHourPm
    endHourAm
    endHourPm
    onAppointmentAm
    onAppointmentPm
  }
  address {
    ...address
  }
  contact {
    telephone
    coOwnerSinisterPhone
    tenantSinisterPhone
  }
}
    `;
export const BasicBuildingFragmentDoc = `
    fragment basicBuilding on Building {
  id
  name
  number
  address {
    ...address
  }
  units {
    id
  }
}
    `;
export const UnitFragmentDoc = `
    fragment unit on Unit {
  id
  number
  coOwnershipByLawsId
  shareBase
  fractionalShares
  managedByFoncia
  situation {
    floor
    property
    stairs
    door
  }
  description {
    freeLabeling
    entityType
    surface {
      liveable
      carrezLaw
      garden
    }
    totalRooms {
      liveable
      commercial
    }
  }
}
    `;
export const BuildingFragmentDoc = `
    fragment building on Building {
  id
  number
  name
  numberOfElevators
  shareBase
  address {
    ...address
  }
  physicalBuildings {
    id
    name
    entrances {
      id
      pinpad
    }
  }
  conciergeOffice {
    exist
    hours
    openingDays
    phone
  }
  units {
    ...unit
  }
}
    `;
export const AccountFragmentDoc = `
    fragment account on Account {
  uuid
  number
  quality
  hasRestrictedAccess
  customer {
    ...customer
  }
  manager {
    id
  }
  branch {
    ...branch
  }
  ... on CoownerAccount {
    hasEReleve
    hasAccessToGeneralAssembly
    hasActiveCoOwnershipMandate
    isTrusteeCouncilMember
    showDalenysHeadband
    eReco {
      isSubscribed
      hasHistory
    }
    suspendedFlow {
      isSuspended
      reactivationDate
    }
    building {
      ...basicBuilding
    }
  }
  ... on LandlordAccount {
    buildings {
      ...building
    }
    mainUnits {
      ...unit
    }
  }
  ... on TenantAccount {
    hasFutureLeaseEntryDate
    building {
      ...basicBuilding
    }
    mainUnit {
      id
    }
  }
}
    `;
export const BasicAccountFragmentDoc = `
    fragment basicAccount on Account {
  uuid
  number
  quality
  count {
    units
    buildings
  }
  customer {
    ...customer
  }
  ... on CoownerAccount {
    building {
      ...basicBuilding
    }
  }
  ... on TenantAccount {
    building {
      ...basicBuilding
    }
  }
  ... on LandlordAccount {
    buildings {
      ...basicBuilding
    }
  }
}
    `;
export const BuildingAccountingCurrentExpenseFragmentDoc = `
    fragment buildingAccountingCurrentExpense on BuildingAccountingCurrentExpense {
  invoiceId
  piece {
    hashFile
  }
  label
  date
  amount {
    ... on Debit {
      value
      currency
    }
    ... on Credit {
      value
      currency
    }
  }
  isFromPreviousPeriod
}
    `;
export const BuildingAccountingCurrentExpenseTypeFragmentDoc = `
    fragment buildingAccountingCurrentExpenseType on BuildingAccountingCurrentExpenseType {
  id
  allocationId
  name
  code
  previousTotal {
    ...amount
  }
  votedTotal {
    ...amount
  }
  total {
    ...amount
  }
  nextVotedTotal {
    ...amount
  }
  expenses {
    ...buildingAccountingCurrentExpense
  }
}
    `;
export const BuildingAccountingCurrentAllocationFragmentDoc = `
    fragment buildingAccountingCurrentAllocation on BuildingAccountingCurrentAllocation {
  id
  name
  code
  previousTotal {
    ...amount
  }
  votedTotal {
    ...amount
  }
  total {
    ...amount
  }
  nextVotedTotal {
    ...amount
  }
  expenseTypes {
    ...buildingAccountingCurrentExpenseType
  }
}
    `;
export const RestrictedUnitFragmentDoc = `
    fragment restrictedUnit on RestrictedUnit {
  id
  number
  coOwnershipByLawsId
}
    `;
export const RestrictedCoownerFragmentDoc = `
    fragment restrictedCoowner on RestrictedCoowner {
  id
  units {
    ...restrictedUnit
  }
  mainHolder {
    propertyType
    customer {
      id
      civility
      firstName
      lastName
      displayName
      address {
        address1
        city
        zipCode
      }
    }
  }
  balance {
    ...amount
  }
}
    `;
export const ExpenseFragmentDoc = `
    fragment expense on Expense {
  id
  label
  date
  invoiceId
  piece {
    hashFile
    category
    id
  }
  toAllocate {
    ...amount
  }
  vat {
    ...amount
  }
  recoverable {
    ...amount
  }
}
    `;
export const ExpenseTypeFragmentDoc = `
    fragment expenseType on ExpenseType {
  id
  allocationId
  name
  code
  toAllocate {
    ...amount
  }
  vat {
    ...amount
  }
  recoverable {
    ...amount
  }
  expenses {
    ...expense
  }
}
    `;
export const AllocationFragmentDoc = `
    fragment allocation on Allocation {
  id
  name
  code
  toAllocate {
    ...amount
  }
  vat {
    ...amount
  }
  recoverable {
    ...amount
  }
  expenseTypes {
    ...expenseType
  }
}
    `;
export const BuildingRepairBudgetFragmentDoc = `
    fragment buildingRepairBudget on BuildingRepairBudget {
  budgetId
  totalToAllocate {
    ...amount
  }
  totalVat {
    ...amount
  }
  totalRecoverable {
    ...amount
  }
  allocations {
    ...allocation
  }
}
    `;
export const ErecoFragmentDoc = `
    fragment ereco on CoownerAccountERecoDocument {
  id
  url
  typeDocument
  document {
    hashFile
  }
  createdAt
  status
}
    `;
export const AccountingBalanceFragmentDoc = `
    fragment accountingBalance on Accounting {
  currentBalance {
    ...amount
  }
  projectedBalance {
    ...amount
  }
}
    `;
export const AccountingLineFragmentDoc = `
    fragment accountingLine on AccountingLine {
  id
  label
  valueDate
  dueDate
  invoiceId
  amount {
    ... on Debit {
      value
      currency
    }
    ... on Credit {
      value
      currency
    }
  }
}
    `;
export const AccountingPeriodFragmentDoc = `
    fragment accountingPeriod on AccountingPeriod {
  id
  name
  openingDate
  closingDate
  status
}
    `;
export const BankingInformationFragmentDoc = `
    fragment bankingInformation on BankingInformation {
  isOutdated
  detail {
    id
    name
    iban
    bic
  }
}
    `;
export const DebitLineFragmentDoc = `
    fragment debitLine on AccountingDebit {
  id
  status
  motive
  createdAt
  instructionDate
  isAuto
  amount {
    ...amount
  }
}
    `;
export const ExpectedPropertyTaxBuildingFragmentDoc = `
    fragment expectedPropertyTaxBuilding on ExpectedLandlordPropertyTaxBuilding {
  building {
    id
    number
    address {
      ...address
    }
  }
  lessorPropertyTaxId
  lessorPropertyTaxBuildingId
}
    `;
export const ExpectedLandlordPropertyTaxFragmentDoc = `
    fragment expectedLandlordPropertyTax on ExpectedLandlordPropertyTax {
  fiscalYear
  buildings {
    ...expectedPropertyTaxBuilding
  }
}
    `;
export const GeneralAssemblyFragmentDoc = `
    fragment generalAssembly on MissionGeneralAssembly {
  id
  label
  officialMeeting {
    date
  }
  meetingPlace {
    ...address
  }
  lastValidPostalVoteReceptionDate
  hasAccessToPostalVote
  hasPreVoting
  status
}
    `;
export const MissionIncidentFragmentDoc = `
    fragment missionIncident on MissionIncident {
  id
  number
  startedAt
  label
  status
}
    `;
export const MissionRepairFragmentDoc = `
    fragment missionRepair on MissionRepair {
  id
  number
  startedAt
  label
  status
}
    `;
export const DocumentFragmentDoc = `
    fragment document on Document {
  id
  hashFile
  mimeType
  originalFilename
  category
  createdAt
}
    `;
export const MissionVisitFragmentDoc = `
    fragment missionVisit on MissionVisit {
  id
  label
  number
  status
  createdAt
  date {
    planned
  }
  documents {
    ...document
  }
}
    `;
export const PageInfoFragmentDoc = `
    fragment pageInfo on PageInfo {
  startCursor
  endCursor
  hasPreviousPage
  hasNextPage
  pageNumber
  itemsPerPage
  totalDisplayPages
  totalPages
}
    `;
export const PaymentLineFragmentDoc = `
    fragment paymentLine on AccountingPayment {
  id
  status
  motive
  createdAt
  instructionDate
  isAuto
  paymentUrl
  amount {
    ...amount
  }
}
    `;
export const PropertyAssetsFragmentDoc = `
    fragment propertyAssets on PropertyAssets {
  netIncomes {
    ...amount
  }
  grossIncomes {
    ...amount
  }
  expensesTotal {
    ...amount
  }
  rentsCollectedTotal {
    ...amount
  }
  rentsCalledTotal {
    ...amount
  }
  unpaidRentCover {
    ...amount
  }
  startDate
}
    `;
export const ManagerFragmentDoc = `
    fragment manager on Manager {
  id
  email
  firstName
  lastName
  branch {
    commercialName
  }
}
    `;
export const TicketMessageFragmentDoc = `
    fragment ticketMessage on TicketMessage {
  content
  source
  createdBy
  createdAt
  documents {
    ...document
  }
  associate {
    ...manager
  }
}
    `;
export const TicketActivityFragmentDoc = `
    fragment ticketActivity on TicketActivity {
  metadata {
    categoryAfter
  }
  createdAt
  activityType
}
    `;
export const TicketFragmentDoc = `
    fragment ticket on Ticket {
  id
  category
  ticketNumber
  status
  createdAt
  finishedAt
  isRead
  canBeReopened
  customerApprovalDate
  messages {
    ...ticketMessage
  }
  activities {
    ...ticketActivity
  }
  building {
    id
    number
    name
    address {
      ...address
    }
  }
  unit {
    ...unit
  }
  cancelledAt
}
    `;
export const MissionMeetingAgendaFragmentDoc = `
    fragment missionMeetingAgenda on MissionMeetingAgenda {
  status
  mission {
    number
    label
    kind
    status
  }
}
    `;
export const MissionMeetingFragmentDoc = `
    fragment missionMeeting on MissionMeeting {
  id
  status
  number
  label
  date {
    planned
    end
  }
  agendas {
    ...missionMeetingAgenda
  }
  housing {
    ... on Building {
      id
      address {
        ...address
      }
    }
  }
  notes {
    id
    text
  }
}
    `;
export const SupplierFragmentDoc = `
    fragment supplier on Supplier {
  id
  name
  firstName
  activity
}
    `;
export const SupplierContractFragmentDoc = `
    fragment supplierContract on SupplierContract {
  id
  label
  description
  number
  endingDate
  supplier {
    ...supplier
  }
  documents {
    ...document
  }
}
    `;
export const MissionWithSaleAgreementFragmentDoc = `
    fragment missionWithSaleAgreement on MissionWithSaleAgreement {
  id
  label
  units {
    id
    unitLegacyNumber
  }
  status
  createdAt
  documentNotificationSendedAt
  document {
    ...document
  }
}
    `;
export const WorkOrderFragmentDoc = `
    fragment workOrder on WorkOrder {
  id
  number
  label
  repairDate {
    start
    end
  }
  supplier {
    ...supplier
  }
}
    `;
export const ResiliateMandateDebitFragmentDoc = `
    fragment resiliateMandateDebit on ResiliateSepaMandateDebit {
  id
  label
  amount {
    ...amount
  }
  instructionDate
}
    `;
export const GetAccountingBalanceDocument = `
    query getAccountingBalance($accountUuid: EncodedID!) {
  account(uuid: $accountUuid) {
    uuid
    accounting {
      ...accountingBalance
    }
  }
}
    ${AccountingBalanceFragmentDoc}
${AmountFragmentDoc}`;
export const useGetAccountingBalanceQuery = <
      TData = Types.GetAccountingBalanceQuery,
      TError = ServerError
    >(
      variables: Types.GetAccountingBalanceQueryVariables,
      options?: UseQueryOptions<Types.GetAccountingBalanceQuery, TError, TData>
    ) =>
    useQuery<Types.GetAccountingBalanceQuery, TError, TData>(
      ['getAccountingBalance', variables],
      fetcher<Types.GetAccountingBalanceQuery, Types.GetAccountingBalanceQueryVariables>(GetAccountingBalanceDocument, variables),
      options
    );

useGetAccountingBalanceQuery.getKey = (variables: Types.GetAccountingBalanceQueryVariables) => ['getAccountingBalance', variables];
;

export const useInfiniteGetAccountingBalanceQuery = <
      TData = Types.GetAccountingBalanceQuery,
      TError = ServerError
    >(
      variables: Types.GetAccountingBalanceQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetAccountingBalanceQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetAccountingBalanceQuery, TError, TData>(
      ['getAccountingBalance.infinite', variables],
      (metaData) => fetcher<Types.GetAccountingBalanceQuery, Types.GetAccountingBalanceQueryVariables>(GetAccountingBalanceDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetAccountingBalanceQuery.getKey = (variables: Types.GetAccountingBalanceQueryVariables) => ['getAccountingBalance.infinite', variables];
;

export const GetAccountingLineNewTenantDocument = `
    query getAccountingLineNewTenant($uuid: EncodedID!) {
  account(uuid: $uuid) {
    uuid
    accounting {
      lines(first: 1) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            ...accountingLine
          }
        }
      }
    }
  }
}
    ${AccountingLineFragmentDoc}`;
export const useGetAccountingLineNewTenantQuery = <
      TData = Types.GetAccountingLineNewTenantQuery,
      TError = ServerError
    >(
      variables: Types.GetAccountingLineNewTenantQueryVariables,
      options?: UseQueryOptions<Types.GetAccountingLineNewTenantQuery, TError, TData>
    ) =>
    useQuery<Types.GetAccountingLineNewTenantQuery, TError, TData>(
      ['getAccountingLineNewTenant', variables],
      fetcher<Types.GetAccountingLineNewTenantQuery, Types.GetAccountingLineNewTenantQueryVariables>(GetAccountingLineNewTenantDocument, variables),
      options
    );

useGetAccountingLineNewTenantQuery.getKey = (variables: Types.GetAccountingLineNewTenantQueryVariables) => ['getAccountingLineNewTenant', variables];
;

export const useInfiniteGetAccountingLineNewTenantQuery = <
      TData = Types.GetAccountingLineNewTenantQuery,
      TError = ServerError
    >(
      variables: Types.GetAccountingLineNewTenantQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetAccountingLineNewTenantQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetAccountingLineNewTenantQuery, TError, TData>(
      ['getAccountingLineNewTenant.infinite', variables],
      (metaData) => fetcher<Types.GetAccountingLineNewTenantQuery, Types.GetAccountingLineNewTenantQueryVariables>(GetAccountingLineNewTenantDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetAccountingLineNewTenantQuery.getKey = (variables: Types.GetAccountingLineNewTenantQueryVariables) => ['getAccountingLineNewTenant.infinite', variables];
;

export const GetAccountingLinesDocument = `
    query getAccountingLines($accountUuid: EncodedID!, $pageOptions: PageOptions) {
  account(uuid: $accountUuid) {
    uuid
    accounting {
      lines(pageOptions: $pageOptions) {
        totalCount
        pageInfo {
          ...pageInfo
        }
        edges {
          node {
            ...accountingLine
          }
        }
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${AccountingLineFragmentDoc}`;
export const useGetAccountingLinesQuery = <
      TData = Types.GetAccountingLinesQuery,
      TError = ServerError
    >(
      variables: Types.GetAccountingLinesQueryVariables,
      options?: UseQueryOptions<Types.GetAccountingLinesQuery, TError, TData>
    ) =>
    useQuery<Types.GetAccountingLinesQuery, TError, TData>(
      ['getAccountingLines', variables],
      fetcher<Types.GetAccountingLinesQuery, Types.GetAccountingLinesQueryVariables>(GetAccountingLinesDocument, variables),
      options
    );

useGetAccountingLinesQuery.getKey = (variables: Types.GetAccountingLinesQueryVariables) => ['getAccountingLines', variables];
;

export const useInfiniteGetAccountingLinesQuery = <
      TData = Types.GetAccountingLinesQuery,
      TError = ServerError
    >(
      variables: Types.GetAccountingLinesQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetAccountingLinesQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetAccountingLinesQuery, TError, TData>(
      ['getAccountingLines.infinite', variables],
      (metaData) => fetcher<Types.GetAccountingLinesQuery, Types.GetAccountingLinesQueryVariables>(GetAccountingLinesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetAccountingLinesQuery.getKey = (variables: Types.GetAccountingLinesQueryVariables) => ['getAccountingLines.infinite', variables];
;

export const GetAccountingLinesCsvDocument = `
    query getAccountingLinesCsv($accountUuid: EncodedID!, $pageOptions: PageOptions) {
  account(uuid: $accountUuid) {
    uuid
    accounting {
      exportedData(pageOptions: $pageOptions)
    }
  }
}
    `;
export const useGetAccountingLinesCsvQuery = <
      TData = Types.GetAccountingLinesCsvQuery,
      TError = ServerError
    >(
      variables: Types.GetAccountingLinesCsvQueryVariables,
      options?: UseQueryOptions<Types.GetAccountingLinesCsvQuery, TError, TData>
    ) =>
    useQuery<Types.GetAccountingLinesCsvQuery, TError, TData>(
      ['getAccountingLinesCsv', variables],
      fetcher<Types.GetAccountingLinesCsvQuery, Types.GetAccountingLinesCsvQueryVariables>(GetAccountingLinesCsvDocument, variables),
      options
    );

useGetAccountingLinesCsvQuery.getKey = (variables: Types.GetAccountingLinesCsvQueryVariables) => ['getAccountingLinesCsv', variables];
;

export const useInfiniteGetAccountingLinesCsvQuery = <
      TData = Types.GetAccountingLinesCsvQuery,
      TError = ServerError
    >(
      variables: Types.GetAccountingLinesCsvQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetAccountingLinesCsvQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetAccountingLinesCsvQuery, TError, TData>(
      ['getAccountingLinesCsv.infinite', variables],
      (metaData) => fetcher<Types.GetAccountingLinesCsvQuery, Types.GetAccountingLinesCsvQueryVariables>(GetAccountingLinesCsvDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetAccountingLinesCsvQuery.getKey = (variables: Types.GetAccountingLinesCsvQueryVariables) => ['getAccountingLinesCsv.infinite', variables];
;

export const GetLandlordAccountingBalanceDocument = `
    query getLandlordAccountingBalance($accountUuid: EncodedID!) {
  account(uuid: $accountUuid) {
    uuid
    accounting {
      ...accountingBalance
    }
    ... on LandlordAccount {
      landlordAccounting {
        balances {
          globalSituation {
            ...amount
          }
        }
        provisionAmount {
          ...amount
        }
      }
    }
  }
}
    ${AccountingBalanceFragmentDoc}
${AmountFragmentDoc}`;
export const useGetLandlordAccountingBalanceQuery = <
      TData = Types.GetLandlordAccountingBalanceQuery,
      TError = ServerError
    >(
      variables: Types.GetLandlordAccountingBalanceQueryVariables,
      options?: UseQueryOptions<Types.GetLandlordAccountingBalanceQuery, TError, TData>
    ) =>
    useQuery<Types.GetLandlordAccountingBalanceQuery, TError, TData>(
      ['getLandlordAccountingBalance', variables],
      fetcher<Types.GetLandlordAccountingBalanceQuery, Types.GetLandlordAccountingBalanceQueryVariables>(GetLandlordAccountingBalanceDocument, variables),
      options
    );

useGetLandlordAccountingBalanceQuery.getKey = (variables: Types.GetLandlordAccountingBalanceQueryVariables) => ['getLandlordAccountingBalance', variables];
;

export const useInfiniteGetLandlordAccountingBalanceQuery = <
      TData = Types.GetLandlordAccountingBalanceQuery,
      TError = ServerError
    >(
      variables: Types.GetLandlordAccountingBalanceQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetLandlordAccountingBalanceQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetLandlordAccountingBalanceQuery, TError, TData>(
      ['getLandlordAccountingBalance.infinite', variables],
      (metaData) => fetcher<Types.GetLandlordAccountingBalanceQuery, Types.GetLandlordAccountingBalanceQueryVariables>(GetLandlordAccountingBalanceDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetLandlordAccountingBalanceQuery.getKey = (variables: Types.GetLandlordAccountingBalanceQueryVariables) => ['getLandlordAccountingBalance.infinite', variables];
;

export const GetLandlordAccountingLinesDocument = `
    query getLandlordAccountingLines($accountUuid: EncodedID!, $first: Int, $before: Cursor, $after: Cursor, $pageOptions: PageOptions, $startingDate: String, $endingDate: String, $buildingIds: [String!], $skipNotPaginatedData: Boolean = false) {
  account(uuid: $accountUuid) {
    uuid
    ... on LandlordAccount {
      landlordAccounting {
        balances {
          buildingsTotal {
            ...amount @skip(if: $skipNotPaginatedData)
          }
          buildingTotals {
            ...buildingBalance @skip(if: $skipNotPaginatedData)
          }
          globalTotal {
            ...amount @skip(if: $skipNotPaginatedData)
          }
        }
        lines(
          before: $before
          after: $after
          first: $first
          pageOptions: $pageOptions
          startingDate: $startingDate
          endingDate: $endingDate
          buildingIds: $buildingIds
        ) {
          totalCount
          pageInfo {
            ...pageInfo
          }
          edges {
            node {
              ...accountingLine
            }
          }
        }
      }
    }
  }
}
    ${AmountFragmentDoc}
${BuildingBalanceFragmentDoc}
${PageInfoFragmentDoc}
${AccountingLineFragmentDoc}`;
export const useGetLandlordAccountingLinesQuery = <
      TData = Types.GetLandlordAccountingLinesQuery,
      TError = ServerError
    >(
      variables: Types.GetLandlordAccountingLinesQueryVariables,
      options?: UseQueryOptions<Types.GetLandlordAccountingLinesQuery, TError, TData>
    ) =>
    useQuery<Types.GetLandlordAccountingLinesQuery, TError, TData>(
      ['getLandlordAccountingLines', variables],
      fetcher<Types.GetLandlordAccountingLinesQuery, Types.GetLandlordAccountingLinesQueryVariables>(GetLandlordAccountingLinesDocument, variables),
      options
    );

useGetLandlordAccountingLinesQuery.getKey = (variables: Types.GetLandlordAccountingLinesQueryVariables) => ['getLandlordAccountingLines', variables];
;

export const useInfiniteGetLandlordAccountingLinesQuery = <
      TData = Types.GetLandlordAccountingLinesQuery,
      TError = ServerError
    >(
      variables: Types.GetLandlordAccountingLinesQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetLandlordAccountingLinesQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetLandlordAccountingLinesQuery, TError, TData>(
      ['getLandlordAccountingLines.infinite', variables],
      (metaData) => fetcher<Types.GetLandlordAccountingLinesQuery, Types.GetLandlordAccountingLinesQueryVariables>(GetLandlordAccountingLinesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetLandlordAccountingLinesQuery.getKey = (variables: Types.GetLandlordAccountingLinesQueryVariables) => ['getLandlordAccountingLines.infinite', variables];
;

export const GetLandlordAccountingLinesCsvDocument = `
    query getLandlordAccountingLinesCsv($accountUuid: EncodedID!, $pageOptions: PageOptions, $first: Int, $before: Cursor, $after: Cursor, $buildingIds: [String!], $startingDate: String, $endingDate: String) {
  account(uuid: $accountUuid) {
    uuid
    ... on LandlordAccount {
      landlordAccounting {
        exportedData(
          pageOptions: $pageOptions
          first: $first
          before: $before
          after: $after
          buildingIds: $buildingIds
          startingDate: $startingDate
          endingDate: $endingDate
        )
      }
    }
  }
}
    `;
export const useGetLandlordAccountingLinesCsvQuery = <
      TData = Types.GetLandlordAccountingLinesCsvQuery,
      TError = ServerError
    >(
      variables: Types.GetLandlordAccountingLinesCsvQueryVariables,
      options?: UseQueryOptions<Types.GetLandlordAccountingLinesCsvQuery, TError, TData>
    ) =>
    useQuery<Types.GetLandlordAccountingLinesCsvQuery, TError, TData>(
      ['getLandlordAccountingLinesCsv', variables],
      fetcher<Types.GetLandlordAccountingLinesCsvQuery, Types.GetLandlordAccountingLinesCsvQueryVariables>(GetLandlordAccountingLinesCsvDocument, variables),
      options
    );

useGetLandlordAccountingLinesCsvQuery.getKey = (variables: Types.GetLandlordAccountingLinesCsvQueryVariables) => ['getLandlordAccountingLinesCsv', variables];
;

export const useInfiniteGetLandlordAccountingLinesCsvQuery = <
      TData = Types.GetLandlordAccountingLinesCsvQuery,
      TError = ServerError
    >(
      variables: Types.GetLandlordAccountingLinesCsvQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetLandlordAccountingLinesCsvQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetLandlordAccountingLinesCsvQuery, TError, TData>(
      ['getLandlordAccountingLinesCsv.infinite', variables],
      (metaData) => fetcher<Types.GetLandlordAccountingLinesCsvQuery, Types.GetLandlordAccountingLinesCsvQueryVariables>(GetLandlordAccountingLinesCsvDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetLandlordAccountingLinesCsvQuery.getKey = (variables: Types.GetLandlordAccountingLinesCsvQueryVariables) => ['getLandlordAccountingLinesCsv.infinite', variables];
;

export const GetAccountDocument = `
    query getAccount($uuid: EncodedID!) {
  account(uuid: $uuid) {
    ...account
  }
}
    ${AccountFragmentDoc}
${CustomerFragmentDoc}
${AddressFragmentDoc}
${BranchFragmentDoc}
${BasicBuildingFragmentDoc}
${BuildingFragmentDoc}
${UnitFragmentDoc}`;
export const useGetAccountQuery = <
      TData = Types.GetAccountQuery,
      TError = ServerError
    >(
      variables: Types.GetAccountQueryVariables,
      options?: UseQueryOptions<Types.GetAccountQuery, TError, TData>
    ) =>
    useQuery<Types.GetAccountQuery, TError, TData>(
      ['getAccount', variables],
      fetcher<Types.GetAccountQuery, Types.GetAccountQueryVariables>(GetAccountDocument, variables),
      options
    );

useGetAccountQuery.getKey = (variables: Types.GetAccountQueryVariables) => ['getAccount', variables];
;

export const useInfiniteGetAccountQuery = <
      TData = Types.GetAccountQuery,
      TError = ServerError
    >(
      variables: Types.GetAccountQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetAccountQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetAccountQuery, TError, TData>(
      ['getAccount.infinite', variables],
      (metaData) => fetcher<Types.GetAccountQuery, Types.GetAccountQueryVariables>(GetAccountDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetAccountQuery.getKey = (variables: Types.GetAccountQueryVariables) => ['getAccount.infinite', variables];
;

export const GetBasicAccountsDocument = `
    query getBasicAccounts($itemsPerPage: Int, $pageNumber: Int) {
  accounts(itemsPerPage: $itemsPerPage, pageNumber: $pageNumber) {
    ...basicAccount
  }
}
    ${BasicAccountFragmentDoc}
${CustomerFragmentDoc}
${AddressFragmentDoc}
${BasicBuildingFragmentDoc}`;
export const useGetBasicAccountsQuery = <
      TData = Types.GetBasicAccountsQuery,
      TError = ServerError
    >(
      variables?: Types.GetBasicAccountsQueryVariables,
      options?: UseQueryOptions<Types.GetBasicAccountsQuery, TError, TData>
    ) =>
    useQuery<Types.GetBasicAccountsQuery, TError, TData>(
      variables === undefined ? ['getBasicAccounts'] : ['getBasicAccounts', variables],
      fetcher<Types.GetBasicAccountsQuery, Types.GetBasicAccountsQueryVariables>(GetBasicAccountsDocument, variables),
      options
    );

useGetBasicAccountsQuery.getKey = (variables?: Types.GetBasicAccountsQueryVariables) => variables === undefined ? ['getBasicAccounts'] : ['getBasicAccounts', variables];
;

export const useInfiniteGetBasicAccountsQuery = <
      TData = Types.GetBasicAccountsQuery,
      TError = ServerError
    >(
      variables?: Types.GetBasicAccountsQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetBasicAccountsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetBasicAccountsQuery, TError, TData>(
      variables === undefined ? ['getBasicAccounts.infinite'] : ['getBasicAccounts.infinite', variables],
      (metaData) => fetcher<Types.GetBasicAccountsQuery, Types.GetBasicAccountsQueryVariables>(GetBasicAccountsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetBasicAccountsQuery.getKey = (variables?: Types.GetBasicAccountsQueryVariables) => variables === undefined ? ['getBasicAccounts.infinite'] : ['getBasicAccounts.infinite', variables];
;

export const ConfirmPasswordUpdateDocument = `
    mutation confirmPasswordUpdate($request: ConfirmPasswordUpdateRequest!) {
  confirmPasswordUpdate(request: $request)
}
    `;
export const useConfirmPasswordUpdateMutation = <
      TError = ServerError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.ConfirmPasswordUpdateMutation, TError, Types.ConfirmPasswordUpdateMutationVariables, TContext>) =>
    useMutation<Types.ConfirmPasswordUpdateMutation, TError, Types.ConfirmPasswordUpdateMutationVariables, TContext>(
      ['confirmPasswordUpdate'],
      (variables?: Types.ConfirmPasswordUpdateMutationVariables) => fetcher<Types.ConfirmPasswordUpdateMutation, Types.ConfirmPasswordUpdateMutationVariables>(ConfirmPasswordUpdateDocument, variables)(),
      options
    );
export const GetMeDocument = `
    query getMe {
  me {
    isTemporary
    login
    civility
    companyName
    fullname
    isCompany
  }
}
    `;
export const useGetMeQuery = <
      TData = Types.GetMeQuery,
      TError = ServerError
    >(
      variables?: Types.GetMeQueryVariables,
      options?: UseQueryOptions<Types.GetMeQuery, TError, TData>
    ) =>
    useQuery<Types.GetMeQuery, TError, TData>(
      variables === undefined ? ['getMe'] : ['getMe', variables],
      fetcher<Types.GetMeQuery, Types.GetMeQueryVariables>(GetMeDocument, variables),
      options
    );

useGetMeQuery.getKey = (variables?: Types.GetMeQueryVariables) => variables === undefined ? ['getMe'] : ['getMe', variables];
;

export const useInfiniteGetMeQuery = <
      TData = Types.GetMeQuery,
      TError = ServerError
    >(
      variables?: Types.GetMeQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetMeQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetMeQuery, TError, TData>(
      variables === undefined ? ['getMe.infinite'] : ['getMe.infinite', variables],
      (metaData) => fetcher<Types.GetMeQuery, Types.GetMeQueryVariables>(GetMeDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetMeQuery.getKey = (variables?: Types.GetMeQueryVariables) => variables === undefined ? ['getMe.infinite'] : ['getMe.infinite', variables];
;

export const LoginDocument = `
    mutation login($request: LoginRequest!) {
  login(request: $request) {
    token
  }
}
    `;
export const useLoginMutation = <
      TError = ServerError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.LoginMutation, TError, Types.LoginMutationVariables, TContext>) =>
    useMutation<Types.LoginMutation, TError, Types.LoginMutationVariables, TContext>(
      ['login'],
      (variables?: Types.LoginMutationVariables) => fetcher<Types.LoginMutation, Types.LoginMutationVariables>(LoginDocument, variables)(),
      options
    );
export const LoginAssociationConfirmationFirstConnectionDocument = `
    mutation loginAssociationConfirmationFirstConnection($customerId: String!, $login: String!, $token: String!, $password: String!, $brandCode: String, $callbackUrl: String) {
  loginAssociationConfirmationFirstConnection(
    customerId: $customerId
    login: $login
    token: $token
    password: $password
    brandCode: $brandCode
    callbackUrl: $callbackUrl
  )
}
    `;
export const useLoginAssociationConfirmationFirstConnectionMutation = <
      TError = ServerError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.LoginAssociationConfirmationFirstConnectionMutation, TError, Types.LoginAssociationConfirmationFirstConnectionMutationVariables, TContext>) =>
    useMutation<Types.LoginAssociationConfirmationFirstConnectionMutation, TError, Types.LoginAssociationConfirmationFirstConnectionMutationVariables, TContext>(
      ['loginAssociationConfirmationFirstConnection'],
      (variables?: Types.LoginAssociationConfirmationFirstConnectionMutationVariables) => fetcher<Types.LoginAssociationConfirmationFirstConnectionMutation, Types.LoginAssociationConfirmationFirstConnectionMutationVariables>(LoginAssociationConfirmationFirstConnectionDocument, variables)(),
      options
    );
export const LoginAssociationRequestFirstConnectionDocument = `
    mutation loginAssociationRequestFirstConnection($login: String!, $customerId: String!, $brandCode: String, $callbackUrl: String) {
  loginAssociationRequestFirstConnection(
    login: $login
    customerId: $customerId
    brandCode: $brandCode
    callbackUrl: $callbackUrl
  )
}
    `;
export const useLoginAssociationRequestFirstConnectionMutation = <
      TError = ServerError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.LoginAssociationRequestFirstConnectionMutation, TError, Types.LoginAssociationRequestFirstConnectionMutationVariables, TContext>) =>
    useMutation<Types.LoginAssociationRequestFirstConnectionMutation, TError, Types.LoginAssociationRequestFirstConnectionMutationVariables, TContext>(
      ['loginAssociationRequestFirstConnection'],
      (variables?: Types.LoginAssociationRequestFirstConnectionMutationVariables) => fetcher<Types.LoginAssociationRequestFirstConnectionMutation, Types.LoginAssociationRequestFirstConnectionMutationVariables>(LoginAssociationRequestFirstConnectionDocument, variables)(),
      options
    );
export const LoginCheckDocument = `
    mutation loginCheck($login: String!) {
  loginCheck(login: $login) {
    isTemporaryLogin
    isOktaAuthenticationFlow
    redirectUrl
  }
}
    `;
export const useLoginCheckMutation = <
      TError = ServerError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.LoginCheckMutation, TError, Types.LoginCheckMutationVariables, TContext>) =>
    useMutation<Types.LoginCheckMutation, TError, Types.LoginCheckMutationVariables, TContext>(
      ['loginCheck'],
      (variables?: Types.LoginCheckMutationVariables) => fetcher<Types.LoginCheckMutation, Types.LoginCheckMutationVariables>(LoginCheckDocument, variables)(),
      options
    );
export const LoginCheckFirstConnectionDocument = `
    mutation loginCheckFirstConnection($login: String!) {
  loginCheckFirstConnection(login: $login) {
    isUniqueLogin
    customerId
  }
}
    `;
export const useLoginCheckFirstConnectionMutation = <
      TError = ServerError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.LoginCheckFirstConnectionMutation, TError, Types.LoginCheckFirstConnectionMutationVariables, TContext>) =>
    useMutation<Types.LoginCheckFirstConnectionMutation, TError, Types.LoginCheckFirstConnectionMutationVariables, TContext>(
      ['loginCheckFirstConnection'],
      (variables?: Types.LoginCheckFirstConnectionMutationVariables) => fetcher<Types.LoginCheckFirstConnectionMutation, Types.LoginCheckFirstConnectionMutationVariables>(LoginCheckFirstConnectionDocument, variables)(),
      options
    );
export const ResetPasswordDocument = `
    mutation resetPassword($request: ResetPasswordRequest!) {
  resetPassword(request: $request)
}
    `;
export const useResetPasswordMutation = <
      TError = ServerError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.ResetPasswordMutation, TError, Types.ResetPasswordMutationVariables, TContext>) =>
    useMutation<Types.ResetPasswordMutation, TError, Types.ResetPasswordMutationVariables, TContext>(
      ['resetPassword'],
      (variables?: Types.ResetPasswordMutationVariables) => fetcher<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>(ResetPasswordDocument, variables)(),
      options
    );
export const UpdateCredentialsDocument = `
    mutation updateCredentials($request: UpdateCredentialsRequest!) {
  updateCredentials(request: $request) {
    id
  }
}
    `;
export const useUpdateCredentialsMutation = <
      TError = ServerError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateCredentialsMutation, TError, Types.UpdateCredentialsMutationVariables, TContext>) =>
    useMutation<Types.UpdateCredentialsMutation, TError, Types.UpdateCredentialsMutationVariables, TContext>(
      ['updateCredentials'],
      (variables?: Types.UpdateCredentialsMutationVariables) => fetcher<Types.UpdateCredentialsMutation, Types.UpdateCredentialsMutationVariables>(UpdateCredentialsDocument, variables)(),
      options
    );
export const UpdatePasswordDocument = `
    mutation updatePassword($request: UpdatePasswordRequest!) {
  updatePassword(request: $request)
}
    `;
export const useUpdatePasswordMutation = <
      TError = ServerError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdatePasswordMutation, TError, Types.UpdatePasswordMutationVariables, TContext>) =>
    useMutation<Types.UpdatePasswordMutation, TError, Types.UpdatePasswordMutationVariables, TContext>(
      ['updatePassword'],
      (variables?: Types.UpdatePasswordMutationVariables) => fetcher<Types.UpdatePasswordMutation, Types.UpdatePasswordMutationVariables>(UpdatePasswordDocument, variables)(),
      options
    );
export const ValidateEmailFirstConnectionDocument = `
    mutation validateEmailFirstConnection($token: String!, $brandCode: String, $callbackUrl: String) {
  validateEmailFirstConnection(
    token: $token
    brandCode: $brandCode
    callbackUrl: $callbackUrl
  )
}
    `;
export const useValidateEmailFirstConnectionMutation = <
      TError = ServerError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.ValidateEmailFirstConnectionMutation, TError, Types.ValidateEmailFirstConnectionMutationVariables, TContext>) =>
    useMutation<Types.ValidateEmailFirstConnectionMutation, TError, Types.ValidateEmailFirstConnectionMutationVariables, TContext>(
      ['validateEmailFirstConnection'],
      (variables?: Types.ValidateEmailFirstConnectionMutationVariables) => fetcher<Types.ValidateEmailFirstConnectionMutation, Types.ValidateEmailFirstConnectionMutationVariables>(ValidateEmailFirstConnectionDocument, variables)(),
      options
    );
export const ValidateResetPasswordDocument = `
    mutation validateResetPassword($request: ValidateResetPasswordRequest!) {
  validateResetPassword(request: $request)
}
    `;
export const useValidateResetPasswordMutation = <
      TError = ServerError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.ValidateResetPasswordMutation, TError, Types.ValidateResetPasswordMutationVariables, TContext>) =>
    useMutation<Types.ValidateResetPasswordMutation, TError, Types.ValidateResetPasswordMutationVariables, TContext>(
      ['validateResetPassword'],
      (variables?: Types.ValidateResetPasswordMutationVariables) => fetcher<Types.ValidateResetPasswordMutation, Types.ValidateResetPasswordMutationVariables>(ValidateResetPasswordDocument, variables)(),
      options
    );
export const VerifyCredentialsDocument = `
    mutation verifyCredentials($request: VerifyCredentialsRequest!) {
  verifyCredentials(request: $request)
}
    `;
export const useVerifyCredentialsMutation = <
      TError = ServerError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.VerifyCredentialsMutation, TError, Types.VerifyCredentialsMutationVariables, TContext>) =>
    useMutation<Types.VerifyCredentialsMutation, TError, Types.VerifyCredentialsMutationVariables, TContext>(
      ['verifyCredentials'],
      (variables?: Types.VerifyCredentialsMutationVariables) => fetcher<Types.VerifyCredentialsMutation, Types.VerifyCredentialsMutationVariables>(VerifyCredentialsDocument, variables)(),
      options
    );
export const GetAccountingPeriodsDocument = `
    query getAccountingPeriods($accountUuid: EncodedID!, $sortBy: [SortByType!], $status: [AccountingPeriodStatusEnum!], $closingDateTo: String, $first: Int, $before: Cursor, $after: Cursor) {
  coownerAccount(uuid: $accountUuid) {
    uuid
    trusteeCouncil {
      accountingPeriods(
        first: $first
        before: $before
        after: $after
        sortBy: $sortBy
        status: $status
        closingDateTo: $closingDateTo
      ) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasPreviousPage
          hasNextPage
        }
        edges {
          node {
            ...accountingPeriod
          }
        }
      }
    }
  }
}
    ${AccountingPeriodFragmentDoc}`;
export const useGetAccountingPeriodsQuery = <
      TData = Types.GetAccountingPeriodsQuery,
      TError = ServerError
    >(
      variables: Types.GetAccountingPeriodsQueryVariables,
      options?: UseQueryOptions<Types.GetAccountingPeriodsQuery, TError, TData>
    ) =>
    useQuery<Types.GetAccountingPeriodsQuery, TError, TData>(
      ['getAccountingPeriods', variables],
      fetcher<Types.GetAccountingPeriodsQuery, Types.GetAccountingPeriodsQueryVariables>(GetAccountingPeriodsDocument, variables),
      options
    );

useGetAccountingPeriodsQuery.getKey = (variables: Types.GetAccountingPeriodsQueryVariables) => ['getAccountingPeriods', variables];
;

export const useInfiniteGetAccountingPeriodsQuery = <
      TData = Types.GetAccountingPeriodsQuery,
      TError = ServerError
    >(
      variables: Types.GetAccountingPeriodsQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetAccountingPeriodsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetAccountingPeriodsQuery, TError, TData>(
      ['getAccountingPeriods.infinite', variables],
      (metaData) => fetcher<Types.GetAccountingPeriodsQuery, Types.GetAccountingPeriodsQueryVariables>(GetAccountingPeriodsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetAccountingPeriodsQuery.getKey = (variables: Types.GetAccountingPeriodsQueryVariables) => ['getAccountingPeriods.infinite', variables];
;

export const GetBuildingAccountingCurrentDocument = `
    query getBuildingAccountingCurrent($uuid: EncodedID!) {
  coownerAccount(uuid: $uuid) {
    uuid
    trusteeCouncil {
      bankBalance {
        ...amount
      }
      accountingCurrent {
        id
        openingDate
        closingDate
        previousTotal {
          ...amount
        }
        votedTotal {
          ...amount
        }
        total {
          ...amount
        }
        nextVotedTotal {
          ...amount
        }
        allocations {
          ...buildingAccountingCurrentAllocation
        }
      }
    }
  }
}
    ${AmountFragmentDoc}
${BuildingAccountingCurrentAllocationFragmentDoc}
${BuildingAccountingCurrentExpenseTypeFragmentDoc}
${BuildingAccountingCurrentExpenseFragmentDoc}`;
export const useGetBuildingAccountingCurrentQuery = <
      TData = Types.GetBuildingAccountingCurrentQuery,
      TError = ServerError
    >(
      variables: Types.GetBuildingAccountingCurrentQueryVariables,
      options?: UseQueryOptions<Types.GetBuildingAccountingCurrentQuery, TError, TData>
    ) =>
    useQuery<Types.GetBuildingAccountingCurrentQuery, TError, TData>(
      ['getBuildingAccountingCurrent', variables],
      fetcher<Types.GetBuildingAccountingCurrentQuery, Types.GetBuildingAccountingCurrentQueryVariables>(GetBuildingAccountingCurrentDocument, variables),
      options
    );

useGetBuildingAccountingCurrentQuery.getKey = (variables: Types.GetBuildingAccountingCurrentQueryVariables) => ['getBuildingAccountingCurrent', variables];
;

export const useInfiniteGetBuildingAccountingCurrentQuery = <
      TData = Types.GetBuildingAccountingCurrentQuery,
      TError = ServerError
    >(
      variables: Types.GetBuildingAccountingCurrentQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetBuildingAccountingCurrentQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetBuildingAccountingCurrentQuery, TError, TData>(
      ['getBuildingAccountingCurrent.infinite', variables],
      (metaData) => fetcher<Types.GetBuildingAccountingCurrentQuery, Types.GetBuildingAccountingCurrentQueryVariables>(GetBuildingAccountingCurrentDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetBuildingAccountingCurrentQuery.getKey = (variables: Types.GetBuildingAccountingCurrentQueryVariables) => ['getBuildingAccountingCurrent.infinite', variables];
;

export const GetBuildingAccountingRgddDocument = `
    query getBuildingAccountingRGDD($uuid: EncodedID!, $accountingPeriodId: String) {
  coownerAccount(uuid: $uuid) {
    uuid
    trusteeCouncil {
      pastAccountingRGDD(accountingPeriodId: $accountingPeriodId) {
        totalToAllocate {
          ...amount
        }
        totalVat {
          ...amount
        }
        totalRecoverable {
          ...amount
        }
        allocations {
          ...allocation
        }
      }
    }
  }
}
    ${AmountFragmentDoc}
${AllocationFragmentDoc}
${ExpenseTypeFragmentDoc}
${ExpenseFragmentDoc}`;
export const useGetBuildingAccountingRgddQuery = <
      TData = Types.GetBuildingAccountingRgddQuery,
      TError = ServerError
    >(
      variables: Types.GetBuildingAccountingRgddQueryVariables,
      options?: UseQueryOptions<Types.GetBuildingAccountingRgddQuery, TError, TData>
    ) =>
    useQuery<Types.GetBuildingAccountingRgddQuery, TError, TData>(
      ['getBuildingAccountingRGDD', variables],
      fetcher<Types.GetBuildingAccountingRgddQuery, Types.GetBuildingAccountingRgddQueryVariables>(GetBuildingAccountingRgddDocument, variables),
      options
    );

useGetBuildingAccountingRgddQuery.getKey = (variables: Types.GetBuildingAccountingRgddQueryVariables) => ['getBuildingAccountingRGDD', variables];
;

export const useInfiniteGetBuildingAccountingRgddQuery = <
      TData = Types.GetBuildingAccountingRgddQuery,
      TError = ServerError
    >(
      variables: Types.GetBuildingAccountingRgddQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetBuildingAccountingRgddQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetBuildingAccountingRgddQuery, TError, TData>(
      ['getBuildingAccountingRGDD.infinite', variables],
      (metaData) => fetcher<Types.GetBuildingAccountingRgddQuery, Types.GetBuildingAccountingRgddQueryVariables>(GetBuildingAccountingRgddDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetBuildingAccountingRgddQuery.getKey = (variables: Types.GetBuildingAccountingRgddQueryVariables) => ['getBuildingAccountingRGDD.infinite', variables];
;

export const GetCouncilCoownersDocument = `
    query getCouncilCoowners($accountUuid: EncodedID!, $first: Int, $after: Cursor, $customerName: String!, $fullAddress: String!, $sortBy: [SortByType!], $units: [String!], $holderProperties: [String!]) {
  coownerAccount(uuid: $accountUuid) {
    uuid
    trusteeCouncil {
      coowners(
        first: $first
        after: $after
        customerName: $customerName
        fullAddress: $fullAddress
        sortBy: $sortBy
        units: $units
        holderProperties: $holderProperties
      ) {
        totalCount
        pageInfo {
          ...pageInfo
        }
        edges {
          node {
            ...restrictedCoowner
          }
        }
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${RestrictedCoownerFragmentDoc}
${RestrictedUnitFragmentDoc}
${AmountFragmentDoc}`;
export const useGetCouncilCoownersQuery = <
      TData = Types.GetCouncilCoownersQuery,
      TError = ServerError
    >(
      variables: Types.GetCouncilCoownersQueryVariables,
      options?: UseQueryOptions<Types.GetCouncilCoownersQuery, TError, TData>
    ) =>
    useQuery<Types.GetCouncilCoownersQuery, TError, TData>(
      ['getCouncilCoowners', variables],
      fetcher<Types.GetCouncilCoownersQuery, Types.GetCouncilCoownersQueryVariables>(GetCouncilCoownersDocument, variables),
      options
    );

useGetCouncilCoownersQuery.getKey = (variables: Types.GetCouncilCoownersQueryVariables) => ['getCouncilCoowners', variables];
;

export const useInfiniteGetCouncilCoownersQuery = <
      TData = Types.GetCouncilCoownersQuery,
      TError = ServerError
    >(
      variables: Types.GetCouncilCoownersQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetCouncilCoownersQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetCouncilCoownersQuery, TError, TData>(
      ['getCouncilCoowners.infinite', variables],
      (metaData) => fetcher<Types.GetCouncilCoownersQuery, Types.GetCouncilCoownersQueryVariables>(GetCouncilCoownersDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetCouncilCoownersQuery.getKey = (variables: Types.GetCouncilCoownersQueryVariables) => ['getCouncilCoowners.infinite', variables];
;

export const GetCouncilProjectDocumentsDocument = `
    query getCouncilProjectDocuments($accountUuid: EncodedID!, $sortBy: [SortByType!], $first: Int, $after: Cursor, $skipNotPaginatedData: Boolean! = false) {
  coownerAccount(uuid: $accountUuid) {
    uuid
    pastGeneralAssembly @skip(if: $skipNotPaginatedData) {
      ...generalAssembly
    }
    nextGeneralAssembly @skip(if: $skipNotPaginatedData) {
      ...generalAssembly
    }
    trusteeCouncil {
      projectDocuments(first: $first, after: $after, sortBy: $sortBy) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasPreviousPage
          hasNextPage
        }
        edges {
          node {
            ...document
          }
        }
      }
    }
  }
}
    ${GeneralAssemblyFragmentDoc}
${AddressFragmentDoc}
${DocumentFragmentDoc}`;
export const useGetCouncilProjectDocumentsQuery = <
      TData = Types.GetCouncilProjectDocumentsQuery,
      TError = ServerError
    >(
      variables: Types.GetCouncilProjectDocumentsQueryVariables,
      options?: UseQueryOptions<Types.GetCouncilProjectDocumentsQuery, TError, TData>
    ) =>
    useQuery<Types.GetCouncilProjectDocumentsQuery, TError, TData>(
      ['getCouncilProjectDocuments', variables],
      fetcher<Types.GetCouncilProjectDocumentsQuery, Types.GetCouncilProjectDocumentsQueryVariables>(GetCouncilProjectDocumentsDocument, variables),
      options
    );

useGetCouncilProjectDocumentsQuery.getKey = (variables: Types.GetCouncilProjectDocumentsQueryVariables) => ['getCouncilProjectDocuments', variables];
;

export const useInfiniteGetCouncilProjectDocumentsQuery = <
      TData = Types.GetCouncilProjectDocumentsQuery,
      TError = ServerError
    >(
      variables: Types.GetCouncilProjectDocumentsQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetCouncilProjectDocumentsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetCouncilProjectDocumentsQuery, TError, TData>(
      ['getCouncilProjectDocuments.infinite', variables],
      (metaData) => fetcher<Types.GetCouncilProjectDocumentsQuery, Types.GetCouncilProjectDocumentsQueryVariables>(GetCouncilProjectDocumentsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetCouncilProjectDocumentsQuery.getKey = (variables: Types.GetCouncilProjectDocumentsQueryVariables) => ['getCouncilProjectDocuments.infinite', variables];
;

export const GetRepairBudgetDetailsDocument = `
    query getRepairBudgetDetails($accountUuid: EncodedID!, $budgetId: ID!) {
  coownerAccount(uuid: $accountUuid) {
    uuid
    trusteeCouncil {
      repairBudgets(accountUuid: $accountUuid, budgetId: $budgetId) {
        ...buildingRepairBudget
      }
    }
  }
}
    ${BuildingRepairBudgetFragmentDoc}
${AmountFragmentDoc}
${AllocationFragmentDoc}
${ExpenseTypeFragmentDoc}
${ExpenseFragmentDoc}`;
export const useGetRepairBudgetDetailsQuery = <
      TData = Types.GetRepairBudgetDetailsQuery,
      TError = ServerError
    >(
      variables: Types.GetRepairBudgetDetailsQueryVariables,
      options?: UseQueryOptions<Types.GetRepairBudgetDetailsQuery, TError, TData>
    ) =>
    useQuery<Types.GetRepairBudgetDetailsQuery, TError, TData>(
      ['getRepairBudgetDetails', variables],
      fetcher<Types.GetRepairBudgetDetailsQuery, Types.GetRepairBudgetDetailsQueryVariables>(GetRepairBudgetDetailsDocument, variables),
      options
    );

useGetRepairBudgetDetailsQuery.getKey = (variables: Types.GetRepairBudgetDetailsQueryVariables) => ['getRepairBudgetDetails', variables];
;

export const useInfiniteGetRepairBudgetDetailsQuery = <
      TData = Types.GetRepairBudgetDetailsQuery,
      TError = ServerError
    >(
      variables: Types.GetRepairBudgetDetailsQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetRepairBudgetDetailsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetRepairBudgetDetailsQuery, TError, TData>(
      ['getRepairBudgetDetails.infinite', variables],
      (metaData) => fetcher<Types.GetRepairBudgetDetailsQuery, Types.GetRepairBudgetDetailsQueryVariables>(GetRepairBudgetDetailsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetRepairBudgetDetailsQuery.getKey = (variables: Types.GetRepairBudgetDetailsQueryVariables) => ['getRepairBudgetDetails.infinite', variables];
;

export const GetRepairBudgetsDocument = `
    query getRepairBudgets($accountUuid: EncodedID!) {
  repairBudgets(accountUuid: $accountUuid) {
    id
    label
    validatedAmount {
      value
      currency
    }
  }
}
    `;
export const useGetRepairBudgetsQuery = <
      TData = Types.GetRepairBudgetsQuery,
      TError = ServerError
    >(
      variables: Types.GetRepairBudgetsQueryVariables,
      options?: UseQueryOptions<Types.GetRepairBudgetsQuery, TError, TData>
    ) =>
    useQuery<Types.GetRepairBudgetsQuery, TError, TData>(
      ['getRepairBudgets', variables],
      fetcher<Types.GetRepairBudgetsQuery, Types.GetRepairBudgetsQueryVariables>(GetRepairBudgetsDocument, variables),
      options
    );

useGetRepairBudgetsQuery.getKey = (variables: Types.GetRepairBudgetsQueryVariables) => ['getRepairBudgets', variables];
;

export const useInfiniteGetRepairBudgetsQuery = <
      TData = Types.GetRepairBudgetsQuery,
      TError = ServerError
    >(
      variables: Types.GetRepairBudgetsQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetRepairBudgetsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetRepairBudgetsQuery, TError, TData>(
      ['getRepairBudgets.infinite', variables],
      (metaData) => fetcher<Types.GetRepairBudgetsQuery, Types.GetRepairBudgetsQueryVariables>(GetRepairBudgetsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetRepairBudgetsQuery.getKey = (variables: Types.GetRepairBudgetsQueryVariables) => ['getRepairBudgets.infinite', variables];
;

export const ConfirmBankingInformationUpdateDocument = `
    mutation confirmBankingInformationUpdate($request: ConfirmBankingInformationUpdateRequest!) {
  confirmBankingInformationUpdate(request: $request)
}
    `;
export const useConfirmBankingInformationUpdateMutation = <
      TError = ServerError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.ConfirmBankingInformationUpdateMutation, TError, Types.ConfirmBankingInformationUpdateMutationVariables, TContext>) =>
    useMutation<Types.ConfirmBankingInformationUpdateMutation, TError, Types.ConfirmBankingInformationUpdateMutationVariables, TContext>(
      ['confirmBankingInformationUpdate'],
      (variables?: Types.ConfirmBankingInformationUpdateMutationVariables) => fetcher<Types.ConfirmBankingInformationUpdateMutation, Types.ConfirmBankingInformationUpdateMutationVariables>(ConfirmBankingInformationUpdateDocument, variables)(),
      options
    );
export const UpdateCoOwnerAccountSnailMailDocument = `
    mutation updateCoOwnerAccountSnailMail($accountUuid: EncodedID!, $request: UpdateCustomerSnailMailRequest!) {
  updateCoOwnerAccountSnailMail(accountUuid: $accountUuid, request: $request) {
    uuid
    hasEReleve
  }
}
    `;
export const useUpdateCoOwnerAccountSnailMailMutation = <
      TError = ServerError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateCoOwnerAccountSnailMailMutation, TError, Types.UpdateCoOwnerAccountSnailMailMutationVariables, TContext>) =>
    useMutation<Types.UpdateCoOwnerAccountSnailMailMutation, TError, Types.UpdateCoOwnerAccountSnailMailMutationVariables, TContext>(
      ['updateCoOwnerAccountSnailMail'],
      (variables?: Types.UpdateCoOwnerAccountSnailMailMutationVariables) => fetcher<Types.UpdateCoOwnerAccountSnailMailMutation, Types.UpdateCoOwnerAccountSnailMailMutationVariables>(UpdateCoOwnerAccountSnailMailDocument, variables)(),
      options
    );
export const UpdateCustomerDocument = `
    mutation updateCustomer($customerId: ID!, $request: UpdateCustomerRequest!) {
  updateCustomer(customerId: $customerId, request: $request) {
    ...customer
  }
}
    ${CustomerFragmentDoc}
${AddressFragmentDoc}`;
export const useUpdateCustomerMutation = <
      TError = ServerError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateCustomerMutation, TError, Types.UpdateCustomerMutationVariables, TContext>) =>
    useMutation<Types.UpdateCustomerMutation, TError, Types.UpdateCustomerMutationVariables, TContext>(
      ['updateCustomer'],
      (variables?: Types.UpdateCustomerMutationVariables) => fetcher<Types.UpdateCustomerMutation, Types.UpdateCustomerMutationVariables>(UpdateCustomerDocument, variables)(),
      options
    );
export const UpdateCustomerConsentDocument = `
    mutation updateCustomerConsent($customerId: ID!, $request: UpdateCustomerConsentRequest!) {
  updateCustomerConsent(customerId: $customerId, request: $request) {
    id
    confidentiality {
      personalData
      partnersOffersInformation
      isFirstSubmit
    }
  }
}
    `;
export const useUpdateCustomerConsentMutation = <
      TError = ServerError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateCustomerConsentMutation, TError, Types.UpdateCustomerConsentMutationVariables, TContext>) =>
    useMutation<Types.UpdateCustomerConsentMutation, TError, Types.UpdateCustomerConsentMutationVariables, TContext>(
      ['updateCustomerConsent'],
      (variables?: Types.UpdateCustomerConsentMutationVariables) => fetcher<Types.UpdateCustomerConsentMutation, Types.UpdateCustomerConsentMutationVariables>(UpdateCustomerConsentDocument, variables)(),
      options
    );
export const UpdateCustomerShowDalenysHeadbandDocument = `
    mutation updateCustomerShowDalenysHeadband($accountUuid: EncodedID!, $request: UpdateCustomerShowDalenysHeadbandRequest!) {
  updateCustomerShowDalenysHeadband(accountUuid: $accountUuid, request: $request) {
    uuid
    showDalenysHeadband
  }
}
    `;
export const useUpdateCustomerShowDalenysHeadbandMutation = <
      TError = ServerError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateCustomerShowDalenysHeadbandMutation, TError, Types.UpdateCustomerShowDalenysHeadbandMutationVariables, TContext>) =>
    useMutation<Types.UpdateCustomerShowDalenysHeadbandMutation, TError, Types.UpdateCustomerShowDalenysHeadbandMutationVariables, TContext>(
      ['updateCustomerShowDalenysHeadband'],
      (variables?: Types.UpdateCustomerShowDalenysHeadbandMutationVariables) => fetcher<Types.UpdateCustomerShowDalenysHeadbandMutation, Types.UpdateCustomerShowDalenysHeadbandMutationVariables>(UpdateCustomerShowDalenysHeadbandDocument, variables)(),
      options
    );
export const UpdateCustomerSnailMailDocument = `
    mutation updateCustomerSnailMail($customerId: ID!, $request: UpdateCustomerSnailMailRequest!) {
  updateCustomerSnailMail(customerId: $customerId, request: $request) {
    id
    noSnailMail
  }
}
    `;
export const useUpdateCustomerSnailMailMutation = <
      TError = ServerError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateCustomerSnailMailMutation, TError, Types.UpdateCustomerSnailMailMutationVariables, TContext>) =>
    useMutation<Types.UpdateCustomerSnailMailMutation, TError, Types.UpdateCustomerSnailMailMutationVariables, TContext>(
      ['updateCustomerSnailMail'],
      (variables?: Types.UpdateCustomerSnailMailMutationVariables) => fetcher<Types.UpdateCustomerSnailMailMutation, Types.UpdateCustomerSnailMailMutationVariables>(UpdateCustomerSnailMailDocument, variables)(),
      options
    );
export const GetBranchDocument = `
    query getBranch($uuid: EncodedID!) {
  account(uuid: $uuid) {
    uuid
    branch {
      ...branch
    }
  }
}
    ${BranchFragmentDoc}
${AddressFragmentDoc}`;
export const useGetBranchQuery = <
      TData = Types.GetBranchQuery,
      TError = ServerError
    >(
      variables: Types.GetBranchQueryVariables,
      options?: UseQueryOptions<Types.GetBranchQuery, TError, TData>
    ) =>
    useQuery<Types.GetBranchQuery, TError, TData>(
      ['getBranch', variables],
      fetcher<Types.GetBranchQuery, Types.GetBranchQueryVariables>(GetBranchDocument, variables),
      options
    );

useGetBranchQuery.getKey = (variables: Types.GetBranchQueryVariables) => ['getBranch', variables];
;

export const useInfiniteGetBranchQuery = <
      TData = Types.GetBranchQuery,
      TError = ServerError
    >(
      variables: Types.GetBranchQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetBranchQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetBranchQuery, TError, TData>(
      ['getBranch.infinite', variables],
      (metaData) => fetcher<Types.GetBranchQuery, Types.GetBranchQueryVariables>(GetBranchDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetBranchQuery.getKey = (variables: Types.GetBranchQueryVariables) => ['getBranch.infinite', variables];
;

export const GetDashboardAccountingBalanceDocument = `
    query getDashboardAccountingBalance($uuid: EncodedID!) {
  account(uuid: $uuid) {
    uuid
    accounting {
      ...accountingBalance
      lines(first: 2) {
        totalCount
        pageInfo {
          ...pageInfo
        }
        edges {
          node {
            ...accountingLine
          }
        }
      }
    }
  }
}
    ${AccountingBalanceFragmentDoc}
${AmountFragmentDoc}
${PageInfoFragmentDoc}
${AccountingLineFragmentDoc}`;
export const useGetDashboardAccountingBalanceQuery = <
      TData = Types.GetDashboardAccountingBalanceQuery,
      TError = ServerError
    >(
      variables: Types.GetDashboardAccountingBalanceQueryVariables,
      options?: UseQueryOptions<Types.GetDashboardAccountingBalanceQuery, TError, TData>
    ) =>
    useQuery<Types.GetDashboardAccountingBalanceQuery, TError, TData>(
      ['getDashboardAccountingBalance', variables],
      fetcher<Types.GetDashboardAccountingBalanceQuery, Types.GetDashboardAccountingBalanceQueryVariables>(GetDashboardAccountingBalanceDocument, variables),
      options
    );

useGetDashboardAccountingBalanceQuery.getKey = (variables: Types.GetDashboardAccountingBalanceQueryVariables) => ['getDashboardAccountingBalance', variables];
;

export const useInfiniteGetDashboardAccountingBalanceQuery = <
      TData = Types.GetDashboardAccountingBalanceQuery,
      TError = ServerError
    >(
      variables: Types.GetDashboardAccountingBalanceQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetDashboardAccountingBalanceQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetDashboardAccountingBalanceQuery, TError, TData>(
      ['getDashboardAccountingBalance.infinite', variables],
      (metaData) => fetcher<Types.GetDashboardAccountingBalanceQuery, Types.GetDashboardAccountingBalanceQueryVariables>(GetDashboardAccountingBalanceDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetDashboardAccountingBalanceQuery.getKey = (variables: Types.GetDashboardAccountingBalanceQueryVariables) => ['getDashboardAccountingBalance.infinite', variables];
;

export const GetDashboardTicketsDocument = `
    query getDashboardTickets($accountUuid: EncodedID!, $sortBy: [SortByType!]) {
  tickets(
    accountUuid: $accountUuid
    sortBy: $sortBy
    first: 5
    status: [WORK_IN_PROGRESS, OPEN, FINISHED, PROCESSED]
  ) {
    edges {
      node {
        ...ticket
      }
    }
  }
}
    ${TicketFragmentDoc}
${TicketMessageFragmentDoc}
${DocumentFragmentDoc}
${ManagerFragmentDoc}
${TicketActivityFragmentDoc}
${AddressFragmentDoc}
${UnitFragmentDoc}`;
export const useGetDashboardTicketsQuery = <
      TData = Types.GetDashboardTicketsQuery,
      TError = ServerError
    >(
      variables: Types.GetDashboardTicketsQueryVariables,
      options?: UseQueryOptions<Types.GetDashboardTicketsQuery, TError, TData>
    ) =>
    useQuery<Types.GetDashboardTicketsQuery, TError, TData>(
      ['getDashboardTickets', variables],
      fetcher<Types.GetDashboardTicketsQuery, Types.GetDashboardTicketsQueryVariables>(GetDashboardTicketsDocument, variables),
      options
    );

useGetDashboardTicketsQuery.getKey = (variables: Types.GetDashboardTicketsQueryVariables) => ['getDashboardTickets', variables];
;

export const useInfiniteGetDashboardTicketsQuery = <
      TData = Types.GetDashboardTicketsQuery,
      TError = ServerError
    >(
      variables: Types.GetDashboardTicketsQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetDashboardTicketsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetDashboardTicketsQuery, TError, TData>(
      ['getDashboardTickets.infinite', variables],
      (metaData) => fetcher<Types.GetDashboardTicketsQuery, Types.GetDashboardTicketsQueryVariables>(GetDashboardTicketsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetDashboardTicketsQuery.getKey = (variables: Types.GetDashboardTicketsQueryVariables) => ['getDashboardTickets.infinite', variables];
;

export const GetManagerDocument = `
    query getManager($uuid: EncodedID!) {
  account(uuid: $uuid) {
    uuid
    manager {
      ...manager
    }
  }
}
    ${ManagerFragmentDoc}`;
export const useGetManagerQuery = <
      TData = Types.GetManagerQuery,
      TError = ServerError
    >(
      variables: Types.GetManagerQueryVariables,
      options?: UseQueryOptions<Types.GetManagerQuery, TError, TData>
    ) =>
    useQuery<Types.GetManagerQuery, TError, TData>(
      ['getManager', variables],
      fetcher<Types.GetManagerQuery, Types.GetManagerQueryVariables>(GetManagerDocument, variables),
      options
    );

useGetManagerQuery.getKey = (variables: Types.GetManagerQueryVariables) => ['getManager', variables];
;

export const useInfiniteGetManagerQuery = <
      TData = Types.GetManagerQuery,
      TError = ServerError
    >(
      variables: Types.GetManagerQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetManagerQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetManagerQuery, TError, TData>(
      ['getManager.infinite', variables],
      (metaData) => fetcher<Types.GetManagerQuery, Types.GetManagerQueryVariables>(GetManagerDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetManagerQuery.getKey = (variables: Types.GetManagerQueryVariables) => ['getManager.infinite', variables];
;

export const CreateFileDraftDocument = `
    mutation createFileDraft($accountUuid: EncodedID!, $request: FileDraftInput!) {
  createFileDraft(accountUuid: $accountUuid, request: $request) {
    id
    uploadUrl
  }
}
    `;
export const useCreateFileDraftMutation = <
      TError = ServerError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateFileDraftMutation, TError, Types.CreateFileDraftMutationVariables, TContext>) =>
    useMutation<Types.CreateFileDraftMutation, TError, Types.CreateFileDraftMutationVariables, TContext>(
      ['createFileDraft'],
      (variables?: Types.CreateFileDraftMutationVariables) => fetcher<Types.CreateFileDraftMutation, Types.CreateFileDraftMutationVariables>(CreateFileDraftDocument, variables)(),
      options
    );
export const GetAccountDocumentsDocument = `
    query getAccountDocuments($accountUuid: EncodedID!, $first: Int, $after: Cursor, $documentCategory: MyFonciaFileCategoryEnum!, $originalFilename: String, $subCategories: [String!], $fromDate: String, $toDate: String, $missionGeneralAssemblyIds: [String!]) {
  account(uuid: $accountUuid) {
    uuid
    documents(
      documentCategory: $documentCategory
      first: $first
      after: $after
      originalFilename: $originalFilename
      subCategories: $subCategories
      fromDate: $fromDate
      toDate: $toDate
      missionGeneralAssemblyIds: $missionGeneralAssemblyIds
    ) {
      totalCount
      pageInfo {
        ...pageInfo
      }
      edges {
        node {
          ...document
        }
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${DocumentFragmentDoc}`;
export const useGetAccountDocumentsQuery = <
      TData = Types.GetAccountDocumentsQuery,
      TError = ServerError
    >(
      variables: Types.GetAccountDocumentsQueryVariables,
      options?: UseQueryOptions<Types.GetAccountDocumentsQuery, TError, TData>
    ) =>
    useQuery<Types.GetAccountDocumentsQuery, TError, TData>(
      ['getAccountDocuments', variables],
      fetcher<Types.GetAccountDocumentsQuery, Types.GetAccountDocumentsQueryVariables>(GetAccountDocumentsDocument, variables),
      options
    );

useGetAccountDocumentsQuery.getKey = (variables: Types.GetAccountDocumentsQueryVariables) => ['getAccountDocuments', variables];
;

export const useInfiniteGetAccountDocumentsQuery = <
      TData = Types.GetAccountDocumentsQuery,
      TError = ServerError
    >(
      variables: Types.GetAccountDocumentsQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetAccountDocumentsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetAccountDocumentsQuery, TError, TData>(
      ['getAccountDocuments.infinite', variables],
      (metaData) => fetcher<Types.GetAccountDocumentsQuery, Types.GetAccountDocumentsQueryVariables>(GetAccountDocumentsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetAccountDocumentsQuery.getKey = (variables: Types.GetAccountDocumentsQueryVariables) => ['getAccountDocuments.infinite', variables];
;

export const GetAccountErecoDocumentsDocument = `
    query getAccountErecoDocuments($accountUuid: EncodedID!, $pageOptions: PageOptions) {
  coownerAccount(uuid: $accountUuid) {
    uuid
    eReco {
      documents(pageOptions: $pageOptions) {
        totalCount
        pageInfo {
          ...pageInfo
        }
        edges {
          node {
            ...ereco
          }
        }
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${ErecoFragmentDoc}`;
export const useGetAccountErecoDocumentsQuery = <
      TData = Types.GetAccountErecoDocumentsQuery,
      TError = ServerError
    >(
      variables: Types.GetAccountErecoDocumentsQueryVariables,
      options?: UseQueryOptions<Types.GetAccountErecoDocumentsQuery, TError, TData>
    ) =>
    useQuery<Types.GetAccountErecoDocumentsQuery, TError, TData>(
      ['getAccountErecoDocuments', variables],
      fetcher<Types.GetAccountErecoDocumentsQuery, Types.GetAccountErecoDocumentsQueryVariables>(GetAccountErecoDocumentsDocument, variables),
      options
    );

useGetAccountErecoDocumentsQuery.getKey = (variables: Types.GetAccountErecoDocumentsQueryVariables) => ['getAccountErecoDocuments', variables];
;

export const useInfiniteGetAccountErecoDocumentsQuery = <
      TData = Types.GetAccountErecoDocumentsQuery,
      TError = ServerError
    >(
      variables: Types.GetAccountErecoDocumentsQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetAccountErecoDocumentsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetAccountErecoDocumentsQuery, TError, TData>(
      ['getAccountErecoDocuments.infinite', variables],
      (metaData) => fetcher<Types.GetAccountErecoDocumentsQuery, Types.GetAccountErecoDocumentsQueryVariables>(GetAccountErecoDocumentsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetAccountErecoDocumentsQuery.getKey = (variables: Types.GetAccountErecoDocumentsQueryVariables) => ['getAccountErecoDocuments.infinite', variables];
;

export const GetDocumentCategoriesDocument = `
    query getDocumentCategories($accountUuid: EncodedID!, $documentCategories: [MyFonciaFileCategoryEnum!], $missionGeneralAssemblyIds: [String!]) {
  account(uuid: $accountUuid) {
    uuid
    quality
    ... on CoownerAccount {
      eReco {
        isSubscribed
        hasHistory
      }
      isTrusteeCouncilMember
    }
    documentCategories(
      documentCategories: $documentCategories
      missionGeneralAssemblyIds: $missionGeneralAssemblyIds
    ) {
      key
      documentStats {
        count
        lastCreatedAt
      }
      subCategories {
        key
      }
    }
  }
}
    `;
export const useGetDocumentCategoriesQuery = <
      TData = Types.GetDocumentCategoriesQuery,
      TError = ServerError
    >(
      variables: Types.GetDocumentCategoriesQueryVariables,
      options?: UseQueryOptions<Types.GetDocumentCategoriesQuery, TError, TData>
    ) =>
    useQuery<Types.GetDocumentCategoriesQuery, TError, TData>(
      ['getDocumentCategories', variables],
      fetcher<Types.GetDocumentCategoriesQuery, Types.GetDocumentCategoriesQueryVariables>(GetDocumentCategoriesDocument, variables),
      options
    );

useGetDocumentCategoriesQuery.getKey = (variables: Types.GetDocumentCategoriesQueryVariables) => ['getDocumentCategories', variables];
;

export const useInfiniteGetDocumentCategoriesQuery = <
      TData = Types.GetDocumentCategoriesQuery,
      TError = ServerError
    >(
      variables: Types.GetDocumentCategoriesQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetDocumentCategoriesQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetDocumentCategoriesQuery, TError, TData>(
      ['getDocumentCategories.infinite', variables],
      (metaData) => fetcher<Types.GetDocumentCategoriesQuery, Types.GetDocumentCategoriesQueryVariables>(GetDocumentCategoriesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetDocumentCategoriesQuery.getKey = (variables: Types.GetDocumentCategoriesQueryVariables) => ['getDocumentCategories.infinite', variables];
;

export const GetDocumentUrlDocument = `
    query getDocumentUrl($hash: String!) {
  documentURL(hash: $hash)
}
    `;
export const useGetDocumentUrlQuery = <
      TData = Types.GetDocumentUrlQuery,
      TError = ServerError
    >(
      variables: Types.GetDocumentUrlQueryVariables,
      options?: UseQueryOptions<Types.GetDocumentUrlQuery, TError, TData>
    ) =>
    useQuery<Types.GetDocumentUrlQuery, TError, TData>(
      ['getDocumentUrl', variables],
      fetcher<Types.GetDocumentUrlQuery, Types.GetDocumentUrlQueryVariables>(GetDocumentUrlDocument, variables),
      options
    );

useGetDocumentUrlQuery.getKey = (variables: Types.GetDocumentUrlQueryVariables) => ['getDocumentUrl', variables];
;

export const useInfiniteGetDocumentUrlQuery = <
      TData = Types.GetDocumentUrlQuery,
      TError = ServerError
    >(
      variables: Types.GetDocumentUrlQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetDocumentUrlQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetDocumentUrlQuery, TError, TData>(
      ['getDocumentUrl.infinite', variables],
      (metaData) => fetcher<Types.GetDocumentUrlQuery, Types.GetDocumentUrlQueryVariables>(GetDocumentUrlDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetDocumentUrlQuery.getKey = (variables: Types.GetDocumentUrlQueryVariables) => ['getDocumentUrl.infinite', variables];
;

export const GetInvoiceUrlDocument = `
    query getInvoiceURL($invoiceId: String!) {
  invoiceURL(invoiceId: $invoiceId)
}
    `;
export const useGetInvoiceUrlQuery = <
      TData = Types.GetInvoiceUrlQuery,
      TError = ServerError
    >(
      variables: Types.GetInvoiceUrlQueryVariables,
      options?: UseQueryOptions<Types.GetInvoiceUrlQuery, TError, TData>
    ) =>
    useQuery<Types.GetInvoiceUrlQuery, TError, TData>(
      ['getInvoiceURL', variables],
      fetcher<Types.GetInvoiceUrlQuery, Types.GetInvoiceUrlQueryVariables>(GetInvoiceUrlDocument, variables),
      options
    );

useGetInvoiceUrlQuery.getKey = (variables: Types.GetInvoiceUrlQueryVariables) => ['getInvoiceURL', variables];
;

export const useInfiniteGetInvoiceUrlQuery = <
      TData = Types.GetInvoiceUrlQuery,
      TError = ServerError
    >(
      variables: Types.GetInvoiceUrlQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetInvoiceUrlQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetInvoiceUrlQuery, TError, TData>(
      ['getInvoiceURL.infinite', variables],
      (metaData) => fetcher<Types.GetInvoiceUrlQuery, Types.GetInvoiceUrlQueryVariables>(GetInvoiceUrlDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetInvoiceUrlQuery.getKey = (variables: Types.GetInvoiceUrlQueryVariables) => ['getInvoiceURL.infinite', variables];
;

export const GetMissionGeneralAssemblyPerYearDocument = `
    query getMissionGeneralAssemblyPerYear($accountUuid: EncodedID!) {
  coownerAccount(uuid: $accountUuid) {
    uuid
    missionGeneralAssemblyPerYear {
      year
      missionGeneralAssemblyIds
    }
  }
}
    `;
export const useGetMissionGeneralAssemblyPerYearQuery = <
      TData = Types.GetMissionGeneralAssemblyPerYearQuery,
      TError = ServerError
    >(
      variables: Types.GetMissionGeneralAssemblyPerYearQueryVariables,
      options?: UseQueryOptions<Types.GetMissionGeneralAssemblyPerYearQuery, TError, TData>
    ) =>
    useQuery<Types.GetMissionGeneralAssemblyPerYearQuery, TError, TData>(
      ['getMissionGeneralAssemblyPerYear', variables],
      fetcher<Types.GetMissionGeneralAssemblyPerYearQuery, Types.GetMissionGeneralAssemblyPerYearQueryVariables>(GetMissionGeneralAssemblyPerYearDocument, variables),
      options
    );

useGetMissionGeneralAssemblyPerYearQuery.getKey = (variables: Types.GetMissionGeneralAssemblyPerYearQueryVariables) => ['getMissionGeneralAssemblyPerYear', variables];
;

export const useInfiniteGetMissionGeneralAssemblyPerYearQuery = <
      TData = Types.GetMissionGeneralAssemblyPerYearQuery,
      TError = ServerError
    >(
      variables: Types.GetMissionGeneralAssemblyPerYearQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetMissionGeneralAssemblyPerYearQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetMissionGeneralAssemblyPerYearQuery, TError, TData>(
      ['getMissionGeneralAssemblyPerYear.infinite', variables],
      (metaData) => fetcher<Types.GetMissionGeneralAssemblyPerYearQuery, Types.GetMissionGeneralAssemblyPerYearQueryVariables>(GetMissionGeneralAssemblyPerYearDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetMissionGeneralAssemblyPerYearQuery.getKey = (variables: Types.GetMissionGeneralAssemblyPerYearQueryVariables) => ['getMissionGeneralAssemblyPerYear.infinite', variables];
;

export const GetZipDocumentWorkflowDocument = `
    query getZipDocumentWorkflow($filename: String!, $ids: [String!]!, $workflow: String) {
  zipFileWorkflow(filename: $filename, ids: $ids, workflow: $workflow) {
    ... on ZipFileWorkflowAcceptedResult {
      workflow
    }
    ... on ZipFileWorkflowResult {
      url
    }
  }
}
    `;
export const useGetZipDocumentWorkflowQuery = <
      TData = Types.GetZipDocumentWorkflowQuery,
      TError = ServerError
    >(
      variables: Types.GetZipDocumentWorkflowQueryVariables,
      options?: UseQueryOptions<Types.GetZipDocumentWorkflowQuery, TError, TData>
    ) =>
    useQuery<Types.GetZipDocumentWorkflowQuery, TError, TData>(
      ['getZipDocumentWorkflow', variables],
      fetcher<Types.GetZipDocumentWorkflowQuery, Types.GetZipDocumentWorkflowQueryVariables>(GetZipDocumentWorkflowDocument, variables),
      options
    );

useGetZipDocumentWorkflowQuery.getKey = (variables: Types.GetZipDocumentWorkflowQueryVariables) => ['getZipDocumentWorkflow', variables];
;

export const useInfiniteGetZipDocumentWorkflowQuery = <
      TData = Types.GetZipDocumentWorkflowQuery,
      TError = ServerError
    >(
      variables: Types.GetZipDocumentWorkflowQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetZipDocumentWorkflowQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetZipDocumentWorkflowQuery, TError, TData>(
      ['getZipDocumentWorkflow.infinite', variables],
      (metaData) => fetcher<Types.GetZipDocumentWorkflowQuery, Types.GetZipDocumentWorkflowQueryVariables>(GetZipDocumentWorkflowDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetZipDocumentWorkflowQuery.getKey = (variables: Types.GetZipDocumentWorkflowQueryVariables) => ['getZipDocumentWorkflow.infinite', variables];
;

export const GetFeatureFlagsDocument = `
    query getFeatureFlags {
  featureFlags {
    activatedFeatures
  }
}
    `;
export const useGetFeatureFlagsQuery = <
      TData = Types.GetFeatureFlagsQuery,
      TError = ServerError
    >(
      variables?: Types.GetFeatureFlagsQueryVariables,
      options?: UseQueryOptions<Types.GetFeatureFlagsQuery, TError, TData>
    ) =>
    useQuery<Types.GetFeatureFlagsQuery, TError, TData>(
      variables === undefined ? ['getFeatureFlags'] : ['getFeatureFlags', variables],
      fetcher<Types.GetFeatureFlagsQuery, Types.GetFeatureFlagsQueryVariables>(GetFeatureFlagsDocument, variables),
      options
    );

useGetFeatureFlagsQuery.getKey = (variables?: Types.GetFeatureFlagsQueryVariables) => variables === undefined ? ['getFeatureFlags'] : ['getFeatureFlags', variables];
;

export const useInfiniteGetFeatureFlagsQuery = <
      TData = Types.GetFeatureFlagsQuery,
      TError = ServerError
    >(
      variables?: Types.GetFeatureFlagsQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetFeatureFlagsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetFeatureFlagsQuery, TError, TData>(
      variables === undefined ? ['getFeatureFlags.infinite'] : ['getFeatureFlags.infinite', variables],
      (metaData) => fetcher<Types.GetFeatureFlagsQuery, Types.GetFeatureFlagsQueryVariables>(GetFeatureFlagsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetFeatureFlagsQuery.getKey = (variables?: Types.GetFeatureFlagsQueryVariables) => variables === undefined ? ['getFeatureFlags.infinite'] : ['getFeatureFlags.infinite', variables];
;

export const GetGeneralAssembliesDocument = `
    query getGeneralAssemblies($accountUuid: EncodedID!) {
  account(uuid: $accountUuid) {
    uuid
    ... on CoownerAccount {
      pastGeneralAssembly {
        ...generalAssembly
      }
      nextGeneralAssembly {
        ...generalAssembly
      }
      nextGeneralAssemblies {
        ...generalAssembly
      }
    }
  }
}
    ${GeneralAssemblyFragmentDoc}
${AddressFragmentDoc}`;
export const useGetGeneralAssembliesQuery = <
      TData = Types.GetGeneralAssembliesQuery,
      TError = ServerError
    >(
      variables: Types.GetGeneralAssembliesQueryVariables,
      options?: UseQueryOptions<Types.GetGeneralAssembliesQuery, TError, TData>
    ) =>
    useQuery<Types.GetGeneralAssembliesQuery, TError, TData>(
      ['getGeneralAssemblies', variables],
      fetcher<Types.GetGeneralAssembliesQuery, Types.GetGeneralAssembliesQueryVariables>(GetGeneralAssembliesDocument, variables),
      options
    );

useGetGeneralAssembliesQuery.getKey = (variables: Types.GetGeneralAssembliesQueryVariables) => ['getGeneralAssemblies', variables];
;

export const useInfiniteGetGeneralAssembliesQuery = <
      TData = Types.GetGeneralAssembliesQuery,
      TError = ServerError
    >(
      variables: Types.GetGeneralAssembliesQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetGeneralAssembliesQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetGeneralAssembliesQuery, TError, TData>(
      ['getGeneralAssemblies.infinite', variables],
      (metaData) => fetcher<Types.GetGeneralAssembliesQuery, Types.GetGeneralAssembliesQueryVariables>(GetGeneralAssembliesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetGeneralAssembliesQuery.getKey = (variables: Types.GetGeneralAssembliesQueryVariables) => ['getGeneralAssemblies.infinite', variables];
;

export const GetEstateListDocument = `
    query getEstateList($uuid: EncodedID!) {
  account(uuid: $uuid) {
    uuid
    ... on LandlordAccount {
      buildings {
        ...building
      }
    }
    ... on CoownerAccount {
      units {
        unit
        saleDate
      }
      building {
        ...building
      }
    }
    ... on TenantAccount {
      building {
        ...building
      }
    }
  }
}
    ${BuildingFragmentDoc}
${AddressFragmentDoc}
${UnitFragmentDoc}`;
export const useGetEstateListQuery = <
      TData = Types.GetEstateListQuery,
      TError = ServerError
    >(
      variables: Types.GetEstateListQueryVariables,
      options?: UseQueryOptions<Types.GetEstateListQuery, TError, TData>
    ) =>
    useQuery<Types.GetEstateListQuery, TError, TData>(
      ['getEstateList', variables],
      fetcher<Types.GetEstateListQuery, Types.GetEstateListQueryVariables>(GetEstateListDocument, variables),
      options
    );

useGetEstateListQuery.getKey = (variables: Types.GetEstateListQueryVariables) => ['getEstateList', variables];
;

export const useInfiniteGetEstateListQuery = <
      TData = Types.GetEstateListQuery,
      TError = ServerError
    >(
      variables: Types.GetEstateListQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetEstateListQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetEstateListQuery, TError, TData>(
      ['getEstateList.infinite', variables],
      (metaData) => fetcher<Types.GetEstateListQuery, Types.GetEstateListQueryVariables>(GetEstateListDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetEstateListQuery.getKey = (variables: Types.GetEstateListQueryVariables) => ['getEstateList.infinite', variables];
;

export const CreateMissionWithSaleAgreementDocument = `
    mutation createMissionWithSaleAgreement($accountUuid: EncodedID!, $request: CreateMissionWithSaleAgreementRequest!) {
  createMissionWithSaleAgreement(accountUuid: $accountUuid, request: $request) {
    dalenysHASH
    dalenysParameters {
      IDENTIFIER
      OPERATIONTYPE
      AMOUNT
      CLIENTEMAIL
      CLIENTIDENT
      HIDECLIENTEMAIL
      DESCRIPTION
      ORDERID
      VERSION
      EXTRADATA
    }
  }
}
    `;
export const useCreateMissionWithSaleAgreementMutation = <
      TError = ServerError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateMissionWithSaleAgreementMutation, TError, Types.CreateMissionWithSaleAgreementMutationVariables, TContext>) =>
    useMutation<Types.CreateMissionWithSaleAgreementMutation, TError, Types.CreateMissionWithSaleAgreementMutationVariables, TContext>(
      ['createMissionWithSaleAgreement'],
      (variables?: Types.CreateMissionWithSaleAgreementMutationVariables) => fetcher<Types.CreateMissionWithSaleAgreementMutation, Types.CreateMissionWithSaleAgreementMutationVariables>(CreateMissionWithSaleAgreementDocument, variables)(),
      options
    );
export const GetCouncilMissionIncidentsDocument = `
    query getCouncilMissionIncidents($accountUuid: EncodedID!, $first: Int, $after: Cursor, $pageOptions: PageOptions, $sortBy: [SortByType!]) {
  coownerAccount(uuid: $accountUuid) {
    uuid
    trusteeCouncil {
      missionIncidents(
        first: $first
        after: $after
        pageOptions: $pageOptions
        sortBy: $sortBy
      ) {
        totalCount
        pageInfo {
          ...pageInfo
        }
        edges {
          node {
            ...missionIncident
          }
        }
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${MissionIncidentFragmentDoc}`;
export const useGetCouncilMissionIncidentsQuery = <
      TData = Types.GetCouncilMissionIncidentsQuery,
      TError = ServerError
    >(
      variables: Types.GetCouncilMissionIncidentsQueryVariables,
      options?: UseQueryOptions<Types.GetCouncilMissionIncidentsQuery, TError, TData>
    ) =>
    useQuery<Types.GetCouncilMissionIncidentsQuery, TError, TData>(
      ['getCouncilMissionIncidents', variables],
      fetcher<Types.GetCouncilMissionIncidentsQuery, Types.GetCouncilMissionIncidentsQueryVariables>(GetCouncilMissionIncidentsDocument, variables),
      options
    );

useGetCouncilMissionIncidentsQuery.getKey = (variables: Types.GetCouncilMissionIncidentsQueryVariables) => ['getCouncilMissionIncidents', variables];
;

export const useInfiniteGetCouncilMissionIncidentsQuery = <
      TData = Types.GetCouncilMissionIncidentsQuery,
      TError = ServerError
    >(
      variables: Types.GetCouncilMissionIncidentsQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetCouncilMissionIncidentsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetCouncilMissionIncidentsQuery, TError, TData>(
      ['getCouncilMissionIncidents.infinite', variables],
      (metaData) => fetcher<Types.GetCouncilMissionIncidentsQuery, Types.GetCouncilMissionIncidentsQueryVariables>(GetCouncilMissionIncidentsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetCouncilMissionIncidentsQuery.getKey = (variables: Types.GetCouncilMissionIncidentsQueryVariables) => ['getCouncilMissionIncidents.infinite', variables];
;

export const GetCouncilMissionMeetingsDocument = `
    query getCouncilMissionMeetings($isPast: Boolean!, $accountUuid: EncodedID!, $first: Int, $after: Cursor) {
  coownerAccount(uuid: $accountUuid) {
    uuid
    trusteeCouncil {
      missionMeetings(isPast: $isPast, first: $first, after: $after) {
        totalCount
        pageInfo {
          ...pageInfo
        }
        edges {
          node {
            ...missionMeeting
          }
        }
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${MissionMeetingFragmentDoc}
${MissionMeetingAgendaFragmentDoc}
${AddressFragmentDoc}`;
export const useGetCouncilMissionMeetingsQuery = <
      TData = Types.GetCouncilMissionMeetingsQuery,
      TError = ServerError
    >(
      variables: Types.GetCouncilMissionMeetingsQueryVariables,
      options?: UseQueryOptions<Types.GetCouncilMissionMeetingsQuery, TError, TData>
    ) =>
    useQuery<Types.GetCouncilMissionMeetingsQuery, TError, TData>(
      ['getCouncilMissionMeetings', variables],
      fetcher<Types.GetCouncilMissionMeetingsQuery, Types.GetCouncilMissionMeetingsQueryVariables>(GetCouncilMissionMeetingsDocument, variables),
      options
    );

useGetCouncilMissionMeetingsQuery.getKey = (variables: Types.GetCouncilMissionMeetingsQueryVariables) => ['getCouncilMissionMeetings', variables];
;

export const useInfiniteGetCouncilMissionMeetingsQuery = <
      TData = Types.GetCouncilMissionMeetingsQuery,
      TError = ServerError
    >(
      variables: Types.GetCouncilMissionMeetingsQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetCouncilMissionMeetingsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetCouncilMissionMeetingsQuery, TError, TData>(
      ['getCouncilMissionMeetings.infinite', variables],
      (metaData) => fetcher<Types.GetCouncilMissionMeetingsQuery, Types.GetCouncilMissionMeetingsQueryVariables>(GetCouncilMissionMeetingsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetCouncilMissionMeetingsQuery.getKey = (variables: Types.GetCouncilMissionMeetingsQueryVariables) => ['getCouncilMissionMeetings.infinite', variables];
;

export const GetCouncilMissionRepairsDocument = `
    query getCouncilMissionRepairs($accountUuid: EncodedID!, $first: Int, $after: Cursor, $sortBy: [SortByType!]) {
  coownerAccount(uuid: $accountUuid) {
    uuid
    trusteeCouncil {
      missionRepairs(first: $first, after: $after, sortBy: $sortBy) {
        totalCount
        pageInfo {
          ...pageInfo
        }
        edges {
          node {
            ...missionRepair
          }
        }
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${MissionRepairFragmentDoc}`;
export const useGetCouncilMissionRepairsQuery = <
      TData = Types.GetCouncilMissionRepairsQuery,
      TError = ServerError
    >(
      variables: Types.GetCouncilMissionRepairsQueryVariables,
      options?: UseQueryOptions<Types.GetCouncilMissionRepairsQuery, TError, TData>
    ) =>
    useQuery<Types.GetCouncilMissionRepairsQuery, TError, TData>(
      ['getCouncilMissionRepairs', variables],
      fetcher<Types.GetCouncilMissionRepairsQuery, Types.GetCouncilMissionRepairsQueryVariables>(GetCouncilMissionRepairsDocument, variables),
      options
    );

useGetCouncilMissionRepairsQuery.getKey = (variables: Types.GetCouncilMissionRepairsQueryVariables) => ['getCouncilMissionRepairs', variables];
;

export const useInfiniteGetCouncilMissionRepairsQuery = <
      TData = Types.GetCouncilMissionRepairsQuery,
      TError = ServerError
    >(
      variables: Types.GetCouncilMissionRepairsQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetCouncilMissionRepairsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetCouncilMissionRepairsQuery, TError, TData>(
      ['getCouncilMissionRepairs.infinite', variables],
      (metaData) => fetcher<Types.GetCouncilMissionRepairsQuery, Types.GetCouncilMissionRepairsQueryVariables>(GetCouncilMissionRepairsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetCouncilMissionRepairsQuery.getKey = (variables: Types.GetCouncilMissionRepairsQueryVariables) => ['getCouncilMissionRepairs.infinite', variables];
;

export const GetCouncilMissionSuppliersDocument = `
    query getCouncilMissionSuppliers($accountUuid: EncodedID!, $first: Int, $after: Cursor, $description: String, $supplierFullname: String, $endingDateFrom: String, $endingDateTo: String) {
  coownerAccount(uuid: $accountUuid) {
    uuid
    trusteeCouncil {
      supplierContracts(
        first: $first
        after: $after
        description: $description
        supplierFullname: $supplierFullname
        endingDateFrom: $endingDateFrom
        endingDateTo: $endingDateTo
      ) {
        totalCount
        pageInfo {
          ...pageInfo
        }
        edges {
          node {
            ...supplierContract
          }
        }
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${SupplierContractFragmentDoc}
${SupplierFragmentDoc}
${DocumentFragmentDoc}`;
export const useGetCouncilMissionSuppliersQuery = <
      TData = Types.GetCouncilMissionSuppliersQuery,
      TError = ServerError
    >(
      variables: Types.GetCouncilMissionSuppliersQueryVariables,
      options?: UseQueryOptions<Types.GetCouncilMissionSuppliersQuery, TError, TData>
    ) =>
    useQuery<Types.GetCouncilMissionSuppliersQuery, TError, TData>(
      ['getCouncilMissionSuppliers', variables],
      fetcher<Types.GetCouncilMissionSuppliersQuery, Types.GetCouncilMissionSuppliersQueryVariables>(GetCouncilMissionSuppliersDocument, variables),
      options
    );

useGetCouncilMissionSuppliersQuery.getKey = (variables: Types.GetCouncilMissionSuppliersQueryVariables) => ['getCouncilMissionSuppliers', variables];
;

export const useInfiniteGetCouncilMissionSuppliersQuery = <
      TData = Types.GetCouncilMissionSuppliersQuery,
      TError = ServerError
    >(
      variables: Types.GetCouncilMissionSuppliersQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetCouncilMissionSuppliersQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetCouncilMissionSuppliersQuery, TError, TData>(
      ['getCouncilMissionSuppliers.infinite', variables],
      (metaData) => fetcher<Types.GetCouncilMissionSuppliersQuery, Types.GetCouncilMissionSuppliersQueryVariables>(GetCouncilMissionSuppliersDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetCouncilMissionSuppliersQuery.getKey = (variables: Types.GetCouncilMissionSuppliersQueryVariables) => ['getCouncilMissionSuppliers.infinite', variables];
;

export const GetCouncilMissionVisitsDocument = `
    query getCouncilMissionVisits($accountUuid: EncodedID!, $first: Int, $after: Cursor, $status: [MissionStatusEnum!], $skipNotPaginatedData: Boolean = false) {
  coownerAccount(uuid: $accountUuid) {
    uuid
    building @skip(if: $skipNotPaginatedData) {
      ...building
    }
    trusteeCouncil {
      missionVisits(status: $status, first: $first, after: $after) {
        totalCount
        pageInfo {
          ...pageInfo
        }
        edges {
          node {
            ...missionVisit
          }
        }
      }
    }
  }
}
    ${BuildingFragmentDoc}
${AddressFragmentDoc}
${UnitFragmentDoc}
${PageInfoFragmentDoc}
${MissionVisitFragmentDoc}
${DocumentFragmentDoc}`;
export const useGetCouncilMissionVisitsQuery = <
      TData = Types.GetCouncilMissionVisitsQuery,
      TError = ServerError
    >(
      variables: Types.GetCouncilMissionVisitsQueryVariables,
      options?: UseQueryOptions<Types.GetCouncilMissionVisitsQuery, TError, TData>
    ) =>
    useQuery<Types.GetCouncilMissionVisitsQuery, TError, TData>(
      ['getCouncilMissionVisits', variables],
      fetcher<Types.GetCouncilMissionVisitsQuery, Types.GetCouncilMissionVisitsQueryVariables>(GetCouncilMissionVisitsDocument, variables),
      options
    );

useGetCouncilMissionVisitsQuery.getKey = (variables: Types.GetCouncilMissionVisitsQueryVariables) => ['getCouncilMissionVisits', variables];
;

export const useInfiniteGetCouncilMissionVisitsQuery = <
      TData = Types.GetCouncilMissionVisitsQuery,
      TError = ServerError
    >(
      variables: Types.GetCouncilMissionVisitsQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetCouncilMissionVisitsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetCouncilMissionVisitsQuery, TError, TData>(
      ['getCouncilMissionVisits.infinite', variables],
      (metaData) => fetcher<Types.GetCouncilMissionVisitsQuery, Types.GetCouncilMissionVisitsQueryVariables>(GetCouncilMissionVisitsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetCouncilMissionVisitsQuery.getKey = (variables: Types.GetCouncilMissionVisitsQueryVariables) => ['getCouncilMissionVisits.infinite', variables];
;

export const GetMissionsWithSaleAgreementDocument = `
    query getMissionsWithSaleAgreement($accountUuid: EncodedID!) {
  missionsWithSaleAgreement(accountUuid: $accountUuid) {
    ...missionWithSaleAgreement
  }
}
    ${MissionWithSaleAgreementFragmentDoc}
${DocumentFragmentDoc}`;
export const useGetMissionsWithSaleAgreementQuery = <
      TData = Types.GetMissionsWithSaleAgreementQuery,
      TError = ServerError
    >(
      variables: Types.GetMissionsWithSaleAgreementQueryVariables,
      options?: UseQueryOptions<Types.GetMissionsWithSaleAgreementQuery, TError, TData>
    ) =>
    useQuery<Types.GetMissionsWithSaleAgreementQuery, TError, TData>(
      ['getMissionsWithSaleAgreement', variables],
      fetcher<Types.GetMissionsWithSaleAgreementQuery, Types.GetMissionsWithSaleAgreementQueryVariables>(GetMissionsWithSaleAgreementDocument, variables),
      options
    );

useGetMissionsWithSaleAgreementQuery.getKey = (variables: Types.GetMissionsWithSaleAgreementQueryVariables) => ['getMissionsWithSaleAgreement', variables];
;

export const useInfiniteGetMissionsWithSaleAgreementQuery = <
      TData = Types.GetMissionsWithSaleAgreementQuery,
      TError = ServerError
    >(
      variables: Types.GetMissionsWithSaleAgreementQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetMissionsWithSaleAgreementQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetMissionsWithSaleAgreementQuery, TError, TData>(
      ['getMissionsWithSaleAgreement.infinite', variables],
      (metaData) => fetcher<Types.GetMissionsWithSaleAgreementQuery, Types.GetMissionsWithSaleAgreementQueryVariables>(GetMissionsWithSaleAgreementDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetMissionsWithSaleAgreementQuery.getKey = (variables: Types.GetMissionsWithSaleAgreementQueryVariables) => ['getMissionsWithSaleAgreement.infinite', variables];
;

export const GetWorkOrdersDocument = `
    query getWorkOrders($accountUuid: EncodedID!, $missionId: ID!, $first: Int, $before: Cursor, $after: Cursor) {
  workOrders(
    accountUuid: $accountUuid
    missionId: $missionId
    first: $first
    before: $before
    after: $after
  ) {
    totalCount
    pageInfo {
      ...pageInfo
    }
    edges {
      node {
        ...workOrder
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${WorkOrderFragmentDoc}
${SupplierFragmentDoc}`;
export const useGetWorkOrdersQuery = <
      TData = Types.GetWorkOrdersQuery,
      TError = ServerError
    >(
      variables: Types.GetWorkOrdersQueryVariables,
      options?: UseQueryOptions<Types.GetWorkOrdersQuery, TError, TData>
    ) =>
    useQuery<Types.GetWorkOrdersQuery, TError, TData>(
      ['getWorkOrders', variables],
      fetcher<Types.GetWorkOrdersQuery, Types.GetWorkOrdersQueryVariables>(GetWorkOrdersDocument, variables),
      options
    );

useGetWorkOrdersQuery.getKey = (variables: Types.GetWorkOrdersQueryVariables) => ['getWorkOrders', variables];
;

export const useInfiniteGetWorkOrdersQuery = <
      TData = Types.GetWorkOrdersQuery,
      TError = ServerError
    >(
      variables: Types.GetWorkOrdersQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetWorkOrdersQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetWorkOrdersQuery, TError, TData>(
      ['getWorkOrders.infinite', variables],
      (metaData) => fetcher<Types.GetWorkOrdersQuery, Types.GetWorkOrdersQueryVariables>(GetWorkOrdersDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetWorkOrdersQuery.getKey = (variables: Types.GetWorkOrdersQueryVariables) => ['getWorkOrders.infinite', variables];
;

export const ValidMissionWithSaleAgreementDocument = `
    mutation validMissionWithSaleAgreement($paramsAsString: String!) {
  validMissionWithSaleAgreement(paramsAsString: $paramsAsString) {
    isSuccess
    message
  }
}
    `;
export const useValidMissionWithSaleAgreementMutation = <
      TError = ServerError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.ValidMissionWithSaleAgreementMutation, TError, Types.ValidMissionWithSaleAgreementMutationVariables, TContext>) =>
    useMutation<Types.ValidMissionWithSaleAgreementMutation, TError, Types.ValidMissionWithSaleAgreementMutationVariables, TContext>(
      ['validMissionWithSaleAgreement'],
      (variables?: Types.ValidMissionWithSaleAgreementMutationVariables) => fetcher<Types.ValidMissionWithSaleAgreementMutation, Types.ValidMissionWithSaleAgreementMutationVariables>(ValidMissionWithSaleAgreementDocument, variables)(),
      options
    );
export const GetNatixisPaymentBanksDocument = `
    query getNatixisPaymentBanks {
  natixisPaymentBanks {
    id
    label
    logo
  }
}
    `;
export const useGetNatixisPaymentBanksQuery = <
      TData = Types.GetNatixisPaymentBanksQuery,
      TError = ServerError
    >(
      variables?: Types.GetNatixisPaymentBanksQueryVariables,
      options?: UseQueryOptions<Types.GetNatixisPaymentBanksQuery, TError, TData>
    ) =>
    useQuery<Types.GetNatixisPaymentBanksQuery, TError, TData>(
      variables === undefined ? ['getNatixisPaymentBanks'] : ['getNatixisPaymentBanks', variables],
      fetcher<Types.GetNatixisPaymentBanksQuery, Types.GetNatixisPaymentBanksQueryVariables>(GetNatixisPaymentBanksDocument, variables),
      options
    );

useGetNatixisPaymentBanksQuery.getKey = (variables?: Types.GetNatixisPaymentBanksQueryVariables) => variables === undefined ? ['getNatixisPaymentBanks'] : ['getNatixisPaymentBanks', variables];
;

export const useInfiniteGetNatixisPaymentBanksQuery = <
      TData = Types.GetNatixisPaymentBanksQuery,
      TError = ServerError
    >(
      variables?: Types.GetNatixisPaymentBanksQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetNatixisPaymentBanksQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetNatixisPaymentBanksQuery, TError, TData>(
      variables === undefined ? ['getNatixisPaymentBanks.infinite'] : ['getNatixisPaymentBanks.infinite', variables],
      (metaData) => fetcher<Types.GetNatixisPaymentBanksQuery, Types.GetNatixisPaymentBanksQueryVariables>(GetNatixisPaymentBanksDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetNatixisPaymentBanksQuery.getKey = (variables?: Types.GetNatixisPaymentBanksQueryVariables) => variables === undefined ? ['getNatixisPaymentBanks.infinite'] : ['getNatixisPaymentBanks.infinite', variables];
;

export const GetNatixisPaymentStatusDocument = `
    query getNatixisPaymentStatus($accountUuid: EncodedID!, $paymentId: String!) {
  natixisPaymentStatus(accountUuid: $accountUuid, paymentId: $paymentId) {
    status
  }
}
    `;
export const useGetNatixisPaymentStatusQuery = <
      TData = Types.GetNatixisPaymentStatusQuery,
      TError = ServerError
    >(
      variables: Types.GetNatixisPaymentStatusQueryVariables,
      options?: UseQueryOptions<Types.GetNatixisPaymentStatusQuery, TError, TData>
    ) =>
    useQuery<Types.GetNatixisPaymentStatusQuery, TError, TData>(
      ['getNatixisPaymentStatus', variables],
      fetcher<Types.GetNatixisPaymentStatusQuery, Types.GetNatixisPaymentStatusQueryVariables>(GetNatixisPaymentStatusDocument, variables),
      options
    );

useGetNatixisPaymentStatusQuery.getKey = (variables: Types.GetNatixisPaymentStatusQueryVariables) => ['getNatixisPaymentStatus', variables];
;

export const useInfiniteGetNatixisPaymentStatusQuery = <
      TData = Types.GetNatixisPaymentStatusQuery,
      TError = ServerError
    >(
      variables: Types.GetNatixisPaymentStatusQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetNatixisPaymentStatusQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetNatixisPaymentStatusQuery, TError, TData>(
      ['getNatixisPaymentStatus.infinite', variables],
      (metaData) => fetcher<Types.GetNatixisPaymentStatusQuery, Types.GetNatixisPaymentStatusQueryVariables>(GetNatixisPaymentStatusDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetNatixisPaymentStatusQuery.getKey = (variables: Types.GetNatixisPaymentStatusQueryVariables) => ['getNatixisPaymentStatus.infinite', variables];
;

export const NatixisPayDocument = `
    mutation natixisPay($accountUuid: EncodedID!, $request: NatixisPayRequest!) {
  natixisPay(accountUuid: $accountUuid, request: $request) {
    url
  }
}
    `;
export const useNatixisPayMutation = <
      TError = ServerError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.NatixisPayMutation, TError, Types.NatixisPayMutationVariables, TContext>) =>
    useMutation<Types.NatixisPayMutation, TError, Types.NatixisPayMutationVariables, TContext>(
      ['natixisPay'],
      (variables?: Types.NatixisPayMutationVariables) => fetcher<Types.NatixisPayMutation, Types.NatixisPayMutationVariables>(NatixisPayDocument, variables)(),
      options
    );
export const CreateDebitDocument = `
    mutation createDebit($accountUuid: EncodedID!, $request: CreateDebitRequest!) {
  createDebit(accountUuid: $accountUuid, request: $request) {
    ...debitLine
  }
}
    ${DebitLineFragmentDoc}
${AmountFragmentDoc}`;
export const useCreateDebitMutation = <
      TError = ServerError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateDebitMutation, TError, Types.CreateDebitMutationVariables, TContext>) =>
    useMutation<Types.CreateDebitMutation, TError, Types.CreateDebitMutationVariables, TContext>(
      ['createDebit'],
      (variables?: Types.CreateDebitMutationVariables) => fetcher<Types.CreateDebitMutation, Types.CreateDebitMutationVariables>(CreateDebitDocument, variables)(),
      options
    );
export const CreatePaymentValidationDocument = `
    mutation createPaymentValidation($request: CreatePaymentValidationRequest!) {
  createPaymentValidation(request: $request) {
    finalDocUrl
  }
}
    `;
export const useCreatePaymentValidationMutation = <
      TError = ServerError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreatePaymentValidationMutation, TError, Types.CreatePaymentValidationMutationVariables, TContext>) =>
    useMutation<Types.CreatePaymentValidationMutation, TError, Types.CreatePaymentValidationMutationVariables, TContext>(
      ['createPaymentValidation'],
      (variables?: Types.CreatePaymentValidationMutationVariables) => fetcher<Types.CreatePaymentValidationMutation, Types.CreatePaymentValidationMutationVariables>(CreatePaymentValidationDocument, variables)(),
      options
    );
export const CreateSepaMandateDocument = `
    mutation createSepaMandate($accountUuid: EncodedID!, $request: CreateSepaMandateRequest!) {
  createSepaMandate(accountUuid: $accountUuid, request: $request) {
    docapostSignUrl
    dpSignatureId
    dpTransactionId
    validationJwt
  }
}
    `;
export const useCreateSepaMandateMutation = <
      TError = ServerError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateSepaMandateMutation, TError, Types.CreateSepaMandateMutationVariables, TContext>) =>
    useMutation<Types.CreateSepaMandateMutation, TError, Types.CreateSepaMandateMutationVariables, TContext>(
      ['createSepaMandate'],
      (variables?: Types.CreateSepaMandateMutationVariables) => fetcher<Types.CreateSepaMandateMutation, Types.CreateSepaMandateMutationVariables>(CreateSepaMandateDocument, variables)(),
      options
    );
export const GetDebitLinesDocument = `
    query getDebitLines($accountUuid: EncodedID!, $pageOptions: PageOptions) {
  account(uuid: $accountUuid) {
    uuid
    accounting {
      debits(pageOptions: $pageOptions) {
        totalCount
        pageInfo {
          ...pageInfo
        }
        edges {
          node {
            ...debitLine
          }
        }
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${DebitLineFragmentDoc}
${AmountFragmentDoc}`;
export const useGetDebitLinesQuery = <
      TData = Types.GetDebitLinesQuery,
      TError = ServerError
    >(
      variables: Types.GetDebitLinesQueryVariables,
      options?: UseQueryOptions<Types.GetDebitLinesQuery, TError, TData>
    ) =>
    useQuery<Types.GetDebitLinesQuery, TError, TData>(
      ['getDebitLines', variables],
      fetcher<Types.GetDebitLinesQuery, Types.GetDebitLinesQueryVariables>(GetDebitLinesDocument, variables),
      options
    );

useGetDebitLinesQuery.getKey = (variables: Types.GetDebitLinesQueryVariables) => ['getDebitLines', variables];
;

export const useInfiniteGetDebitLinesQuery = <
      TData = Types.GetDebitLinesQuery,
      TError = ServerError
    >(
      variables: Types.GetDebitLinesQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetDebitLinesQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetDebitLinesQuery, TError, TData>(
      ['getDebitLines.infinite', variables],
      (metaData) => fetcher<Types.GetDebitLinesQuery, Types.GetDebitLinesQueryVariables>(GetDebitLinesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetDebitLinesQuery.getKey = (variables: Types.GetDebitLinesQueryVariables) => ['getDebitLines.infinite', variables];
;

export const GetIbanValidationDocument = `
    query getIbanValidation($iban: String!, $bic: String, $allowForeignIban: Boolean) {
  ibanValidation(iban: $iban, bic: $bic, allowForeignIban: $allowForeignIban) {
    bic
  }
}
    `;
export const useGetIbanValidationQuery = <
      TData = Types.GetIbanValidationQuery,
      TError = ServerError
    >(
      variables: Types.GetIbanValidationQueryVariables,
      options?: UseQueryOptions<Types.GetIbanValidationQuery, TError, TData>
    ) =>
    useQuery<Types.GetIbanValidationQuery, TError, TData>(
      ['getIbanValidation', variables],
      fetcher<Types.GetIbanValidationQuery, Types.GetIbanValidationQueryVariables>(GetIbanValidationDocument, variables),
      options
    );

useGetIbanValidationQuery.getKey = (variables: Types.GetIbanValidationQueryVariables) => ['getIbanValidation', variables];
;

export const useInfiniteGetIbanValidationQuery = <
      TData = Types.GetIbanValidationQuery,
      TError = ServerError
    >(
      variables: Types.GetIbanValidationQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetIbanValidationQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetIbanValidationQuery, TError, TData>(
      ['getIbanValidation.infinite', variables],
      (metaData) => fetcher<Types.GetIbanValidationQuery, Types.GetIbanValidationQueryVariables>(GetIbanValidationDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetIbanValidationQuery.getKey = (variables: Types.GetIbanValidationQueryVariables) => ['getIbanValidation.infinite', variables];
;

export const GetLandlordBankingInformationDocument = `
    query getLandlordBankingInformation($accountUuid: EncodedID!) {
  account(uuid: $accountUuid) {
    uuid
    quality
    bankingInformation {
      ... on CreditBankingInformation {
        creditPaymentType
        detail {
          ...bankingInformation
        }
      }
    }
  }
}
    ${BankingInformationFragmentDoc}`;
export const useGetLandlordBankingInformationQuery = <
      TData = Types.GetLandlordBankingInformationQuery,
      TError = ServerError
    >(
      variables: Types.GetLandlordBankingInformationQueryVariables,
      options?: UseQueryOptions<Types.GetLandlordBankingInformationQuery, TError, TData>
    ) =>
    useQuery<Types.GetLandlordBankingInformationQuery, TError, TData>(
      ['getLandlordBankingInformation', variables],
      fetcher<Types.GetLandlordBankingInformationQuery, Types.GetLandlordBankingInformationQueryVariables>(GetLandlordBankingInformationDocument, variables),
      options
    );

useGetLandlordBankingInformationQuery.getKey = (variables: Types.GetLandlordBankingInformationQueryVariables) => ['getLandlordBankingInformation', variables];
;

export const useInfiniteGetLandlordBankingInformationQuery = <
      TData = Types.GetLandlordBankingInformationQuery,
      TError = ServerError
    >(
      variables: Types.GetLandlordBankingInformationQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetLandlordBankingInformationQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetLandlordBankingInformationQuery, TError, TData>(
      ['getLandlordBankingInformation.infinite', variables],
      (metaData) => fetcher<Types.GetLandlordBankingInformationQuery, Types.GetLandlordBankingInformationQueryVariables>(GetLandlordBankingInformationDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetLandlordBankingInformationQuery.getKey = (variables: Types.GetLandlordBankingInformationQueryVariables) => ['getLandlordBankingInformation.infinite', variables];
;

export const GetPaymentAutomaticDocument = `
    query getPaymentAutomatic($accountUuid: EncodedID!) {
  account(uuid: $accountUuid) {
    uuid
    quality
    accounting {
      ...accountingBalance
    }
    bankingInformation {
      ... on DebitBankingInformation {
        automatic {
          ...bankingInformation
        }
      }
    }
  }
}
    ${AccountingBalanceFragmentDoc}
${AmountFragmentDoc}
${BankingInformationFragmentDoc}`;
export const useGetPaymentAutomaticQuery = <
      TData = Types.GetPaymentAutomaticQuery,
      TError = ServerError
    >(
      variables: Types.GetPaymentAutomaticQueryVariables,
      options?: UseQueryOptions<Types.GetPaymentAutomaticQuery, TError, TData>
    ) =>
    useQuery<Types.GetPaymentAutomaticQuery, TError, TData>(
      ['getPaymentAutomatic', variables],
      fetcher<Types.GetPaymentAutomaticQuery, Types.GetPaymentAutomaticQueryVariables>(GetPaymentAutomaticDocument, variables),
      options
    );

useGetPaymentAutomaticQuery.getKey = (variables: Types.GetPaymentAutomaticQueryVariables) => ['getPaymentAutomatic', variables];
;

export const useInfiniteGetPaymentAutomaticQuery = <
      TData = Types.GetPaymentAutomaticQuery,
      TError = ServerError
    >(
      variables: Types.GetPaymentAutomaticQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetPaymentAutomaticQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetPaymentAutomaticQuery, TError, TData>(
      ['getPaymentAutomatic.infinite', variables],
      (metaData) => fetcher<Types.GetPaymentAutomaticQuery, Types.GetPaymentAutomaticQueryVariables>(GetPaymentAutomaticDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetPaymentAutomaticQuery.getKey = (variables: Types.GetPaymentAutomaticQueryVariables) => ['getPaymentAutomatic.infinite', variables];
;

export const GetPaymentLinesDocument = `
    query getPaymentLines($accountUuid: EncodedID!, $pageOptions: PageOptions) {
  account(uuid: $accountUuid) {
    uuid
    accounting {
      payments(pageOptions: $pageOptions) {
        totalCount
        pageInfo {
          ...pageInfo
        }
        edges {
          node {
            ...paymentLine
          }
        }
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${PaymentLineFragmentDoc}
${AmountFragmentDoc}`;
export const useGetPaymentLinesQuery = <
      TData = Types.GetPaymentLinesQuery,
      TError = ServerError
    >(
      variables: Types.GetPaymentLinesQueryVariables,
      options?: UseQueryOptions<Types.GetPaymentLinesQuery, TError, TData>
    ) =>
    useQuery<Types.GetPaymentLinesQuery, TError, TData>(
      ['getPaymentLines', variables],
      fetcher<Types.GetPaymentLinesQuery, Types.GetPaymentLinesQueryVariables>(GetPaymentLinesDocument, variables),
      options
    );

useGetPaymentLinesQuery.getKey = (variables: Types.GetPaymentLinesQueryVariables) => ['getPaymentLines', variables];
;

export const useInfiniteGetPaymentLinesQuery = <
      TData = Types.GetPaymentLinesQuery,
      TError = ServerError
    >(
      variables: Types.GetPaymentLinesQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetPaymentLinesQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetPaymentLinesQuery, TError, TData>(
      ['getPaymentLines.infinite', variables],
      (metaData) => fetcher<Types.GetPaymentLinesQuery, Types.GetPaymentLinesQueryVariables>(GetPaymentLinesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetPaymentLinesQuery.getKey = (variables: Types.GetPaymentLinesQueryVariables) => ['getPaymentLines.infinite', variables];
;

export const GetPaymentModeDataDocument = `
    query getPaymentModeData($accountUuid: EncodedID!) {
  account(uuid: $accountUuid) {
    uuid
    quality
    bankingInformation {
      ... on DebitBankingInformation {
        automatic {
          ...bankingInformation
        }
        onDemand {
          ...bankingInformation
        }
        hasDirectDebitPayments
      }
    }
    accounting {
      ...accountingBalance
    }
    customer {
      isValidAddress
    }
  }
}
    ${BankingInformationFragmentDoc}
${AccountingBalanceFragmentDoc}
${AmountFragmentDoc}`;
export const useGetPaymentModeDataQuery = <
      TData = Types.GetPaymentModeDataQuery,
      TError = ServerError
    >(
      variables: Types.GetPaymentModeDataQueryVariables,
      options?: UseQueryOptions<Types.GetPaymentModeDataQuery, TError, TData>
    ) =>
    useQuery<Types.GetPaymentModeDataQuery, TError, TData>(
      ['getPaymentModeData', variables],
      fetcher<Types.GetPaymentModeDataQuery, Types.GetPaymentModeDataQueryVariables>(GetPaymentModeDataDocument, variables),
      options
    );

useGetPaymentModeDataQuery.getKey = (variables: Types.GetPaymentModeDataQueryVariables) => ['getPaymentModeData', variables];
;

export const useInfiniteGetPaymentModeDataQuery = <
      TData = Types.GetPaymentModeDataQuery,
      TError = ServerError
    >(
      variables: Types.GetPaymentModeDataQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetPaymentModeDataQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetPaymentModeDataQuery, TError, TData>(
      ['getPaymentModeData.infinite', variables],
      (metaData) => fetcher<Types.GetPaymentModeDataQuery, Types.GetPaymentModeDataQueryVariables>(GetPaymentModeDataDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetPaymentModeDataQuery.getKey = (variables: Types.GetPaymentModeDataQueryVariables) => ['getPaymentModeData.infinite', variables];
;

export const GetPaymentOnDemandDocument = `
    query getPaymentOnDemand($accountUuid: EncodedID!) {
  account(uuid: $accountUuid) {
    uuid
    quality
    accounting {
      ...accountingBalance
    }
    bankingInformation {
      ... on DebitBankingInformation {
        onDemand {
          ...bankingInformation
        }
      }
    }
  }
}
    ${AccountingBalanceFragmentDoc}
${AmountFragmentDoc}
${BankingInformationFragmentDoc}`;
export const useGetPaymentOnDemandQuery = <
      TData = Types.GetPaymentOnDemandQuery,
      TError = ServerError
    >(
      variables: Types.GetPaymentOnDemandQueryVariables,
      options?: UseQueryOptions<Types.GetPaymentOnDemandQuery, TError, TData>
    ) =>
    useQuery<Types.GetPaymentOnDemandQuery, TError, TData>(
      ['getPaymentOnDemand', variables],
      fetcher<Types.GetPaymentOnDemandQuery, Types.GetPaymentOnDemandQueryVariables>(GetPaymentOnDemandDocument, variables),
      options
    );

useGetPaymentOnDemandQuery.getKey = (variables: Types.GetPaymentOnDemandQueryVariables) => ['getPaymentOnDemand', variables];
;

export const useInfiniteGetPaymentOnDemandQuery = <
      TData = Types.GetPaymentOnDemandQuery,
      TError = ServerError
    >(
      variables: Types.GetPaymentOnDemandQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetPaymentOnDemandQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetPaymentOnDemandQuery, TError, TData>(
      ['getPaymentOnDemand.infinite', variables],
      (metaData) => fetcher<Types.GetPaymentOnDemandQuery, Types.GetPaymentOnDemandQueryVariables>(GetPaymentOnDemandDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetPaymentOnDemandQuery.getKey = (variables: Types.GetPaymentOnDemandQueryVariables) => ['getPaymentOnDemand.infinite', variables];
;

export const ResiliateSepaMandateDocument = `
    mutation resiliateSepaMandate($accountUuid: EncodedID!, $bankInformationId: ID!) {
  resiliateSepaMandate(
    bankInformationId: $bankInformationId
    accountUuid: $accountUuid
  ) {
    debitsCancelled {
      ...resiliateMandateDebit
    }
    debitsNotCancelled {
      ...resiliateMandateDebit
    }
  }
}
    ${ResiliateMandateDebitFragmentDoc}
${AmountFragmentDoc}`;
export const useResiliateSepaMandateMutation = <
      TError = ServerError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.ResiliateSepaMandateMutation, TError, Types.ResiliateSepaMandateMutationVariables, TContext>) =>
    useMutation<Types.ResiliateSepaMandateMutation, TError, Types.ResiliateSepaMandateMutationVariables, TContext>(
      ['resiliateSepaMandate'],
      (variables?: Types.ResiliateSepaMandateMutationVariables) => fetcher<Types.ResiliateSepaMandateMutation, Types.ResiliateSepaMandateMutationVariables>(ResiliateSepaMandateDocument, variables)(),
      options
    );
export const UpdateCreditBankingInformationDocument = `
    mutation updateCreditBankingInformation($accountUuid: EncodedID!, $request: UpdateCreditBankingInformationRequest!) {
  updateCreditBankingInformation(accountUuid: $accountUuid, request: $request) {
    creditPaymentType
    detail {
      ...bankingInformation
    }
  }
}
    ${BankingInformationFragmentDoc}`;
export const useUpdateCreditBankingInformationMutation = <
      TError = ServerError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateCreditBankingInformationMutation, TError, Types.UpdateCreditBankingInformationMutationVariables, TContext>) =>
    useMutation<Types.UpdateCreditBankingInformationMutation, TError, Types.UpdateCreditBankingInformationMutationVariables, TContext>(
      ['updateCreditBankingInformation'],
      (variables?: Types.UpdateCreditBankingInformationMutationVariables) => fetcher<Types.UpdateCreditBankingInformationMutation, Types.UpdateCreditBankingInformationMutationVariables>(UpdateCreditBankingInformationDocument, variables)(),
      options
    );
export const CreatePostalVoteDraftDocument = `
    mutation createPostalVoteDraft($accountUuid: EncodedID!, $generalAssemblyId: String!, $votes: [VoteInput!]!) {
  createPostalVoteDraft(
    accountUuid: $accountUuid
    generalAssemblyId: $generalAssemblyId
    votes: $votes
  ) {
    status
    resolutionId
    candidate
    isSecondVote
  }
}
    `;
export const useCreatePostalVoteDraftMutation = <
      TError = ServerError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreatePostalVoteDraftMutation, TError, Types.CreatePostalVoteDraftMutationVariables, TContext>) =>
    useMutation<Types.CreatePostalVoteDraftMutation, TError, Types.CreatePostalVoteDraftMutationVariables, TContext>(
      ['createPostalVoteDraft'],
      (variables?: Types.CreatePostalVoteDraftMutationVariables) => fetcher<Types.CreatePostalVoteDraftMutation, Types.CreatePostalVoteDraftMutationVariables>(CreatePostalVoteDraftDocument, variables)(),
      options
    );
export const DeletePostalVoteDocument = `
    mutation deletePostalVote($accountUuid: EncodedID!, $generalAssemblyId: String!) {
  deletePostalVote(
    accountUuid: $accountUuid
    generalAssemblyId: $generalAssemblyId
  )
}
    `;
export const useDeletePostalVoteMutation = <
      TError = ServerError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DeletePostalVoteMutation, TError, Types.DeletePostalVoteMutationVariables, TContext>) =>
    useMutation<Types.DeletePostalVoteMutation, TError, Types.DeletePostalVoteMutationVariables, TContext>(
      ['deletePostalVote'],
      (variables?: Types.DeletePostalVoteMutationVariables) => fetcher<Types.DeletePostalVoteMutation, Types.DeletePostalVoteMutationVariables>(DeletePostalVoteDocument, variables)(),
      options
    );
export const GetGeneralAssemblyDashbordDocument = `
    query getGeneralAssemblyDashbord($uuid: EncodedID!, $generalAssemblyId: String!) {
  resolutions(accountUuid: $uuid, generalAssemblyId: $generalAssemblyId) {
    id
    votable
    typeNumber
    label
    text
    voteKind
    proxyNumber
    proxySubNumber
    candidate
    isCouncilMemberVote
    isEligibleSecondVote
    hasVoteResolution
  }
  postalVoteDraft(accountUuid: $uuid, generalAssemblyId: $generalAssemblyId) {
    resolutionId
    candidate
    status
    isSecondVote
  }
  postalVote(accountUuid: $uuid, generalAssemblyId: $generalAssemblyId) {
    voteReceptionDate
    voteDocument {
      ...document
    }
  }
}
    ${DocumentFragmentDoc}`;
export const useGetGeneralAssemblyDashbordQuery = <
      TData = Types.GetGeneralAssemblyDashbordQuery,
      TError = ServerError
    >(
      variables: Types.GetGeneralAssemblyDashbordQueryVariables,
      options?: UseQueryOptions<Types.GetGeneralAssemblyDashbordQuery, TError, TData>
    ) =>
    useQuery<Types.GetGeneralAssemblyDashbordQuery, TError, TData>(
      ['getGeneralAssemblyDashbord', variables],
      fetcher<Types.GetGeneralAssemblyDashbordQuery, Types.GetGeneralAssemblyDashbordQueryVariables>(GetGeneralAssemblyDashbordDocument, variables),
      options
    );

useGetGeneralAssemblyDashbordQuery.getKey = (variables: Types.GetGeneralAssemblyDashbordQueryVariables) => ['getGeneralAssemblyDashbord', variables];
;

export const useInfiniteGetGeneralAssemblyDashbordQuery = <
      TData = Types.GetGeneralAssemblyDashbordQuery,
      TError = ServerError
    >(
      variables: Types.GetGeneralAssemblyDashbordQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetGeneralAssemblyDashbordQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetGeneralAssemblyDashbordQuery, TError, TData>(
      ['getGeneralAssemblyDashbord.infinite', variables],
      (metaData) => fetcher<Types.GetGeneralAssemblyDashbordQuery, Types.GetGeneralAssemblyDashbordQueryVariables>(GetGeneralAssemblyDashbordDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetGeneralAssemblyDashbordQuery.getKey = (variables: Types.GetGeneralAssemblyDashbordQueryVariables) => ['getGeneralAssemblyDashbord.infinite', variables];
;

export const StartSignaturePostalVoteResolutionsDocument = `
    mutation startSignaturePostalVoteResolutions($accountUuid: EncodedID!, $generalAssemblyId: String!, $brandCode: String!) {
  startSignaturePostalVoteResolutions(
    accountUuid: $accountUuid
    generalAssemblyId: $generalAssemblyId
    brandCode: $brandCode
  ) {
    transactionId
    signUrl
    document {
      ...document
    }
  }
}
    ${DocumentFragmentDoc}`;
export const useStartSignaturePostalVoteResolutionsMutation = <
      TError = ServerError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.StartSignaturePostalVoteResolutionsMutation, TError, Types.StartSignaturePostalVoteResolutionsMutationVariables, TContext>) =>
    useMutation<Types.StartSignaturePostalVoteResolutionsMutation, TError, Types.StartSignaturePostalVoteResolutionsMutationVariables, TContext>(
      ['startSignaturePostalVoteResolutions'],
      (variables?: Types.StartSignaturePostalVoteResolutionsMutationVariables) => fetcher<Types.StartSignaturePostalVoteResolutionsMutation, Types.StartSignaturePostalVoteResolutionsMutationVariables>(StartSignaturePostalVoteResolutionsDocument, variables)(),
      options
    );
export const TerminateSignaturePostalVoteResolutionsDocument = `
    mutation terminateSignaturePostalVoteResolutions($accountUuid: EncodedID!, $generalAssemblyId: String!, $transactionId: String!) {
  terminateSignaturePostalVoteResolutions(
    accountUuid: $accountUuid
    generalAssemblyId: $generalAssemblyId
    transactionId: $transactionId
  ) {
    transactionId
    document {
      ...document
    }
  }
}
    ${DocumentFragmentDoc}`;
export const useTerminateSignaturePostalVoteResolutionsMutation = <
      TError = ServerError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.TerminateSignaturePostalVoteResolutionsMutation, TError, Types.TerminateSignaturePostalVoteResolutionsMutationVariables, TContext>) =>
    useMutation<Types.TerminateSignaturePostalVoteResolutionsMutation, TError, Types.TerminateSignaturePostalVoteResolutionsMutationVariables, TContext>(
      ['terminateSignaturePostalVoteResolutions'],
      (variables?: Types.TerminateSignaturePostalVoteResolutionsMutationVariables) => fetcher<Types.TerminateSignaturePostalVoteResolutionsMutation, Types.TerminateSignaturePostalVoteResolutionsMutationVariables>(TerminateSignaturePostalVoteResolutionsDocument, variables)(),
      options
    );
export const GetPropertyAssetsDocument = `
    query getPropertyAssets($accountUuid: EncodedID!, $buildingIds: [String!]!, $assetsYear: Int!) {
  propertyAssets(
    accountUuid: $accountUuid
    buildingIds: $buildingIds
    assetsYear: $assetsYear
  ) {
    ...propertyAssets
  }
}
    ${PropertyAssetsFragmentDoc}
${AmountFragmentDoc}`;
export const useGetPropertyAssetsQuery = <
      TData = Types.GetPropertyAssetsQuery,
      TError = ServerError
    >(
      variables: Types.GetPropertyAssetsQueryVariables,
      options?: UseQueryOptions<Types.GetPropertyAssetsQuery, TError, TData>
    ) =>
    useQuery<Types.GetPropertyAssetsQuery, TError, TData>(
      ['getPropertyAssets', variables],
      fetcher<Types.GetPropertyAssetsQuery, Types.GetPropertyAssetsQueryVariables>(GetPropertyAssetsDocument, variables),
      options
    );

useGetPropertyAssetsQuery.getKey = (variables: Types.GetPropertyAssetsQueryVariables) => ['getPropertyAssets', variables];
;

export const useInfiniteGetPropertyAssetsQuery = <
      TData = Types.GetPropertyAssetsQuery,
      TError = ServerError
    >(
      variables: Types.GetPropertyAssetsQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetPropertyAssetsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetPropertyAssetsQuery, TError, TData>(
      ['getPropertyAssets.infinite', variables],
      (metaData) => fetcher<Types.GetPropertyAssetsQuery, Types.GetPropertyAssetsQueryVariables>(GetPropertyAssetsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetPropertyAssetsQuery.getKey = (variables: Types.GetPropertyAssetsQueryVariables) => ['getPropertyAssets.infinite', variables];
;

export const ComputeExpectedLessorPropertyTaxDocument = `
    mutation computeExpectedLessorPropertyTax($accountUuid: EncodedID!, $request: ComputeExpectedLessorPropertyTaxRequest!) {
  computeExpectedLessorPropertyTax(accountUuid: $accountUuid, request: $request)
}
    `;
export const useComputeExpectedLessorPropertyTaxMutation = <
      TError = ServerError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.ComputeExpectedLessorPropertyTaxMutation, TError, Types.ComputeExpectedLessorPropertyTaxMutationVariables, TContext>) =>
    useMutation<Types.ComputeExpectedLessorPropertyTaxMutation, TError, Types.ComputeExpectedLessorPropertyTaxMutationVariables, TContext>(
      ['computeExpectedLessorPropertyTax'],
      (variables?: Types.ComputeExpectedLessorPropertyTaxMutationVariables) => fetcher<Types.ComputeExpectedLessorPropertyTaxMutation, Types.ComputeExpectedLessorPropertyTaxMutationVariables>(ComputeExpectedLessorPropertyTaxDocument, variables)(),
      options
    );
export const GetExpectedLandlordPropertyTaxesDocument = `
    query getExpectedLandlordPropertyTaxes($accountUuid: EncodedID!, $request: GetExpectedLandlordPropertyTaxesRequest) {
  expectedLandlordPropertyTaxes(accountUuid: $accountUuid, request: $request) {
    item {
      ...expectedLandlordPropertyTax
    }
  }
}
    ${ExpectedLandlordPropertyTaxFragmentDoc}
${ExpectedPropertyTaxBuildingFragmentDoc}
${AddressFragmentDoc}`;
export const useGetExpectedLandlordPropertyTaxesQuery = <
      TData = Types.GetExpectedLandlordPropertyTaxesQuery,
      TError = ServerError
    >(
      variables: Types.GetExpectedLandlordPropertyTaxesQueryVariables,
      options?: UseQueryOptions<Types.GetExpectedLandlordPropertyTaxesQuery, TError, TData>
    ) =>
    useQuery<Types.GetExpectedLandlordPropertyTaxesQuery, TError, TData>(
      ['getExpectedLandlordPropertyTaxes', variables],
      fetcher<Types.GetExpectedLandlordPropertyTaxesQuery, Types.GetExpectedLandlordPropertyTaxesQueryVariables>(GetExpectedLandlordPropertyTaxesDocument, variables),
      options
    );

useGetExpectedLandlordPropertyTaxesQuery.getKey = (variables: Types.GetExpectedLandlordPropertyTaxesQueryVariables) => ['getExpectedLandlordPropertyTaxes', variables];
;

export const useInfiniteGetExpectedLandlordPropertyTaxesQuery = <
      TData = Types.GetExpectedLandlordPropertyTaxesQuery,
      TError = ServerError
    >(
      variables: Types.GetExpectedLandlordPropertyTaxesQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetExpectedLandlordPropertyTaxesQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetExpectedLandlordPropertyTaxesQuery, TError, TData>(
      ['getExpectedLandlordPropertyTaxes.infinite', variables],
      (metaData) => fetcher<Types.GetExpectedLandlordPropertyTaxesQuery, Types.GetExpectedLandlordPropertyTaxesQueryVariables>(GetExpectedLandlordPropertyTaxesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetExpectedLandlordPropertyTaxesQuery.getKey = (variables: Types.GetExpectedLandlordPropertyTaxesQueryVariables) => ['getExpectedLandlordPropertyTaxes.infinite', variables];
;

export const GetExpectedLandlordPropertyTaxesFiscalYearsDocument = `
    query getExpectedLandlordPropertyTaxesFiscalYears($accountUuid: EncodedID!) {
  expectedLandlordPropertyTaxesFiscalYears(accountUuid: $accountUuid) {
    fiscalYears {
      fiscalYear
      status
    }
  }
}
    `;
export const useGetExpectedLandlordPropertyTaxesFiscalYearsQuery = <
      TData = Types.GetExpectedLandlordPropertyTaxesFiscalYearsQuery,
      TError = ServerError
    >(
      variables: Types.GetExpectedLandlordPropertyTaxesFiscalYearsQueryVariables,
      options?: UseQueryOptions<Types.GetExpectedLandlordPropertyTaxesFiscalYearsQuery, TError, TData>
    ) =>
    useQuery<Types.GetExpectedLandlordPropertyTaxesFiscalYearsQuery, TError, TData>(
      ['getExpectedLandlordPropertyTaxesFiscalYears', variables],
      fetcher<Types.GetExpectedLandlordPropertyTaxesFiscalYearsQuery, Types.GetExpectedLandlordPropertyTaxesFiscalYearsQueryVariables>(GetExpectedLandlordPropertyTaxesFiscalYearsDocument, variables),
      options
    );

useGetExpectedLandlordPropertyTaxesFiscalYearsQuery.getKey = (variables: Types.GetExpectedLandlordPropertyTaxesFiscalYearsQueryVariables) => ['getExpectedLandlordPropertyTaxesFiscalYears', variables];
;

export const useInfiniteGetExpectedLandlordPropertyTaxesFiscalYearsQuery = <
      TData = Types.GetExpectedLandlordPropertyTaxesFiscalYearsQuery,
      TError = ServerError
    >(
      variables: Types.GetExpectedLandlordPropertyTaxesFiscalYearsQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetExpectedLandlordPropertyTaxesFiscalYearsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetExpectedLandlordPropertyTaxesFiscalYearsQuery, TError, TData>(
      ['getExpectedLandlordPropertyTaxesFiscalYears.infinite', variables],
      (metaData) => fetcher<Types.GetExpectedLandlordPropertyTaxesFiscalYearsQuery, Types.GetExpectedLandlordPropertyTaxesFiscalYearsQueryVariables>(GetExpectedLandlordPropertyTaxesFiscalYearsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetExpectedLandlordPropertyTaxesFiscalYearsQuery.getKey = (variables: Types.GetExpectedLandlordPropertyTaxesFiscalYearsQueryVariables) => ['getExpectedLandlordPropertyTaxesFiscalYears.infinite', variables];
;

export const GetExpectedLessorPropertyTaxComputeProgressDocument = `
    query getExpectedLessorPropertyTaxComputeProgress($accountUuid: EncodedID!, $request: GetExpectedLessorPropertyTaxComputeProgressRequest!) {
  expectedLessorPropertyTaxComputeProgress(
    accountUuid: $accountUuid
    request: $request
  ) {
    computeProgress
  }
}
    `;
export const useGetExpectedLessorPropertyTaxComputeProgressQuery = <
      TData = Types.GetExpectedLessorPropertyTaxComputeProgressQuery,
      TError = ServerError
    >(
      variables: Types.GetExpectedLessorPropertyTaxComputeProgressQueryVariables,
      options?: UseQueryOptions<Types.GetExpectedLessorPropertyTaxComputeProgressQuery, TError, TData>
    ) =>
    useQuery<Types.GetExpectedLessorPropertyTaxComputeProgressQuery, TError, TData>(
      ['getExpectedLessorPropertyTaxComputeProgress', variables],
      fetcher<Types.GetExpectedLessorPropertyTaxComputeProgressQuery, Types.GetExpectedLessorPropertyTaxComputeProgressQueryVariables>(GetExpectedLessorPropertyTaxComputeProgressDocument, variables),
      options
    );

useGetExpectedLessorPropertyTaxComputeProgressQuery.getKey = (variables: Types.GetExpectedLessorPropertyTaxComputeProgressQueryVariables) => ['getExpectedLessorPropertyTaxComputeProgress', variables];
;

export const useInfiniteGetExpectedLessorPropertyTaxComputeProgressQuery = <
      TData = Types.GetExpectedLessorPropertyTaxComputeProgressQuery,
      TError = ServerError
    >(
      variables: Types.GetExpectedLessorPropertyTaxComputeProgressQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetExpectedLessorPropertyTaxComputeProgressQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetExpectedLessorPropertyTaxComputeProgressQuery, TError, TData>(
      ['getExpectedLessorPropertyTaxComputeProgress.infinite', variables],
      (metaData) => fetcher<Types.GetExpectedLessorPropertyTaxComputeProgressQuery, Types.GetExpectedLessorPropertyTaxComputeProgressQueryVariables>(GetExpectedLessorPropertyTaxComputeProgressDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetExpectedLessorPropertyTaxComputeProgressQuery.getKey = (variables: Types.GetExpectedLessorPropertyTaxComputeProgressQueryVariables) => ['getExpectedLessorPropertyTaxComputeProgress.infinite', variables];
;

export const UploadLandlordPropertyTaxDocument = `
    mutation uploadLandlordPropertyTax($accountUuid: EncodedID!, $request: UploadLandLordPropertyTaxInput!) {
  uploadLandlordPropertyTax(accountUuid: $accountUuid, request: $request) {
    id
  }
}
    `;
export const useUploadLandlordPropertyTaxMutation = <
      TError = ServerError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UploadLandlordPropertyTaxMutation, TError, Types.UploadLandlordPropertyTaxMutationVariables, TContext>) =>
    useMutation<Types.UploadLandlordPropertyTaxMutation, TError, Types.UploadLandlordPropertyTaxMutationVariables, TContext>(
      ['uploadLandlordPropertyTax'],
      (variables?: Types.UploadLandlordPropertyTaxMutationVariables) => fetcher<Types.UploadLandlordPropertyTaxMutation, Types.UploadLandlordPropertyTaxMutationVariables>(UploadLandlordPropertyTaxDocument, variables)(),
      options
    );
export const AddMessageTicketDocument = `
    mutation addMessageTicket($ticketId: String!, $message: MessageTicketInput!, $documents: [Upload!]) {
  addMessageTicket(ticketId: $ticketId, message: $message, documents: $documents) {
    ...ticket
  }
}
    ${TicketFragmentDoc}
${TicketMessageFragmentDoc}
${DocumentFragmentDoc}
${ManagerFragmentDoc}
${TicketActivityFragmentDoc}
${AddressFragmentDoc}
${UnitFragmentDoc}`;
export const useAddMessageTicketMutation = <
      TError = ServerError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.AddMessageTicketMutation, TError, Types.AddMessageTicketMutationVariables, TContext>) =>
    useMutation<Types.AddMessageTicketMutation, TError, Types.AddMessageTicketMutationVariables, TContext>(
      ['addMessageTicket'],
      (variables?: Types.AddMessageTicketMutationVariables) => fetcher<Types.AddMessageTicketMutation, Types.AddMessageTicketMutationVariables>(AddMessageTicketDocument, variables)(),
      options
    );
export const ApproveTicketDocument = `
    mutation approveTicket($ticketId: String!) {
  approveTicket(ticketId: $ticketId) {
    ...ticket
  }
}
    ${TicketFragmentDoc}
${TicketMessageFragmentDoc}
${DocumentFragmentDoc}
${ManagerFragmentDoc}
${TicketActivityFragmentDoc}
${AddressFragmentDoc}
${UnitFragmentDoc}`;
export const useApproveTicketMutation = <
      TError = ServerError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.ApproveTicketMutation, TError, Types.ApproveTicketMutationVariables, TContext>) =>
    useMutation<Types.ApproveTicketMutation, TError, Types.ApproveTicketMutationVariables, TContext>(
      ['approveTicket'],
      (variables?: Types.ApproveTicketMutationVariables) => fetcher<Types.ApproveTicketMutation, Types.ApproveTicketMutationVariables>(ApproveTicketDocument, variables)(),
      options
    );
export const CreateTicketDocument = `
    mutation createTicket($accountUuid: EncodedID!, $request: CreateTicketInput!, $documents: [Upload!]) {
  createTicket(
    accountUuid: $accountUuid
    request: $request
    documents: $documents
  ) {
    ...ticket
  }
}
    ${TicketFragmentDoc}
${TicketMessageFragmentDoc}
${DocumentFragmentDoc}
${ManagerFragmentDoc}
${TicketActivityFragmentDoc}
${AddressFragmentDoc}
${UnitFragmentDoc}`;
export const useCreateTicketMutation = <
      TError = ServerError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateTicketMutation, TError, Types.CreateTicketMutationVariables, TContext>) =>
    useMutation<Types.CreateTicketMutation, TError, Types.CreateTicketMutationVariables, TContext>(
      ['createTicket'],
      (variables?: Types.CreateTicketMutationVariables) => fetcher<Types.CreateTicketMutation, Types.CreateTicketMutationVariables>(CreateTicketDocument, variables)(),
      options
    );
export const GetTicketDocument = `
    query getTicket($ticketId: String!) {
  ticket(ticketId: $ticketId) {
    ...ticket
  }
}
    ${TicketFragmentDoc}
${TicketMessageFragmentDoc}
${DocumentFragmentDoc}
${ManagerFragmentDoc}
${TicketActivityFragmentDoc}
${AddressFragmentDoc}
${UnitFragmentDoc}`;
export const useGetTicketQuery = <
      TData = Types.GetTicketQuery,
      TError = ServerError
    >(
      variables: Types.GetTicketQueryVariables,
      options?: UseQueryOptions<Types.GetTicketQuery, TError, TData>
    ) =>
    useQuery<Types.GetTicketQuery, TError, TData>(
      ['getTicket', variables],
      fetcher<Types.GetTicketQuery, Types.GetTicketQueryVariables>(GetTicketDocument, variables),
      options
    );

useGetTicketQuery.getKey = (variables: Types.GetTicketQueryVariables) => ['getTicket', variables];
;

export const useInfiniteGetTicketQuery = <
      TData = Types.GetTicketQuery,
      TError = ServerError
    >(
      variables: Types.GetTicketQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetTicketQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetTicketQuery, TError, TData>(
      ['getTicket.infinite', variables],
      (metaData) => fetcher<Types.GetTicketQuery, Types.GetTicketQueryVariables>(GetTicketDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetTicketQuery.getKey = (variables: Types.GetTicketQueryVariables) => ['getTicket.infinite', variables];
;

export const GetTicketsDocument = `
    query getTickets($accountUuid: EncodedID!, $status: [TicketStatusEnum!], $category: [TicketCategoryEnum!], $createdDateFrom: String, $createdDateTo: String, $ticketNumber: String, $source: [TicketSourceEnum!], $isRead: Boolean, $first: Int, $before: Cursor, $after: Cursor, $sortBy: [SortByType!]) {
  tickets(
    accountUuid: $accountUuid
    first: $first
    before: $before
    after: $after
    status: $status
    category: $category
    createdDateFrom: $createdDateFrom
    createdDateTo: $createdDateTo
    ticketNumber: $ticketNumber
    source: $source
    isRead: $isRead
    sortBy: $sortBy
  ) {
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
    edges {
      node {
        ...ticket
      }
    }
  }
}
    ${TicketFragmentDoc}
${TicketMessageFragmentDoc}
${DocumentFragmentDoc}
${ManagerFragmentDoc}
${TicketActivityFragmentDoc}
${AddressFragmentDoc}
${UnitFragmentDoc}`;
export const useGetTicketsQuery = <
      TData = Types.GetTicketsQuery,
      TError = ServerError
    >(
      variables: Types.GetTicketsQueryVariables,
      options?: UseQueryOptions<Types.GetTicketsQuery, TError, TData>
    ) =>
    useQuery<Types.GetTicketsQuery, TError, TData>(
      ['getTickets', variables],
      fetcher<Types.GetTicketsQuery, Types.GetTicketsQueryVariables>(GetTicketsDocument, variables),
      options
    );

useGetTicketsQuery.getKey = (variables: Types.GetTicketsQueryVariables) => ['getTickets', variables];
;

export const useInfiniteGetTicketsQuery = <
      TData = Types.GetTicketsQuery,
      TError = ServerError
    >(
      variables: Types.GetTicketsQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetTicketsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetTicketsQuery, TError, TData>(
      ['getTickets.infinite', variables],
      (metaData) => fetcher<Types.GetTicketsQuery, Types.GetTicketsQueryVariables>(GetTicketsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetTicketsQuery.getKey = (variables: Types.GetTicketsQueryVariables) => ['getTickets.infinite', variables];
;

export const UpdateIsReadTicketDocument = `
    mutation updateIsReadTicket($ticketId: String!, $isRead: Boolean!) {
  updateIsReadTicket(ticketId: $ticketId, isRead: $isRead) {
    ...ticket
  }
}
    ${TicketFragmentDoc}
${TicketMessageFragmentDoc}
${DocumentFragmentDoc}
${ManagerFragmentDoc}
${TicketActivityFragmentDoc}
${AddressFragmentDoc}
${UnitFragmentDoc}`;
export const useUpdateIsReadTicketMutation = <
      TError = ServerError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateIsReadTicketMutation, TError, Types.UpdateIsReadTicketMutationVariables, TContext>) =>
    useMutation<Types.UpdateIsReadTicketMutation, TError, Types.UpdateIsReadTicketMutationVariables, TContext>(
      ['updateIsReadTicket'],
      (variables?: Types.UpdateIsReadTicketMutationVariables) => fetcher<Types.UpdateIsReadTicketMutation, Types.UpdateIsReadTicketMutationVariables>(UpdateIsReadTicketDocument, variables)(),
      options
    );