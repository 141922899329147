import { Box, BoxProps, Typography } from "@mui/material";
import { rem } from "polished";
import React from "react";
import styled from "styled-components";

import { hasDigit, hasLowerCase, hasUpperCase, isLengthEnought } from "src/utils";

import { Icon } from "../Icon";

const conditions = [
  { test: hasUpperCase, message: "Doit contenir une majuscule" },
  { test: hasLowerCase, message: "Doit contenir une minuscule" },
  { test: hasDigit, message: "Doit contenir un chiffre" },
  { test: isLengthEnought, message: "Etre composé d'un minimum de 8 caractères" },
];

export const StyledIconContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${(props) => props.theme.spacing(2)};
  width: ${rem(25)};
  height: ${rem(25)};
  border-radius: 50%;
`;

export const StyledIcon = styled(Icon)`
  fill: ${(props) => props.theme.palette.white.main};
`;

interface Props extends BoxProps {
  password: string;
}

export const PasswordStrengthIndicator = ({ password, ...boxProps }: Props) => {
  const tests = conditions.map((condition) => {
    return {
      ...condition,
      result: condition.test(password),
    };
  });

  return (
    <Box {...boxProps} sx={{ p: 2, backgroundColor: "gray.light", ...boxProps.sx }}>
      <Typography>
        Pour la <b>sécurité de votre compte</b>, votre mot de passe :
      </Typography>
      {tests.map((test, index) => {
        return (
          <Box
            data-cy-password-test={test.test.name}
            data-cy-password-valid={test.result}
            key={index}
            sx={{ display: "flex", alignItems: "center", mt: 1 }}
          >
            <StyledIconContainer sx={{ backgroundColor: test.result ? "success.main" : "#99b2cb" }}>
              <StyledIcon name="check" size={16} />
            </StyledIconContainer>
            <Typography
              sx={{ fontWeight: "bold", color: test.result ? "secondary.main" : "#99b2cb" }}
            >
              {test.message}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};
