import { Box, BoxProps } from "@mui/material";
import { darken, rem } from "polished";
import React from "react";
import styled from "styled-components";

import { AmountFragment } from "src/graphql/generated/types";
import { formatAmount } from "src/utils";

import { amountCellWidth } from "../../../constants";
import { AmountLabel } from "../AmountLabel";

export const FirstLevelRowStyled = styled(Box)`
  cursor: pointer;
  background: ${(props) => props.theme.palette.blue.light};
  color: white;
  display: flex;

  &:hover {
    background: ${(props) => darken(0.1, props.theme.palette.blue.light || "")};
  }

  > div {
    width: ${rem(amountCellWidth)};
    padding: ${(props) => props.theme.spacing(1.5)};
    border-left: 1px solid #ccc;
    text-align: center;
    font-weight: 500;

    &:nth-child(1) {
      flex: 1;
      width: auto;
      display: flex;
      align-items: center;
      border-left: 0;
      text-align: left;
    }
  }
`;

type Props = {
  toAllocate: AmountFragment;
  vat: AmountFragment;
  recoverable: AmountFragment;
} & BoxProps;

export const FirstLevelRow = ({ children, toAllocate, vat, recoverable, ...boxProps }: Props) => {
  return (
    <FirstLevelRowStyled {...boxProps}>
      <div>{children}</div>
      <div>
        <AmountLabel>A répartir</AmountLabel>
        {formatAmount(toAllocate)}
      </div>
      <div>
        <AmountLabel>TVA</AmountLabel>
        {formatAmount(vat)}
      </div>
      <div>
        <AmountLabel>Récupérable</AmountLabel>
        {formatAmount(recoverable)}
      </div>
    </FirstLevelRowStyled>
  );
};
