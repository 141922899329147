import { DateType } from "src/components";

const START_YEAR = 2000;
const END_YEAR = 2099;

// exclude special (accented caracters) from \u00C0 (unicode U+00C0 (À)) to \u00C0 (unicode U+00FF (ÿ))
export const emailPattern = /^(([^<>()[\]\\.,;:\u00C0-\u00FF\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const getPastYearOptions = (startYear = START_YEAR): Array<number> => {
  const currentYear = new Date().getFullYear();
  const numberOfYears = currentYear - startYear + 1;

  return [...Array(numberOfYears)].map((_, index) => currentYear - index);
};

export const getFutureYearOptions = (endYear = END_YEAR): Array<number> => {
  const currentYear = new Date().getFullYear();
  const numberOfYears = endYear - currentYear + 1;

  return [...Array(numberOfYears)].map((_, index) => currentYear + index);
};

export const formatYearMonthFilter = (date: DateType) => {
  return {
    fromDate: date
      ? new Date(date.year, date.month ? date.month - 1 : 0, 1).toISOString()
      : undefined,
    toDate: date ? new Date(date.year, date.month || 12, 0, 23, 59, 59).toISOString() : undefined,
  };
};
