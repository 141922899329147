import { CircularProgress } from "@mui/material";
import { rem } from "polished";
import React from "react";
import styled from "styled-components";

interface Props {
  className?: string;
}

const StyledLoaderContainer = styled.div`
  padding: ${rem(100)};
  display: flex;
  justify-content: center;
`;

export const Loader = ({ className }: Props) => {
  return (
    <StyledLoaderContainer className={className}>
      <CircularProgress />
    </StyledLoaderContainer>
  );
};
