import React, { ReactNode } from "react";

import { ErrorGlobal, Loader } from "src/components";
import { API_MAINTENANCE_CODE, REACT_QUERY_STALE_TIME } from "src/constants";
import { useGetAccountQuery } from "src/graphql/generated/queries";
import { useUuid } from "src/hooks";
import { useAuthStore } from "src/stores/auth-store";
import { getSpecificError } from "src/utils";

export const AccountWrapper = ({ children }: { children: ReactNode }) => {
  const uuid = useUuid();
  const logout = useAuthStore((state) => state.logout);

  const { data, isLoading, error, refetch } = useGetAccountQuery(
    {
      uuid,
    },
    {
      staleTime: REACT_QUERY_STALE_TIME,
    }
  );

  if (error) {
    return (
      <ErrorGlobal
        isInMaintenance={
          error.response?.errors?.some((e) => e.extensions?.code === API_MAINTENANCE_CODE) || false
        }
        title="Erreur lors de la récupération des informations du compte"
        description={getSpecificError(error, "Veuillez réessayer plus tard")}
        onRefetch={refetch}
        onLogout={logout}
      />
    );
  }

  if (isLoading || !data) {
    return <Loader />;
  }

  return <>{children}</>;
};
