import { datadogLogs } from "@datadog/browser-logs";
import * as Sentry from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect } from "react";

import {
  useUpdateCustomerConsentMutation,
  useGetAccountQuery,
} from "src/graphql/generated/queries";
import { GetAccountQuery } from "src/graphql/generated/types";
import { useCurrentAccount, useCurrentLogin, useSnackbar, useUuid } from "src/hooks";

import { ConfidentialityInputs, ConfidentialityModal } from "./_components/ConfidentialityModal";

type Props = {
  children: React.ReactNode;
};

export const CustomerWrapper = ({ children }: Props) => {
  const uuid = useUuid();
  const queryClient = useQueryClient();

  const login = useCurrentLogin();
  const { customer, quality } = useCurrentAccount();

  const { enqueueSnackbar } = useSnackbar();

  const {
    mutate: updateConfidentiality,
    ...updateConfidentialityInfos
  } = useUpdateCustomerConsentMutation({
    onSuccess: ({ updateCustomerConsent: { confidentiality } }) => {
      enqueueSnackbar("Vos paramètres de confidentialité ont bien été enregistrés", {
        variant: "success",
      });

      const GET_ACCOUNT_KEY = useGetAccountQuery.getKey({ uuid });

      queryClient.setQueryData<GetAccountQuery>(GET_ACCOUNT_KEY, (cachedData) => {
        if (!cachedData) {
          return undefined;
        }

        return {
          __typename: "Query",
          account: {
            ...cachedData.account,
            customer: {
              ...cachedData.account.customer,
              confidentiality: {
                ...cachedData.account.customer.confidentiality,
                ...confidentiality,
              },
            },
          },
        };
      });
    },
    onError: () => {
      enqueueSnackbar("Erreur lors de l'enregistrement de vos paramètres de confidentialité", {
        variant: "error",
      });
    },
  });

  useEffect(() => {
    Sentry.setUser({
      id: customer.id,
      email: login,
    });
    datadogLogs.setUser({ id: customer.id, email: login, quality });
    datadogLogs.setUserProperty("type", "customer");
  }, [customer]);

  const onSubmit = ({ personalData, partnersOffersInformation }: ConfidentialityInputs) => {
    updateConfidentiality({
      customerId: customer.id,
      request: {
        personalData,
        partnersOffersInformation,
        isFirstSubmit: true,
      },
    });
  };

  return (
    <>
      {children}
      <ConfidentialityModal
        open={!customer.confidentiality.isFirstSubmit}
        loading={updateConfidentialityInfos.isLoading}
        onSubmit={onSubmit}
      />
    </>
  );
};
