import { useState } from "react";

import { useGetDocumentUrlQuery } from "src/graphql/generated/queries";
import { useSnackbar } from "src/hooks/useSnackbar";
import { getSpecificError } from "src/utils";

export const useOpenDocument = (
  props: {
    onSuccess: (documentURL: string) => void;
  } | void
) => {
  const { enqueueSnackbar } = useSnackbar();
  const [hash, setHash] = useState("");

  const { isInitialLoading, isRefetching } = useGetDocumentUrlQuery(
    { hash },
    {
      enabled: !!hash,
      onSuccess: (data) => {
        if (!data.documentURL) {
          enqueueSnackbar("Une erreur est survenue lors de la récupération du document", {
            variant: "error",
          });
          return;
        }

        // @note: by default, document is open in a new tab
        if (props?.onSuccess) {
          props.onSuccess(data.documentURL);
        } else {
          window.open(data.documentURL, "_blank");
        }
      },
      onError: (error) => {
        enqueueSnackbar(
          getSpecificError(error, "Une erreur est survenue lors du téléchargement du document"),
          {
            variant: "error",
          }
        );
      },
      onSettled: () => {
        setHash("");
      },
    }
  );

  return { openDocument: setHash, openDocumentLoading: isInitialLoading || isRefetching };
};
