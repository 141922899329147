/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */
/* eslint-disable no-console */
import { datadogLogs } from "@datadog/browser-logs";
import * as Sentry from "@sentry/react";

import appConfig from "config/app";

interface AbstractLogger {
  error: (message: string) => void;

  info: (message: string) => void;
}

class AdapterConsole implements AbstractLogger {
  error: (message: string) => void;

  info: (message: string) => void;

  constructor() {
    this.error = console.error;
    this.info = console.log;
  }
}

class AdapterLogger implements AbstractLogger {
  constructor() {
    // init datadog
    if (appConfig.datadog?.token) {
      datadogLogs.init({
        clientToken: appConfig.datadog.token,
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
        site: "datadoghq.eu",
        env: appConfig.app.nodeEnv,
        service: appConfig.app.id,
      });
    }
    // init sentry
    if (appConfig.sentry?.dsn) {
      Sentry.init({
        environment: appConfig.app.nodeEnv,
        dsn: appConfig.sentry.dsn,
        normalizeDepth: 5,
      });
    }
  }

  error(message: string): void {
    Sentry.captureException(new Error(message));
    if (appConfig.datadog?.token && datadogLogs) {
      datadogLogs.logger.error(message);
    }
  }

  info(message: string): void {
    Sentry.addBreadcrumb({
      category: "info",
      message,
      level: Sentry.Severity.Info,
    });
    if (appConfig.datadog?.token && datadogLogs) {
      datadogLogs.logger.log(message);
    }
  }
}

class Logger implements AbstractLogger {
  adapter: AbstractLogger;

  error: (message: string) => void;

  info: (message: string) => void;

  static getAdapter() {
    try {
      return appConfig.datadog.token || appConfig.sentry.dsn
        ? new AdapterLogger()
        : new AdapterConsole();
    } catch (error) {
      return new AdapterConsole();
    }
  }

  constructor() {
    this.adapter = Logger.getAdapter();
    this.error = this.adapter.error;
    this.info = this.adapter.info;
  }
}

export const logger = new Logger();
