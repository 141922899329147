import { CivilityLabels } from "src/constants";
import { legalFormMapping } from "src/constants/app/legalFormMapping";
import { CustomerFragment } from "src/graphql/generated/types";

import { validName } from "../name";

export function getCustomerDisplayName(
  customer: Pick<CustomerFragment, "firstName" | "lastName" | "civility" | "company">,
  options?: { withCivility?: boolean; nameFirst?: boolean }
): string {
  const names = options?.nameFirst
    ? [customer.lastName, customer.firstName]
    : [customer.firstName, customer.lastName];
  const data = customer.company ? [customer.company.name] : names;
  if (customer.civility && options?.withCivility && !customer.company) {
    data.unshift(CivilityLabels[customer.civility]);
  }
  if (customer.company && customer.company.legalForm) {
    data.unshift(legalFormMapping[customer.company.legalForm as keyof typeof legalFormMapping]);
  }

  return data.filter(validName).join(" ");
}
