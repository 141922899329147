import { rem } from "polished";
import React, { ReactNode } from "react";
import styled from "styled-components";

export const amountCellWidth = 150;

type Props = {
  header: ReactNode;
  children: ReactNode;
};

const StyledExpenseTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const StyledExpenseHeader = styled.tr`
  background: #ddd;
  font-weight: 500;

  > td {
    padding: ${(props) => props.theme.spacing(1.5)};

    &:nth-child(1) {
      width: 5%;
    }
    &:nth-child(2) {
      width: 10%;
      text-align: center;
    }
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      text-align: center;
      width: ${rem(amountCellWidth)};
    }
  }
`;

export const ExpenseTable = ({ header, children }: Props) => (
  <StyledExpenseTable>
    <thead>
      <StyledExpenseHeader>{header}</StyledExpenseHeader>
    </thead>
    <tbody>{children}</tbody>
  </StyledExpenseTable>
);
