import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, DialogProps, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { rem } from "polished";
import React, { ReactElement } from "react";
import styled from "styled-components";

import { useDeviceWidth } from "src/hooks";
import { mobileStyle } from "src/utils";

import { Icon, IconName } from "../Icon";

const StyledModal = styled(Dialog)`
  .MuiDialog-paper {
    min-width: 850px;
    min-height: 350px;

    ${mobileStyle`
      min-width: unset;
      min-height: unset;
      height: calc(100vh - 4rem);
    `}
  }

  .MuiDialog-paperScrollPaper {
    display: initial;

    ${mobileStyle`
      margin: ${({ theme }) => theme.spacing(4)};
      border-radius: ${rem(16)};
      align-self: flex-start;
    `}
  }
  h2.MuiDialogTitle-root {
    text-align: center;
    color: ${(props) => props.theme.palette.primary.main};
    font-size: ${rem(30)};

    ${mobileStyle`
      font-size: ${rem(20)};
      font-weight: bold;
      text-transform: uppercase;
      display: table-caption;
    `}
  }

  .MuiDialogContent-root {
    ${mobileStyle`
      padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};
    `}
  }
`;

const StyledIcon = styled(Icon)`
  width: 100%;
  font-size: ${rem(60)};
  color: ${(props) => props.theme.palette.primary.main};

  ${mobileStyle`
    width: ${rem(50)};
  `}
`;

const StyledDialogHeader = styled.div`
  ${mobileStyle`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  `}
`;

interface Props extends Omit<DialogProps, "title"> {
  icon?: IconName | null;
  title?: ReactElement | string | null;
  open: boolean;
  hideCloseButton?: boolean;
  disableBackdropClick?: boolean;
  handleClose: () => void;
}

export const Modal = ({
  icon,
  title,
  open,
  hideCloseButton,
  disableBackdropClick,
  handleClose,
  children,
  ...dialogProps
}: Props) => {
  const { isMobile } = useDeviceWidth();

  return (
    <StyledModal
      open={open}
      onClose={(_event, reason) => {
        if (reason === "backdropClick" && disableBackdropClick) return;

        handleClose();
      }}
      aria-labelledby="form-dialog-title"
      fullScreen={isMobile}
      {...dialogProps}
    >
      <Box sx={{ mt: hideCloseButton && (title || icon) ? 4 : 0 }}>
        <Box
          sx={{
            display: hideCloseButton ? "none" : "flex",
            justifyContent: "flex-end",
            px: 0.5,
            py: 1,
          }}
        >
          <IconButton aria-label="fermer fenêtre modale" onClick={handleClose} color="inherit">
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>

      {isMobile ? (
        <StyledDialogHeader>
          {icon && <StyledIcon name={icon} size={50} />}
          {title && <DialogTitle id="form-dialog-title">{title}</DialogTitle>}
        </StyledDialogHeader>
      ) : (
        <>
          {icon && <StyledIcon name={icon} size={50} />}
          {title && <DialogTitle id="form-dialog-title">{title}</DialogTitle>}
        </>
      )}

      <DialogContent>{children}</DialogContent>
    </StyledModal>
  );
};
