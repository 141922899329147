/* eslint-disable */
import * as Types from './types';

import { ServerError } from "../fetcher";

import { useMutation, useQuery, useInfiniteQuery, UseMutationOptions, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { fetcher } from '../fetcher';

export const SubscribeErecoDocument = `
    mutation subscribeEreco($accountId: ID!, $customerId: ID!) {
  subscribeEreco(accountId: $accountId, customerId: $customerId)
}
    `;
export const useSubscribeErecoMutation = <
      TError = ServerError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.SubscribeErecoMutation, TError, Types.SubscribeErecoMutationVariables, TContext>) =>
    useMutation<Types.SubscribeErecoMutation, TError, Types.SubscribeErecoMutationVariables, TContext>(
      ['subscribeEreco'],
      (variables?: Types.SubscribeErecoMutationVariables) => fetcher<Types.SubscribeErecoMutation, Types.SubscribeErecoMutationVariables>(SubscribeErecoDocument, variables)(),
      options
    );
export const GetLeasesDocument = `
    query GetLeases($filters: LeasesFiltersInput!, $cursor: CursorInput) {
  leases(filters: $filters, cursor: $cursor) {
    edges {
      node {
        id
        entryDate
        leaveDate
        tenants {
          isMain
          customer {
            id
            ... on CustomerPerson {
              lastName
              firstName
            }
            ... on CustomerCompany {
              companyName
            }
          }
        }
        units {
          isMain
          unit {
            coOwnershipBylawsId
            ... on UnitHousing {
              subType
            }
            ... on UnitApartment {
              subType
            }
            ... on UnitParking {
              category
            }
            ... on UnitProfessionalRetailer {
              category
            }
            ... on UnitLand {
              category
            }
            ... on UnitOther {
              category
            }
            ... on UnitIslet {
              category
            }
          }
        }
        building {
          address {
            address1
            zipCode
            city
            country
          }
        }
        tenantBalance {
          value
          currency
        }
      }
    }
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;
export const useGetLeasesQuery = <
      TData = Types.GetLeasesQuery,
      TError = ServerError
    >(
      variables: Types.GetLeasesQueryVariables,
      options?: UseQueryOptions<Types.GetLeasesQuery, TError, TData>
    ) =>
    useQuery<Types.GetLeasesQuery, TError, TData>(
      ['GetLeases', variables],
      fetcher<Types.GetLeasesQuery, Types.GetLeasesQueryVariables>(GetLeasesDocument, variables),
      options
    );

useGetLeasesQuery.getKey = (variables: Types.GetLeasesQueryVariables) => ['GetLeases', variables];
;

export const useInfiniteGetLeasesQuery = <
      TData = Types.GetLeasesQuery,
      TError = ServerError
    >(
      variables: Types.GetLeasesQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetLeasesQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetLeasesQuery, TError, TData>(
      ['GetLeases.infinite', variables],
      (metaData) => fetcher<Types.GetLeasesQuery, Types.GetLeasesQueryVariables>(GetLeasesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetLeasesQuery.getKey = (variables: Types.GetLeasesQueryVariables) => ['GetLeases.infinite', variables];
;

export const GetRentalSituationFileDocument = `
    query GetRentalSituationFile($lessorAccountId: ID!) {
  rentalSituationFile(lessorAccountId: $lessorAccountId) {
    fileId
  }
}
    `;
export const useGetRentalSituationFileQuery = <
      TData = Types.GetRentalSituationFileQuery,
      TError = ServerError
    >(
      variables: Types.GetRentalSituationFileQueryVariables,
      options?: UseQueryOptions<Types.GetRentalSituationFileQuery, TError, TData>
    ) =>
    useQuery<Types.GetRentalSituationFileQuery, TError, TData>(
      ['GetRentalSituationFile', variables],
      fetcher<Types.GetRentalSituationFileQuery, Types.GetRentalSituationFileQueryVariables>(GetRentalSituationFileDocument, variables),
      options
    );

useGetRentalSituationFileQuery.getKey = (variables: Types.GetRentalSituationFileQueryVariables) => ['GetRentalSituationFile', variables];
;

export const useInfiniteGetRentalSituationFileQuery = <
      TData = Types.GetRentalSituationFileQuery,
      TError = ServerError
    >(
      variables: Types.GetRentalSituationFileQueryVariables,
      options?: UseInfiniteQueryOptions<Types.GetRentalSituationFileQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<Types.GetRentalSituationFileQuery, TError, TData>(
      ['GetRentalSituationFile.infinite', variables],
      (metaData) => fetcher<Types.GetRentalSituationFileQuery, Types.GetRentalSituationFileQueryVariables>(GetRentalSituationFileDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetRentalSituationFileQuery.getKey = (variables: Types.GetRentalSituationFileQueryVariables) => ['GetRentalSituationFile.infinite', variables];
;
