import { Button, ButtonProps } from "@mui/material";
import React from "react";
import styled from "styled-components";

export const StyledFetchMore = styled(Button)`
  padding: ${(props) => props.theme.spacing(2)};
  background: ${(props) => props.theme.palette.primary.dark};
  color: ${(props) => props.theme.palette.primary.contrastText};
  width: 100%;
  border-radius: 0;

  &:hover {
    background: ${(props) => props.theme.palette.primary.main};
  }
`;

interface Props extends ButtonProps {
  isLoading?: boolean;
}

export const ListFetchMore = ({ isLoading, ...boxProps }: Props) => {
  return (
    <StyledFetchMore {...boxProps} disabled={isLoading || boxProps.disabled}>
      {isLoading ? "Chargement..." : "Afficher plus"}
    </StyledFetchMore>
  );
};
