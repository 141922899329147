import type { IconName } from "src/components";
import {
  FileCategoryEnum,
  MailStatusEnum,
  MyFonciaFileCategoryEnum,
} from "src/graphql/generated/types";

export const DocumentCategoryLabel: {
  [key in MyFonciaFileCategoryEnum]: string;
} = {
  [MyFonciaFileCategoryEnum.Sinister]: "Sinistre",
  [MyFonciaFileCategoryEnum.Litigation]: "Contentieux",
  [MyFonciaFileCategoryEnum.AccountSituation]: "Situation de compte à date",
  [MyFonciaFileCategoryEnum.Insurance]: "Assurance",
  [MyFonciaFileCategoryEnum.ProvisionCalls]: "Appels de provisions",
  [MyFonciaFileCategoryEnum.Repair]: "Dépannage et réparation",
  [MyFonciaFileCategoryEnum.Correspondence]: "Correspondances",
  [MyFonciaFileCategoryEnum.LessorCoOwnershipDocuments]: "Documents de ma copropriété",
  [MyFonciaFileCategoryEnum.BoilerCertificate]: "Attestation chaudière",
  [MyFonciaFileCategoryEnum.BankReconciliation]: "Relevés des comptes bancaires",
  [MyFonciaFileCategoryEnum.TrusteeContract]: "Contrat de syndic",
  [MyFonciaFileCategoryEnum.Deposit]: "Acomptes versés",
  [MyFonciaFileCategoryEnum.DiagnosesGl]: "Diagnostics",
  [MyFonciaFileCategoryEnum.DueNotice]: "Avis d’échéance, Quittance de loyer",
  [MyFonciaFileCategoryEnum.GeneralAssembly]: "Assemblées générales",
  [MyFonciaFileCategoryEnum.PeriodGeneralAssembly]: "Assemblées générales par période comptable",
  [MyFonciaFileCategoryEnum.LandlordPropertyTaxes]: "Taxes foncières",
  [MyFonciaFileCategoryEnum.HouseholdWasteTax]: "Taxe d’enlèvement des ordures ménagères",
  [MyFonciaFileCategoryEnum.CoOwnershipIdentificationNumber]: "Immatriculation",
  [MyFonciaFileCategoryEnum.Invoices]: "Factures",
  [MyFonciaFileCategoryEnum.LandIncomes]: "Déclarations de revenus fonciers",
  [MyFonciaFileCategoryEnum.Leases]: "Bail",
  [MyFonciaFileCategoryEnum.OperatingLeaseReport]: "Comptes rendus de gestion",
  [MyFonciaFileCategoryEnum.RcpSettlement]: "Règlement de copropriété",
  [MyFonciaFileCategoryEnum.RegularizationRentalManagement]: "Régularisation de charges locatives",
  [MyFonciaFileCategoryEnum.StatementGeneralExpenses]: "Relevé général des dépenses",
  [MyFonciaFileCategoryEnum.RentReview]: "Révision de loyer",
  [MyFonciaFileCategoryEnum.ExpensesRepartition]: "Bilan annuel des charges",
  [MyFonciaFileCategoryEnum.CouncilMeetingReport]:
    "Comptes rendus des réunions du conseil syndical",
  [MyFonciaFileCategoryEnum.ReportVisit]: "Comptes rendus de visite d’immeuble",
  [MyFonciaFileCategoryEnum.ResidentialRentalInspection]: "État des lieux",
  [MyFonciaFileCategoryEnum.TenantStatementOfAccount]: "Arrêté de compte locataire",
  [MyFonciaFileCategoryEnum.TenantSettlementAdditional]:
    "Arrêté de compte locataire complémentaire",
  [MyFonciaFileCategoryEnum.Mandate]: "Mandat de prélèvement",
  [MyFonciaFileCategoryEnum.MandateDebit]: "Mandat de prélèvement",
  [MyFonciaFileCategoryEnum.ServiceOrder]: "Ordre de service",
  [MyFonciaFileCategoryEnum.ElectroRegistredMailSubscription]: "E-reco",
  [MyFonciaFileCategoryEnum.DiagnosesTech]: "Diagnostics techniques",
  [MyFonciaFileCategoryEnum.Sale]: "Acquisition de mon bien",
  [MyFonciaFileCategoryEnum.CoOwnershipTransfer]: "Vente de mon bien",
  [MyFonciaFileCategoryEnum.Contract]: "Contrats fournisseurs",
  [MyFonciaFileCategoryEnum.SignedManagementMandate]: "Mandat de gestion",
};

type DocumentCategoryDescriptionFunction = (isLandlord?: boolean) => string | null;

export const DocumentCategoryDescription: {
  [key in MyFonciaFileCategoryEnum]: DocumentCategoryDescriptionFunction | null;
} = {
  [MyFonciaFileCategoryEnum.Litigation]: null,
  [MyFonciaFileCategoryEnum.Sinister]: null,
  [MyFonciaFileCategoryEnum.AccountSituation]: () =>
    "Situation de votre compte Foncia, vous pouvez à tout moment demander de l'éditer auprès de votre gestionnaire",
  [MyFonciaFileCategoryEnum.Repair]: () => "L'ensemble des documents concernant des interventions",
  [MyFonciaFileCategoryEnum.Correspondence]: () => "Documents partagés par votre gestionnaire",
  [MyFonciaFileCategoryEnum.LessorCoOwnershipDocuments]: () =>
    "Les documents transmis par votre syndic de votre copropriété",
  [MyFonciaFileCategoryEnum.Contract]: () =>
    "Contrats en cours sur l'immeuble avec les fournisseurs",
  [MyFonciaFileCategoryEnum.Insurance]: () => "Contrat d'assurance habitation",
  [MyFonciaFileCategoryEnum.ProvisionCalls]: () =>
    "Derniers appels de provisions sur charges et/ou réparations",
  [MyFonciaFileCategoryEnum.BoilerCertificate]: () =>
    "Contrat annuel d'entretien de votre chaudière",
  [MyFonciaFileCategoryEnum.BankReconciliation]: () =>
    "Relevés des comptes bancaires des comptes de la copropriété",
  [MyFonciaFileCategoryEnum.TrusteeContract]: () => "Contrat avec Foncia pour votre copropriété",
  [MyFonciaFileCategoryEnum.Deposit]: () => "Mes derniers acomptes versés de la part de Foncia",
  [MyFonciaFileCategoryEnum.DiagnosesGl]: () =>
    "Ensemble des diagnostics immobiliers obligatoires réalisés sur mon bien",
  [MyFonciaFileCategoryEnum.DueNotice]: () =>
    "Derniers avis d'échéances (appels de loyer de charges locatives) et quittances de loyer",
  [MyFonciaFileCategoryEnum.GeneralAssembly]: () =>
    "Convocation, procès-verbaux,.. l'ensemble de vos documents concernant l'assemblée générale de votre copropriété",
  [MyFonciaFileCategoryEnum.PeriodGeneralAssembly]: null,
  [MyFonciaFileCategoryEnum.LandlordPropertyTaxes]: (isLandlord) =>
    isLandlord
      ? "Ajoutez et retrouvez vos avis de taxe foncière"
      : "Retrouvez ici les documents relatifs à la taxe foncière de votre bien",
  [MyFonciaFileCategoryEnum.HouseholdWasteTax]: () =>
    "Taxe annuelle à ma charge pour l'enlèvement des ordures ménagères",
  [MyFonciaFileCategoryEnum.CoOwnershipIdentificationNumber]: () =>
    "Immatriculation de mon immeuble au registre des copropriétés",
  [MyFonciaFileCategoryEnum.Invoices]: () => "L'ensemble de vos factures",
  [MyFonciaFileCategoryEnum.LandIncomes]: () =>
    "Document d'aide à la déclaration de mes revenus fonciers",
  [MyFonciaFileCategoryEnum.Leases]: () => "Contrat de location",
  [MyFonciaFileCategoryEnum.OperatingLeaseReport]: () =>
    "Résumé des dépenses et encaissements liés à mon bien",
  [MyFonciaFileCategoryEnum.RcpSettlement]: () =>
    "Règlement d'origine, modificatifs et plans de la copropriété",
  [MyFonciaFileCategoryEnum.RegularizationRentalManagement]: () =>
    "Chaque année, le montant des charges locatives provisionnées chaque mois est comparé aux dépenses réelles.",
  [MyFonciaFileCategoryEnum.StatementGeneralExpenses]: () =>
    "Récapitulatif des dépenses engagées pour la gestion de ma copropriété",
  [MyFonciaFileCategoryEnum.RentReview]: () =>
    "Chaque année mon loyer évolue selon un indice de référence",
  [MyFonciaFileCategoryEnum.TenantStatementOfAccount]: () =>
    "Récapitulatif de vos frais, les sommes qui vous sont dues et les éventuelles retenues sur votre dépôt de garantie",
  [MyFonciaFileCategoryEnum.TenantSettlementAdditional]: () =>
    "Récapitulatif de vos frais, les sommes qui vous sont dues et les éventuelles retenues suite à votre arrêté de compte locataire complémentaire",
  [MyFonciaFileCategoryEnum.ExpensesRepartition]: () =>
    "Régularisation de charges courantes annuelles et régularisations de charges pour les travaux votés en assemblée générale",
  [MyFonciaFileCategoryEnum.CouncilMeetingReport]: () =>
    "Compte-rendu des rencontres entre votre gestionnaire et vos représentants du conseil syndical",
  [MyFonciaFileCategoryEnum.ReportVisit]: () =>
    "Observations constatées lors de la dernière visite d'immeuble et actions à mener",
  [MyFonciaFileCategoryEnum.ResidentialRentalInspection]: () =>
    "États des lieux d’entrée et de sortie réalisés pour mon bien",
  [MyFonciaFileCategoryEnum.Mandate]: null,
  [MyFonciaFileCategoryEnum.MandateDebit]: null,
  [MyFonciaFileCategoryEnum.ServiceOrder]: null,
  [MyFonciaFileCategoryEnum.ElectroRegistredMailSubscription]: null,
  [MyFonciaFileCategoryEnum.DiagnosesTech]: () =>
    "Ensemble des diagnostics techniques réalisés sur mes biens",
  [MyFonciaFileCategoryEnum.Sale]: () => "Retrouvez-ici toutes les informations sur votre bien",
  [MyFonciaFileCategoryEnum.CoOwnershipTransfer]: null,
  [MyFonciaFileCategoryEnum.SignedManagementMandate]: () => "Votre contrat avec Foncia",
};

export const DocumentCategoryIcon: { [key in MyFonciaFileCategoryEnum]: IconName } = {
  [MyFonciaFileCategoryEnum.AccountSituation]: "landIncomes",
  [MyFonciaFileCategoryEnum.Sinister]: "sinister",
  [MyFonciaFileCategoryEnum.Litigation]: "litigation",
  [MyFonciaFileCategoryEnum.Repair]: "repair",
  [MyFonciaFileCategoryEnum.Correspondence]: "correspondence",
  [MyFonciaFileCategoryEnum.LessorCoOwnershipDocuments]: "coOwnershipDocuments",
  [MyFonciaFileCategoryEnum.Contract]: "trusteeContract",
  [MyFonciaFileCategoryEnum.Insurance]: "insurance",
  [MyFonciaFileCategoryEnum.ProvisionCalls]: "provisionCalls",
  [MyFonciaFileCategoryEnum.BoilerCertificate]: "boilerCertificate",
  [MyFonciaFileCategoryEnum.BankReconciliation]: "bankReconciliation",
  [MyFonciaFileCategoryEnum.TrusteeContract]: "trusteeContract",
  [MyFonciaFileCategoryEnum.Deposit]: "deposit",
  [MyFonciaFileCategoryEnum.DiagnosesGl]: "diagnostic",
  [MyFonciaFileCategoryEnum.DueNotice]: "dueNotice",
  [MyFonciaFileCategoryEnum.GeneralAssembly]: "generalAssembly",
  [MyFonciaFileCategoryEnum.PeriodGeneralAssembly]: "generalAssembly",
  [MyFonciaFileCategoryEnum.HouseholdWasteTax]: "householdWasteTax",
  [MyFonciaFileCategoryEnum.CoOwnershipIdentificationNumber]: "trusteeContract",
  [MyFonciaFileCategoryEnum.Invoices]: "invoices",
  [MyFonciaFileCategoryEnum.LandIncomes]: "landIncomes",
  [MyFonciaFileCategoryEnum.Leases]: "leases",
  [MyFonciaFileCategoryEnum.OperatingLeaseReport]: "operatingLeaseReport",
  [MyFonciaFileCategoryEnum.RcpSettlement]: "reglementDeCoownership",
  [MyFonciaFileCategoryEnum.RegularizationRentalManagement]: "regularizationRentalManagement",
  [MyFonciaFileCategoryEnum.StatementGeneralExpenses]: "budget",
  [MyFonciaFileCategoryEnum.RentReview]: "rentReview",
  [MyFonciaFileCategoryEnum.TenantStatementOfAccount]: "pieChart",
  [MyFonciaFileCategoryEnum.TenantSettlementAdditional]: "pieChart",
  [MyFonciaFileCategoryEnum.ExpensesRepartition]: "expensesRepartition",
  [MyFonciaFileCategoryEnum.CouncilMeetingReport]: "councilMeetingReport",
  [MyFonciaFileCategoryEnum.ReportVisit]: "building",
  [MyFonciaFileCategoryEnum.ResidentialRentalInspection]: "residentialRentalInspection",
  [MyFonciaFileCategoryEnum.Mandate]: "mandate",
  [MyFonciaFileCategoryEnum.MandateDebit]: "mandate",
  [MyFonciaFileCategoryEnum.ServiceOrder]: "trusteeContract",
  [MyFonciaFileCategoryEnum.ElectroRegistredMailSubscription]: "ereco",
  [MyFonciaFileCategoryEnum.DiagnosesTech]: "diagnostic",
  [MyFonciaFileCategoryEnum.Sale]: "accord",
  [MyFonciaFileCategoryEnum.CoOwnershipTransfer]: "trusteeContract",
  [MyFonciaFileCategoryEnum.SignedManagementMandate]: "handleMandate",
  [MyFonciaFileCategoryEnum.LandlordPropertyTaxes]: "bankReconciliation",
};

export const FileCategoryLabel: { [key in FileCategoryEnum]: string } = {
  [FileCategoryEnum.AccountSituation]: "Situation de compte",
  [FileCategoryEnum.DeficiencyMinutes]: "PV de carence assemblée générale",
  [FileCategoryEnum.Sinister]: "Sinistre",
  [FileCategoryEnum.Litigation]: "Contentieux",
  [FileCategoryEnum.AccountStatus]: "Situation de compte",
  [FileCategoryEnum.DatedState]: "État daté",
  [FileCategoryEnum.AccountingBalance]: "Balance comptable",
  [FileCategoryEnum.AccountingJournalReglement]: "Compta journal règlement",
  [FileCategoryEnum.AccountingJournalRentIssuance]: "Compta journal quittancement",
  [FileCategoryEnum.AccountingJournaux]: "Compta journaux",
  [FileCategoryEnum.AccountingLedgerAccount]: "Compta grand livre",
  [FileCategoryEnum.OtherCoOwner]: "Divers",
  [FileCategoryEnum.AccountingLinesCsv]: "",
  [FileCategoryEnum.AccountingPeriodAppendixes]: "Exercice comptable Annexe",
  [FileCategoryEnum.AccountingPointeCollateralie]: "Compta pointe garantie",
  [FileCategoryEnum.Annexe1]: "Annexe 1",
  [FileCategoryEnum.Annexe1Bis]: "Annexe 1bis",
  [FileCategoryEnum.Annexe2]: "Annexe 2",
  [FileCategoryEnum.Annexe3]: "Annexe 3",
  [FileCategoryEnum.Annexe4]: "Annexe 4",
  [FileCategoryEnum.Annexe5]: "Annexe 5",
  [FileCategoryEnum.AgCover]: "",
  [FileCategoryEnum.AppendixBalanceAccountant]: "",
  [FileCategoryEnum.AppendixReconciliationBank]: "",
  [FileCategoryEnum.Rgdd]: "Annexe RGDD",
  [FileCategoryEnum.CostInvoice]: "",
  [FileCategoryEnum.RgddN]: "Annexe_N RGDD",
  [FileCategoryEnum.RgddN1]: "Annexe_N1 RGDD",
  [FileCategoryEnum.RgddRepair]: "",
  [FileCategoryEnum.AnnexeFt]: "Annnexe FT",
  [FileCategoryEnum.AppendixLedgerAccount]: "",
  [FileCategoryEnum.RepairInvoice]: "Facture réparation",
  [FileCategoryEnum.AppendixArchive]: "",
  [FileCategoryEnum.AttendanceSheetFinalSigned]: "",
  [FileCategoryEnum.AttendanceSheetFinal]: "",
  [FileCategoryEnum.AttendanceSheetSigned]: "",
  [FileCategoryEnum.AttendanceSheet]: "",
  [FileCategoryEnum.AvisImposition]: "",
  [FileCategoryEnum.AwsTest]: "Aws test",
  [FileCategoryEnum.BankOrder]: "Ordre bancaire",
  [FileCategoryEnum.BankStatement]: "Relevés Bancaires",
  [FileCategoryEnum.CheckingBankStatement]: "Relevés Comptes courants",
  [FileCategoryEnum.SavingBankStatement]: "Relevés Comptes d'épargne",
  [FileCategoryEnum.CheckRemittanceNote]: "Bordereaux remise chèque",
  [FileCategoryEnum.Budget]: "Budget",
  [FileCategoryEnum.PayChecks]: "Bulletin de salaire",
  [FileCategoryEnum.CafAlFile]: "",
  [FileCategoryEnum.CandidateTenant]: "Candidat locataire",
  [FileCategoryEnum.MaintenanceBook]: "Carnet d'entretien",
  [FileCategoryEnum.BoilerCertificate]: "Attestation chaudière",
  [FileCategoryEnum.Check]: "Chèque",
  [FileCategoryEnum.LetterCheck]: "Lettre Chèque",
  [FileCategoryEnum.BankReconciliation]: "Rapprochement des comptes bancaires",
  [FileCategoryEnum.CompteReconciliationBank]: "Compte rapprochement bancaire",
  [FileCategoryEnum.Contract]: "Contrat",
  [FileCategoryEnum.GuardiansContract]: "Contrat gardiens",
  [FileCategoryEnum.TrusteeContract]: "Contrat syndic",
  [FileCategoryEnum.ContractorConstatimmo]: "",
  [FileCategoryEnum.Convocation]: "Convocation assemblée générale",
  [FileCategoryEnum.Deposit]: "Acomptes",
  [FileCategoryEnum.Diagnoses]: "Autre diagnostic",
  [FileCategoryEnum.DiagnosesGl]: "Diagnostics gl",
  [FileCategoryEnum.DiagnosesAsbestos]: "Diagnostic amiante",
  [FileCategoryEnum.DiagnosesPlumb]: "Diagnostic plomb",
  [FileCategoryEnum.DiagnosesEnergy]: "Diagnostic energétique",
  [FileCategoryEnum.DiagnosesParasiticState]: "Diagnostic état parasitaire",
  [FileCategoryEnum.DiagnosesGlobalTechnical]: "Diagnostic technique global (DTG)",
  [FileCategoryEnum.DiagnosesElevator]: "Diagnostic ascenseur",
  [FileCategoryEnum.DiagnosesOccupationalRisks]: "Diagnostic des risques professionnels",
  [FileCategoryEnum.DiagnosticSigne]: "Diagnostics lot",
  [FileCategoryEnum.DiagnosticInvoice]: "",
  [FileCategoryEnum.OthersLessors]: "Divers propriétaire",
  [FileCategoryEnum.OthersCoOwnership]: "Doc copropriété",
  [FileCategoryEnum.DueNotice]: "Avis d'échéance",
  [FileCategoryEnum.DueNoticeRent]: "Avis d'échéance loyer",
  [FileCategoryEnum.Decree]: "",
  [FileCategoryEnum.EdenredTransfersFile]: "",
  [FileCategoryEnum.EntryUnitAssesment]: "État des lieux entrée",
  [FileCategoryEnum.FeesConsolidationTransfer]: "",
  [FileCategoryEnum.FinancialPlan]: "Plan de financement",
  [FileCategoryEnum.Finding]: "Constat",
  [FileCategoryEnum.FundsCall]: "Appels de provisions",
  [FileCategoryEnum.GeneralAssembly]: "Assemblée générale",
  [FileCategoryEnum.GeneralAssemblyCertificate]: "Certificat d'assemblée générale",
  [FileCategoryEnum.GeneralAssemblyNotRepresentativeCoOwnerIdentity]: "",
  [FileCategoryEnum.LandlordPropertyTaxes]: "Taxes foncières",
  [FileCategoryEnum.HouseholdWasteTax]: "Taxe ordures ménagères",
  [FileCategoryEnum.CoOwnershipIdentificationNumber]: "Immatriculation copro",
  [FileCategoryEnum.IncomingMail]: "",
  [FileCategoryEnum.InsuranceOwner]: "Assurance propriétaire",
  [FileCategoryEnum.InsuranceTenant]: "Assurance locataires",
  [FileCategoryEnum.Invoices]: "Factures",
  [FileCategoryEnum.InvoiceFee]: "Honoraires",
  [FileCategoryEnum.JustificatifDivers]: "Justificatif divers",
  [FileCategoryEnum.JustificatifDomicile]: "Justificatif de domicile",
  [FileCategoryEnum.JustificatifIdentity]: "Justiticatif d'identité",
  [FileCategoryEnum.JustificatifIndemnites]: "Justificatif d'indemnités",
  [FileCategoryEnum.TenantLeaves]: "Justificatif de préavis",
  [FileCategoryEnum.JustificatifProfessionnel]: "Justificatif professionnel",
  [FileCategoryEnum.JustificatifTravail]: "Justificatif de travail",
  [FileCategoryEnum.JustificatifUnitsTransfer]: "",
  [FileCategoryEnum.KyribaFile]: "",
  [FileCategoryEnum.EdenredVirtualIban]: "",
  [FileCategoryEnum.LandIncomes]: "Déclaration de revenus fonciers",
  [FileCategoryEnum.Leases]: "Bail",
  [FileCategoryEnum.LeaseDivers]: "Divers locataire",
  [FileCategoryEnum.LeaseOtherDocument]: "Autre document de bail",
  [FileCategoryEnum.LeaseGuaranteeCommitment]: "Cautionnement Garant",
  [FileCategoryEnum.LedgerAccount]: "Grand livre",
  [FileCategoryEnum.MailStamping]: "",
  [FileCategoryEnum.MinutesSigned]: "Procès verbal AG (signé)",
  [FileCategoryEnum.MinutesSignedGl]: "PV signé",
  [FileCategoryEnum.ConvocationGl]: "Convocation",
  [FileCategoryEnum.OtherLessorCoOwnerShip]: "Autre",
  [FileCategoryEnum.Minutes]: "Procès verbal AG",
  [FileCategoryEnum.MissionAttachment]: "Mission attachement",
  [FileCategoryEnum.MoveOutInspection]: "État des lieux sortie",
  [FileCategoryEnum.MultiriskInsurance]: "",
  [FileCategoryEnum.Transfert]: "Avis de mutation",
  [FileCategoryEnum.OperatingLeaseReport]: "CRG",
  [FileCategoryEnum.OperatingLeaseReportCoownership]: "CRG Copropriétaire",
  [FileCategoryEnum.LessorRentalSituation]: "Etat locatif",
  [FileCategoryEnum.Organigrammes]: "Organigrammes",
  [FileCategoryEnum.OsDmdvDi]: "Os dmdv di",
  [FileCategoryEnum.OutgoingMail]: "Courrier sortant",
  [FileCategoryEnum.OutgoingMailAck]: "",
  [FileCategoryEnum.OutgoingMailScannedAck]: "",
  [FileCategoryEnum.PackFraicheur]: "Pack fraicheur",
  [FileCategoryEnum.PagardimRomAccountingLines]: "",
  [FileCategoryEnum.PagardimSalAccountingLines]: "",
  [FileCategoryEnum.PagardimSalaries]: "Fiche de salaire Gardien",
  [FileCategoryEnum.Passerelle]: "",
  [FileCategoryEnum.PicturePreVisit]: "",
  [FileCategoryEnum.PicturePublication]: "Photo publication",
  [FileCategoryEnum.Plans]: "Plans",
  [FileCategoryEnum.CarteProfessionnelle]: "Carte professionnelle",
  [FileCategoryEnum.RcpModificatifs]: "Rcp modificatifs",
  [FileCategoryEnum.RcpSettlement]: "Règlement de copropriété",
  [FileCategoryEnum.RegistreSecurite]: "Registre sécurité",
  [FileCategoryEnum.RegularizationRentalManagement]: "Régularisation de charges locatives",
  [FileCategoryEnum.RelanceCoownership]: "Relance copro",
  [FileCategoryEnum.RelanceGl]: "Relance gl",
  [FileCategoryEnum.StatementGeneralExpenses]: "Relevé général de dépenses",
  [FileCategoryEnum.RenewLease]: "Renouvellement bail",
  [FileCategoryEnum.AvisRentReceiptRent]: "Quittance de loyer",
  [FileCategoryEnum.RentReview]: "Révision de loyer",
  [FileCategoryEnum.Repartition]: "",
  [FileCategoryEnum.ExpensesRepartition]: "Répartition des charges courantes",
  [FileCategoryEnum.RepartitionRepair]: "Répartition des charges travaux",
  [FileCategoryEnum.MeetingReport]: "Compte rendu de réunion",
  [FileCategoryEnum.CouncilMeetingReport]: "Compte rendu de réunion CS",
  [FileCategoryEnum.ReportVisit]: "Compte rendu visite",
  [FileCategoryEnum.CouncilReportVisit]: "Compte rendu visite CS",
  [FileCategoryEnum.ResidentialRentalInspection]: "État des lieux",
  [FileCategoryEnum.Resolution]: "Résolution",
  [FileCategoryEnum.Sample]: "Sample",
  [FileCategoryEnum.Mandate]: "Mandat de prélèvement",
  [FileCategoryEnum.MandateDebit]: "Mandat de prélèvement",
  [FileCategoryEnum.ServiceOrder]: "Ordre de service",
  [FileCategoryEnum.ShowCasePoster]: "",
  [FileCategoryEnum.SignedDepositEngagement]: "Engagement caution signée",
  [FileCategoryEnum.SmokeDetector]: "Détecteur de fumée",
  [FileCategoryEnum.StatementAmountDues]: "Relevé des sommes dues",
  [FileCategoryEnum.ElectroRegistredMailSubscription]: "Souscription à E-reco",
  [FileCategoryEnum.TenantStatementOfAccount]: "Arrêté de compte locataire",
  [FileCategoryEnum.TenantSettlementAdditional]: "Arrêté de compte locataire complémentaire",
  [FileCategoryEnum.WorkOrder]: "Ordre service",
  [FileCategoryEnum.Workday]: "",
  [FileCategoryEnum.Sale]: "Vente",
  [FileCategoryEnum.WelcomePack]: "Document de bienvenue",
  [FileCategoryEnum.TransfertNotification]: "Avis virement",
  [FileCategoryEnum.CashAdvancePermed]: "Avance de trésorerie permanente",
  [FileCategoryEnum.AccountingInvoices]: "Exercice comptable Factures",
  [FileCategoryEnum.PayingCustomers]: "",
  [FileCategoryEnum.AccontingControl]: "",
  [FileCategoryEnum.OthersGuardians]: "Divers Gardiens",
  [FileCategoryEnum.Request]: "Demande",
  [FileCategoryEnum.Quote]: "Devis",
  [FileCategoryEnum.DocumentExtraction]: "",
  [FileCategoryEnum.AccountingLinesExtraction]: "",
  [FileCategoryEnum.InvoiceMediaPayment]: "",
  [FileCategoryEnum.InvoiceStamp]: "",
  [FileCategoryEnum.BankOperationMediaPayment]: "",
  [FileCategoryEnum.SaleAgreement]: "Document Promesse de Vente",
  [FileCategoryEnum.GlobalDocument]: "",
  [FileCategoryEnum.GeneralAssemblyPostalVote]: "Vote par correspondance",
  [FileCategoryEnum.SummarySheet]: "Fiche synthèse",
  [FileCategoryEnum.SubrogationNoticeDraft]: "Quittance subrogative non signée",
  [FileCategoryEnum.SubrogationNoticeSigned]: "Quittance subrogative signée",
  [FileCategoryEnum.InsuranceQuote]: "",
  [FileCategoryEnum.NewMandateDocuments]: "",
  [FileCategoryEnum.RentalManagementMandateAppendix]: "",
  [FileCategoryEnum.RcpRcdCertificate]: "",
  [FileCategoryEnum.StepCreationStatement]: "",
  [FileCategoryEnum.InsuranceContract]: "",
  [FileCategoryEnum.SaleAgreementInvoice]: "Facture DPV",
  [FileCategoryEnum.SignedManagementMandate]: "Mandat de gestion signé",
  [FileCategoryEnum.MissionCreationWelcomeDocumentCoOwner]: "Document de bienvenue",
  [FileCategoryEnum.AccountingCollectiveLoan]: "Documents bancaires",
  [FileCategoryEnum.SupportingCollectiveLoan]: "Documents pièces justificatives",
  [FileCategoryEnum.Reminders]: "Relances",
};

export const E_RECO_STATUSES_MAPPING: Partial<Record<MailStatusEnum, string>> = {
  [MailStatusEnum.ErecoRead]: "Lu",
  [MailStatusEnum.ErecoNotified]: "Non lu",
  [MailStatusEnum.ErecoExpired]: "Expiré",
};
