import { useInfiniteQuery, UseInfiniteQueryOptions } from "@tanstack/react-query";

import { fetcher, ServerError } from "src/graphql/fetcher";
import { GetBasicAccountsDocument } from "src/graphql/generated/queries";
import * as Types from "src/graphql/generated/types";

const ITEMS_PER_PAGE = 100;

export const useCustomInfiniteGetBasicAccountsQuery = <
  TData = Types.GetBasicAccountsQuery,
  TError = ServerError
>(
  options?: UseInfiniteQueryOptions<Types.GetBasicAccountsQuery, TError, TData>
) =>
  useInfiniteQuery<Types.GetBasicAccountsQuery, TError, TData>(
    ["getAccounts.infinite.custom"],
    async ({ pageParam = 1 }) =>
      fetcher<Types.GetBasicAccountsQuery, Types.GetBasicAccountsQueryVariables>(
        GetBasicAccountsDocument,
        {
          pageNumber: pageParam,
          itemsPerPage: ITEMS_PER_PAGE,
        }
      )(),
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.accounts.length > 0 ? allPages.length + 1 : undefined;
      },
      ...options,
    }
  );
