import { css, DefaultTheme, ThemeProps } from "styled-components";

import { MOBILE_MAX_SCREEN_WIDTH, TABLET_MAX_SCREEN_WIDTH } from "src/constants";

type StyledProps = Array<
  string | number | ((props: { [key: string]: any } & ThemeProps<DefaultTheme>) => any)
>;

export function mobileStyle(strings: TemplateStringsArray, ...args: StyledProps) {
  return css`
    @media screen and (max-width: ${MOBILE_MAX_SCREEN_WIDTH}px) {
      ${css(strings, ...args)}
    }
  `;
}

export function tabletStyle(strings: TemplateStringsArray, ...args: StyledProps) {
  return css`
    @media screen and (min-width: ${MOBILE_MAX_SCREEN_WIDTH +
      1}px) and (max-width: ${TABLET_MAX_SCREEN_WIDTH}px) {
      ${css(strings, ...args)}
    }
  `;
}

export function tabletMobileStyle(strings: TemplateStringsArray, ...args: StyledProps) {
  return css`
    @media screen and (max-width: ${TABLET_MAX_SCREEN_WIDTH}px) {
      ${css(strings, ...args)}
    }
  `;
}
