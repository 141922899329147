import { Box, BoxProps, PaginationItem } from "@mui/material";
import React from "react";
import styled from "styled-components";

import { PageInfoFragment } from "src/graphql/generated/types";
import { useDeviceWidth } from "src/hooks";

type Props = {
  pageInfo: PageInfoFragment;
  onPageChange: (newPageNumber: number) => void;
} & BoxProps;

const StyledRoot = styled(Box)`
  padding: ${(props) => props.theme.spacing(1)};
  display: flex;
  justify-content: center;

  > *:not(:first-child) {
    margin-left: ${(props) => props.theme.spacing(2)};
  }
`;

const StyledPaginationItem = styled(PaginationItem)`
  font-weight: 500;
`;

export const ListPaginator = ({ pageInfo, onPageChange, ...boxProps }: Props) => {
  const { isMobile } = useDeviceWidth();
  const { pageNumber, totalPages, totalDisplayPages } = pageInfo;
  const maxPageNumber = totalPages || totalDisplayPages;
  const PAGINATION_RANGE = isMobile ? 1 : 2;

  // @note: can't works without current pageNumber
  // @note: can't works totalPages OR totalDisplayPages
  if (typeof pageNumber !== "number" || !maxPageNumber) {
    return null;
  }

  // @note: where there is only one page, there is no need for pagination
  if (pageNumber === 1 && maxPageNumber === 1) {
    return null;
  }

  let start = pageNumber - PAGINATION_RANGE;
  let end = pageNumber + PAGINATION_RANGE;
  if (start < 1) {
    // @note temporary add the diff to "end" in order to try to have (PAGINATION_RANGE * 2) + 1 visible pagination items
    end += Math.abs(start) + 1;
    start = 1;
  }
  if (end > maxPageNumber) {
    // @note try to add the diff to "start" in order to try to have (PAGINATION_RANGE * 2) + 1 visible pagination items
    const diff = end - maxPageNumber;
    start -= diff;
    if (start < 1) {
      start = 1;
    }
    end = maxPageNumber;
  }

  return (
    <StyledRoot {...boxProps}>
      <StyledPaginationItem
        disabled={pageNumber === 1}
        variant="outlined"
        type="previous"
        shape="rounded"
        onClick={() => {
          onPageChange(pageNumber - 1);
        }}
      />
      {[...new Array(end - start + 1)].map((_value, index) => {
        const realPageNumber = start + index;
        return (
          <StyledPaginationItem
            key={index}
            data-cy-page={realPageNumber}
            disabled={realPageNumber === pageNumber}
            selected={realPageNumber === pageNumber}
            variant="outlined"
            page={realPageNumber}
            shape="rounded"
            onClick={() => {
              onPageChange(realPageNumber);
            }}
          />
        );
      })}

      {end < maxPageNumber &&
        (!totalPages || (typeof totalPages === "number" && end + 1 < totalPages)) && (
          <StyledPaginationItem disabled type="start-ellipsis" variant="outlined" shape="rounded" />
        )}
      {typeof totalPages === "number" && end < totalPages && (
        <StyledPaginationItem
          page={totalPages}
          variant="outlined"
          shape="rounded"
          onClick={() => {
            onPageChange(totalPages);
          }}
        />
      )}
      <StyledPaginationItem
        disabled={pageNumber >= maxPageNumber}
        variant="outlined"
        type="next"
        shape="rounded"
        onClick={() => {
          onPageChange(pageNumber + 1);
        }}
      />
    </StyledRoot>
  );
};
