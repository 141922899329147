import { Chip, ChipProps } from "@mui/material";
import React from "react";
import styled from "styled-components";

import { MissionStatusColor, MissionStatusLabel } from "src/constants";
import { MissionStatusEnum } from "src/graphql/generated/types";

interface Props extends ChipProps {
  status: MissionStatusEnum;
}

const StyledChip = styled(Chip)<{ status: MissionStatusEnum }>`
  background-color: ${(props) => MissionStatusColor[props.status]};
  color: ${(props) => props.theme.palette.white.main};
  span {
    font-weight: bold;
  }
`;

export const ChipMission = ({ status, ...chipProps }: Props) => {
  return <StyledChip status={status} label={MissionStatusLabel[status]} {...chipProps} />;
};
