import React from "react";

import { EmeriaEuropeLink } from "../EmeriaEuropeLink";

export const ConfidentialityPersonalData = () => (
  <div>
    J'autorise Foncia et les autres filiales d’
    <EmeriaEuropeLink /> (anciennement Foncia Groupe) à analyser mes données afin de me faire
    parvenir des informations adaptées sur leurs services
  </div>
);
