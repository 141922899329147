import { Box, Grid } from "@mui/material";
import React, { useMemo } from "react";

import { AccountQualityEnum, TicketHeadingCategoryEnum } from "src/graphql/generated/types";
import { useDeviceWidth } from "src/hooks";
import { getTicketHeadingCategoryOptions } from "src/utils";

import { CategoryCard } from "../CategoryCard";

type Props = {
  quality: AccountQualityEnum;
  selectedHeadingCategory: TicketHeadingCategoryEnum | "";
  setSelectedHeadingCategory: (value: TicketHeadingCategoryEnum) => void;
  disabled?: boolean;
};

export const CategoriesGrid = ({
  quality,
  selectedHeadingCategory,
  setSelectedHeadingCategory,
  disabled,
}: Props) => {
  const ticketCategories = useMemo(() => getTicketHeadingCategoryOptions(quality), []);
  const { isTabletMobile } = useDeviceWidth();

  return (
    <Box sx={{ mx: 2 }}>
      <Grid container spacing={3}>
        {ticketCategories.map((card, index) => {
          const isSelected = ticketCategories[index].value === selectedHeadingCategory;
          return (
            <Grid xs={isTabletMobile ? 12 : 4} item key={card.value}>
              <CategoryCard
                onClick={() => setSelectedHeadingCategory(card.value)}
                isSelected={isSelected}
                icon={card.icon}
                label={card.label}
                disabled={disabled}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
