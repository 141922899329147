import { InfiniteData } from "@tanstack/react-query";

import { GetTicketsQuery, TicketFragment } from "src/graphql/generated/types";

export function displayInputValue<TEnum>(
  options: Array<TEnum>,
  enumLength: number,
  selectedOption: string | undefined
): TEnum | string {
  if (options.length === 1 && selectedOption) {
    return selectedOption;
  }
  if (options.length > 1 && options.length < enumLength) {
    return `${options.length} filtres`;
  }
  return "Tous";
}

export function filterInfiniteTicketsQuery(
  InfiniteTickets: InfiniteData<GetTicketsQuery> | undefined
) {
  return (InfiniteTickets?.pages || []).reduce<Array<TicketFragment>>((accu, item) => {
    return [...accu, ...item.tickets.edges.map((e) => e.node)];
  }, []);
}
