import React, { createContext, useCallback, useMemo, useState } from "react";

import { Amount } from "src/graphql/generated/types";
import { PaymentAutomaticSuccessModal } from "src/pages/PaymentAutomatic/SuccessModal";
import { PaymentOnDemandModal } from "src/pages/PaymentOnDemand/PaymentModal";

type PaymentAutomaticState = {
  isCoOwner: boolean;
  isDebtor: boolean;
} | null;

type PaymentOnDemandState =
  | {
      params: {
        amount: Omit<Amount, "__typename">;
        date: Date;
        instructionDayInt?: number;
      };
      keepMandate?: boolean;
    }
  | undefined;
interface Props {
  setPaymentAutomaticParams: (params: PaymentAutomaticState) => void;
  setPaymentOnDemandState: (state: PaymentOnDemandState) => void;
}

export const ModalPaymentContext = createContext<Props>({
  setPaymentAutomaticParams: () => null,
  setPaymentOnDemandState: () => null,
});

export const ModalPaymentProvider = ({ children }: { children: React.ReactNode }) => {
  const [paymentAutomaticParams, setPaymentAutomaticParams] = useState<PaymentAutomaticState>(null);
  const [paymentOnDemandState, setPaymentOnDemandState] = useState<PaymentOnDemandState>(undefined);

  const onClosePaymentAutomatic = useCallback(() => setPaymentAutomaticParams(null), []);
  const onClosePaymentOnDemand = useCallback(() => setPaymentOnDemandState(undefined), []);

  const memoizedValue = useMemo(
    () => ({
      setPaymentAutomaticParams,
      setPaymentOnDemandState,
    }),
    []
  );

  return (
    <ModalPaymentContext.Provider value={memoizedValue}>
      {children}
      <PaymentAutomaticSuccessModal
        isOpen={!!paymentAutomaticParams}
        isCoOwner={paymentAutomaticParams?.isCoOwner}
        isDebtor={paymentAutomaticParams?.isDebtor}
        onClose={onClosePaymentAutomatic}
      />
      <PaymentOnDemandModal
        isOpen={!!paymentOnDemandState?.params}
        amount={paymentOnDemandState?.params?.amount}
        date={paymentOnDemandState?.params?.date}
        instructionDayInt={paymentOnDemandState?.params?.instructionDayInt}
        onClose={onClosePaymentOnDemand}
        keepMandate={paymentOnDemandState?.keepMandate}
      />
    </ModalPaymentContext.Provider>
  );
};
