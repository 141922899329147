import appConfig from "config/app";

import { logger } from "..";

export function activateGTag() {
  if (!appConfig.firebaseConfig) return;
  window.dataLayer = window.dataLayer || [];
  function gtag(...args: Array<any>) {
    window.dataLayer.push(args);
  }
  gtag("js", new Date());
  gtag("config", appConfig.firebaseConfig.measurementId, { send_page_view: false });
  logger.info("Gtag initialized");
}
