import { IconName } from "src/components";
import {
  SortByType,
  SortDirectionEnum,
  AccountQualityEnum,
  TicketCategoryEnum,
  TicketHeadingCategoryEnum,
  TicketSourceEnum,
  TicketActivityEnum,
  TicketStatusEnum,
} from "src/graphql/generated/types";

export const DEFAULT_TICKETS_SORT: Array<SortByType> = [
  { key: "isRead", direction: SortDirectionEnum.Asc },
  { key: "updatedAt", direction: SortDirectionEnum.Desc },
];

interface TicketCategoryQualityDetails {
  quality: AccountQualityEnum;
  label: string;
  order: number;
  icon: IconName;
  isHeadingCategoryActive: boolean;
}

export const TicketHeadingCategory: Record<
  TicketHeadingCategoryEnum,
  TicketCategoryQualityDetails
> = {
  // TENANT
  [TicketHeadingCategoryEnum.TenantPayment]: {
    quality: AccountQualityEnum.Tenant,
    label: "Paiements, charges, dépôt de garantie",
    order: 1,
    icon: "bankReconciliation",
    isHeadingCategoryActive: true,
  },
  [TicketHeadingCategoryEnum.TenantRepair]: {
    quality: AccountQualityEnum.Tenant,
    label: "Dépannage, travaux, sinistres",
    order: 2,
    icon: "diagnostic",
    isHeadingCategoryActive: true,
  },
  [TicketHeadingCategoryEnum.TenantDeparture]: {
    quality: AccountQualityEnum.Tenant,
    label: "Départ du logement",
    order: 3,
    icon: "visitPrevious",
    isHeadingCategoryActive: false,
  },
  [TicketHeadingCategoryEnum.TenantDocuments]: {
    quality: AccountQualityEnum.Tenant,
    label: "Attestations, coordonnées, documents",
    order: 4,
    icon: "attachment",
    isHeadingCategoryActive: true,
  },
  [TicketHeadingCategoryEnum.TenantBuilding]: {
    quality: AccountQualityEnum.Tenant,
    label: "Accès et entretien de l'immeuble",
    order: 5,
    icon: "building",
    isHeadingCategoryActive: true,
  },
  [TicketHeadingCategoryEnum.TenantMyfoncia]: {
    quality: AccountQualityEnum.Tenant,
    label: "Espace client MyFoncia",
    order: 6,
    icon: "ereco",
    isHeadingCategoryActive: true,
  },

  // LANLORD
  [TicketHeadingCategoryEnum.LandlordIncome]: {
    quality: AccountQualityEnum.Landlord,
    label: "Revenus locatifs, charges, taxes",
    order: 1,
    icon: "bankReconciliation",
    isHeadingCategoryActive: true,
  },
  [TicketHeadingCategoryEnum.LandlordDocuments]: {
    quality: AccountQualityEnum.Landlord,
    label: "Coordonnées, documents et vente",
    order: 2,
    icon: "attachment",
    isHeadingCategoryActive: true,
  },
  [TicketHeadingCategoryEnum.LandlordMyfoncia]: {
    quality: AccountQualityEnum.Landlord,
    label: "Espace client MyFoncia",
    order: 3,
    icon: "ereco",
    isHeadingCategoryActive: true,
  },
  [TicketHeadingCategoryEnum.LandlordRepair]: {
    quality: AccountQualityEnum.Landlord,
    label: "Dépannage, travaux, sinistres",
    order: 4,
    icon: "diagnostic",
    isHeadingCategoryActive: true,
  },
  [TicketHeadingCategoryEnum.LandlordTenant]: {
    quality: AccountQualityEnum.Landlord,
    label: "Suivi de la location de mon bien",
    order: 5,
    icon: "visitPrevious",
    isHeadingCategoryActive: true,
  },
  [TicketHeadingCategoryEnum.LandlordCopro]: {
    quality: AccountQualityEnum.Landlord,
    label: "Informations copropriété",
    order: 6,
    icon: "building",
    isHeadingCategoryActive: true,
  },

  // CO_OWNER
  [TicketHeadingCategoryEnum.CoOwnerPayment]: {
    quality: AccountQualityEnum.CoOwner,
    label: "Paiements, charges et déclarations",
    order: 1,
    icon: "bankReconciliation",
    isHeadingCategoryActive: true,
  },
  [TicketHeadingCategoryEnum.CoOwnerMyfoncia]: {
    quality: AccountQualityEnum.CoOwner,
    label: "Espace client MyFoncia",
    order: 2,
    icon: "ereco",
    isHeadingCategoryActive: true,
  },
  [TicketHeadingCategoryEnum.CoOwnerRepair]: {
    quality: AccountQualityEnum.CoOwner,
    label: "Dépannage, travaux, sinistres",
    order: 3,
    icon: "diagnostic",
    isHeadingCategoryActive: true,
  },
  [TicketHeadingCategoryEnum.CoOwnerDocuments]: {
    quality: AccountQualityEnum.CoOwner,
    label: "Coordonnées, documents et vente",
    order: 4,
    icon: "attachment",
    isHeadingCategoryActive: true,
  },
  [TicketHeadingCategoryEnum.CoOwnerBuildings]: {
    quality: AccountQualityEnum.CoOwner,
    label: "Accès, entretien et vie de l'immeuble",
    order: 5,
    icon: "building",
    isHeadingCategoryActive: true,
  },
  [TicketHeadingCategoryEnum.CoOwnerAg]: {
    quality: AccountQualityEnum.CoOwner,
    label: "Assemblées générales et conseil syndical",
    order: 6,
    icon: "generalAssembly",
    isHeadingCategoryActive: true,
  },
};

interface TicketCategoryDetails {
  headingCategory?: TicketHeadingCategoryEnum;
  label: string;
  order: number;
}

export const TicketCategory: Record<TicketCategoryEnum, TicketCategoryDetails> = {
  [TicketCategoryEnum.TenantPaymentRent]: {
    // TicketHeadingCategoryEnum.TenantPayment
    headingCategory: TicketHeadingCategoryEnum.TenantPayment,
    label: "Paiement du loyer",
    order: 1,
  },
  [TicketCategoryEnum.TenantPaymentReceipt]: {
    headingCategory: TicketHeadingCategoryEnum.TenantPayment,
    label: "Informations sur la quittance, l'avis d'échéance",
    order: 2,
  },
  [TicketCategoryEnum.TenantPaymentLatepayment]: {
    headingCategory: TicketHeadingCategoryEnum.TenantPayment,
    label: "Retard de paiement",
    order: 3,
  },
  [TicketCategoryEnum.TenantPaymentDeposit]: {
    headingCategory: TicketHeadingCategoryEnum.TenantPayment,
    label: "Dépôt de garantie",
    order: 4,
  },
  [TicketCategoryEnum.TenantPaymentRegularization]: {
    headingCategory: TicketHeadingCategoryEnum.TenantPayment,
    label: "Régularisation de charges",
    order: 5,
  },
  [TicketCategoryEnum.TenantPaymentAccount]: {
    headingCategory: TicketHeadingCategoryEnum.TenantPayment,
    label: "Situation de mon compte",
    order: 6,
  },
  [TicketCategoryEnum.TenantPaymentOther]: {
    headingCategory: TicketHeadingCategoryEnum.TenantPayment,
    label: "Autre",
    order: 7,
  },

  // TicketCategoryEnum.TenantRepairIntervention
  [TicketCategoryEnum.TenantRepairIntervention]: {
    headingCategory: TicketHeadingCategoryEnum.TenantRepair,
    label: "Demande et suivi d'intervention pour dépannage",
    order: 1,
  },
  [TicketCategoryEnum.TenantRepairWork]: {
    headingCategory: TicketHeadingCategoryEnum.TenantRepair,
    label: "Suivi des travaux",
    order: 2,
  },
  [TicketCategoryEnum.TenantRepairSinister]: {
    headingCategory: TicketHeadingCategoryEnum.TenantRepair,
    label: "Déclaration et suivi de sinistre",
    order: 3,
  },
  [TicketCategoryEnum.TenantRepairOther]: {
    headingCategory: TicketHeadingCategoryEnum.TenantRepair,
    label: "Autre",
    order: 4,
  },

  // TicketCategoryEnum.TenantDeparture
  [TicketCategoryEnum.TenantDeparture]: {
    headingCategory: TicketHeadingCategoryEnum.TenantDeparture,
    label: "",
    order: 1,
  },

  // TicketCategoryEnum.TenantDocumentsContacts
  [TicketCategoryEnum.TenantDocumentsContacts]: {
    headingCategory: TicketHeadingCategoryEnum.TenantDocuments,
    label: "Modification des coordonnées",
    order: 1,
  },
  [TicketCategoryEnum.TenantDocumentsInsurance]: {
    headingCategory: TicketHeadingCategoryEnum.TenantDocuments,
    label: "Attestation d'assurance",
    order: 2,
  },
  [TicketCategoryEnum.TenantDocumentsDemand]: {
    headingCategory: TicketHeadingCategoryEnum.TenantDocuments,
    label: "Demande de document",
    order: 3,
  },
  [TicketCategoryEnum.TenantDocumentsOther]: {
    headingCategory: TicketHeadingCategoryEnum.TenantDocuments,
    label: "Autre",
    order: 4,
  },

  // TicketCategoryEnum.TenantBuildingAccess
  [TicketCategoryEnum.TenantBuildingAccess]: {
    headingCategory: TicketHeadingCategoryEnum.TenantBuilding,
    label: "Accès à l'immeuble (badge, digicode...)",
    order: 1,
  },
  [TicketCategoryEnum.TenantBuildingMaintenance]: {
    headingCategory: TicketHeadingCategoryEnum.TenantBuilding,
    label: "Entretien de l'immeuble",
    order: 2,
  },
  [TicketCategoryEnum.TenantBuildingOther]: {
    headingCategory: TicketHeadingCategoryEnum.TenantBuilding,
    label: "Autre",
    order: 3,
  },

  // TicketCategoryEnum.TenantMyfonciaPayment
  [TicketCategoryEnum.TenantMyfonciaPayment]: {
    headingCategory: TicketHeadingCategoryEnum.TenantMyfoncia,
    label: "Comment payer dans MyFoncia",
    order: 1,
  },
  [TicketCategoryEnum.TenantMyfonciaHelp]: {
    headingCategory: TicketHeadingCategoryEnum.TenantMyfoncia,
    label: "Aide dans MyFoncia",
    order: 2,
  },
  [TicketCategoryEnum.TenantMyfonciaOther]: {
    headingCategory: TicketHeadingCategoryEnum.TenantMyfoncia,
    label: "Autre",
    order: 3,
  },

  // TicketCategoryEnum.LandlordIncomeDeclaration
  [TicketCategoryEnum.LandlordIncomeDeclaration]: {
    headingCategory: TicketHeadingCategoryEnum.LandlordIncome,
    label: "Déclaration des revenus fonciers",
    order: 1,
  },
  [TicketCategoryEnum.LandlordIncomePayment]: {
    headingCategory: TicketHeadingCategoryEnum.LandlordIncome,
    label: "Paiement des factures et des charges de copropriété",
    order: 2,
  },
  [TicketCategoryEnum.LandlordIncomeCrg]: {
    headingCategory: TicketHeadingCategoryEnum.LandlordIncome,
    label: "Information sur le compte rendu de gestion (CRG)",
    order: 3,
  },
  [TicketCategoryEnum.LandlordIncomeTax]: {
    headingCategory: TicketHeadingCategoryEnum.LandlordIncome,
    label: "Taxes foncière, ordures ménagères",
    order: 4,
  },
  [TicketCategoryEnum.LandlordIncomeFees]: {
    headingCategory: TicketHeadingCategoryEnum.LandlordIncome,
    label: "Information sur les honoraires",
    order: 5,
  },
  [TicketCategoryEnum.LandlordIncomeTenant]: {
    headingCategory: TicketHeadingCategoryEnum.LandlordIncome,
    label: "Situation du compte locataire",
    order: 6,
  },
  [TicketCategoryEnum.LandlordIncomeAccount]: {
    headingCategory: TicketHeadingCategoryEnum.LandlordIncome,
    label: "Situation de mon compte",
    order: 7,
  },
  [TicketCategoryEnum.LandlordIncomeCompensation]: {
    headingCategory: TicketHeadingCategoryEnum.LandlordIncome,
    label: "Indemnisation garantie loyer impayé",
    order: 8,
  },
  [TicketCategoryEnum.LandlordIncomeOther]: {
    headingCategory: TicketHeadingCategoryEnum.LandlordIncome,
    label: "Autre",
    order: 9,
  },

  // TicketCategoryEnum.LandlordDocumentsContacts
  [TicketCategoryEnum.LandlordDocumentsContacts]: {
    headingCategory: TicketHeadingCategoryEnum.LandlordDocuments,
    label: "Modification des coordonnées",
    order: 1,
  },
  [TicketCategoryEnum.LandlordDocumentsDemand]: {
    headingCategory: TicketHeadingCategoryEnum.LandlordDocuments,
    label: "Demande de document",
    order: 2,
  },
  [TicketCategoryEnum.LandlordDocumentsEstimate]: {
    headingCategory: TicketHeadingCategoryEnum.LandlordDocuments,
    label: "Demande d'estimation de mon bien",
    order: 3,
  },
  [TicketCategoryEnum.LandlordDocumentsSale]: {
    headingCategory: TicketHeadingCategoryEnum.LandlordDocuments,
    label: "Information vente de mon bien",
    order: 4,
  },
  [TicketCategoryEnum.LandlordDocumentsOther]: {
    headingCategory: TicketHeadingCategoryEnum.LandlordDocuments,
    label: "Autre",
    order: 5,
  },

  // TicketCategoryEnum.LandlordMyfoncia
  [TicketCategoryEnum.LandlordMyfoncia]: {
    headingCategory: TicketHeadingCategoryEnum.LandlordMyfoncia,
    label: "",
    order: 1,
  },

  // TicketCategoryEnum.LandlordRepairIntervention
  [TicketCategoryEnum.LandlordRepairIntervention]: {
    headingCategory: TicketHeadingCategoryEnum.LandlordRepair,
    label: "Demande et suivi d'intervention pour dépannage",
    order: 1,
  },
  [TicketCategoryEnum.LandlordRepairWork]: {
    headingCategory: TicketHeadingCategoryEnum.LandlordRepair,
    label: "Suivi des travaux",
    order: 2,
  },
  [TicketCategoryEnum.LandlordRepairSinister]: {
    headingCategory: TicketHeadingCategoryEnum.LandlordRepair,
    label: "Déclaration et suivi de sinistre",
    order: 3,
  },
  [TicketCategoryEnum.LandlordRepairOther]: {
    headingCategory: TicketHeadingCategoryEnum.LandlordRepair,
    label: "Autre",
    order: 4,
  },

  // TicketCategoryEnum.LandlordTenant
  [TicketCategoryEnum.LandlordTenant]: {
    headingCategory: TicketHeadingCategoryEnum.LandlordTenant,
    label: "",
    order: 1,
  },

  // TicketCategoryEnum.LandlordCoproSyndic
  [TicketCategoryEnum.LandlordCoproSyndic]: {
    headingCategory: TicketHeadingCategoryEnum.LandlordCopro,
    label: "Echanges avec le syndic",
    order: 1,
  },
  [TicketCategoryEnum.LandlordCoproAgorganisation]: {
    headingCategory: TicketHeadingCategoryEnum.LandlordCopro,
    label: "Organisation de l'assemblée générale",
    order: 2,
  },
  [TicketCategoryEnum.LandlordCoproAgdecision]: {
    headingCategory: TicketHeadingCategoryEnum.LandlordCopro,
    label: "Décisions d'assemblée générale",
    order: 3,
  },
  [TicketCategoryEnum.LandlordCoproOther]: {
    headingCategory: TicketHeadingCategoryEnum.LandlordCopro,
    label: "Autre",
    order: 4,
  },

  // TicketCategoryEnum.CoOwnerPaymentCharges
  [TicketCategoryEnum.CoOwnerPaymentCharges]: {
    headingCategory: TicketHeadingCategoryEnum.CoOwnerPayment,
    label: "Paiement de mes charges",
    order: 1,
  },
  [TicketCategoryEnum.CoOwnerPaymentLatepayment]: {
    headingCategory: TicketHeadingCategoryEnum.CoOwnerPayment,
    label: "Retard de paiement",
    order: 2,
  },
  [TicketCategoryEnum.CoOwnerPaymentExplanations]: {
    headingCategory: TicketHeadingCategoryEnum.CoOwnerPayment,
    label: "Explications situation de mon compte",
    order: 3,
  },
  [TicketCategoryEnum.CoOwnerPaymentDivision]: {
    headingCategory: TicketHeadingCategoryEnum.CoOwnerPayment,
    label: "Répartition de charges",
    order: 4,
  },
  [TicketCategoryEnum.CoOwnerPaymentDeclaration]: {
    headingCategory: TicketHeadingCategoryEnum.CoOwnerPayment,
    label: "Déclaration des revenus fonciers",
    order: 5,
  },
  [TicketCategoryEnum.CoOwnerPaymentOther]: {
    headingCategory: TicketHeadingCategoryEnum.CoOwnerPayment,
    label: "Autre",
    order: 6,
  },

  // TicketCategoryEnum.CoOwnerMyfonciaPayment
  [TicketCategoryEnum.CoOwnerMyfonciaPayment]: {
    headingCategory: TicketHeadingCategoryEnum.CoOwnerMyfoncia,
    label: "Comment payer dans MyFoncia",
    order: 1,
  },
  [TicketCategoryEnum.CoOwnerMyfonciaHelp]: {
    headingCategory: TicketHeadingCategoryEnum.CoOwnerMyfoncia,
    label: "Aide dans MyFoncia",
    order: 2,
  },
  [TicketCategoryEnum.CoOwnerMyfonciaOther]: {
    headingCategory: TicketHeadingCategoryEnum.CoOwnerMyfoncia,
    label: "Autre",
    order: 3,
  },

  // TicketCategoryEnum.CoOwnerRepairIntervention
  [TicketCategoryEnum.CoOwnerRepairIntervention]: {
    headingCategory: TicketHeadingCategoryEnum.CoOwnerRepair,
    label: "Demande et suivi d'intervention pour dépannage",
    order: 1,
  },
  [TicketCategoryEnum.CoOwnerRepairWork]: {
    headingCategory: TicketHeadingCategoryEnum.CoOwnerRepair,
    label: "Suivi des travaux",
    order: 2,
  },
  [TicketCategoryEnum.CoOwnerRepairSinister]: {
    headingCategory: TicketHeadingCategoryEnum.CoOwnerRepair,
    label: "Déclaration et suivi de sinistre",
    order: 3,
  },
  [TicketCategoryEnum.CoOwnerRepairOther]: {
    headingCategory: TicketHeadingCategoryEnum.CoOwnerRepair,
    label: "Autre",
    order: 4,
  },

  // TicketCategoryEnum.CoOwnerDocumentsDemand
  [TicketCategoryEnum.CoOwnerDocumentsDemand]: {
    headingCategory: TicketHeadingCategoryEnum.CoOwnerDocuments,
    label: "Demande de document",
    order: 1,
  },
  [TicketCategoryEnum.CoOwnerDocumentsContact]: {
    headingCategory: TicketHeadingCategoryEnum.CoOwnerDocuments,
    label: "Modification des coordonnées",
    order: 2,
  },
  [TicketCategoryEnum.CoOwnerDocumentsEstimate]: {
    headingCategory: TicketHeadingCategoryEnum.CoOwnerDocuments,
    label: "Demande d'estimation de mon bien",
    order: 3,
  },
  [TicketCategoryEnum.CoOwnerDocumentsSale]: {
    headingCategory: TicketHeadingCategoryEnum.CoOwnerDocuments,
    label: "Information vente de mon bien",
    order: 4,
  },
  [TicketCategoryEnum.CoOwnerDocumentsOther]: {
    headingCategory: TicketHeadingCategoryEnum.CoOwnerDocuments,
    label: "Autre",
    order: 5,
  },

  // TicketCategoryEnum.CoOwnerBuildingsAccess
  [TicketCategoryEnum.CoOwnerBuildingsAccess]: {
    headingCategory: TicketHeadingCategoryEnum.CoOwnerBuildings,
    label: "Accès à l'immeuble (badge, digicode...)",
    order: 1,
  },
  [TicketCategoryEnum.CoOwnerBuildingsMaintenance]: {
    headingCategory: TicketHeadingCategoryEnum.CoOwnerBuildings,
    label: "Entretien de l'immeuble",
    order: 2,
  },
  [TicketCategoryEnum.CoOwnerBuildingsIssue]: {
    headingCategory: TicketHeadingCategoryEnum.CoOwnerBuildings,
    label: "Problème de voisinage, incivilités",
    order: 3,
  },
  [TicketCategoryEnum.CoOwnerBuildingsOther]: {
    headingCategory: TicketHeadingCategoryEnum.CoOwnerBuildings,
    label: "Autre",
    order: 4,
  },

  // TicketCategoryEnum.CoOwnerAgOrganisation
  [TicketCategoryEnum.CoOwnerAgOrganisation]: {
    headingCategory: TicketHeadingCategoryEnum.CoOwnerAg,
    label: "Organisation de l'assemblée générale",
    order: 1,
  },
  [TicketCategoryEnum.CoOwnerAgDecision]: {
    headingCategory: TicketHeadingCategoryEnum.CoOwnerAg,
    label: "Décisions d'assemblée générale",
    order: 2,
  },
  [TicketCategoryEnum.CoOwnerAgContacts]: {
    headingCategory: TicketHeadingCategoryEnum.CoOwnerAg,
    label: "Contacts du conseil syndical",
    order: 3,
  },
  [TicketCategoryEnum.CoOwnerAgOther]: {
    headingCategory: TicketHeadingCategoryEnum.CoOwnerAg,
    label: "Autre",
    order: 4,
  },

  // TicketCategoryEnum.Unknown
  [TicketCategoryEnum.Unknown]: {
    label: "Autre",
    order: 1,
  },
};

export const TicketStatusHeaderColor: { [key in TicketStatusEnum]: string } = {
  [TicketStatusEnum.Open]: "primary.main",
  [TicketStatusEnum.WorkInProgress]: "primary.main",
  [TicketStatusEnum.Processed]: "green.main",
  [TicketStatusEnum.Finished]: "green.main",
};

export const ChipTicketStatusColor: { [key in TicketStatusEnum]: string } = {
  [TicketStatusEnum.Open]: "orange.boxBackground",
  [TicketStatusEnum.WorkInProgress]: "cyan.dark",
  [TicketStatusEnum.Processed]: "green.light",
  [TicketStatusEnum.Finished]: "green.light",
};

export const TicketStatusTextColor: { [key in TicketStatusEnum]: string } = {
  [TicketStatusEnum.Open]: "amountColor.negative",
  [TicketStatusEnum.WorkInProgress]: "primary.main",
  [TicketStatusEnum.Processed]: "amountColor.positive",
  [TicketStatusEnum.Finished]: "amountColor.positive",
};

export const TicketStatusLabel: {
  [Key in TicketStatusEnum]: string;
} = {
  [TicketStatusEnum.Open]: "Enregistrée",
  [TicketStatusEnum.WorkInProgress]: "En cours",
  [TicketStatusEnum.Processed]: "Traitée",
  [TicketStatusEnum.Finished]: "Clôturée",
};

export const TicketSourceLabel: { [key in TicketSourceEnum]: string } = {
  [TicketSourceEnum.Agence]: "Agence",
  [TicketSourceEnum.Email]: "E-mail",
  [TicketSourceEnum.Myfoncia]: "MyFoncia",
  [TicketSourceEnum.Telephone]: "Téléphone",
};

export enum TicketResponseStatusEnum {
  All = "Toutes",
  Read = "Lues",
  NotRead = "Non Lues",
}

export const TicketResponseStatusValue: {
  [key in TicketResponseStatusEnum]: boolean | undefined;
} = {
  [TicketResponseStatusEnum.All]: undefined,
  [TicketResponseStatusEnum.Read]: true,
  [TicketResponseStatusEnum.NotRead]: false,
};

export const ProfileInformationHeadingCategory: {
  [key in AccountQualityEnum]: TicketHeadingCategoryEnum;
} = {
  [AccountQualityEnum.Tenant]: TicketHeadingCategoryEnum.TenantDocuments,
  [AccountQualityEnum.CoOwner]: TicketHeadingCategoryEnum.CoOwnerDocuments,
  [AccountQualityEnum.Landlord]: TicketHeadingCategoryEnum.LandlordDocuments,
};

export const TICKET_CREATION_ERROR = "Une erreur est survenue lors de la création de la demande";

export const TicketActivityLabel: Record<TicketActivityEnum, string | null> = {
  [TicketActivityEnum.CategoryChanged]:
    "Pour un meilleur traitement de votre demande, nous avons modifié son motif. L’objet de votre demande est désormais : ",
  [TicketActivityEnum.MissionAttached]: null,
  [TicketActivityEnum.TransferFromAssociate]: null,
  [TicketActivityEnum.TransferFromSupport]:
    "Pour un meilleur traitement de votre demande, celle-ci a été transférée à votre gestionnaire.",
  [TicketActivityEnum.TransferToMiddleOffice]: null,
};

export const UPLOAD_PLACEHOLDER_TEXT = "Ajoutez jusqu'à 5 pièces jointes";
