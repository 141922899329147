import { useParams } from "react-router-dom";

/*
This hook will be removed once the migration to Gateway my-foncia-next is fully merged, as the concept of uuid will no longer be relevant.
Currently, the uuid is a token generated from an object containing 3 to 5 values, but with the new architecture, this approach will become obsolete.
*/

export const useUuid = (): string => {
  const params = useParams();

  if (!params.uuid) {
    throw new Error("UUID is required");
  }

  return params.uuid;
};
