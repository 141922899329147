import { GetApp } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { rem } from "polished";
import React from "react";
import styled from "styled-components";

import { pluralize } from "src/utils";

import { Loader } from "../Loader";

const DownloadButton = styled(Button)`
  color: ${(props) => props.theme.palette.white.main};
  font-size: ${rem(16)};
`;

const DownloadLoader = styled(Loader)`
  .MuiCircularProgress-colorPrimary {
    color: ${(props) => props.theme.palette.white.main};
  }
`;

const DownloadText = styled(Typography)`
  color: ${(props) => props.theme.palette.white.main};
  font-size: ${rem(16)};
`;

interface Props {
  onClick: () => void;
  filesCount: number;
  isLoading?: boolean;
}

export const DocumentListDownloadBanner = ({ onClick, filesCount, isLoading = false }: Props) => {
  return (
    <Box
      sx={{
        width: "100vw",
        position: "fixed",
        left: 0,
        bottom: 0,
        height: "100px",
        backgroundColor: "green.main",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <DownloadLoader />
          <DownloadText>Vos documents sont en cours de téléchargement</DownloadText>
        </Box>
      )}
      {isLoading === false && (
        <Box
          sx={{
            border: "2px solid white",
            borderRadius: 2,
            height: "60px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-around",
            fontStyle: "bold",
            paddingLeft: 3,
            paddingRight: 3,
          }}
          component={(props) => (
            <DownloadButton
              {...props}
              startIcon={<GetApp htmlColor="white" style={{ width: 30, height: 30 }} />}
              onClick={onClick}
            >{`Télécharger ${pluralize("le", filesCount, "s")} ${
              (filesCount > 1 && filesCount) || ""
            } ${pluralize("document", filesCount, "s")}`}</DownloadButton>
          )}
        />
      )}
    </Box>
  );
};
