export const excludesFalse = (Boolean as any) as <T>(x: T | false) => x is T;

type KeyOf<T> = Extract<keyof T, string>;
type ValueOf<T> = T[KeyOf<T>];

/**
 * Nicely typed aliases for some `Object` Methods
 * - PSA: Don't mutate `yourObject`s
 * - Numerical keys are BAD `{ 1: 'ha!' }` may not appear in your resulting types
 * - Discussion: https://stackoverflow.com/a/65117465/565877
 */
export const ObjectTyped = {
  /**
   * Object.keys, but with nice typing (`Array<keyof T>`)
   */
  keys: Object.keys as <T extends {}>(yourObject: T) => Array<KeyOf<T>>,
  /**
   * Object.values, but with nice typing
   */
  values: Object.values as <T extends {}>(yourObject: T) => Array<ValueOf<T>>,
  /**
   * Object.entries, but with nice typing
   */
  entries: Object.entries as <T extends {}>(yourObject: T) => Array<[KeyOf<T>, ValueOf<T>]>,
};

// FILTERS
export function nonNullable<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined;
}

export function notNull<T>(val: T | null): T {
  if (val === null) {
    throw new Error("Invariant: value should not be null");
  }

  return val;
}

export function notUndefined<T>(val: T | undefined): T {
  if (val === undefined) {
    throw new Error("Invariant: value should not be undefined");
  }

  return val;
}
