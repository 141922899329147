import React from "react";

import { SubNav } from "src/components";
import {
  COUNCIL_ACCOUNTING_HOME_PAGE,
  COUNCIL_ACCOUNTING_REPAIR_BUDGETS_PAGE,
  COUNCIL_ACCOUNTING_RGDD_PAGE,
} from "src/constants";

type Props = {
  children: React.ReactNode;
};

export const CouncilAccountingLayout = ({ children }: Props) => {
  const links = [
    {
      label: "Comptabilité de l'exercice en cours",
      link: COUNCIL_ACCOUNTING_HOME_PAGE,
    },
    {
      label: "Comptabilité des exercices passés",
      link: COUNCIL_ACCOUNTING_RGDD_PAGE,
    },
    {
      label: "Comptes Travaux",
      link: COUNCIL_ACCOUNTING_REPAIR_BUDGETS_PAGE,
    },
  ];

  return (
    <div>
      <SubNav links={links} sx={{ backgroundColor: "white" }} />
      <div>{children}</div>
    </div>
  );
};
