import { SxProps } from "@mui/material";

export const sectionContainer: SxProps = {
  mx: 10,
  my: 2,
  py: 1,
  display: "flex",
  justifyContent: "space-between",
  color: "gray.blueGray",
  borderBottomStyle: "solid",
  borderTopStyle: "solid",
  borderBottomColor: "gray.deep",
  borderTopColor: "gray.deep",
};

export const labelContainer: SxProps = {
  display: "flex",
  alignItems: "center",
};

export const labelStyle: SxProps = {
  color: "gray.dark",
  fontWeight: "bold",
  ml: 2,
};

export const rightNodeContainer: SxProps = { display: "flex" };
