import { SxProps } from "@mui/material";

export const modalInputContainer: SxProps = {
  mt: 2,
  borderWidth: 0.1,
  backgroundColor: "tickets.boxBackground",
  borderStyle: "solid",
  borderColor: "tickets.border",
  mx: 2,
  p: 3,
};

export const inputTitleStyle: SxProps = {
  ml: 2,
  mb: 0.5,
  fontSize: 12,
  color: "gray.main",
};
