import { DialogActions } from "@mui/material";
import styled from "styled-components";

import { mobileStyle } from "../../utils";

export const ModalFooter = styled(DialogActions)`
  justify-content: center;
  padding: ${(props) => props.theme.spacing(2)};

  ${mobileStyle`
    padding: ${(props) => props.theme.spacing(4)};
    padding-bottom: ${(props) => props.theme.spacing(2)};
  `}
`;
