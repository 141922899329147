import { Box, Button } from "@mui/material";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";

import { useDeviceWidth } from "src/hooks";
import { megabytesTobytes } from "src/utils";

import { UploadedDocument } from "../UploadedDocuments";

const StyledDropzone = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${(props) => props.theme.spacing(2)};
  background-color: #fff;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  &:focus {
    cursor: pointer;
  }
`;

interface Props {
  documents: Array<File> | null;
  onChange: (files: Array<File> | null) => void;
  placeholder: string;
}

export const Dropzone = ({ documents, onChange, placeholder }: Props) => {
  const onDropAccepted = (files: Array<File>) => onChange([...(documents || []), ...files]);
  const { isMobile } = useDeviceWidth();

  const disabled = documents ? documents?.length > 4 : false;

  const { getRootProps, getInputProps, isDragReject, isDragActive, isDragAccept } = useDropzone({
    accept: {
      "image/*": [],
      "application/pdf": [],
    },
    multiple: true,
    maxFiles: 5,
    maxSize: Math.round(megabytesTobytes(10)),
    disabled,
    onDropAccepted,
  });

  const reset = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, file: File) => {
      event.stopPropagation();

      if (documents) onChange(documents.filter((document: File) => document.name !== file.name));
    },
    [documents]
  );

  return (
    <Box sx={{ backgroundColor: "white.main" }}>
      <StyledDropzone
        {...getRootProps({
          isDragActive,
          isDragAccept,
          isDragReject,
        })}
      >
        <Box
          sx={{ color: "gray.dark", backgroundColor: "gray.deep", position: "absolute", left: 75 }}
        >
          {!isMobile && (
            <Button variant="contained" color="inherit" disabled={disabled}>
              Parcourir
            </Button>
          )}
        </Box>

        <Box sx={{ display: "flex" }}>
          <input {...getInputProps()} disabled={disabled} />
          {documents && documents?.length !== 0 ? (
            documents.map((document) => (
              <UploadedDocument
                key={document.name}
                name={document.name}
                onClick={(e) => reset(e, document)}
              />
            ))
          ) : (
            <Box component="p" sx={{ mr: 2 }}>
              {placeholder}
            </Box>
          )}
        </Box>
      </StyledDropzone>
    </Box>
  );
};
