import { useQueryClient } from "@tanstack/react-query";

import { useSubscribeErecoMutation } from "src/graphql-next/generated/queries";
import { useGetAccountQuery } from "src/graphql/generated/queries";
import { GetAccountQuery } from "src/graphql/generated/types";

import { useSnackbar } from "./useSnackbar";
import { useUuid } from "./useUuid";

export function useSubscribeEReco() {
  const uuid = useUuid();
  const GET_ACCOUNT_KEY = useGetAccountQuery.getKey({ uuid });
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useSubscribeErecoMutation({
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: GET_ACCOUNT_KEY });

      const previousAccount = queryClient.getQueryData(GET_ACCOUNT_KEY);

      queryClient.setQueryData<GetAccountQuery>(GET_ACCOUNT_KEY, (cachedData) => {
        if (!cachedData) {
          return undefined;
        }

        return {
          __typename: "Query",
          account: {
            ...cachedData.account,
            ...(cachedData.account.__typename === "CoownerAccount" && {
              eReco: {
                ...cachedData.account.eReco,
                isSubscribed: true,
              },
            }),
          },
        };
      });

      return { previousAccount };
    },
    onSuccess: () => {
      enqueueSnackbar("Vous avez maintenant souscrit à l'E-Reco", {
        variant: "success",
      });
    },
    onError: (_, __, context) => {
      enqueueSnackbar("Une erreur est survenue lors de la souscription", {
        variant: "error",
      });
      queryClient.setQueryData(GET_ACCOUNT_KEY, context?.previousAccount);
    },
  });
}
