import { useMediaQuery } from "@mui/material";

import { MOBILE_MAX_SCREEN_WIDTH, TABLET_MAX_SCREEN_WIDTH } from "src/constants";

export const useDeviceWidth = () => {
  const isMobile = useMediaQuery(`(max-width: ${MOBILE_MAX_SCREEN_WIDTH}px)`);
  const isTablet = useMediaQuery(
    `(min-width: ${MOBILE_MAX_SCREEN_WIDTH + 1}px) and (max-width: ${TABLET_MAX_SCREEN_WIDTH})`
  );
  const isTabletMobile = useMediaQuery(`(max-width: ${TABLET_MAX_SCREEN_WIDTH}px)`);
  const isDesktop = useMediaQuery(`(min-width: ${TABLET_MAX_SCREEN_WIDTH + 1}px)`);

  return {
    isMobile,
    isDesktop,
    isTablet,
    isTabletMobile,
  };
};
