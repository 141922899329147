import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import appConfig from "config/app";
import {
  MetaTags,
  PasswordStrengthIndicator,
  ConfirmationStepLogin,
  PasswordInput,
  AuthBox,
} from "src/components";
import { PATH_PUBLIC_LOGIN } from "src/constants";
import {
  useLoginCheckFirstConnectionMutation,
  useUpdateCredentialsMutation,
  useLoginAssociationRequestFirstConnectionMutation,
  useGetMeQuery,
} from "src/graphql/generated/queries";
import { useSnackbar } from "src/hooks";
import { useAuthStore } from "src/stores/auth-store";
import { emailPattern, getSpecificError, isValidPassword } from "src/utils";

import { AskToAssociate } from "./_components";

interface TemporaryLoginInputs {
  newLogin: string;
  nextNewLogin: string;
  password: string;
}

type FormSteps =
  | { slug: "email" }
  | { slug: "password" }
  | { slug: "askToAssociate"; rejected: boolean };

export const TemporaryLogin = () => {
  const navigate = useNavigate();
  const logout = useAuthStore((state) => state.logout);

  const { data, isLoading } = useGetMeQuery();
  const { enqueueSnackbar } = useSnackbar();
  const [formStep, setFormStep] = useState<FormSteps>({ slug: "email" });
  const [nextEmailEntered, setNextEmailEntered] = useState("");
  const [customerId, setCustomerId] = useState<string>("");
  const [goToLoginCounter, setGoToLoginCounter] = useState(5);

  useEffect(() => {
    if (!isLoading && !data?.me.isTemporary) {
      enqueueSnackbar("Veuillez utiliser vos nouveaux identifiants pour vous connecter", {
        variant: "error",
      });

      const interval = setInterval(() => setGoToLoginCounter((count) => count - 1), 1000);

      return () => clearInterval(interval);
    }

    return undefined;
  }, [data?.me]);

  useEffect(() => {
    if (!goToLoginCounter) logout();
  }, [goToLoginCounter]);

  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm<TemporaryLoginInputs>({
    defaultValues: { newLogin: "", nextNewLogin: "", password: "" },
  });

  const newLogin = watch("newLogin");
  const nextNewLogin = watch("nextNewLogin");
  const password = watch("password");

  const {
    mutate: loginCheckFirstConnection,
    ...loginCheckFirstConnectionInfos
  } = useLoginCheckFirstConnectionMutation({
    onSuccess: (data) => {
      if (
        !data.loginCheckFirstConnection.isUniqueLogin &&
        data.loginCheckFirstConnection.customerId
      ) {
        setFormStep({ slug: "askToAssociate", rejected: false });
        setCustomerId(data.loginCheckFirstConnection.customerId);
      } else {
        setFormStep({ slug: "password" });
      }
      if (
        formStep.slug === "askToAssociate" &&
        formStep.rejected &&
        !data.loginCheckFirstConnection.isUniqueLogin
      ) {
        setNextEmailEntered(nextNewLogin);
      }
    },
    onError: (error) => {
      enqueueSnackbar(getSpecificError(error, "Erreur lors de la validation du login"), {
        variant: "error",
      });
    },
  });

  const {
    mutate: loginAssociationRequest,
    ...loginAssociationRequestInfos
  } = useLoginAssociationRequestFirstConnectionMutation({
    onSuccess: () => {
      setFormStep({ slug: "askToAssociate", rejected: false });
    },
    onError: (error) => {
      enqueueSnackbar(getSpecificError(error, "Erreur lors de l'association de vos comptes"), {
        variant: "error",
      });
    },
  });

  const { mutate: updateCredentials, ...updateCredentialsInfos } = useUpdateCredentialsMutation({
    onError: (error) => {
      enqueueSnackbar(
        getSpecificError(error, "Erreur lors de l'enregistrement de vos identifiants"),
        {
          variant: "error",
        }
      );
    },
  });

  const onSubmit = ({ newLogin, password }: TemporaryLoginInputs) => {
    if (!data?.me.login) return;

    updateCredentials({
      request: {
        temporaryLogin: data.me.login,
        newLogin: nextNewLogin || newLogin,
        password,
        brandCode: appConfig.brand.code,
        callbackUrl: appConfig.app.env,
      },
    });
  };

  const handleNewLoginCheckAction = ({ newLogin }: { newLogin: string }) => {
    loginCheckFirstConnection({ login: newLogin });
  };

  const handleNextNewLoginCheckAction = ({ nextNewLogin }: { nextNewLogin: string }) => {
    loginCheckFirstConnection({ login: nextNewLogin });
  };

  const handleAcceptAssociation = () => {
    loginAssociationRequest({
      login: nextEmailEntered.length > 0 ? nextEmailEntered : newLogin,
      customerId,
      brandCode: appConfig.brand.code,
      callbackUrl: appConfig.app.env,
    });
  };

  const handleCancelAction = () => {
    logout();
    navigate(PATH_PUBLIC_LOGIN);
  };

  const APPLY_BUTTON = {
    text: {
      email: "SUIVANT",
      password: "VALIDER",
      askToAssociate: undefined,
    },
    disabled: {
      email: newLogin.length === 0,
      password: !isValidPassword(password),
      askToAssociate: undefined,
    },
    pending: {
      email: loginCheckFirstConnectionInfos.isLoading,
      password: updateCredentialsInfos.isLoading,
      askToAssociate: loginAssociationRequestInfos.isLoading,
    },
    action: {
      email: handleSubmit(handleNewLoginCheckAction),
      password: handleSubmit(onSubmit),
      askToAssociate: handleSubmit(handleNextNewLoginCheckAction),
    },
  };

  const CANCEL_BUTTON = {
    text: {
      email: "ANNULER",
      password: "RETOUR",
      askToAssociate: undefined,
    },
    action: {
      email: handleCancelAction,
      password: () => setFormStep({ slug: "email" }),
      askToAssociate: undefined,
    },
  };

  return (
    <>
      <MetaTags title="Connexion temporaire" />

      <AuthBox title={data?.me.fullname ? `Bienvenue ${data.me.fullname}` : undefined}>
        {loginAssociationRequestInfos.data && (
          <ConfirmationStepLogin
            title={<b>Votre demande a été enregistrée.</b>}
            text={
              <b>
                Consultez votre adresse email {nextEmailEntered || newLogin} pour confirmer cette
                association puis reconnectez-vous avec vos identifiants habituels.
              </b>
            }
          />
        )}

        {updateCredentialsInfos.data ? (
          <ConfirmationStepLogin
            title={<b>Vos identifiants ont été enregistrés.</b>}
            text={<b>Consultez votre boite mail pour confirmer la création de vos identifiants.</b>}
            renderBackButton={false}
          />
        ) : (
          <>
            {formStep.slug !== "askToAssociate" && (
              <Typography variant="h6" color="secondary" textAlign="center">
                Pour votre première connexion, <br /> veuillez mettre à jour vos identifiants.
              </Typography>
            )}

            {formStep.slug === "email" && (
              <FormControl
                fullWidth
                variant="outlined"
                error={Boolean(errors.newLogin)}
                sx={{ my: 2 }}
              >
                <InputLabel htmlFor="newLogin">Votre adresse email</InputLabel>
                <Controller
                  name="newLogin"
                  control={control}
                  rules={{
                    required: "Un email est requis",
                    pattern: { value: emailPattern, message: "Email invalide" },
                  }}
                  render={({ field }) => (
                    <OutlinedInput
                      disabled={!data?.me.isTemporary}
                      label="Votre adresse email"
                      placeholder="Votre adresse email"
                      {...field}
                    />
                  )}
                />
                <FormHelperText>{errors.newLogin?.message}</FormHelperText>
              </FormControl>
            )}

            {formStep.slug === "password" && (
              <>
                <Box sx={{ my: 2 }}>
                  <PasswordInput
                    control={control}
                    rules={{
                      required: "Un mot de passe est requis",
                      validate: (value) =>
                        isValidPassword(value) || "Nouveau mot de passe trop simple",
                    }}
                    error={errors.password?.message}
                  />
                </Box>
                <PasswordStrengthIndicator password={password} />
              </>
            )}

            {!loginAssociationRequestInfos.data && formStep.slug === "askToAssociate" && (
              <AskToAssociate
                email={newLogin}
                nextEmail={nextEmailEntered}
                loading={APPLY_BUTTON.pending[formStep.slug]}
                onAccept={handleAcceptAssociation}
                onCancel={() => setFormStep({ slug: "askToAssociate", rejected: true })}
              />
            )}

            {formStep.slug === "askToAssociate" && formStep.rejected && (
              <Box sx={{ backgroundColor: "gray.light", p: 3, mt: 2 }}>
                <FormControl fullWidth variant="outlined" error={Boolean(errors.nextNewLogin)}>
                  <InputLabel htmlFor="nextNewLogin">Votre adresse email</InputLabel>
                  <Controller
                    name="nextNewLogin"
                    control={control}
                    rules={{
                      required: "Un email est requis",
                      pattern: { value: emailPattern, message: "Email invalide" },
                    }}
                    render={({ field }) => (
                      <OutlinedInput
                        label="Votre adresse email"
                        placeholder="Votre adresse email"
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText>{errors.nextNewLogin?.message}</FormHelperText>
                </FormControl>

                <LoadingButton
                  data-cy="temporary-login-submit-button"
                  type="submit"
                  fullWidth
                  disabled={nextNewLogin.length === 0}
                  loading={APPLY_BUTTON.pending[formStep.slug]}
                  variant="contained"
                  onClick={APPLY_BUTTON.action[formStep.slug]}
                  sx={{ mt: 2, textTransform: "uppercase" }}
                >
                  Suivant
                </LoadingButton>
              </Box>
            )}

            {formStep.slug !== "askToAssociate" && (
              <>
                <LoadingButton
                  data-cy="temporary-login-submit-button"
                  type="submit"
                  fullWidth
                  disabled={APPLY_BUTTON.disabled[formStep.slug]}
                  loading={APPLY_BUTTON.pending[formStep.slug]}
                  variant="contained"
                  onClick={APPLY_BUTTON.action[formStep.slug]}
                  sx={{ mt: 2 }}
                >
                  {APPLY_BUTTON.text[formStep.slug]}
                </LoadingButton>

                <Box sx={{ mt: 2, color: "gray.dark", backgroundColor: "gray.deep" }}>
                  <Button color="inherit" onClick={CANCEL_BUTTON.action[formStep.slug]} fullWidth>
                    {CANCEL_BUTTON.text[formStep.slug]}
                  </Button>
                </Box>
              </>
            )}
          </>
        )}
      </AuthBox>
    </>
  );
};
