import LoadingButton from "@mui/lab/LoadingButton";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  MenuItem,
  FormLabel,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";

import {
  ChoiceListItem,
  CustomMenuProps,
  Dropzone,
  FormElementContainer,
  ModalFooter,
} from "src/components";
import { UPLOAD_PLACEHOLDER_TEXT } from "src/constants";
import {
  AccountQualityEnum,
  BuildingFragment,
  TicketHeadingCategoryEnum,
  TicketCategoryEnum,
} from "src/graphql/generated/types";
import { getTicketCategories, getBuildingMainUnits, MainUnit } from "src/utils";

import { CategoriesGrid } from "../CategoriesGrid";

const CreationMessageSuccess = React.lazy(() => import("../CreationSuccessMessage"));

const StyledLabel = styled(FormLabel)`
  margin: ${(props) => props.theme.spacing(1)} 0;
`;

export interface CreateTicketInputs {
  selectedHeadingCategory: TicketHeadingCategoryEnum | "";
  selectedCategory: TicketCategoryEnum | "";
  building: BuildingFragment | undefined;
  mainUnit: MainUnit | undefined;
  description: string;
  documents: Array<File> | null;
}

interface Props {
  loading: boolean;
  mainUnits?: Array<MainUnit> | null;
  buildings?: Array<BuildingFragment> | null;
  isTicketCreated: boolean;
  quality: AccountQualityEnum;
  preselectedCategory?: {
    headingCategory: TicketHeadingCategoryEnum;
    category: TicketCategoryEnum | undefined;
  };
  preFilledDescription?: string;
  handleClose(): void;
  onSubmit(data: CreateTicketInputs): void;
}

export const FormCreateTicket = ({
  loading,
  mainUnits,
  buildings,
  isTicketCreated,
  quality,
  preselectedCategory,
  preFilledDescription,
  handleClose,
  onSubmit,
}: Props) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm<CreateTicketInputs>({
    defaultValues: {
      selectedHeadingCategory: preselectedCategory?.headingCategory ?? "",
      selectedCategory: preselectedCategory?.category ?? "",
      building: {},
      mainUnit: {},
      description: preFilledDescription ?? "",
    },
  });

  const building = watch("building");
  const buildingMainUnits = getBuildingMainUnits(mainUnits, building?.id);

  const selectedHeadingCategory = watch("selectedHeadingCategory");
  const selectedCategory = watch("selectedCategory");
  const description = watch("description");

  const categories = useMemo(
    () => (selectedHeadingCategory ? getTicketCategories(selectedHeadingCategory) : []),
    [selectedHeadingCategory]
  );

  useEffect(() => {
    if (categories.length === 1) setValue("selectedCategory", categories[0].category);
  }, [categories]);

  const handleSelectHeadingCategory = (value: TicketHeadingCategoryEnum) => {
    if (preselectedCategory) return;
    setValue("selectedHeadingCategory", value);
  };

  const formIsNotValid = !selectedHeadingCategory || !selectedCategory || description.length === 0;

  return (
    <>
      {isTicketCreated ? (
        <CreationMessageSuccess handleClose={handleClose} />
      ) : (
        <form>
          <CategoriesGrid
            selectedHeadingCategory={selectedHeadingCategory}
            quality={quality}
            setSelectedHeadingCategory={handleSelectHeadingCategory}
            disabled={!!preselectedCategory}
          />

          <FormElementContainer sx={{ mt: 5, mx: 2, p: 3 }}>
            {mainUnits && (
              <>
                <Box sx={{ mt: 2 }}>
                  <FormControl fullWidth variant="outlined" error={Boolean(errors.building)}>
                    <Controller
                      name="building"
                      control={control}
                      rules={{ required: "Un immeuble est requis" }}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete<BuildingFragment, false, boolean>
                          noOptionsText="Aucun résultat"
                          disableClearable={Boolean(buildings?.length === 1)}
                          value={value}
                          options={buildings || []}
                          renderOption={(props, building) => (
                            <ChoiceListItem {...props}>
                              <Typography>{`Immeuble n°${building.number}`}</Typography>
                              <Typography>{`${building.address.address1} ${building.address.zipCode} ${building.address.city}`}</Typography>
                            </ChoiceListItem>
                          )}
                          onChange={(_event, value) => {
                            if (!value) setValue("mainUnit", undefined);

                            const buildingMainUnits = getBuildingMainUnits(mainUnits, value?.id);
                            if (buildingMainUnits?.length === 1)
                              setValue("mainUnit", buildingMainUnits[0]);

                            onChange(value);
                          }}
                          getOptionLabel={(building) =>
                            building.number ? `Immeuble n°${building.number}` : ""
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="building"
                              label="Sélection d'un immeuble"
                              variant="outlined"
                              error={Boolean(errors.building)}
                            />
                          )}
                        />
                      )}
                    />
                    <FormHelperText>{errors.building?.message}</FormHelperText>
                  </FormControl>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <FormControl fullWidth variant="outlined" error={Boolean(errors.mainUnit)}>
                    <Controller
                      name="mainUnit"
                      control={control}
                      rules={{ required: "Un lot est requis" }}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete<MainUnit>
                          disabled={!building}
                          noOptionsText="Aucun résultat"
                          options={buildingMainUnits || []}
                          renderOption={(props, mainUnit) => (
                            <ChoiceListItem {...props}>
                              <Typography>{`Lot n°${mainUnit.coOwnershipByLawsId}`}</Typography>
                              <Typography>{`Nombre de chambre : ${mainUnit.description.totalRooms?.liveable}`}</Typography>
                              <Typography>{`Surface (loi carrez) : ${mainUnit.description.surface?.liveable}m2`}</Typography>
                            </ChoiceListItem>
                          )}
                          value={value}
                          onChange={(_event, value) => onChange(value)}
                          getOptionLabel={(mainUnit) =>
                            mainUnit.coOwnershipByLawsId
                              ? `Lot n°${mainUnit.coOwnershipByLawsId}`
                              : ""
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="mainUnit"
                              label="Sélection d'un lot"
                              variant="outlined"
                              error={Boolean(errors.mainUnit)}
                            />
                          )}
                        />
                      )}
                    />
                    <FormHelperText>{errors.mainUnit?.message}</FormHelperText>
                  </FormControl>
                </Box>
              </>
            )}

            {categories.length > 1 && (
              <Box sx={{ mt: 2 }}>
                <FormControl fullWidth variant="outlined" error={Boolean(errors.selectedCategory)}>
                  <StyledLabel htmlFor="selectedCategory">Détail de la demande</StyledLabel>
                  <Controller
                    name="selectedCategory"
                    control={control}
                    rules={{ required: "Un objet est requis" }}
                    render={() => (
                      <Select
                        disabled={!!preselectedCategory}
                        style={{ backgroundColor: "#fff" }}
                        variant="outlined"
                        MenuProps={CustomMenuProps}
                        value={selectedCategory}
                        onChange={(e) =>
                          setValue("selectedCategory", e.target.value as TicketCategoryEnum | "")
                        }
                        native={false}
                      >
                        {categories.map(({ label, category }) => (
                          <MenuItem key={label} value={category}>
                            <Typography sx={{ whiteSpace: "pre-wrap" }}>{label}</Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  <FormHelperText data-cy="requests-error-object">
                    {errors.selectedCategory?.message}
                  </FormHelperText>
                </FormControl>
              </Box>
            )}

            <Box sx={{ mt: 2, borderWidth: 1 }}>
              <FormControl fullWidth variant="outlined" error={Boolean(errors.description)}>
                <InputLabel htmlFor="description">Descriptif</InputLabel>
                <Controller
                  name="description"
                  control={control}
                  rules={{
                    required: "Un descriptif est requis",
                    minLength: { value: 2, message: "Descriptif trop court" },
                    maxLength: { value: 1000, message: "Descriptif trop long" },
                  }}
                  render={({ field }) => (
                    <OutlinedInput
                      style={{ backgroundColor: "#fff" }}
                      label="Descriptif"
                      multiline
                      rows={4}
                      {...field}
                    />
                  )}
                />
                <FormHelperText data-cy="requests-error-description">
                  {errors.description?.message}
                </FormHelperText>
              </FormControl>
            </Box>
          </FormElementContainer>

          <Box sx={{ mt: 2, mx: 2 }}>
            <FormElementContainer label="Pièces jointes" sx={{ p: 3 }}>
              <Controller
                name="documents"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Dropzone
                    placeholder={UPLOAD_PLACEHOLDER_TEXT}
                    onChange={onChange}
                    documents={value}
                  />
                )}
              />
            </FormElementContainer>
          </Box>

          <ModalFooter>
            <Button variant="outlined" onClick={handleClose} data-testid="cancel">
              Annuler
            </Button>
            <LoadingButton
              type="submit"
              disabled={formIsNotValid}
              loading={loading}
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              data-testid="send"
            >
              Envoyer
            </LoadingButton>
          </ModalFooter>
        </form>
      )}
    </>
  );
};
