import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DescriptionIcon from "@mui/icons-material/Description";
import GroupsIcon from "@mui/icons-material/Groups";
import HowToVoteIcon from "@mui/icons-material/HowToVote";
import KeySharpIcon from "@mui/icons-material/KeySharp";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import MessageIcon from "@mui/icons-material/Message";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import { Box, BoxProps } from "@mui/material";
import { rem } from "polished";
import React from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import styled from "styled-components";

import {
  ACCOUNTING_PAGE,
  COUNCIL_PAGE,
  DASHBOARD_PAGE,
  DOCUMENTS_PAGE,
  ESTATE_PAGE,
  GENERAL_ASSEMBLY_PAGE,
  OFFERS_AND_SERVICES_PAGE,
  PAYMENTS_PAGE,
  RESTRICTED_DASHBOARD_PAGE,
  TENANTS_BALANCE_PAGE,
  TICKETS_PAGE,
} from "src/constants";
import { AccountQualityEnum, AnalyticsCustomEventNameEnum } from "src/graphql/generated/types";
import { logCustomEvent } from "src/utils";

export interface MainNavItemProps extends NavLinkProps {
  label: string;
  slug: string;
}

export type MainLayoutNavBarProps = {
  hasRestrictedAccess: boolean;
  hasAccessToGeneralAssembly: boolean;
  isTrusteeCouncilMember: boolean;
  accountQuality: AccountQualityEnum;
  closeSidebar(): void;
} & BoxProps;

const NavLinkComponent = (props: NavLinkProps) => <NavLink {...props} />;

const StyledItem = styled(NavLinkComponent)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${rem(60)};
  background: ${(props) => props.theme.palette.white.main};
  border-left: ${rem(6)} solid ${(props) => props.theme.palette.primary.light};
  text-decoration: none;
  padding: 0 ${(props) => props.theme.spacing(3)};

  color: ${(props) => props.theme.palette.primary.main};
  font-size: ${rem(16)};
  font-weight: 500;

  &:not(:first-child) {
    margin-top: 1px;
  }

  svg {
    margin-right: ${(props) => props.theme.spacing(2)};
  }

  &:hover,
  &.active {
    background: ${(props) => props.theme.palette.primary.main};
    border-left-color: ${(props) => props.theme.palette.primary.dark};
    color: ${(props) => props.theme.palette.white.main};
    font-weight: bold;

    svg {
      fill: ${(props) => props.theme.palette.white.main};
    }
  }
`;

export const MainLayoutNavBar = ({
  hasRestrictedAccess,
  hasAccessToGeneralAssembly,
  isTrusteeCouncilMember,
  accountQuality,
  closeSidebar,
  ...boxProps
}: MainLayoutNavBarProps) => {
  return (
    <Box {...boxProps}>
      <StyledItem
        onClick={closeSidebar}
        to={hasRestrictedAccess ? RESTRICTED_DASHBOARD_PAGE : DASHBOARD_PAGE}
      >
        <DashboardIcon />
        Mon tableau de bord
      </StyledItem>
      <StyledItem
        onClick={() => {
          closeSidebar();
          logCustomEvent(AnalyticsCustomEventNameEnum.SidebarTicketsPage);
        }}
        to={TICKETS_PAGE}
      >
        <MessageIcon />
        Mes demandes
      </StyledItem>
      {!hasRestrictedAccess && (
        <StyledItem onClick={closeSidebar} to={ACCOUNTING_PAGE}>
          <AccountBalanceWalletIcon />
          Ma comptabilité
        </StyledItem>
      )}
      {accountQuality !== AccountQualityEnum.Landlord && (
        <StyledItem onClick={closeSidebar} to={PAYMENTS_PAGE}>
          <CreditCardIcon />
          Mes paiements
        </StyledItem>
      )}
      {!hasRestrictedAccess && (
        <StyledItem onClick={closeSidebar} to={OFFERS_AND_SERVICES_PAGE}>
          <RoomServiceIcon />
          Offres et services
        </StyledItem>
      )}
      {!hasRestrictedAccess && isTrusteeCouncilMember && (
        <StyledItem onClick={closeSidebar} to={COUNCIL_PAGE}>
          <GroupsIcon />
          Mon conseil syndical
        </StyledItem>
      )}
      {!hasRestrictedAccess && accountQuality === AccountQualityEnum.Landlord && (
        <StyledItem onClick={closeSidebar} to={TENANTS_BALANCE_PAGE}>
          <MapsHomeWorkIcon />
          Mes locataires
        </StyledItem>
      )}
      {!hasRestrictedAccess && (
        <StyledItem onClick={closeSidebar} to={ESTATE_PAGE}>
          <KeySharpIcon />
          Mes biens
        </StyledItem>
      )}
      <StyledItem onClick={closeSidebar} to={DOCUMENTS_PAGE}>
        <DescriptionIcon />
        Mes documents
      </StyledItem>
      {!hasRestrictedAccess && hasAccessToGeneralAssembly && (
        <StyledItem onClick={closeSidebar} to={GENERAL_ASSEMBLY_PAGE}>
          <HowToVoteIcon />
          Mon assemblée générale
        </StyledItem>
      )}
    </Box>
  );
};
