import AccountIconMUI from "@mui/icons-material/AccountBox";
import DescriptionIconMUI from "@mui/icons-material/Description";
import MessageIconMUI from "@mui/icons-material/Message";
import { LoadingButton } from "@mui/lab";
import { Box, Typography as TypographyMUI } from "@mui/material";
import { rem } from "polished";
import styled, { css } from "styled-components";

import { mobileStyle } from "src/utils";

type ContainerProps = {
  $isAssociate: boolean;
};

export const Container = styled(Box)<ContainerProps>`
  ${({ theme: { spacing, palette } }) =>
    css`
      padding: ${spacing(2)};
      margin: ${spacing(2)} ${spacing(5)} ${spacing(2)} ${spacing(25)};
      color: #c499b9;
      border-radius: ${spacing(1)};
      background-color: ${palette.white.main};
      box-shadow: ${palette.boxShadow};
      ${mobileStyle`
         margin:  ${spacing(3)} ${spacing(0)} ${spacing(1)} ${spacing(3)}
      `}
    `};

  ${({ $isAssociate, theme: { spacing } }) =>
    $isAssociate &&
    css`
      color: #27adb0;
      margin: ${spacing(2)} ${spacing(25)} ${spacing(2)} ${spacing(5)};
      ${mobileStyle`
        margin:  ${spacing(3)} ${spacing(3)} ${spacing(1)} ${spacing(0)}
      `}
    `};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FirstTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const SecondTitle = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.palette.gray.main};
`;

export const Content = styled.div`
  display: flex;
  background-color: #f9fbfe;
  margin: ${rem(15)};
  border-left: ${rem(3)} solid;
`;

export const DocumentContainer = styled.div`
  margin-top: ${rem(20)};
`;

export const DocumentList = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: #f9fbfe;
  padding: ${rem(10)};
`;

export const Document = styled(LoadingButton)`
  color: ${(props) => props.theme.palette.gray.dark};
`;

export const Margin = styled.div`
  margin: ${rem(5)};
`;

export const MobileDateContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  color: ${(props) => props.theme.palette.gray.dark};
  font-weight: bold;
  margin-top: ${rem(10)};
`;

export const Typography = TypographyMUI;

export const MessageIcon = MessageIconMUI;

export const AccountIcon = AccountIconMUI;

export const DescriptionIcon = DescriptionIconMUI;
