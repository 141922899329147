import { IconName } from "src/components";
import { PropertyTypeEnum } from "src/graphql/generated/types";

export const PropertyLabels: { [K in PropertyTypeEnum]: string } = {
  [PropertyTypeEnum.ActivityPremises]: "Local d'activités",
  [PropertyTypeEnum.AdministrativeArea]: "Surface administrative",
  [PropertyTypeEnum.AdvertisementBoard]: "Panneau publicitaire",
  [PropertyTypeEnum.Agricultural]: "Agricole",
  [PropertyTypeEnum.Apartment]: "Appartement",
  [PropertyTypeEnum.ArtisanalPremise]: "Local artisanal",
  [PropertyTypeEnum.Attic]: "Grenier",
  [PropertyTypeEnum.Bakery]: "Boulangerie",
  [PropertyTypeEnum.BikeRoom]: "Local vélo",
  [PropertyTypeEnum.Bar]: "Bar",
  [PropertyTypeEnum.Box]: "Box",
  [PropertyTypeEnum.Brewery]: "Brasserie",
  [PropertyTypeEnum.Building]: "Appartement",
  [PropertyTypeEnum.Cellar]: "Cave",
  [PropertyTypeEnum.CellularAntenna]: "Antenne relais",
  [PropertyTypeEnum.CoffeeDeskTransfer]: "Bureau café cession",
  [PropertyTypeEnum.CommercialPremise]: "Local commercial",
  [PropertyTypeEnum.Cupboard]: "Placard",
  [PropertyTypeEnum.DoctorOffice]: "Cabinet médical",
  [PropertyTypeEnum.Duplex]: "Duplex",
  [PropertyTypeEnum.Empty]: "Nu",
  [PropertyTypeEnum.EmptyWallCommercialProperty]: "Mur vide et fonds de commerce",
  [PropertyTypeEnum.Field]: "Champ",
  [PropertyTypeEnum.Fitted]: "Équipé",
  [PropertyTypeEnum.FittedTreeFilled]: "Équipé et arboré",
  [PropertyTypeEnum.Forest]: "Forêt",
  [PropertyTypeEnum.Garage]: "Garage",
  [PropertyTypeEnum.Garden]: "Jardin",
  [PropertyTypeEnum.Garret]: "Mansarde",
  [PropertyTypeEnum.Grocery]: "Dépôt épicerie",
  [PropertyTypeEnum.Hotel]: "Hôtel",
  [PropertyTypeEnum.HotelRestaurantBrewery]: "Hôtel restaurant/brasserie",
  [PropertyTypeEnum.House]: "Maison",
  [PropertyTypeEnum.Housing]: "Maison",
  [PropertyTypeEnum.IndoorParking]: "Parking intérieur",
  [PropertyTypeEnum.IndustrialCraftLand]: "Terrain industriel / artisanal",
  [PropertyTypeEnum.IndustrialPremise]: "Local industriel",
  [PropertyTypeEnum.IndustrialPremises]: "Locaux industriels",
  [PropertyTypeEnum.InporteParking]: "Parking intérieur",
  [PropertyTypeEnum.Land]: "Terrain",
  [PropertyTypeEnum.Landscape]: "Paysagé",
  [PropertyTypeEnum.Leisure]: "De loisir",
  [PropertyTypeEnum.LivingLocal]: "Local d’habitation",
  [PropertyTypeEnum.Local]: "Local",
  [PropertyTypeEnum.LockUp]: "Box",
  [PropertyTypeEnum.Loft]: "Loft",
  [PropertyTypeEnum.Nc]: "Non communiqué",
  [PropertyTypeEnum.Offices]: "Bureaux",
  [PropertyTypeEnum.Other]: "Autre",
  [PropertyTypeEnum.OutdoorParking]: "Stationnement extérieur",
  [PropertyTypeEnum.OutsideParking]: "Parking extérieur",
  [PropertyTypeEnum.Pantry]: "Cellier",
  [PropertyTypeEnum.Parking]: "Parking",
  [PropertyTypeEnum.ParkingArea]: "Aire de stationnement",
  [PropertyTypeEnum.Pasture]: "Prairie",
  [PropertyTypeEnum.PortCleat]: "Anneau de port",
  [PropertyTypeEnum.ProfessionalPremise]: "Local professionnel",
  [PropertyTypeEnum.ProfessionalRetailer]: "Local commercial",
  [PropertyTypeEnum.Rental]: "Location",
  [PropertyTypeEnum.Reserved]: "Réserve",
  [PropertyTypeEnum.Restaurant]: "Restaurant",
  [PropertyTypeEnum.Room]: "Chambre",
  [PropertyTypeEnum.Sale]: "Vente",
  [PropertyTypeEnum.Showcase]: "Vitrine",
  [PropertyTypeEnum.Souplex]: "Souplex",
  [PropertyTypeEnum.Store]: "Magasin",
  [PropertyTypeEnum.Storeroom]: "Cellier",
  [PropertyTypeEnum.Studio]: "Studio",
  [PropertyTypeEnum.TeaRoom]: "Salon de thé",
  [PropertyTypeEnum.TechnicalPremise]: "Local technique",
  [PropertyTypeEnum.Terrace]: "Terrasse",
  [PropertyTypeEnum.Toilets]: "Sanitaire",
  [PropertyTypeEnum.TreeFilled]: "Arboré",
  [PropertyTypeEnum.Triplex]: "Triplex",
  [PropertyTypeEnum.Undefined]: "Non défini",
  [PropertyTypeEnum.UninhabitedRoom]: "Pièce",
  [PropertyTypeEnum.UrbanAbandonedLand]: "Terrain urbain / abandonné",
  [PropertyTypeEnum.Villa]: "Villa",
  [PropertyTypeEnum.Warehouse]: "Entrepôt",
  [PropertyTypeEnum.Warehouses]: "Entrepôts",
  [PropertyTypeEnum.Diverse]: "Divers",
};

export const PropertyColor = {
  box: "#f24ca5",
  cellar: "#44d7b6",
  parking: "#eb690b",
  home: "#4a90e2",
} as const;

export interface PropertyIcon {
  icon: IconName;
  color?: string;
}

const iconBox: PropertyIcon = {
  icon: "box",
  color: PropertyColor.box,
};

const iconCellar: PropertyIcon = {
  icon: "cellar",
  color: PropertyColor.cellar,
};

const iconParking: PropertyIcon = {
  icon: "parking",
  color: PropertyColor.parking,
};

const iconHome: PropertyIcon = {
  icon: "home",
  color: PropertyColor.home,
};

export const PropertyIcons: { [K in PropertyTypeEnum]: PropertyIcon } = {
  [PropertyTypeEnum.Box]: iconBox,
  [PropertyTypeEnum.Cellar]: iconCellar,
  [PropertyTypeEnum.Parking]: iconParking,
  [PropertyTypeEnum.OutsideParking]: iconParking,
  [PropertyTypeEnum.IndoorParking]: iconParking,
  [PropertyTypeEnum.InporteParking]: iconParking,
  [PropertyTypeEnum.Garage]: iconParking,
  [PropertyTypeEnum.PortCleat]: iconHome,
  [PropertyTypeEnum.Studio]: iconHome,
  [PropertyTypeEnum.Room]: iconHome,
  [PropertyTypeEnum.Duplex]: iconHome,
  [PropertyTypeEnum.House]: iconHome,
  [PropertyTypeEnum.Offices]: iconHome,
  [PropertyTypeEnum.Pantry]: iconHome,
  [PropertyTypeEnum.Warehouse]: iconHome,
  [PropertyTypeEnum.Attic]: iconHome,
  [PropertyTypeEnum.Local]: iconHome,
  [PropertyTypeEnum.CommercialPremise]: iconHome,
  [PropertyTypeEnum.ProfessionalPremise]: iconHome,
  [PropertyTypeEnum.TechnicalPremise]: iconHome,
  [PropertyTypeEnum.Loft]: iconHome,
  [PropertyTypeEnum.Garret]: iconHome,
  [PropertyTypeEnum.AdvertisementBoard]: iconHome,
  [PropertyTypeEnum.Reserved]: iconHome,
  [PropertyTypeEnum.Toilets]: iconHome,
  [PropertyTypeEnum.Land]: iconHome,
  [PropertyTypeEnum.Terrace]: iconHome,
  [PropertyTypeEnum.Apartment]: iconHome,
  [PropertyTypeEnum.Garden]: iconHome,
  [PropertyTypeEnum.CellularAntenna]: iconHome,
  [PropertyTypeEnum.Souplex]: iconHome,
  [PropertyTypeEnum.Triplex]: iconHome,
  [PropertyTypeEnum.Undefined]: iconHome,
  [PropertyTypeEnum.Villa]: iconHome,
  [PropertyTypeEnum.Warehouses]: iconHome,
  [PropertyTypeEnum.ActivityPremises]: iconHome,
  [PropertyTypeEnum.IndustrialPremises]: iconHome,
  [PropertyTypeEnum.Showcase]: iconHome,
  [PropertyTypeEnum.Nc]: iconHome,
  [PropertyTypeEnum.AdministrativeArea]: iconHome,
  [PropertyTypeEnum.Agricultural]: iconHome,
  [PropertyTypeEnum.ArtisanalPremise]: iconHome,
  [PropertyTypeEnum.Bakery]: iconHome,
  [PropertyTypeEnum.Bar]: iconHome,
  [PropertyTypeEnum.BikeRoom]: iconHome,
  [PropertyTypeEnum.Brewery]: iconHome,
  [PropertyTypeEnum.CoffeeDeskTransfer]: iconHome,
  [PropertyTypeEnum.Empty]: iconHome,
  [PropertyTypeEnum.EmptyWallCommercialProperty]: iconHome,
  [PropertyTypeEnum.Field]: iconHome,
  [PropertyTypeEnum.Fitted]: iconHome,
  [PropertyTypeEnum.FittedTreeFilled]: iconHome,
  [PropertyTypeEnum.Forest]: iconHome,
  [PropertyTypeEnum.Grocery]: iconHome,
  [PropertyTypeEnum.Hotel]: iconHome,
  [PropertyTypeEnum.HotelRestaurantBrewery]: iconHome,
  [PropertyTypeEnum.IndustrialCraftLand]: iconHome,
  [PropertyTypeEnum.IndustrialPremise]: iconHome,
  [PropertyTypeEnum.Landscape]: iconHome,
  [PropertyTypeEnum.Leisure]: iconHome,
  [PropertyTypeEnum.LockUp]: iconHome,
  [PropertyTypeEnum.OutdoorParking]: iconHome,
  [PropertyTypeEnum.Pasture]: iconHome,
  [PropertyTypeEnum.Rental]: iconHome,
  [PropertyTypeEnum.Restaurant]: iconHome,
  [PropertyTypeEnum.Sale]: iconHome,
  [PropertyTypeEnum.TeaRoom]: iconHome,
  [PropertyTypeEnum.TreeFilled]: iconHome,
  [PropertyTypeEnum.UrbanAbandonedLand]: iconHome,
  [PropertyTypeEnum.Housing]: iconHome,
  [PropertyTypeEnum.Building]: iconHome,
  [PropertyTypeEnum.ProfessionalRetailer]: iconHome,
  [PropertyTypeEnum.DoctorOffice]: iconHome,
  [PropertyTypeEnum.Other]: iconHome,
  [PropertyTypeEnum.UninhabitedRoom]: iconHome,
  [PropertyTypeEnum.Cupboard]: iconHome,
  [PropertyTypeEnum.Storeroom]: iconHome,
  [PropertyTypeEnum.Store]: iconHome,
  [PropertyTypeEnum.LivingLocal]: iconHome,
  [PropertyTypeEnum.ParkingArea]: iconHome,
  [PropertyTypeEnum.Diverse]: iconHome,
};
