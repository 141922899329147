import { MenuProps } from "@mui/material";
import { rem } from "polished";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const CustomMenuProps: Partial<MenuProps> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: -8,
    horizontal: "left",
  },
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: rem(200),
    },
  },
};
