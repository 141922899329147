import { Box } from "@mui/material";
import React from "react";

import { TicketStatusLabel, ChipTicketStatusColor, TicketStatusTextColor } from "src/constants";
import { TicketStatusEnum } from "src/graphql/generated/types";

type Props = {
  status: TicketStatusEnum;
};

export const ChipTicketStatus = ({ status }: Props) => {
  return (
    <Box
      sx={{
        backgroundColor: ChipTicketStatusColor[status],
        color: TicketStatusTextColor[status],
        fontWeight: "bold",
        borderRadius: 1,
        px: 2,
        py: 0.5,
      }}
      data-testid="ticketStatus"
    >
      {TicketStatusLabel[status]}
    </Box>
  );
};
