import { Box, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";

import ImageLogoFoncia from "src/assets/images/logo-foncia.svg";
import { mobileStyle } from "src/utils";

const StyledContainer = styled(Box)`
  display: flex;
  flex: 1;
  align-items: center;
`;

const StyledBox = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 600px;
  min-height: 600px;
  margin-top: ${(props) => props.theme.spacing(2)};
  margin-bottom: ${(props) => props.theme.spacing(2)};
  background-color: ${(props) => props.theme.palette.white.main};
  border-radius: ${(props) => props.theme.spacing(2)};
  overflow: hidden;
  padding: ${(props) => props.theme.spacing(4)};

  ${mobileStyle`
    width: calc(100vw - ${(props) => props.theme.spacing(2)});
    padding: ${(props) => props.theme.spacing(2)};
  `}
`;

type Props = {
  title?: string | null;
  children: React.ReactNode;
};

export const AuthBox = ({ title = "Bienvenue sur MyFoncia !", children }: Props) => {
  return (
    <StyledContainer>
      <StyledBox>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img src={ImageLogoFoncia} width={170} alt="logo foncia" />
        </Box>

        {title && (
          <Typography
            variant="h6"
            color="secondary"
            textAlign="center"
            fontWeight="bold"
            sx={{ textAlign: "center", mb: 2, mt: 1 }}
          >
            {title}
          </Typography>
        )}

        {children}
      </StyledBox>
    </StyledContainer>
  );
};
