import React, { Suspense, useEffect } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  generatePath,
  useParams,
} from "react-router-dom";

import { ErrorGlobal, Loader } from "src/components";
import {
  ACCOUNTING_PAGE,
  API_MAINTENANCE_CODE,
  AccountPageParams,
  BUILDING_ACCOUNTING_PAGE,
  COUNCIL_ACCOUNTING_HOME_PAGE,
  COUNCIL_ACCOUNTING_PAGE,
  COUNCIL_ACCOUNTING_REPAIR_BUDGETS_PAGE,
  COUNCIL_ACCOUNTING_RGDD_PAGE,
  COUNCIL_COOWNERS_PAGE,
  COUNCIL_DOCUMENTS_PAGE,
  COUNCIL_INCIDENTS_PAGE,
  COUNCIL_MEETINGS_PAGE,
  COUNCIL_PAGE,
  COUNCIL_SERVICES_PAGE,
  COUNCIL_SUPPLIERS_PAGE,
  COUNCIL_VISITS_PAGE,
  COUNCIL_WORKS_PAGE,
  DASHBOARD_PAGE,
  DOCUMENTS_OKFONCIA_PAGE,
  DOCUMENTS_PAGE,
  ESTATE_PAGE,
  GENERAL_ASSEMBLY_PAGE,
  GLOBAL_ACCOUNTING_PAGE,
  OFFERS_AND_SERVICES_PAGE,
  PATH_AUTH,
  PATH_SELECTED_ACCOUNT_BASE,
  PAYMENTS_PAGE,
  PAYMENT_AUTOMATIC_PAGE,
  PAYMENT_DALENYS_PAGE,
  PAYMENT_ON_DEMAND_PAGE,
  POSTAL_VOTING_PAGE,
  PROFILE_CONFIDENTIALITY_PAGE,
  PROFILE_CREDENTIALS_PAGE,
  PROFILE_INFOS_PAGE,
  PROFILE_LANDLORD_BANKINFOS_PAGE,
  PROFILE_PAGE,
  PROFILE_PAYMENTS_PAGE,
  PROFILE_PREFERENCES_PAGE,
  PROPERTY_TAXES_FORM,
  PROPERTY_TAX_FORM,
  REACT_QUERY_STALE_TIME,
  RESTRICTED_DASHBOARD_PAGE,
  TENANTS_BALANCE_PAGE,
  TICKETS_PAGE,
  TICKET_MESSAGES_PAGE,
} from "src/constants";
import { ERecoProvider, ModalPaymentProvider } from "src/contexts";
import { AccountQualityEnum, MyFonciaFileCategoryEnum } from "src/graphql/generated/types";
import {
  useCustomInfiniteGetBasicAccountsQuery,
  useCurrentAccount,
  useHasBlockedPayment,
} from "src/hooks";
import { TicketMessages } from "src/pages/TicketMessages";
import { useAuthStore } from "src/stores/auth-store";
import { decodeJWT, isTrusteeCouncilMember, setCurrentQuality } from "src/utils";

import { AccountWrapper } from "../AccountWrapper";
import { AccountingLayout } from "../AccountingLayout";
import { CouncilAccountingLayout } from "../CouncilAccountingLayout";
import { CouncilLayout } from "../CouncilLayout";
import { CouncilServicesLayout } from "../CouncilServicesLayout";
import { CustomerWrapper } from "../CustomerWrapper";
import lazyWithRetry from "../LazyWithRetry";
import { MainLayout } from "../MainLayout";
import { ProfileLayout } from "../ProfileLayout";
import SecurityLevel from "../SecurityLevel";
import { ScrollRestoration } from "./_components/ScrollRestauration";
import { Wisepops } from "./_components/Wisepops";

const Accounting = lazyWithRetry(() => import("src/pages/Accounting"));
const GlobalAccounting = lazyWithRetry(() => import("src/pages/GlobalAccounting"));
const BuildingAccounting = lazyWithRetry(() => import("src/pages/BuildingAccounting"));
const CouncilAccountingHome = lazyWithRetry(() => import("src/pages/CouncilAccountingHome"));
// const CouncilAccountingRepair = lazyWithRetry(() => import("src/pages/CouncilAccountingRepair"));
const CouncilAccountingRGDD = lazyWithRetry(() => import("src/pages/CouncilAccountingRGDD"));
const CouncilAccountingRepairBudgets = lazyWithRetry(
  () => import("src/pages/CouncilAccountingRepairBudgets")
);
const CouncilCoowners = lazyWithRetry(() => import("src/pages/CouncilCoowners"));
const CouncilIncidents = lazyWithRetry(() => import("src/pages/CouncilIncidents"));
const CouncilMeetings = lazyWithRetry(() => import("src/pages/CouncilMeetings"));
const CouncilProjectDocuments = lazyWithRetry(() => import("src/pages/CouncilProjectDocuments"));
const CouncilSuppliers = lazyWithRetry(() => import("src/pages/CouncilSuppliers"));
const CouncilVisits = lazyWithRetry(() => import("src/pages/CouncilVisits"));
const CouncilWorks = lazyWithRetry(() => import("src/pages/CouncilWorks"));
const Dashboard = lazyWithRetry(() => import("src/pages/Dashboard"));
const DashboardRestricted = lazyWithRetry(() => import("src/pages/DashboardRestricted"));
const DocumentCategories = lazyWithRetry(() => import("src/pages/DocumentCategories"));
const DocumentContractList = lazyWithRetry(() => import("src/pages/DocumentContractList"));
const DocumentERecoList = lazyWithRetry(() => import("src/pages/DocumentERecoList"));
const DocumentGAList = lazyWithRetry(() => import("src/pages/DocumentGAList"));
const DocumentList = lazyWithRetry(() => import("src/pages/DocumentList"));
const DocumentOkFoncia = lazyWithRetry(() => import("src/pages/DocumentOkFoncia"));
const Estate = lazyWithRetry(() => import("src/pages/Estate"));
const TenantsBalance = lazyWithRetry(() => import("src/pages/TenantsBalance"));

const GeneralAssembly = lazyWithRetry(() => import("src/pages/GeneralAssembly"));
const GeneralAssemblyResolutions = lazyWithRetry(
  () => import("src/pages/GeneralAssemblyResolutions")
);

const OffersAndServices = lazyWithRetry(() => import("src/pages/OffersAndServices"));
const PaymentAutomatic = lazyWithRetry(() => import("src/pages/PaymentAutomatic"));
const PaymentDalenys = lazyWithRetry(() => import("src/pages/PaymentDalenys"));
const PaymentOnDemand = lazyWithRetry(() => import("src/pages/PaymentOnDemand"));
const Payments = lazyWithRetry(() => import("src/pages/Payments"));
const ProfileConfidentiality = lazyWithRetry(() => import("src/pages/ProfileConfidentiality"));
const ProfileCredentials = lazyWithRetry(() => import("src/pages/ProfileCredentials"));
const ProfileInfos = lazyWithRetry(() => import("src/pages/ProfileInfos"));
const ProfileLandlordBankInfos = lazyWithRetry(() => import("src/pages/ProfileLandlordBankInfos"));
const ProfilePayment = lazyWithRetry(() => import("src/pages/ProfilePayment"));
const ProfilePreferences = lazyWithRetry(() => import("src/pages/ProfilePreferences"));

const SelectAccount = lazyWithRetry(() => import("src/pages/SelectAccount"));

const Tickets = lazyWithRetry(() => import("src/pages/_shared/Tickets"));
const UploadLandlordPropertyTax = lazyWithRetry(
  () => import("src/pages/_shared/UploadLandlordPropertyTax")
);
const UploadLandlordropertyTaxes = lazyWithRetry(
  () => import("src/pages/_shared/UploadLandlordPropertyTaxes")
);

// LAZY IMPORT END

interface JWTProps {
  agencies: Array<string>;
  appId: "myfoncia";
  customerIds: Array<string>;
  exp: number;
  iat: number;
  id: string;
  lessorAccounts: Array<{ agencyRef: number; id: string }>;
  qualities: Array<AccountQualityEnum>;
  role: "CUSTOMER";
  type: "Customer";
  iss?: string; // new tokens (2025 okta migration)
}

const CouncilAccountingPages = () => (
  <CouncilAccountingLayout>
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route index element={<Navigate to={COUNCIL_ACCOUNTING_HOME_PAGE} />} />
        <Route path={COUNCIL_ACCOUNTING_HOME_PAGE} element={<CouncilAccountingHome />} />
        <Route path={COUNCIL_ACCOUNTING_RGDD_PAGE} element={<CouncilAccountingRGDD />} />
        {/* <Route path={COUNCIL_ACCOUNTING_REPAIR_PAGE} element={<CouncilAccountingRepair />} /> */}
        <Route
          path={COUNCIL_ACCOUNTING_REPAIR_BUDGETS_PAGE}
          element={<CouncilAccountingRepairBudgets />}
        />
      </Routes>
    </Suspense>
  </CouncilAccountingLayout>
);

const CouncilServicesPages = () => (
  <CouncilServicesLayout>
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route index element={<Navigate to={COUNCIL_WORKS_PAGE} />} />
        <Route path={COUNCIL_WORKS_PAGE} element={<CouncilWorks />} />
        <Route path={COUNCIL_INCIDENTS_PAGE} element={<CouncilIncidents />} />
      </Routes>
    </Suspense>
  </CouncilServicesLayout>
);

const CouncilPages = () => (
  <CouncilLayout>
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route index element={<Navigate to={COUNCIL_MEETINGS_PAGE} />} />
        <Route path={COUNCIL_MEETINGS_PAGE} element={<CouncilMeetings />} />
        <Route path={`${COUNCIL_SERVICES_PAGE}/*`} element={<CouncilServicesPages />} />
        <Route path={COUNCIL_COOWNERS_PAGE} element={<CouncilCoowners />} />
        <Route path={COUNCIL_SUPPLIERS_PAGE} element={<CouncilSuppliers />} />
        <Route path={COUNCIL_VISITS_PAGE} element={<CouncilVisits />} />
        <Route path={`${COUNCIL_ACCOUNTING_PAGE}/*`} element={<CouncilAccountingPages />} />
        <Route path={COUNCIL_DOCUMENTS_PAGE} element={<CouncilProjectDocuments />} />
      </Routes>
    </Suspense>
  </CouncilLayout>
);

const ProfilePages = () => {
  const currentAccount = useCurrentAccount();
  const hasBlockedPayment = useHasBlockedPayment();

  let bankRoute = null;
  if (currentAccount.__typename === "LandlordAccount") {
    bankRoute = (
      <Route path={PROFILE_LANDLORD_BANKINFOS_PAGE} element={<ProfileLandlordBankInfos />} />
    );
  } else if (!hasBlockedPayment) {
    bankRoute = <Route path={PROFILE_PAYMENTS_PAGE} element={<ProfilePayment />} />;
  }

  return (
    <ProfileLayout
      accountQuality={currentAccount.quality}
      canSeeServices={
        !currentAccount.hasRestrictedAccess &&
        currentAccount.__typename === "CoownerAccount" &&
        currentAccount.hasActiveCoOwnershipMandate
      }
    >
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route index element={<Navigate to={PROFILE_CREDENTIALS_PAGE} />} />
          <Route path={PROFILE_CREDENTIALS_PAGE} element={<ProfileCredentials />} />
          <Route path={PROFILE_PAYMENTS_PAGE} element={<ProfilePayment />} />
          <Route path={PROFILE_INFOS_PAGE} element={<ProfileInfos />} />
          {bankRoute}
          <Route path={PROFILE_CONFIDENTIALITY_PAGE} element={<ProfileConfidentiality />} />
          <Route path={PROFILE_PREFERENCES_PAGE} element={<ProfilePreferences />} />
        </Routes>
      </Suspense>
    </ProfileLayout>
  );
};

const LandlordAccountingPages = () => {
  return (
    <AccountingLayout>
      <Routes>
        <Route index element={<Navigate to={GLOBAL_ACCOUNTING_PAGE} />} />
        <Route path={BUILDING_ACCOUNTING_PAGE} element={<BuildingAccounting />} />
        <Route path={GLOBAL_ACCOUNTING_PAGE} element={<GlobalAccounting />} />
      </Routes>
    </AccountingLayout>
  );
};

const PaymentPages = () => {
  const currentAccount = useCurrentAccount();
  const { uuid } = useParams<AccountPageParams>();

  if (!uuid) return null;
  // fixme: uuid can really be null ??
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route index element={<Payments />} />
        <Route path={PAYMENTS_PAGE} element={<Payments />} />
        {!currentAccount.hasRestrictedAccess && (
          <Route path={PAYMENT_AUTOMATIC_PAGE} element={<PaymentAutomatic />} />
        )}
        <Route path={PAYMENT_ON_DEMAND_PAGE} element={<PaymentOnDemand />} />
      </Routes>
    </Suspense>
  );
};

const TicketsPage = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route index element={<Tickets />} />
        <Route path={TICKETS_PAGE} element={<Tickets />} />
        <Route path={TICKET_MESSAGES_PAGE} element={<TicketMessages />} />
      </Routes>
    </Suspense>
  );
};

const DocumentPages = () => {
  const currentAccount = useCurrentAccount();

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/:categoryKey" element={<DocumentList />} />
        <Route path={MyFonciaFileCategoryEnum.GeneralAssembly} element={<DocumentGAList />} />
        <Route path={MyFonciaFileCategoryEnum.Contract} element={<DocumentContractList />} />
        <Route path={DOCUMENTS_OKFONCIA_PAGE} element={<DocumentOkFoncia />} />
        {currentAccount.quality === AccountQualityEnum.Landlord && (
          <Route
            path={PROPERTY_TAXES_FORM}
            element={<UploadLandlordropertyTaxes uuid={currentAccount.uuid} />}
          />
        )}
        <Route
          path={MyFonciaFileCategoryEnum.ElectroRegistredMailSubscription}
          element={<DocumentERecoList />}
        />
      </Routes>
    </Suspense>
  );
};

const AccountPages = () => {
  const currentAccount = useCurrentAccount();
  const logout = useAuthStore((state) => state.logout);
  const token = useAuthStore((state) => state.token);
  const { hasRestrictedAccess, __typename: accountType } = currentAccount;

  useEffect(() => {
    // @TODO this code may be obsolete (old enough to already have disconnected a lot of users)
    // @note: disconnect landlords who have an outdated token
    if (token && accountType === "LandlordAccount") {
      const decodedToken = decodeJWT<JWTProps>(token);
      if (!decodedToken?.payload?.agencies || !decodedToken?.payload?.lessorAccounts) {
        // prevent logout for new tokens (2025 okta migration)
        if (!decodedToken?.payload.iss) {
          logout();
        }
      }
    }
  }, [token]);

  useEffect(() => {
    setCurrentQuality(currentAccount.quality);
  }, [currentAccount]);

  return (
    <ERecoProvider>
      <ModalPaymentProvider>
        <MainLayout>
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route
                index
                element={
                  <Navigate to={hasRestrictedAccess ? RESTRICTED_DASHBOARD_PAGE : DASHBOARD_PAGE} />
                }
              />

              {hasRestrictedAccess ? (
                <Route path={RESTRICTED_DASHBOARD_PAGE} element={<DashboardRestricted />} />
              ) : (
                <Route path={DASHBOARD_PAGE} element={<Dashboard />} />
              )}

              {!hasRestrictedAccess && accountType === "LandlordAccount" && (
                <Route path={`${ACCOUNTING_PAGE}/*`} element={<LandlordAccountingPages />} />
              )}

              <Route path={`${TICKETS_PAGE}/*`} element={<TicketsPage />} />

              {!hasRestrictedAccess && accountType !== "LandlordAccount" && (
                <Route path={ACCOUNTING_PAGE} element={<Accounting />} />
              )}
              {accountType !== "LandlordAccount" && (
                <Route path={`${PAYMENTS_PAGE}/*`} element={<PaymentPages />} />
              )}
              {!hasRestrictedAccess && isTrusteeCouncilMember(currentAccount) && (
                <Route path={`${COUNCIL_PAGE}/*`} element={<CouncilPages />} />
              )}
              {!hasRestrictedAccess && (
                <Route path={`${PROFILE_PAGE}/*`} element={<ProfilePages />} />
              )}
              {!hasRestrictedAccess && (
                <Route path={TENANTS_BALANCE_PAGE} element={<TenantsBalance />} />
              )}
              {!hasRestrictedAccess && <Route path={ESTATE_PAGE} element={<Estate />} />}
              {!hasRestrictedAccess && (
                <Route path={OFFERS_AND_SERVICES_PAGE} element={<OffersAndServices />} />
              )}
              <Route path={DOCUMENTS_PAGE} element={<DocumentCategories />} />
              <Route path={DOCUMENTS_OKFONCIA_PAGE} element={<DocumentOkFoncia />} />

              {accountType === "LandlordAccount" && (
                <Route
                  path={`${PROPERTY_TAX_FORM}/*`}
                  element={<UploadLandlordPropertyTax uuid={currentAccount.uuid} />}
                />
              )}

              {!hasRestrictedAccess && isTrusteeCouncilMember(currentAccount) && (
                <Route path={`${COUNCIL_PAGE}/*`} element={<CouncilPages />} />
              )}

              {!hasRestrictedAccess && (
                <Route path={`${GENERAL_ASSEMBLY_PAGE}/*`} element={<GeneralAssembly />} />
              )}

              {!hasRestrictedAccess && (
                <Route path={POSTAL_VOTING_PAGE} element={<GeneralAssemblyResolutions />} />
              )}

              <Route path={`${DOCUMENTS_PAGE}/*`} element={<DocumentPages />} />
            </Routes>
          </Suspense>
        </MainLayout>
      </ModalPaymentProvider>
    </ERecoProvider>
  );
};

const AccountsPages = () => {
  const logout = useAuthStore((state) => state.logout);

  const {
    data,
    error,
    refetch,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useCustomInfiniteGetBasicAccountsQuery({
    staleTime: REACT_QUERY_STALE_TIME,
  });

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  const accountsData = data?.pages?.flatMap((page) => page.accounts) || [];

  if (error) {
    return (
      <ErrorGlobal
        isInMaintenance={
          error.response?.errors?.some((e) => e.extensions?.code === API_MAINTENANCE_CODE) || false
        }
        title="Erreur comptes client"
        description="Une erreur est survenue lors de la récupération des comptes client"
        onRefetch={refetch}
        onLogout={logout}
      />
    );
  }

  if (!data) {
    return <Loader />;
  }

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {accountsData.length === 1 && (
          <Route
            path={PATH_AUTH}
            element={
              <Navigate
                replace
                to={generatePath(PATH_SELECTED_ACCOUNT_BASE, {
                  uuid: accountsData[0].uuid,
                })}
              />
            }
          />
        )}
        <Route
          path={PATH_AUTH}
          element={
            <SelectAccount
              accounts={accountsData}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
            />
          }
        />
        <Route path="*" element={<Navigate replace to={PATH_AUTH} />} />
      </Routes>
    </Suspense>
  );
};

export const AuthenticatedPages = () => {
  return (
    <Routes>
      <Route
        path={`${PATH_SELECTED_ACCOUNT_BASE}/*`}
        element={
          <AccountWrapper>
            <CustomerWrapper>
              <AccountPages />
            </CustomerWrapper>
          </AccountWrapper>
        }
      />
      <Route path={PAYMENT_DALENYS_PAGE} element={<PaymentDalenys />} />
      <Route path="*" element={<AccountsPages />} />
    </Routes>
  );
};

const AppRouter = () => {
  return (
    <Router>
      <ScrollRestoration />
      <Wisepops />
      <Suspense fallback={<Loader />}>
        <SecurityLevel />
      </Suspense>
    </Router>
  );
};

export default AppRouter;
