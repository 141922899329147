import { PlatoErrorCodesEnum } from "src/graphql/generated/types";

export const PlatoErrors: { [key in PlatoErrorCodesEnum]: string } = {
  // Iban
  [PlatoErrorCodesEnum.IbanBicMismatch]: "Le BIC et l'IBAN ne correspondent pas",
  [PlatoErrorCodesEnum.SddNotSupported]:
    "Votre compte bancaire n'autorise pas les prélèvements SEPA",

  // Login
  [PlatoErrorCodesEnum.LoginNotFound]: "Vos identifiants sont introuvables",
  [PlatoErrorCodesEnum.CoownerUnitsSold]: "Votre bien a été vendu",
  [PlatoErrorCodesEnum.CoownerMandatesOutdated]: "Votre mandat de gestion du syndic a expiré",
  [PlatoErrorCodesEnum.LessorManagementOutdated]: "Votre contrat de gestion locative a expiré",
  [PlatoErrorCodesEnum.LessorMandateOutdated]: "Votre mandat de gestion locative a expiré",
  [PlatoErrorCodesEnum.LeaseOutdated]: "Votre bail a expiré",

  // Update login
  [PlatoErrorCodesEnum.MultiCustomerUser]:
    "Vous ne pouvez pas changer votre identifiant lorsque vous avez plusieurs comptes clients",
  [PlatoErrorCodesEnum.LoginAlreadyChanged]: "Votre identifiant a déjà été modifié",
  [PlatoErrorCodesEnum.LoginAlreadyExists]: "L'identifiant que vous avez choisi existe déjà",
  [PlatoErrorCodesEnum.TokenInvalidOrExpired]:
    "Le lien de changement d'identifiant a expiré, veuillez recommencer",

  // Temporary login
  [PlatoErrorCodesEnum.FirstConnectionBadPassword]: "Mot de passe erroné",
  [PlatoErrorCodesEnum.FirstConnectionPasswordFromTemporaryLogin]: "Mot de passe erroné",

  // Debit
  [PlatoErrorCodesEnum.DebitAlreadyExists]:
    "Votre paiement a déjà été pris en compte. Il apparaîtra sur votre espace MyFoncia dans quelques instants.",
};
