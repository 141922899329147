import type { AccountQualityEnum, AccountFragment } from "src/graphql/generated/types";

import { logger } from "../logger";

export function isTrusteeCouncilMember(account: AccountFragment): boolean {
  return account.__typename === "CoownerAccount" && account.isTrusteeCouncilMember;
}

export function decodeJWT<T>(jwt: string) {
  const base64UrlDecode = (str: string) => {
    let base64 = str.replace(/-/g, "+").replace(/_/g, "/");
    while (base64.length % 4) {
      base64 += "=";
    }
    return atob(base64);
  };

  try {
    const parts = jwt.split(".");
    if (parts.length !== 3) {
      throw new Error("JWT does not have 3 parts!");
    }

    const header = JSON.parse(base64UrlDecode(parts[0]));
    const payload: T = JSON.parse(base64UrlDecode(parts[1]));

    return { header, payload };
  } catch (err) {
    logger.error(`Failed to decode JWT with errror: ${err}`);
    return null;
  }
}

export function decodeBase64UUID(
  encodedUUID: string
): {
  quality: AccountQualityEnum;
  customerId: string;
  accountId: string;
} {
  const decodedString = atob(encodedUUID);

  const decodedObject = JSON.parse(decodedString);

  return decodedObject;
}
