import { MediaPaymentStatusEnum, DebitInstructionDayEnum } from "src/graphql/generated/types";

import { PAYMENTS_PAGE, PROFILE_PAGE } from "./pages";

export const PaymentStatusLabel: {
  [K in MediaPaymentStatusEnum]: string;
} = {
  [MediaPaymentStatusEnum.Awaiting]: "En attente",
  [MediaPaymentStatusEnum.Cancelled]: "Annulé",
  [MediaPaymentStatusEnum.Consolidated]: "En traitement",
  [MediaPaymentStatusEnum.Processed]: "Validé",
  [MediaPaymentStatusEnum.Rejected]: "Rejeté",
  [MediaPaymentStatusEnum.Standby]: "En attente",
  [MediaPaymentStatusEnum.ToProcess]: "En attente de traitement",
  [MediaPaymentStatusEnum.Suspended]: "Suspendu",
  [MediaPaymentStatusEnum.ErrorProcess]: "Erreur",
  [MediaPaymentStatusEnum.Processing]: "Traité",
  [MediaPaymentStatusEnum.Transfered]: "Transféré",
};

export const PaymentStatusColor: {
  [K in MediaPaymentStatusEnum]: string;
} = {
  [MediaPaymentStatusEnum.Awaiting]: "#FFBA74",
  [MediaPaymentStatusEnum.Cancelled]: "#FF5F6C",
  [MediaPaymentStatusEnum.Consolidated]: "#4C84FF",
  [MediaPaymentStatusEnum.Processed]: "#7ED321",
  [MediaPaymentStatusEnum.Rejected]: "#FF5F6C",
  [MediaPaymentStatusEnum.Standby]: "#FFBA74",
  [MediaPaymentStatusEnum.ToProcess]: "#4C84FF",
  [MediaPaymentStatusEnum.Suspended]: "#FF5F6C",
  [MediaPaymentStatusEnum.ErrorProcess]: "#FF5F6C",
  [MediaPaymentStatusEnum.Processing]: "#4C84FF",
  [MediaPaymentStatusEnum.Transfered]: "#7ED321",
};

export enum PaymentPageOriginEnum {
  Profile = "PROFILE",
  PaymentSummary = "PAYMENT_SUMMARY",
}

export const PaymentPagePath: {
  [k in PaymentPageOriginEnum]: string;
} = {
  [PaymentPageOriginEnum.Profile]: `${PROFILE_PAGE}/${PAYMENTS_PAGE}`,
  [PaymentPageOriginEnum.PaymentSummary]: PAYMENTS_PAGE,
};

export type PaymentHistoryState = {
  pageOrigin: PaymentPageOriginEnum;
};

export const DEBIT_INSTRUCTION_DAYS = Object.values(DebitInstructionDayEnum);
