export function downloadFile(args: { url: string; filename: string }) {
  const link = document.createElement("a");
  link.href = args.url;
  link.download = args.filename;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  link.remove();
  // Defer the call to avoid crash on safari iOs
  window.setTimeout(() => {
    URL.revokeObjectURL(args.url);
  });
}
