import { Box, BoxProps, Button } from "@mui/material";
import { rem } from "polished";
import React from "react";
import styled from "styled-components";

import { AccountQualityImageSource, AccountQualityLabels } from "src/constants";
import { AccountQualityEnum, AccountFragment, CustomerFragment } from "src/graphql/generated/types";
import { getCustomerDisplayName } from "src/utils";

import { ContentEstate } from "../ContentStateProps";

const StyledRootBox = styled(Box)`
  background: ${(props) => props.theme.palette.white.main};
`;

const StyledHeader = styled.div<{ quality: AccountQualityEnum }>`
  position: relative;
  background: ${(props) => `url(${AccountQualityImageSource[props.quality]})`};
  background-size: cover;
  height: ${rem(200)};
`;

const StyledHeaderContent = styled.div`
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  padding: ${(props) => props.theme.spacing(2)};
  color: ${(props) => props.theme.palette.white.main};
  font-weight: bold;
`;

const StyledContent = styled.div`
  padding: ${(props) => props.theme.spacing(2)};
`;

const StyledChangeAccountButton = styled(Button)`
  margin-top: ${(props) => props.theme.spacing(2)};
`;

interface Props extends BoxProps {
  customer: CustomerFragment;
  account: AccountFragment;
  hasSeveralAccounts: boolean;
  toAuth(): void;
}

export const MainLayoutAccountInfosDesktop = ({
  account,
  customer,
  hasSeveralAccounts,
  toAuth,
  ...boxProps
}: Props) => {
  const buildings =
    account.__typename === "LandlordAccount" ? account.buildings : [account.building];

  return (
    <StyledRootBox {...boxProps}>
      <StyledHeader quality={account.quality}>
        <StyledHeaderContent>
          <div>{getCustomerDisplayName(customer).toUpperCase()}</div>
          <div>{AccountQualityLabels[account.quality]}</div>
          <div>n° du compte : {account.number}</div>
        </StyledHeaderContent>
      </StyledHeader>
      <StyledContent>
        <ContentEstate buildings={buildings} />
        {hasSeveralAccounts && (
          <StyledChangeAccountButton variant="contained" fullWidth onClick={toAuth}>
            Changer de compte
          </StyledChangeAccountButton>
        )}
      </StyledContent>
    </StyledRootBox>
  );
};
