import { Box, BoxProps } from "@mui/material";
import { rem } from "polished";
import React, { ReactNode } from "react";
import styled, { css } from "styled-components";

import { mobileStyle } from "src/utils";

type Props = {
  icon?: ReactNode;
  title: string;
  rightNode?: ReactNode;
  disableBackground?: boolean;
} & BoxProps;

export const StyledBox = styled(Box)`
  background: ${(props) => props.theme.palette.white.main};
  ${mobileStyle`
    background-color: unset;
  `}
`;

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: ${rem(80)};
  background: ${(props) => props.theme.palette.white.main};
  align-items: center;
  color: green;
  border-bottom: ${rem(1)} solid ${(props) => props.theme.palette.background.default};

  ${mobileStyle`
    height: auto;
    flex-direction: column;
    align-items: flex-start;
  `}
`;

export const StyledTitleContainer = styled.div`
  display: flex;
  align-items: center;
  ${mobileStyle`
    width: 100%;
    justify-content: space-between;
    padding: ${(props) => props.theme.spacing(2)} ${(props) => props.theme.spacing(2)} 0;
    
  `}
`;

export const StyledRightNodeContainer = styled.div`
  ${mobileStyle`
    width: 100%;
    margin-top: ${(props) => props.theme.spacing(2)};
  `}
`;

export const StyledContent = styled.div<{ $disableBackground: boolean }>`
  ${(props) =>
    props.$disableBackground === false
      ? css`
          background: ${props.theme.palette.white.main};
        `
      : ""};

  ${mobileStyle`
    margin: 0 ${(props) => props.theme.spacing(0)};
  `}
`;

export const StyledTitle = styled.div`
  flex: 1;
  font-size: ${rem(20)};
  font-weight: 500;
  color: ${(props) => props.theme.palette.secondary.main};
  margin: 0 ${(props) => props.theme.spacing(2)};
  ${mobileStyle`
    font-size: ${rem(16)};
  `}
`;

export const Section = ({
  icon,
  title,
  rightNode,
  children,
  disableBackground = false,
  ...boxProps
}: Props) => {
  return (
    <StyledBox {...boxProps}>
      <StyledHeader>
        <StyledTitleContainer>
          {icon && <>{icon}</>}
          <StyledTitle>{title}</StyledTitle>
        </StyledTitleContainer>
        {rightNode && <StyledRightNodeContainer>{rightNode}</StyledRightNodeContainer>}
      </StyledHeader>
      <StyledContent $disableBackground={disableBackground}>{children}</StyledContent>
    </StyledBox>
  );
};
