import { Box, Typography, Button, BoxProps } from "@mui/material";
import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

import { PATH_PUBLIC_LOGIN } from "src/constants";
import { useAuthStore } from "src/stores/auth-store";

type Props = {
  title: ReactNode;
  text?: ReactNode;
  renderBackButton?: boolean;
} & Omit<BoxProps, "title">;

export const ConfirmationStepLogin = ({
  title,
  text,
  renderBackButton = true,
  ...boxProps
}: Props) => {
  const navigate = useNavigate();
  const logout = useAuthStore((state) => state.logout);

  const handleRedirect = () => {
    logout();
    navigate(PATH_PUBLIC_LOGIN);
  };

  return (
    <Box {...boxProps}>
      <Box sx={{ textAlign: "center", color: "secondary.main" }}>
        <Typography
          variant="subtitle1"
          sx={{ mb: 2, color: text ? "primary.light" : "secondary.main" }}
        >
          {title}
        </Typography>
        <Typography variant="subtitle1" sx={{ mb: 2, color: "secondary.main" }}>
          {text}
        </Typography>
      </Box>

      {renderBackButton && (
        <Box sx={{ m: 1, textAlign: "center" }}>
          <Button
            fullWidth
            size="large"
            color="primary"
            variant="contained"
            onClick={handleRedirect}
          >
            RETOUR À LA PAGE DE CONNEXION
          </Button>
        </Box>
      )}
    </Box>
  );
};
