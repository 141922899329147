import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, FormControl, InputLabel, OutlinedInput, FormHelperText } from "@mui/material";
import React from "react";
import { Controller, UseControllerProps } from "react-hook-form";

import { Dropzone, Modal, ModalFooter } from "src/components";
import { UPLOAD_PLACEHOLDER_TEXT } from "src/constants";

import { AddMessageInputs } from "../..";
import { modalInputContainer, inputTitleStyle } from "./styles";

const CreationMessageSuccess = React.lazy(
  () => import("src/pages/_shared/CreateTicket/_components/CreationSuccessMessage")
);

type Props = {
  handleCloseWithoutRedirect(): void;
  handleCloseWithRedirect(): void;
  handleSubmit(): void;
  isSuccess: boolean;
  openModal: boolean;
  addMessageLoading: boolean;
  control: UseControllerProps<AddMessageInputs>["control"];
  errorMessage?: string;
};

export const TicketModal = ({
  isSuccess,
  handleCloseWithoutRedirect,
  handleCloseWithRedirect,
  openModal,
  addMessageLoading,
  handleSubmit,
  control,
  errorMessage,
}: Props) => (
  <Modal
    title={isSuccess ? "Message envoyé " : "Ajouter un message"}
    handleClose={handleCloseWithoutRedirect}
    open={openModal}
  >
    {isSuccess ? (
      <CreationMessageSuccess handleClose={handleCloseWithRedirect} />
    ) : (
      <>
        <Box sx={modalInputContainer}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="description">Descriptif</InputLabel>
            <Controller
              name="content"
              control={control}
              rules={{
                required: "Un descriptif est requis",
                minLength: { value: 2, message: "Descriptif trop court" },
                maxLength: { value: 1000, message: "Descriptif trop long" },
              }}
              render={({ field }) => (
                <OutlinedInput
                  sx={{ backgroundColor: "white.main" }}
                  label="Descriptif"
                  multiline
                  rows={5}
                  {...field}
                />
              )}
            />
            {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
          </FormControl>
        </Box>

        <Box sx={{ mt: 5 }}>
          <Box component="p" sx={inputTitleStyle}>
            Pièces jointes
          </Box>
          <Box sx={modalInputContainer}>
            <Controller
              name="documents"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Dropzone
                  placeholder={UPLOAD_PLACEHOLDER_TEXT}
                  onChange={onChange}
                  documents={value}
                />
              )}
            />
          </Box>
        </Box>

        <ModalFooter>
          <Button variant="outlined" onClick={handleCloseWithoutRedirect}>
            Annuler
          </Button>
          <LoadingButton
            loading={addMessageLoading}
            type="submit"
            variant="contained"
            onClick={handleSubmit}
          >
            Envoyer
          </LoadingButton>
        </ModalFooter>
      </>
    )}
  </Modal>
);
