import { Box, BoxProps, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";

const StyledRoot = styled(Box)`
  background-color: #ffeed0;
  padding: ${(props) => props.theme.spacing(2)};
  color: #a67e39;
`;

export const DocumentListDownloadHelper = ({ ...boxProps }: BoxProps) => {
  return (
    <StyledRoot {...boxProps}>
      <Typography>
        Si vous ne parvenez pas à télécharger vos documents, nous vous invitons à vérifier dans les
        paramètres de votre navigateur que l'ouverture des fenêtres n'est pas bloquée.
      </Typography>
    </StyledRoot>
  );
};
